// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import Highlights from 'components/Contentful/Highlights'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'
import ProAndCon from 'components/Contentful/ProAndCon'
import Brands from 'components/Contentful/Brands'
import ProductList from 'components/Contentful/ProductList'
import Locations from 'components/Contentful/Locations'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Wärmepumpen &amp; Photovoltaik Lösung"
        meta={[
          { name: 'description', content: 'Wärmepumpe &amp; Photovoltaik Kombinations-Lösung ➤ Betriebs- &amp; Heizkosten sparen ✔️ Nachhaltig &amp; wirtschaftlich ✔️ Jetzt informieren!' },
          { name: 'keywords', content: 'Wärmepumpe Photovoltaik' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='7tVlCKOU1i7oBX3S6D4iTK-2tTJCAvkEmlSvSMp7X7TcX'
          id='waermepumpe-und-photovoltaik-das-wirtschaftliche-duo'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "2tTJCAvkEmlSvSMp7X7TcX",
    "updatedAt": "2022-03-16T09:58:57.240Z",
    "title": "Unser Kombi-Angebot: Wärmepumpe und Photovoltaik",
    "size": "Groß",
    "teaser": "Diese Kombinations-Lösung bringt Unabhängigkeit mit sich und lässt damit die Betriebs- und Heizkosten Ihrer Wärmepumpe maximal optimieren.",
    "image": {
        "description": "Herobild Solarmacher",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/1qMyxAd5y1eFg3FDpZjo06/0a477cdad699ca66b6878489931f9769/2203---Landingpage-Solarmacher---Hero-Bild.png"
    },
    "overlayImage": {
        "description": "Heizungsmacher-Solarmacher-Logo SVG",
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/6kGPOD7LFw0zwf6WyrIsmb/1884030a0ee58f257a774492ac4fb44a/Logo_AI.svg"
    },
    "links": [
        {
            "type": "link",
            "id": "7uEHZy5iE9oCmB81zLnVLQ",
            "updatedAt": "2020-09-01T20:05:21.110Z",
            "label": "Beratung anfordern",
            "url": "https://www.heizungsmacher.ch/kontakt"
        }
    ],
    "anchorName": "waermepumpe-und-photovoltaik-das-wirtschaftliche-duo"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='7tVlCKOU1i7oBX3S6D4iTK-7hkdOxvqcpf3hZ1FS96opp'
          id='ihre-komplett-loesung'
        >
          <Highlights
            {...{
    "type": "highlights",
    "id": "7hkdOxvqcpf3hZ1FS96opp",
    "updatedAt": "2022-03-22T08:31:13.195Z",
    "title": "Ihre Komplett-Lösung",
    "description": "<p><strong>Unabhängig . Nachhaltig . Wirtschaftlich</strong></p>\n<p>Eine <strong>Wärmepumpe bezieht Wärme aus erneuerbaren Umweltressourcen</strong>. Wird diese ausserdem noch mit <strong>Solarstrom vom eigenen Dach</strong> betrieben, wird man praktisch unabhängig vom Netzstrom. <strong>Profitieren Sie von unserem Komplett-Angebot</strong>.</p>\n",
    "highlights": [
        {
            "type": "highlight",
            "id": "1O8FQdiovLsbzsKIYnQrmk",
            "updatedAt": "2022-03-22T09:01:01.877Z",
            "title": "Fördergelder bis zu 40'000 Franken",
            "description": "Für Wärmepumpen wie auch für Photovoltaikanlagen gibt es schweizweit diverse Förderprogramme. Je nach Grösse, Typ und Leistung, kann für beide Anlagen zusammen bis zu einem Förderbeitrag von 40'000 Franken gerechnet werden. Profitieren Sie von unserer Komplett-Lösung und überlassen Sie uns das Beantragen der Fördergelder.",
            "image": {
                "description": "Icon - Fördergelder",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/4aCtuZczEvIKecGyvjDmsH/6f7925bd1989478f81d65c444300aab8/Icon_-_f__rderung.svg"
            }
        },
        {
            "type": "highlight",
            "id": "26Dvm9CGvyP77MLWUBKO5D",
            "updatedAt": "2022-10-17T05:47:55.316Z",
            "title": "Wir als Ihr Partner - über 1000 zufriedene und glückliche Kunden",
            "description": "Die Zeiten vom einfachen Bauen sind vorbei. Vergewissern Sie sich, dass Ihr Bauvorhaben von erfahrenen Profis begleitet und durchgeführt wird. Als Wärmepumpen und Photovoltaik Spezialisten haben wir langjährige Erfahrung mit sämtlichen Kontaktstellen und können somit rasch und autorisiert handeln.",
            "image": {
                "description": "Icon - Erfahrung",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2k2HNZC95Z09ps3M5cyOZC/b38f181bd344fe95a2a2018d1ff37531/Icon_-_erfahrung.svg"
            }
        },
        {
            "type": "highlight",
            "id": "4g81jRbsXd7MzzqoCzGm8a",
            "updatedAt": "2022-03-18T10:39:44.046Z",
            "title": "Perfekte Planung von A-Z. Sie geniessen - wir erledigen",
            "description": "Überlassen Sie uns die komplette Planung von A-Z inklusiv das Beantragen der Baugesuche und Fördergelder. Die Photovoltaik-Einbindung bei der Wärmepumpe respektive der dazugehörenden Speicherkomponenten gehört in Profihände und muss genau und korrekt geplant sein. ",
            "image": {
                "description": "Icon perfekte Planung",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5xG7RcRbNnxtf5fQeh4AoW/2cd74f6b67a2a0c168260ee2aa0563ed/Icon_-_planung.svg"
            }
        },
        {
            "type": "highlight",
            "id": "RHSKz9NqAQcrTzErRsTAO",
            "updatedAt": "2022-03-23T13:13:36.025Z",
            "title": "Starker und fairer Preis",
            "description": "Sie profitieren von einem einmaligen Preis-Leistungsverhältnis, welches wir Ihnen dank unserer intelligenten Komplett-Lösung anbieten können. Transparent und ganz ohne versteckten Kosten. ",
            "image": {
                "description": "Icon Trust - Starker Preis",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/6IaDunXx12GewDlGGIqXKf/bd5ecc0d061d4388043bd9171da0bce5/Icon_-_Trust.svg"
            }
        },
        {
            "type": "highlight",
            "id": "5j1lJMjTRgLbLkhSLWXAmx",
            "updatedAt": "2022-03-17T08:05:09.104Z",
            "title": "100% markenunabhängige und transparente Beratung",
            "description": "Mit einer markenunabhängigen Beratung stellen Sie sicher, dass Sie die geeignete Anlage für Ihr Eigenheim erhalten. Lassen Sie sich von uns beraten und profitieren Sie von unserem breiten Angebot an modernen Wärmepumpen und Photovoltaik-Anlagen von bekannten und zertifizierten Herstellern. ",
            "image": {
                "description": "icon waage - unabhängigkeit",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/6FrnhA7o8k9ZzMVmLB8xbR/7531aae216f841b43e22618e1ff7eaf6/icon_-_waage__unabh__ngigkeit_.svg"
            }
        },
        {
            "type": "highlight",
            "id": "2G14Sg3PbCJ5LAu6BLLWQW",
            "updatedAt": "2022-03-17T08:10:41.396Z",
            "title": "Eigenverbrauchs-Optimierung",
            "description": "Der selber produzierte Strom wird clever genutzt, wenn er dorthin geleitet wird, wo er am sinnvollsten eingesetzt werden kann. Photovoltaikanlagen unterstützen den Betrieb der Wärmepumpen und sorgen dafür, dass alle im Haushalt vorhandenen Stromverbraucher mit Energie versorgt werden. Sind alle Geräte versorgt, wird der Speicher aufgeladen und bei Bedarf ins Netz eingespeist und vergütet.",
            "image": {
                "description": "icon Eigenverbrauchsoptimierung",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3ONX3sjdBfOserKc3tOIiA/198a7b19e88fe711b7d5883128d5bfa2/icon_-_optimierung.svg"
            }
        }
    ],
    "anchorName": "ihre-komplett-loesung"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='7tVlCKOU1i7oBX3S6D4iTK-7iOjiOYpXbRc9Hjyi6j4TC'
          id='sind-sie-auf-der-suche-nach-dem-passenden-partner'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "7iOjiOYpXbRc9Hjyi6j4TC",
    "updatedAt": "2022-03-24T08:21:30.657Z",
    "title": "Sind Sie auf der Suche nach dem passenden Partner?",
    "anchorName": "sind-sie-auf-der-suche-nach-dem-passenden-partner",
    "image": {
        "description": "eine Grafik welche aufzeigt, ob wir der richtige Partner sind",
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/2YfqKcBGnpKRX07YHslb8X/95eb3ef39cb279ffb1e98a9149acb0bd/2203_-__sind_Sie_auf_der_Suche_nach_dem_passenden_Partner_.svg"
    },
    "link": {
        "type": "link",
        "id": "7uEHZy5iE9oCmB81zLnVLQ",
        "updatedAt": "2020-09-01T20:05:21.110Z",
        "label": "Beratung anfordern",
        "url": "https://www.heizungsmacher.ch/kontakt"
    },
    "text": ""
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='7tVlCKOU1i7oBX3S6D4iTK-03iweDO3d596i0wCUsp7q'
          id='alles-aus-einer-hand-installation-waermepumpe-und-photovoltaik'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "03iweDO3d596i0wCUsp7q",
    "updatedAt": "2022-04-01T07:44:54.422Z",
    "title": "Alles aus einer Hand - Installation Wärmepumpe und Photovoltaik",
    "anchorName": "alles-aus-einer-hand-installation-waermepumpe-und-photovoltaik",
    "text": "<p>Als Ihr einziger Ansprechpartner kümmern wir uns um die ganze Planung und Umsetzung.</p>\n",
    "image": {
        "description": "Heizungsmacher-Solarmacher - Alles aus einer Hand Bild",
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/7o19hhCGgO5LbZaS3szw38/a9aae660fc7f849251ceb6ea4c67b277/Bild_-_Alles_aus_einer_Hand.svg"
    },
    "link": {
        "type": "link",
        "id": "5Rv4CldRK0aI4UGakKsISC",
        "updatedAt": "2022-04-01T07:44:51.656Z",
        "label": "Zur Referenz-Übersicht",
        "page": {
            "slug": "referenzen"
        }
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='7tVlCKOU1i7oBX3S6D4iTK-2LEd3Cy36yhIZ2SVok7Mh3'
          id='ihre-vorteile-durch-eine-kombination-einer-photovoltaikanlage-mit-einer'
        >
          <ProAndCon
            {...{
    "type": "proAndCon",
    "id": "2LEd3Cy36yhIZ2SVok7Mh3",
    "updatedAt": "2024-05-13T19:29:35.426Z",
    "title": "Was sind die Vorteile einer Photovoltaikanlage mit einer Wärmepumpe",
    "anchorName": "ihre-vorteile-durch-eine-kombination-einer-photovoltaikanlage-mit-einer",
    "pros": [
        "Aufwertung der Immobilie",
        "Sonnenenergie ist frei verfügbar",
        "unabhängiger vom Netzstrom",
        "niedrigere Heizkosten",
        "niedrige Wartungskosten der beiden Anlagen",
        "Stromproduktion auch möglich bei wenig Sonneneinstrahlung",
        "moderne Wärmepumpen sind ideal ausgestattet, um in Verbindung mit einer Photovoltaikanlage zu laufen",
        "Reduktion der Co2 Emissionen",
        "sowohl die Wärmepumpe wie auch die Photovoltaikanlage können über eine App vom Handy oder Computer aus gesteuert werden",
        "Elektroauto mit Solarstrom aufladen"
    ],
    "link": {
        "type": "link",
        "id": "3hcA730OnlmZUp7ba85Y0o",
        "updatedAt": "2022-04-01T07:36:04.463Z",
        "label": "in den Wissens-Bereich",
        "page": {
            "slug": "wissen/waermepumpe-und-photovoltaik-ein-wirtschaftliches-duo"
        }
    },
    "text": ""
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='7tVlCKOU1i7oBX3S6D4iTK-345IUgwylugi95sMSNhdrc'
          id='mit-unserer-marke-solarmacher-sind-wir-auch-als-photovoltaik-profis'
        >
          <Brands
            {...{
    "type": "brands",
    "id": "345IUgwylugi95sMSNhdrc",
    "updatedAt": "2022-07-18T06:56:44.224Z",
    "title": "Mit unserer Marke Solarmacher sind wir auch als Photovoltaik-Profis unterwegs",
    "description": "<p>Wir setzen ausschliesslich auf qualitativ hochwertige Produkte und Systeme von bekannten Herstellern.</p>\n",
    "logos": [
        {
            "description": "SolarEdge Logo farbig",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/2spFS6dnE1lltNPSSN9XqG/0cacc728d157e6a030fcf7e830c249ad/SolarEdge_Logo_farbig-01.svg"
        },
        {
            "description": "Sunpower Logo farbig",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/5uEiaYy6XUGUMZgf8OpWXI/d666fcd76393f01a993d9581e3f8fb19/Sunpower_Logo_farbig-01.svg"
        },
        {
            "description": "",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/1OCHm3TPPcDcDa8GJ4hcEc/744c999310d37533e5009bf2c946ff25/Page_1__2_.svg"
        },
        {
            "description": "LG Chem Logo farbig",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/7MfUOtOh6MqiDhwe8NbQiq/ae444ef89347792a052a681bbcc9d40a/LG_Chem_Logo_farbig-01.svg"
        },
        {
            "description": "",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/PkbF812RpGhZVnzfXNkvH/fb87895cc25ba46e8a8e3efbe5007f15/Page_1__3_.svg"
        }
    ],
    "anchorName": "mit-unserer-marke-solarmacher-sind-wir-auch-als-photovoltaik-profis"
}} location={props.location}
          />
        </div>
<ProductList
            {...{
    "type": "productList",
    "id": "2rmeXiSNFYxAnHdBVQvAYL",
    "updatedAt": "2022-04-03T11:59:05.779Z",
    "title": "Unsere Hochleistungs-Photovoltaikmodule",
    "category": "Photovoltaikmodul Hochleistungs-Module",
    "products": {
        "metaProducts": [
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WPL 15 AS",
                        "price": 11455,
                        "id": "01t0N00000982hPQAQ",
                        "description": "Die WPL 15 AS Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 6.86 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz4bQAA",
                                "type": "pdf",
                                "size": 656765,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 15 AS Stiebel Eltron",
                                "modified": "2018-10-18T13:23:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkLRQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 15 AS",
                        "seoKeyword": "WPL 15 AS",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe (WPL 15 AS) zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. Erfahren Sie bei uns mehr!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZHQAZ",
                            "name": "Hauptbild WPL 15 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 15 AS Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 15 ACS (Kühlung)",
                        "price": 12249,
                        "id": "01t0N000009hWNKQA2",
                        "description": "Die WPL 15 ACS Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 6.86 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz41QAA",
                                "type": "pdf",
                                "size": 657801,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 15 ACS Stiebel Eltron",
                                "modified": "2018-10-18T13:23:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkKrQAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 15 ACS (Kühlung)",
                        "seoKeyword": "WPL 15 ACS",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion. ➤ Hier mehr dazu!",
                        "defaultImage": {
                            "id": "0690N00000Cj3YxQAJ",
                            "name": "Hauptbild WPL 15 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 15 ACS Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTdhQAG",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 15 ACS Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 20 A",
                        "price": 14138,
                        "id": "01t0N00000982hFQAQ",
                        "description": "Die WPL 20 A Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 9.54 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz5JQAQ",
                                "type": "pdf",
                                "size": 654783,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 20 A Stiebel Eltron",
                                "modified": "2018-10-18T13:25:31.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkM9QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 20 A",
                        "seoKeyword": "Stiebel Eltron WPL 20 A",
                        "seoDescription": "Die WPL 20 A Inverter ist eine Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZRQAZ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 20 A Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000t71cAAAQ",
                                "name": "Offertenbild Stiebel Eltron WPL 20 25 A - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 20 AC (Kühlung)",
                        "price": 14935,
                        "id": "01t0N000009iEYaQAM",
                        "description": "Die WPL 20 AC Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 9.54 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz6RQAQ",
                                "type": "pdf",
                                "size": 657844,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 20 AC Stiebel Eltron",
                                "modified": "2018-10-18T13:27:01.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkNHQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 20 AC",
                        "seoKeyword": "WPL 20 AC Stiebel eltron",
                        "seoDescription": "Inverter Luft-Wasser-Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. Weitere Informationen bekommen Sie bei uns!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZWQAZ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 20 AC Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTeVQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 20 AC Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 25 A",
                        "price": 15733,
                        "id": "01t0N00000982bwQAA",
                        "description": "Die WPL 25 A Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 12.86 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz8EQAQ",
                                "type": "pdf",
                                "size": 656543,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 25 A Stiebel Eltron",
                                "modified": "2018-10-18T13:31:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkP8QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 25 A",
                        "seoKeyword": "WPL 25 A",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. ➤ Weitere Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZbQAJ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 25 A Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTbHQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 25 A Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 25 AC (Kühlung)",
                        "price": 16530,
                        "id": "01t0N000009jdeBQAQ",
                        "description": "Die WPL 25 AC Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 12.86 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzJlQAI",
                                "type": "pdf",
                                "size": 657686,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 25 AC Stiebel Eltron",
                                "modified": "2018-10-18T13:50:27.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkaLQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 25 AC (Kühlung)",
                        "seoKeyword": "Stiebel Eltron WPL 25 AC",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe (WPL 25 AC) zur kompakten Aussenaufstellung mit niedrigem Schallleistungspegel und aktiver Kühlfunktion. Mehr hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZgQAJ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 25 AC Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTeWQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 25 AC Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher SBP 200 E",
                        "price": 950,
                        "id": "01t0N00000982j3QAA",
                        "description": "Der Standspeicher SBP 200 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit  perfekt isolierender Ummantelung.\r\n\r\nVolumen: 207 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 63 x 153.5 cm (58kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkCgQAI",
                                "type": "pdf",
                                "size": 399066,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 200 E Stiebel Eltron",
                                "modified": "2018-10-18T13:59:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxYeQAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 200 E",
                        "seoKeyword": "Stiebel Eltron SBP 200 E",
                        "seoDescription": "Der Stiebel Eltron Standspeicher eignet sich mit perfekt isolierender Ummantelung als Pufferspeicher für Wärmepumpenanlagen. ➤ Jetzt informieren!",
                        "defaultImage": {
                            "id": "0690N00000CiwNsQAJ",
                            "name": "Stiebel_Eltron_Pufferspeicher_SBP_200_400_700_WEB",
                            "alt": "Stiebel Eltron SBP 200 E Pufferspeicher (200 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGhRQAW",
                                "name": "SE_SBP_200_400E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 200 E Pufferspeicher (200 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher SBP 400 E",
                        "price": 1240,
                        "id": "01t0N00000982jbQAA",
                        "description": "Der Standspeicher SBP 400 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit perfekt isolierender Ummantelung.\r\n\r\nVolumen: 415 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 171 cm (81 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkCuQAI",
                                "type": "pdf",
                                "size": 398895,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 400 E Stiebel Eltron",
                                "modified": "2018-10-18T14:00:29.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxYsQAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 400 E",
                        "seoKeyword": "Pufferspeicher SBP 400 E",
                        "seoDescription": "Der Standspeicher SBP 400 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ✅ Isolierende Ummantelung ✅ Energieklasse B ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000CiwPtQAJ",
                            "name": "Stiebel_Eltron_Pufferspeicher_SBP_200_400_700_WEB",
                            "alt": "Stiebel Eltron SBP 400 E Pufferspeicher (400 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGgsQAG",
                                "name": "SE_SBP_200_400E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 400 E Pufferspeicher (400 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher SBP 700 E",
                        "price": 1733,
                        "id": "01t0N00000982jlQAA",
                        "description": "Der Standspeicher SBP 700 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit  perfekt isolierender Ummantelung.\r\n\r\nVolumen: 720 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 91 x 189 cm (185 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkD4QAI",
                                "type": "pdf",
                                "size": 399136,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 700 E Stiebel Eltron",
                                "modified": "2018-10-18T14:01:33.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxZ2QAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 700 E",
                        "seoKeyword": "Stiebel Eltron Pufferspeicher",
                        "seoDescription": "Der Standspeicher eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit perfekt isolierender Ummantelung Energieklasse B.",
                        "defaultImage": {
                            "id": "0690N00000Ck2h7QAB",
                            "name": "Stiebel Eltron SBP 700 E (Hauptbild)",
                            "alt": "Stiebel Eltron SBP 700 E Pufferspeicher (700 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGgEQAW",
                                "name": "SE_SBP_700E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 700 E Pufferspeicher (700 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher SBB 312.B WP",
                        "price": 2320,
                        "id": "01t0N00000982lYQAQ",
                        "description": "Standspeicher SBB 312.B mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. Wärmedämmung als PU-Direktumschäumung.\r\n\r\nVolumen: 309 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 173.8 cm (194 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzX7QAI",
                                "type": "pdf",
                                "size": 200096,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 312.B Stiebel Eltron",
                                "modified": "2021-08-10T08:24:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zknQQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 312.B WP",
                        "seoKeyword": "Stiebel Eltron Trinkwasserspeicher",
                        "seoDescription": "Standspeicher mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000CiwVhQAJ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": "Stiebel Eltron SBB 312.B WP Trinkwarmwasserspeicher (300 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UH0iQAG",
                                "name": "SE_SBB_312B_WP_Trink_Warmwasserspeicher",
                                "alt": "Stiebel Eltron SBB 312.B WP Trinkwarmwasserspeicher (300 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher  SBB 411.B WP",
                        "price": 2646,
                        "id": "01t0N00000982liQAA",
                        "description": "Standspeicher SBB 411.B mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. Wärmedämmung als PU-Direktumschäumung.\r\n\r\nVolumen: 395 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 185 cm (192 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzrBQAQ",
                                "type": "pdf",
                                "size": 201522,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:43.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zl7PQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 411.B WP",
                        "seoKeyword": "Stiebel Eltron Trinkwarmwasserspeicher",
                        "seoDescription": "SBB 411.B Standspeicher ➤ Innen liegenden Glattrohr-Wärmeübertrager ✔️ Emailliert & Verkalkungsunempfindlich ✔️ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000CiwWBQAZ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": "Stiebel Eltron SBB 411.B WP Trinkwarmwasserspeicher (400 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UH1HQAW",
                                "name": "SE_SBB_411B_WP_Trink_Warmwasserspeicher",
                                "alt": "Stiebel Eltron SBB 411.B WP Trinkwarmwasserspeicher (400 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher SBB 501 WP",
                        "price": 3081,
                        "id": "01t5p00000CQhLAAA1",
                        "description": "Standspeicher SBB 501 WP SOL mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und besitzen eine hohe Trinkwarmwasserverfügbarkeit durch abgestimmte Ein- und Ausströmtechnik.\r\n\r\nVolumen: 495 Liter\r\nMasse ohne Isolierung: ⌀xH: 69 x 198.8 cm (222 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vKByWAAW",
                                "type": "pdf",
                                "size": 208855,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 501 Stiebel Eltron - Heizungsmacher AG",
                                "modified": "2022-01-11T07:01:49.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000uizjOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 501 WP",
                        "seoKeyword": "Stiebel Eltron Trinkwarmwasserspeicher",
                        "seoDescription": "SBB 501.B Standspeicher ➤ Innen liegenden Glattrohr-Wärmeübertrager ✔️ Emailliert & Verkalkungsunempfindlich ✔️ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0695p00000uiziCAAQ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000uiziDAAQ",
                                "name": "SE_SBB_411B_WP_Trink_Warmwasserspeicher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Erweiterung WPE",
                        "price": 830,
                        "id": "01t0N000009jMZKQA2",
                        "description": "Funktionserweiterungsmodul für den WPM.\r\nRegelung von zwei zusätzlichen gemischten Heizkreisen.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzvjQAA",
                                "type": "pdf",
                                "size": 915359,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPE Stiebel Eltron",
                                "modified": "2018-10-18T15:04:24.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zlBlQAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron Erweiterung WPE ➤ FFunktionserweiterungsmodul für den WPM ✔️ Regelung von zwei zusätzlichen gemischten Heizkreisen ✔️",
                        "defaultImage": {
                            "id": "0690N00000Cj3ajQAB",
                            "name": "Hauptbild WPE Stiebel Eltron",
                            "alt": "Stiebel Eltron Wärmepumpenregler WPE Erweiterung"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000ANFQPQA5",
                                "name": "SE_WPE_Erweiterung_2",
                                "alt": "Stiebel Eltron Wärmepumpenregler WPE Erweiterung"
                            },
                            {
                                "id": "0690N000006zlDIQAY",
                                "name": "Übersicht WPM Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpenregler WPM",
                        "price": 1131,
                        "id": "01t0N00000982mHQAQ",
                        "description": "Der Wärmepumpen-Manager WPM ist für die steuer- und regelungstechnischen Abläufe der Wärmepumpe zuständig. Mit dem Gerät können Einstellungen vorgenommen und Informationen zum Betrieb der Wärmepumpe angezeigt werden.\r\n-Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xc8cQAA",
                                "type": "pdf",
                                "size": 915254,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:05:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwTQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8dQAA",
                                "type": "pdf",
                                "size": 1610169,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Meldungsliste WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwUQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8hQAA",
                                "type": "pdf",
                                "size": 1880046,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Inbetriebnahme WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:55.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwYQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8mQAA",
                                "type": "pdf",
                                "size": 4618726,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installation WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:39.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwdQAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8rQAA",
                                "type": "pdf",
                                "size": 5067872,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:08.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwiQAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron Wärmepumpenregler WPM ➤ Wärmepumpen-Manager ✔️ Steuer- und regelungstechnische Abläufe ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0690N00000Cj3aUQAR",
                            "name": "Hauptbild WPM 4 Stiebel Eltron",
                            "alt": "Stiebel Eltron Wärmepumpenregler WPM"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000ANFRDQA5",
                                "name": "SE_WPM_4_2",
                                "alt": "Stiebel Eltron Wärmepumpenregler WPM"
                            },
                            {
                                "id": "0690N000006zlDIQAY",
                                "name": "Übersicht WPM Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WPL 19",
                        "price": 15407,
                        "id": "01t0N0000098r9XQAQ",
                        "description": "Die innenaufgestellte Luft-Wasser-Wärmepumpe WPL 19 besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Trotz minimaler Schallemissionen plus besonders leisem Nachtmodus erreicht die Pumpe eine hohe Vorlauftemperatur von +65°C. Das Gerät erreicht bei einer Aussentemperatur von -7°C eine Wärmeleistung von 9.91 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdttkQAB",
                                "type": "pdf",
                                "size": 252022,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron WPL 19 I Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:39:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsuiQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OdtvUQAR",
                                "type": "pdf",
                                "size": 28899489,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation Stiebel Eltron WPL 19 24 Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:38:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NWstzQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 19",
                        "seoKeyword": "Stiebel Eltron WPL 19",
                        "seoDescription": "Die innenaufgestellte WPL 19 Luft-Wasser-Wärmepumpe besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Alle Vorteile hier!",
                        "defaultImage": {
                            "id": "0690N00000PiwOTQAZ",
                            "name": "Hauptbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                            "alt": "Stiebel Eltron WPL 19 Wärmepumpe (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwOYQAZ",
                                "name": "Offertbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                                "alt": "Stiebel Eltron WPL 19 Wärmepumpe (innen)"
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 24",
                        "price": 16969,
                        "id": "01t0N0000098r9cQAA",
                        "description": "Die innenaufgestellte Luft-Wasser-Wärmepumpe WPL 24 besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Trotz minimaler Schallemissionen plus besonders leisem Nachtmodus erreicht die Pumpe eine hohe Vorlauftemperatur von +65°C. Das Gerät erreicht bei einer Aussentemperatur von -7°C eine Wärmeleistung von 13,45 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdttlQAB",
                                "type": "pdf",
                                "size": 252431,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron WPL 24 I Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:40:28.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsujQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OdtvUQAR",
                                "type": "pdf",
                                "size": 28899489,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation Stiebel Eltron WPL 19 24 Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:38:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NWstzQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 24",
                        "seoKeyword": "Stiebel Eltron WPL 24",
                        "seoDescription": "Die innenaufgestellte Luft-Wasser-Wärmepumpe besticht durch exzellente Energieeffizienz dank moderner Invertertechnik. Alle weiteren Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000PiwOsQAJ",
                            "name": "Hauptbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                            "alt": "Stiebel Eltron WPL 24 Wärmepumpe (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwOtQAJ",
                                "name": "Offertbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                                "alt": "Stiebel Eltron WPL 24 Wärmepumpe (innen)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kombispeicher HS-BM 560L WT1",
                        "price": 5700,
                        "id": "01t0N000009ODegQAG",
                        "description": "Der Hygiene-Systemspeicher HS-BM056-18 WT1 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nVolumen: 560 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 89 (65) x 198 cm (157kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002mugQIAQ",
                                "type": "pdf",
                                "size": 1532093,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-056-18 WT1",
                                "modified": "2024-02-23T15:38:05.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jJblIAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 560L",
                        "seoKeyword": "Stiebel Eltron Kombispeicher",
                        "seoDescription": "Der multivalente Hygiene-Systemspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie. 560l Fassungsvermögen ➤ Hier weitere Infos!",
                        "defaultImage": {
                            "id": "0690N00000PiYtNQAV",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 560L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtOQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 560L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 560L WT2",
                        "price": 6473,
                        "id": "01tVj0000024OnNIAU",
                        "description": "Der Hygiene-Systemspeicher HS-BM056-18 WT2 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nMit zusätzlichem Register für einen zweiten Wärmeerzeuger (1.5 m2).\r\n\r\nVolumen: 560 Liter \r\nMasse mit (ohne) Isolierung: ⌀xH: 89 (65) x 198 cm (162 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n7teIAA",
                                "type": "pdf",
                                "size": 627868,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-056-18 WT2",
                                "modified": "2024-02-23T15:39:56.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jWsEIAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 560L",
                        "seoKeyword": "Stiebel Eltron Kombispeicher",
                        "seoDescription": "Der multivalente Hygiene-Systemspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie. 560l Fassungsvermögen ➤ Hier weitere Infos!",
                        "defaultImage": {
                            "id": "069Vj000002jKnsIAE",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000002jS75IAE",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 820L WT1",
                        "price": 6630,
                        "id": "01t0N000009OEaHQAW",
                        "description": "Der Hygiene-Systemspeicher HS-BM080-18 WT1 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nVolumen: 820 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 101 (77) x 203 cm (181 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n00yIAA",
                                "type": "pdf",
                                "size": 1537486,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-080-18 WT1",
                                "modified": "2024-02-23T15:38:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jP1AIAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 820 L",
                        "seoKeyword": "Stiebel Eltron HS-BM",
                        "seoDescription": "Der Hygiene-Systemspeicher HS-BM von Stiebel Eltron gilt als Herzstück einer Heizanlage. Alles zu seiner innovativen Technologie erfahren Sie hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYthQAF",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 820L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtQQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 820L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 820L WT2",
                        "price": 7530,
                        "id": "01t0N000009jqE4QAI",
                        "description": "Der Hygiene-Systemspeicher  HS-BM080-18 WT2 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nMit zusätzlichem Register für einen zweiten Wärmeerzeuger (2.6 m2).\r\n\r\nVolumen: 820 Liter \r\nMasse mit (ohne) Isolierung: ⌀xH: 1'100 (77) x 203 cm (191 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n7yUIAQ",
                                "type": "pdf",
                                "size": 634799,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-080-18 WT2",
                                "modified": "2024-02-23T15:39:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jWx4IAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "069Vj000002jVjFIAU",
                            "name": "Hauptbild Forstner HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000002jRPbIAM",
                                "name": "Offertbild Forstner HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 960L WT1",
                        "price": 8424,
                        "id": "01t0N00000AJECNQA5",
                        "description": "Der Hygiene-Systemspeicher HS-BM096-18 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nNenninhalt: 960 Liter\r\nDurchmesser ohne (mit) Isolation: 790mm (1030mm)\r\nHöhe mit Isolation: 2250mm\r\n\r\nVolumen: 960 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 103 (79) x 225 cm (198 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n4kVIAQ",
                                "type": "pdf",
                                "size": 1531211,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-096-18 WT1",
                                "modified": "2024-02-23T15:40:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jTr9IAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 960L",
                        "seoKeyword": "Stienel Eltron HS-BM 960",
                        "seoDescription": "Der Kombispeicher HS-BM 960 gilt als Herzstück einer Heizanlage. Alle Infos zur innovativen Technologie dieses multivalenten Schichtenspeichers hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYtLQAV",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 960L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtKQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 960L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Mutltifunktionsspeicher MFS 600 S",
                        "price": 4373,
                        "id": "01t0N0000099NBDQA2",
                        "description": "Der Multifunktionsspeicher MFS 600 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 572 Liter (7.5 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 80 (65) x 193 cm (140 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfFKQAZ",
                                "type": "pdf",
                                "size": 126296,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 600 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:22:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULydQAH",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 600 S",
                        "seoKeyword": "Alpha Innotec MFS 600 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 600 S vereint Puffer & Trinkwarmwasser in einem. Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip.",
                        "defaultImage": {
                            "id": "0690N00000NULnRQAX",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 600 S Multifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8HKQA1",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 600 S Multifunktionsspeicher"
                            }
                        ]
                    },
                    {
                        "title": "Mutltifunktionsspeicher MFS 830 S",
                        "price": 4909,
                        "id": "01t0N000009O5azQAC",
                        "description": "Der Multifunktionsspeicher MFS 830 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 846 Liter (8.7 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 199 cm (200 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfFtQAJ",
                                "type": "pdf",
                                "size": 140839,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 830 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:26:32.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULzCQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 830 S",
                        "seoKeyword": "Alpha Innotec 830 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 830 S von Alpha Innotec vereint Puffer & Trinkwarmwasser. Die Aufbereitung erfolgt im Durchlauferhitzerprinzip.",
                        "defaultImage": {
                            "id": "0690N00000NY8IhQAL",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 830 S Mutltifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8HLQA1",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 830 S Mutltifunktionsspeicher"
                            }
                        ]
                    },
                    {
                        "title": "Mutltifunktionsspeicher MFS 1000 S",
                        "price": 6069,
                        "id": "01t0N000009P7xAQAS",
                        "description": "Der Multifunktionsspeicher MFS 1000 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 928 Liter (10.9 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 214 cm (230 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfC6QAJ",
                                "type": "pdf",
                                "size": 129902,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 1000 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:27:17.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULvLQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 1000 S",
                        "seoKeyword": "MFS 1000 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 1000 S von Alpha Innotec vereint Puffer und Trinkwarmwasser in einem. ➤ Alle Infos dazu bei uns!",
                        "defaultImage": {
                            "id": "0690N00000NULvKQAX",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 1000 S Mutltifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8JaQAL",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 1000 S Mutltifunktionsspeicher"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher TPS 200",
                        "price": 1088,
                        "id": "01t0N000009PKl6QAG",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 200).\r\n\r\nVolumen: 200 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 60 x 130 cm (60 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsRQAV",
                                "type": "pdf",
                                "size": 112737,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 200 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:14:21.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXwQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 200",
                        "seoKeyword": "alpha innotec TPS 200",
                        "seoDescription": "Pufferspeicher zur Optimierung der Laufzeit & Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung.",
                        "defaultImage": {
                            "id": "0690N00000PiYY1QAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 200 - Heizungsmacher AG",
                            "alt": "NIBE TPS 200 Pufferspeicher (200 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYY0QAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 200 - Heizungsmacher AG",
                                "alt": "NIBE TPS 200 Pufferspeicher (200 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 300.11",
                        "price": 1435,
                        "id": "01t0N000009MIH7QAO",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 300.11).\r\n\r\nVolumen: 301 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 66 x 174 cm (93 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000wzrTqAAI",
                                "type": "pdf",
                                "size": 146385,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 300-11 - Heizungsmacher AG",
                                "modified": "2022-02-22T15:31:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYYPQA3",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Pufferspeicher TPS 300",
                        "seoKeyword": "Alpha Innotec TPS 300",
                        "seoDescription": "Der Alpha Innotec TPS 300 Pufferspeicher zur Optimierung der Laufzeit & Vergrösserung des Systemvolumens. ➤ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiYUoQAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 300 - Heizungsmacher AG",
                            "alt": "NIBE TPS 300 Pufferspeicher (300 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYUnQAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 300 - Heizungsmacher AG",
                                "alt": "NIBE TPS 300 Pufferspeicher (300 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 500.1",
                        "price": 1660,
                        "id": "01t0N000009MTuPQAW",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 500).\r\n\r\nVolumen: 485 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 76 x 193 cm (111 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLt5QAF",
                                "type": "pdf",
                                "size": 138757,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 500-1 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:18:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYYZQA3",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 500.1",
                        "seoKeyword": "Alpha Innotec TPS 500",
                        "seoDescription": "TPS 500.1 Pufferspeicher ➤ Optimierung der Laufzeit ✔️ Eingeschweisste Tauchhülsen ✔️  Abnehmbare Wärmedämmung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0690N00000PiYYfQAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                            "alt": "NIBE TPS 500.1 Pufferspeicher (500 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYYeQAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                                "alt": "NIBE TPS 500.1 Pufferspeicher (500 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 500.3",
                        "price": 1918,
                        "id": "01t5p00000BksjIAAR",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und abnehmbarer Wärmedämmung (baugleich Alpha Innotec TPS 500).\r\n\r\nDurchmesser ohne Isolation: 650mm\r\nKippmass ohne Isolation: 1840mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 500.3",
                        "seoKeyword": "Alpha Innotec TPS 500",
                        "seoDescription": "TPS 500.3 Pufferspeicher ➤ Optimierung der Laufzeit ✔️ Eingeschweisste Tauchhülsen ✔️  Abnehmbare Wärmedämmung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p00000j6rA1AAI",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                            "alt": "NIBE TPS 500.3 Pufferspeicher (500 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000j6rA6AAI",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                                "alt": "NIBE TPS 500.3 Pufferspeicher (500 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S2125-8",
                        "price": 10075,
                        "id": "01t0N000009M6nDQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE S2125-8 deckt einen Leistungsbereich bis 5.3 kW ab (bei A-7/W55). Leiser Betrieb von 50 dB(A) Nacht-Schallleistungspegel und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nDas in der NIBE S2125 eingesetzte Kältemittel ist umweltfreundlich und nachhaltig. Bei reinem Verdichterbetrieb werden hohe Ladetemperaturen von bis zu 75 °C erreicht. Ebenso können bei Außentemperaturen von –25 °C noch Temperaturen von bis zu 65 °C erzeugt werden. Dies ermöglicht eine ganzjährig effiziente Betriebsweise mit hohem Brauchwasserkomfort und den Betrieb mit bestehenden Heizkörpern.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014miVLAAY",
                                "type": "pdf",
                                "size": 722379,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S2125 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:39:43.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDrAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-8",
                        "seoKeyword": "Nibe F2120-8",
                        "seoDescription": "Die NIBE F2120-8 deckt einen Leistungsbereich bis 5.5 kW ab (bei A-7/W55). Merkmale sind ihr leiser Betrieb & die geringen Betriebskosten. Hier mehr!",
                        "defaultImage": {
                            "id": "0695p000014yhDvAAI",
                            "name": "Hauptbild NIBE S2125 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yhD3AAI",
                                "name": "Offertenbild NIBE S2125-8 -12 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhDwAAI",
                                "name": "Bild 1 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhDxAAI",
                                "name": "Bild 2 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S2125-12",
                        "price": 11146,
                        "id": "01t0N000009M6nNQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE S2125-12 deckt einen Leistungsbereich bis 8.2 kW ab (bei A-7/W55). Leiser Betrieb von 50 dB(A) Nacht-Schallleistungspegel und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nDas in der NIBE S2125 eingesetzte Kältemittel ist umweltfreundlich und nachhaltig. Bei reinem Verdichterbetrieb werden hohe Ladetemperaturen von bis zu 75 °C erreicht. Ebenso können bei Außentemperaturen von –25 °C noch Temperaturen von bis zu 65 °C erzeugt werden. Dies ermöglicht eine ganzjährig effiziente Betriebsweise mit hohem Brauchwasserkomfort und den Betrieb mit bestehenden Heizkörpern.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014midSAAQ",
                                "type": "pdf",
                                "size": 722379,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S2125 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:42:06.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhGpAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-12",
                        "seoKeyword": "Nibe F2120-12",
                        "seoDescription": "Die Monoblock-Ausseneinheit NIBE F2120-12 deckt einen Leistungsbereich bis 7.6 kW ab. ✅ Leiser Betrieb ✅ minimale Betriebskosten. Hier mehr!",
                        "defaultImage": {
                            "id": "0695p000014yhDsAAI",
                            "name": "Hauptbild NIBE S2125 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yhGqAAI",
                                "name": "Offertenbild NIBE S2125-8 -12 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhGuAAI",
                                "name": "Bild 1 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhGvAAI",
                                "name": "Bild 2 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe F2120-16",
                        "price": 14280,
                        "id": "01t0N000009M6nXQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE F2120-16 deckt einen Leistungsbereich bis 10.9 kW ab (bei A-7/W55). Flüsterleiser Betrieb dank einem Schallleistungspegel von 55 dB(A) und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nMeilenstein in der Effizienz mit einem SCOP grösser als 5.0. Vorlauftemperatur bis 65°C ideal für die Sanierung. Keine Vignettenpflicht da weniger als 3 kg Kältemittel.",
                        "attributes": "<ul><li>Hocheffizienz-Wärmepumpe für den Heizungstausch.</li><li>Erreicht Effizienzwerte die bisher nur mit Erdwärme möglich waren.</li><li>Bisher unerreichter Betriebsbereich mit bis zu 65 °C Ladetemperatur und 63 °C bei –25 °C Aussenlufttemperatur</li><li>Minimaler Geräuschpegel, auch bei voller Leistung.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014miabAAA",
                                "type": "pdf",
                                "size": 433933,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE F2120 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:45:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDyAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-16",
                        "seoKeyword": "Nibe F2120-16",
                        "seoDescription": "Die Monoblock-Ausseneinheit NIBE F2120-16 deckt einen Leistungsbereich bis 10.9 kW ab. Leiser Betrieb dank einem Schallleistungspegel von 55 dB(A).",
                        "defaultImage": {
                            "id": "0690N00000I36q6QAB",
                            "name": "Hauptbild NIBE F2120 -8 -12 -16 -20",
                            "alt": "NIBE F2120-16 Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I36pdQAB",
                                "name": "Offertenbild NIBE F2120 -8 -12 -16 -20",
                                "alt": "NIBE F2120-16 Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0695p000014yhKhAAI",
                                "name": "Ansicht 1 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKiAAI",
                                "name": "Ansicht 2 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKjAAI",
                                "name": "Ansicht 3 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe F2120-20",
                        "price": 15926,
                        "id": "01t0N000009M6ncQAC",
                        "description": "Die Monoblock-Ausseneinheit NIBE F2120-20 deckt einen Leistungsbereich bis 14.1 kW ab (bei A-7/W55). Flüsterleiser Betrieb dank einem Schallleistungspegel von 55 dB(A) und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nMeilenstein in der Effizienz mit einem SCOP grösser als 5.0. Vorlauftemperatur bis 65°C ideal für die Sanierung. Keine Vignettenpflicht da weniger als 3 kg Kältemittel.",
                        "attributes": "<ul><li>Hocheffizienz-Wärmepumpe für den Heizungstausch.</li><li>Erreicht Effizienzwerte die bisher nur mit Erdwärme möglich waren.</li><li>Bisher unerreichter Betriebsbereich mit bis zu 65 °C Ladetemperatur und 63 °C bei –25 °C Aussenlufttemperatur</li><li>Minimaler Geräuschpegel, auch bei voller Leistung.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mihUAAQ",
                                "type": "pdf",
                                "size": 433933,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE F2120 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:45:53.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDtAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-20",
                        "seoKeyword": "Nibe F2120-20",
                        "seoDescription": "Die NIBE F2120-20 deckt einen Leistungsbereich bis 14.1 kW ab. Flüsterleiser Betrieb und minimale Betriebskosten durch Inverter-Technologie.",
                        "defaultImage": {
                            "id": "0690N00000I36pXQAR",
                            "name": "Hauptbild NIBE F2120 -8 -12 -16 -20",
                            "alt": "NIBE F2120-20 Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I36pcQAB",
                                "name": "Offertenbild NIBE F2120 -8 -12 -16 -20",
                                "alt": "NIBE F2120-20 Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0695p000014yhDuAAI",
                                "name": "Ansicht 2 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKkAAI",
                                "name": "Ansicht 1 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKlAAI",
                                "name": "Ansicht 3 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher VVM S320",
                        "price": 6822,
                        "id": "01t0N00000BBwk3QAD",
                        "description": "Kompakte Inneneinheit NIBE VVM S320 mit sehr hohem Vorfertigungsgrad für Standardanwendungen im Einfamilienhaus. Das intelligente, anwenderfreundliche Regelgerät der NIBE VVM S320 ermöglicht eine effiziente Wärme- und Brauchwasserversorgung.\r\nVVM S320 wird in Kombination mit einer Luft-/Wasser-Wärmepumpe NIBE F2120 als zentrales Bindeglied zwischen Wärmeaufnahme, -speicherung und Wärmeabgabe eingesetzt. \r\n\r\nDie kompakte Inneneinheit ist mit einem 180 Liter Brauchwasserspeicher, einem Pufferspeicher sowie mit einem Ausdehnungsgefäß ausgestattet. Zusätzlich sind die hocheffizienz Lade- und Entladepumpen sowie eine elektrische Notheizung bereits integriert. \r\n\r\nMit integriertem WLAN fügt sich die S-Serie wie selbstverständlich in Ihr vernetztes Zuhause ein. Die intelligente Technologie passt das Raumklima automatisch an, wobei Sie via Smartphone oder Tablet die volle Kontrolle behalten. Bei minimalem Energieverbrauch genießen Sie höchsten Wohnkomfort und schonen die Umwelt.",
                        "attributes": "<ul><li>In Kombination mit einer NIBE Luft-/Wasser-Wärmepumpe bildet die NIBE VVM S320 Inneneinheit ein integriertes System für das Einfamilienhaus.</li><li>Anwenderfreundliche Touchscreen-Bedienung, integrierte Drahtlosverbindung sowie die energiesparende Smart-Technologie sorgen für höchsten Komfort.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000JA0v7QAD",
                                "type": "pdf",
                                "size": 1641419,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:16:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37aiQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000JA0vCQAT",
                                "type": "pdf",
                                "size": 514870,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:16:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37anQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000JA0vHQAT",
                                "type": "pdf",
                                "size": 4505784,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:15:55.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37asQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": "NIBE Kompaktspeicher VVM S320 (NEU)",
                        "seoKeyword": "VVM S320 Nibe",
                        "seoDescription": "Kompakte Inneneinheit NIBE VVM S320 mit sehr hohem Vorfertigungsgrad für Standardanwendungen im Einfamilienhaus. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000I37aVQAR",
                            "name": "Hauptbild NIBE VVM S320 - Heizungsmacher AG",
                            "alt": "NIBE VVM S320 Kompaktspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I37aTQAR",
                                "name": "Ansicht 1 NIBE VVM S320 - Heizungsmacher AG",
                                "alt": "NIBE VVM S320 Kompaktspeicher"
                            },
                            {
                                "id": "0690N00000I37aUQAR",
                                "name": "Ansicht 2 NIBE VVM S320 - Heizungsmacher AG",
                                "alt": "Moderne Küche mit Kompaktspeicher VVM S320 von NIBE"
                            },
                            {
                                "id": "0690N00000I37aYQAR",
                                "name": "Offertenbild NIBE VVM S320 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher WWS 405",
                        "price": 2765,
                        "id": "01t0N000009MBvoQAG",
                        "description": "Der Trinkwarmwasserspeicher WWS 405 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert durch hochwirksame Wärmedämmung die Wärmeverluste (Baugleich Alpha InnoTec). \r\n\r\nVolumen: 362 Liter (5.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 163 cm / Isolierung nicht abnehmbar\r\nKippmass: 172cm",
                        "attributes": "<ul><li>Durchmesser (mit Isolation, nicht demontierbar): 75cm</li><li>Höhe: 163cm</li><li>Kippmass: 172cm</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsTQAV",
                                "type": "pdf",
                                "size": 131119,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Trinkwarmwasserspeicher WWS 405-2 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:44:38.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXxQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLv0QAF",
                                "type": "pdf",
                                "size": 4424427,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Trinkwarmwasserspeicher WWS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:42:37.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYaGQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha innotec Trinkwarmwasserspeicher WWS 405.2",
                        "seoKeyword": "Alpha innotec WWS 405.2",
                        "seoDescription": "Der Trinkwarmwasserspeicher WWS 405 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert Wärmeverluste.",
                        "defaultImage": {
                            "id": "0690N00000Piwd5QAB",
                            "name": "Hauptbild NIBE alpha innotec WWS 405 - Heizungsmacher AG",
                            "alt": "NIBE WWS 405 Trinkwarmwasserspeicher (362 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000Piwd6QAB",
                                "name": "Offertbild NIBE alpha innotec WWS 405 - Heizungsmacher AG",
                                "alt": "NIBE WWS 405 Trinkwarmwasserspeicher (362 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WWS 507",
                        "price": 3522,
                        "id": "01t0N000009NrkwQAC",
                        "description": "Der Trinkwarmwasserspeicher WWS 507 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert durch hochwirksame Wärmedämmung die Wärmeverluste (Baugleich Alpha InnoTec).\r\n\r\nDurchmesser (mit Isolation, nicht demontierbar): 75cm\r\nKippmass: 203cm\r\nRegisterfläche: 7.0m2",
                        "attributes": "<ul><li>Durchmesser (mit Isolation, nicht demontierbar): 75cm</li><li>Höhe: 196cm</li><li>Kippmass: 203cm</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsUQAV",
                                "type": "pdf",
                                "size": 169890,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Trinkwarmwasserspeicher WWS 507-2 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:45:47.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXyQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLv0QAF",
                                "type": "pdf",
                                "size": 4424427,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Trinkwarmwasserspeicher WWS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:42:37.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYaGQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Trinkwarmwasserspeicher WWS 507.2",
                        "seoKeyword": "Alpha Innotec wws 507.2",
                        "seoDescription": "Der Trinkwarmwasserspeicher WWS 507 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert Wärmeverluste.",
                        "defaultImage": {
                            "id": "0690N00000PiwkKQAR",
                            "name": "Hauptbild NIBE alpha innotec WWS 507 - Heizungsmacher AG",
                            "alt": "NIBE WWS 507 Trinkwarmwasserspeicher (432 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwkLQAR",
                                "name": "Offertbild NIBE alpha innotec WWS 507 - Heizungsmacher AG",
                                "alt": "NIBE WWS 507 Trinkwarmwasserspeicher (432 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe LICV 82R1/3",
                        "price": 13860,
                        "id": "01t0N000009MCeHQAW",
                        "description": "Mit der neuen LICV 8.2R1/3 (innen) von NIBE (baugleich Novelan und Alpha Innotec LWCV) entscheiden Sie sich für eine vielseitige Modullösung. Sehr leise 38 dB(A) Schallleistung im Nachtbetrieb. 6.4 kW Heizleistung bei einer Aussentemperatur von -7°C.\r\n\r\nDie Geräte sind werkseitig mit folgenden Komponenten ausgestattet:\r\n– Wärmepumpenregler Luxtronik 2.1\r\n– Energieeffizienz-Umwälzpumpe für Heizung\r\n– Umschaltventil Heizung/ Trinkwasser\r\n– Überströmventil für den Heizkreis\r\n– Pufferspeicher 82 Liter für Heizung\r\n– Elektroheizelement zur Unterstützung der Heizung und Brauchwarmwasserladung\r\n– Ausdehnungsgefäss (18l)\r\n– Wärmemengenerfassung\r\n– Schwingungsentkopplung für den Heizkreis",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vLylYAAS",
                                "type": "pdf",
                                "size": 599943,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE LICV - Heizungsmacher AG",
                                "modified": "2022-01-21T09:11:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000ukjnzAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe LICV 82R1/3",
                        "seoKeyword": "Nibe LICV",
                        "seoDescription": "Mit der neuen LICV-Serie von NIBE entscheiden Sie sich für eine vielseitige und sehr leise Modullösung. ➤ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiwBUQAZ",
                            "name": "Offertbild LICV - Heizungsmacher AG",
                            "alt": "NIBE Wärmepumpe LICV 8.2R1/3 (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwBPQAZ",
                                "name": "Bild 1 LICV - Heizungsmacher AG",
                                "alt": "Einzelteile der NIBE Wärmepumpe LICV 8.2R1/3 (innen)"
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe LICV 122R1/3",
                        "price": 15408,
                        "id": "01t0N000009NbvBQAS",
                        "description": "Mit der neuen LICV 12.2R1/3 (innen) von NIBE (baugleich Novelan und Alpha Innotec LWCV) entscheiden Sie sich für eine vielseitige Modullösung. Leise 43dB(A) Schallleistung im Nachtbetrieb. 8.5 kW Heizleistung bei einer Aussentemperatur von -7°C. Die Geräte sind werkseitig mit folgenden Komponenten ausgestattet:\r\n– Wärmepumpenregler Luxtronik 2.1\r\n– Energieeffizienz-Umwälzpumpe für Heizung\r\n– Umschaltventil Heizung/ Trinkwasser\r\n– Überströmventil für den Heizkreis\r\n– Pufferspeicher 82 Liter für Heizung\r\n– Elektroheizelement zur Unterstützung der Heizung und Brauchwarmwasserladung\r\n– Ausdehnungsgefäss (12l)\r\n– Wärmemengenerfassung\r\n– Schwingungsentkopplung für den Heizkreis",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vLylYAAS",
                                "type": "pdf",
                                "size": 599943,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE LICV - Heizungsmacher AG",
                                "modified": "2022-01-21T09:11:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000ukjnzAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe LICV 122R1/3",
                        "seoKeyword": "Nibe LICV 122R1/3",
                        "seoDescription": "Mit der neuen LICV-Serie von NIBE entscheiden Sie sich für eine vielseitige Modullösung. Alle Vorteile erfahren Sie bei uns!",
                        "defaultImage": {
                            "id": "0690N00000PiwAqQAJ",
                            "name": "Offertbild LICV - Heizungsmacher AG",
                            "alt": "NIBE Wärmepumpe LICV 12.2R1/3 (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000Piw86QAB",
                                "name": "Bild 2 LICV Einzelteile - Heizungsmacher AG",
                                "alt": "Einzelteile der NIBE Wärmepumpe LICV 12.2R1/3 (innen)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hydraulikmodul Vitocal 200-A",
                        "price": 2666,
                        "id": "01t0N000009OlebQAC",
                        "description": "Inneneinheit mit Wärmepumpenregelung Vitotronic 200, Hocheffizienz- Umwälzpumpe für den Sekundärkreis, eingebautem Heizwasser- Durchlauferhitzer (Notheizung), 3- Wege- Umschaltventil und Sicherheitsgruppe. Inklusive benötigter Temperatursensoren und Vitoconnect 100, Typ OPTO1.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MekCDQAZ",
                                "type": "pdf",
                                "size": 343061,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Viessmann Vitocal 200-A - Heizungsmacher AG",
                                "modified": "2020-04-22T12:33:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NVQsmQAH",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Hydraulikmodul Vitocal 200-A ➤ Inneneinheit mit Wärmepumpenregelung ✔️ Hocheffizienz- Umwälzpumpe ✔️ Temperatursensoren ✔️",
                        "defaultImage": {
                            "id": "0690N0000072DZrQAM",
                            "name": "Hauptbild Viessmann Hydraulikmodul Vitocal-200",
                            "alt": "Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072DZvQAM",
                                "name": "Schnitt Viessmann Hydraulikmodul Vitocal-200",
                                "alt": "Schnitt Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                            },
                            {
                                "id": "0690N00000NVQrPQAX",
                                "name": "Offertbild Viessmann Hydraulikeinheit Vitocal-200 - Heizungsmacher AG",
                                "alt": "Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                            },
                            {
                                "id": "0690N00000NV8bOQAT",
                                "name": "Vicare-App",
                                "alt": null
                            },
                            {
                                "id": "0690N00000NV8bTQAT",
                                "name": "Vitotronic-Regelung",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hochleistungs-Photovoltaikmodul MAXEON (430Wp)",
                        "price": 386,
                        "id": "01t5p00000CRBDmAAP",
                        "description": "Mit über 30-Jahre Produkterfahrung gehört SunPower zu den Pionierunternehmen der Solarbranche und konnte schon mit Projekten für die NASA oder Solar Impulse seine Qualität unter Beweis stellen. Solarexperten wissen, dass SunPower Module die widerstandsfähigsten auf dem Markt sind. Mit 25 Jahre Produkt-und Leistungs Garantie ist SunPower die Benchmark bei Garantieleistungen und dadurch die Referenz für Zuverlässigkeit und Langlebigkeit. Die Maxeon® Solarzellen sind grundlegend anders und besser. Durch die Kupferbasis bieten die Solarzellen eine beispiellose Zuverlässigkeit und ermöglichen die Entwicklung von Modulen mit einem der weltweit höchsten Wirkungsgrade im Markt.\r\n\r\nModulwirkungsgrad: 22.70 %\r\nLänge: 1812 mm\r\nBreite: 1046 mm",
                        "attributes": "<ul><li>Modulleistung<ul><li>430 Wp</li></ul></li><li>Typ<ul><li><span style=\"font-size: 13px;\">SunPower MAXEON 3</span></li></ul></li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001Apgs7AAB",
                                "type": "pdf",
                                "size": 734161,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Sunpower Maxeon 3 430 Wp - Heizungsmacher AG",
                                "modified": "2022-10-21T10:58:41.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001AxU7JAAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Sunpower",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Hochleistungs-Module"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Sunpower Hochleistungs-Photovoltaikmodul MAXEON (430Wp) ➤ Kupferbasis bietet eine beispiellose Zuverlässigkeit ✔️ SunPower MAXEON 3 ✔️",
                        "defaultImage": {
                            "id": "0695p00000wr3MOAAY",
                            "name": "Hauptbild Sunpower Maxeon 3 430 Wp - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Hochleistungs-Photovoltaikmodul MAXEON (420Wp Schwarz)",
                        "price": 386,
                        "id": "01t5p00000CRpq2AAD",
                        "description": "Mit über 30-Jahre Produkterfahrung gehört SunPower zu den Pionierunternehmen der Solarbranche und konnte schon mit Projekten für die NASA oder Solar Impulse seine Qualität unter Beweis stellen. Solarexperten wissen, dass SunPower Module die widerstandsfähigsten auf dem Markt sind. Mit 25 Jahre Produkt-und Leistungs Garantie ist SunPower die Benchmark bei Garantieleistungen und dadurch die Referenz für Zuverlässigkeit und Langlebigkeit. Die Maxeon® Solarzellen sind grundlegend anders und besser. Durch die Kupferbasis bieten die Solarzellen eine beispiellose Zuverlässigkeit und ermöglichen die Entwicklung von Modulen mit einem der weltweit höchsten Wirkungsgrade im Markt.\r\n\r\nModulwirkungsgrad: 22.20 %\r\nLänge: 1812 mm\r\nBreite: 1046 mm",
                        "attributes": "<ul><li>Modulleistung<ul><li>420 Wp</li></ul></li><li>Typ<ul><li><span style=\"font-size: 13px;\">SunPower MAXEON 3 BLACK</span></li></ul></li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001ApgwXAAR",
                                "type": "pdf",
                                "size": 693284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Sunpower Maxeon 3 420 Wp black - Heizungsmacher AG",
                                "modified": "2022-10-21T10:58:02.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001AxUBeAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Sunpower",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Hochleistungs-Module"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Sunpower Hochleistungs-Photovoltaikmodul MAXEON (420Wp Schwarz) ➤ SunPower MAXEON 3 BLACK ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p00000zfEU6AAM",
                            "name": "Sunpower Maxeon 3 420 Wp Black - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpenregler SMO S40",
                        "price": 1425,
                        "id": "01t5p00000BozQFAAZ",
                        "description": "Der neue SMO S40 ist ein äusserst flexibel einsetzbares Regelgerät für NIBE Wärmepumpen, das sehr viele Anwendungsmöglichkeiten abdeckt. Umfangreiche, übersichtliche Einstellungen und die integrierte Internetverbindung via www.myuplink.com runden das Einsatzspektrum ab.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000XXJ7KAAX",
                                "type": "pdf",
                                "size": 617382,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE SMO S40 - Heizungsmacher AG",
                                "modified": "2020-12-08T08:39:46.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jB4AAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p00000XXJ7LAAX",
                                "type": "pdf",
                                "size": 188054,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE MyUplink Internetverbindung - Heizungsmacher AG",
                                "modified": "2020-12-08T08:39:32.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jB5AAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p00000XXJ7UAAX",
                                "type": "pdf",
                                "size": 3917056,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE SMO S40 - Heizungsmacher AG",
                                "modified": "2020-12-08T08:37:44.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jBEAAZ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "NIBE Wärmepumpenregler SMO S40 ➤ Flexibel einsetzbares Regelgerät ✔️ Viele Anwendungsmöglichkeiten ✔️ NIBE ✔️",
                        "defaultImage": {
                            "id": "0695p00000X9jBAAAZ",
                            "name": "Hauptbild NIBE SMO S40 - Heizungsmacher AG",
                            "alt": "NIBE SMO S40 Wärmepumpenregler"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000X9jB9AAJ",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": "Smartphone mit Applikation zum NIBE SMO S40 Wärmepumpenregler"
                            },
                            {
                                "id": "0695p00000X9jBJAAZ",
                                "name": "Offertenbild NIBE SMO S40 Regler - Heizungsmacher AG",
                                "alt": "NIBE SMO S40 Wärmepumpenregler"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S1155-6",
                        "price": 11310,
                        "id": "01t0N00000BCnC8QAL",
                        "description": "NIBE S1155-6 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 139</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-6",
                        "seoKeyword": "Nibe s1155-6",
                        "seoDescription": "Die NIBE S1155 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Mehr dazu hier!",
                        "defaultImage": {
                            "id": "0690N00000L5U9bQAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-6"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U9gQAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-6"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-6 PC",
                        "price": 13284,
                        "id": "01t0N00000BCnCFQA1",
                        "description": "NIBE S1155-6 PC ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung, Kühlung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 139</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "NIBE Wärmepumpe S1155-6 PC - Erfahren Sie bei Heizungsmacher alle Infos über dieses Produkt und fordern Sie sofort einen Expertenrat an!",
                        "defaultImage": {
                            "id": "0690N00000L5U9qQAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U9vQAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-12",
                        "price": 13675,
                        "id": "01t0N00000BCnByQAL",
                        "description": "NIBE S1155-12 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 3–12 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 167</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-12",
                        "seoKeyword": "Nibe S1155-12",
                        "seoDescription": "NIBE S1155 ist eine intelligente Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird nach Bedarf gewählt.",
                        "defaultImage": {
                            "id": "0690N00000L5U97QAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-12"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U98QAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-12"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-16",
                        "price": 14149,
                        "id": "01t0N00000BCc7aQAD",
                        "description": "NIBE S1155-16 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 4–16 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620 </li><li>Leergewicht (kg)172 </li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-16",
                        "seoKeyword": "Nibe S1155-16",
                        "seoDescription": "NIBE S1155 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Alle Infos bei uns!",
                        "defaultImage": {
                            "id": "0690N00000L4RGIQA3",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-16"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L4RInQAN",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-16"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S1255-6",
                        "price": 14396,
                        "id": "01t0N00000BCnCkQAL",
                        "description": "NIBE S1255-6 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 220</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-6",
                        "seoKeyword": "Nibe S1255-6",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung. Hier alle Infos!",
                        "defaultImage": {
                            "id": "0690N00000L5UDjQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDkQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-12",
                        "price": 15716,
                        "id": "01t0N00000BCnChQAL",
                        "description": "NIBE S1255-12 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 3–12 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 250</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-12",
                        "seoKeyword": "Nibe s1255-12",
                        "seoDescription": "NIBE S1255 ist eine intelligente Erdwärmepumpe mit integrierter Brauchwasserbereitung. Die Wärmepumpe bietet höchstes Einsparpotenzial. ➤ Mehr hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UDnQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-12"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDsQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-12"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-6 PC",
                        "price": 16331,
                        "id": "01t0N00000BCnCrQAL",
                        "description": "NIBE S1255-6 PC ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Kühlung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 220</li><li>Mit integrierter Kühlung</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-6 PC",
                        "seoKeyword": "Nibe s1255 PC",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe mit integrierter Brauchwasserbereitung. ➤ Weitere Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UDdQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6 PC"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDiQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6 PC"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-16",
                        "price": 17109,
                        "id": "01t0N00000BCnCNQA1",
                        "description": "NIBE S1255-16 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 4–16 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 255 </li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-16",
                        "seoKeyword": "Nibe S2155-16",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung. Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UCLQA3",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-16"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UCMQA3",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-16"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher HSBC 300 L",
                        "price": 5655,
                        "id": "01t0N000009MR6PQAW",
                        "description": "Beim Integralspeicher HSBC 300 L cool stehen nicht zwei Einzelspeicher nebeneinander, sondern Trinkwarmwasser- und Pufferspeicher sind übereinander in einem Gerät angeordnet. Der Platzbedarf wird so quasi halbiert und eine Aufstellung auf engstem Raum wird möglich – für mehr Nutzfläche.\r\n\r\nPräzise abgestimmt auf die Nutzung mit einer Wärmepumpe deckt der HSBC L cool alles ab, was man in einem Einfamilienhaus in Bezug auf Wärme und Trinkwarmwasser benötigt.\r\n\r\nNenninhalt Trinkwarmwasser: 270 Liter\r\nNenninhalt Pufferspeicher: 100 Liter",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdtNMQAZ",
                                "type": "pdf",
                                "size": 129463,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron HSBC 300 L Kompaktspeicher - Heizungsmacher AG",
                                "modified": "2022-05-17T09:31:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsRSQA1",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kompaktspeicher HSBC 300 L cool",
                        "seoKeyword": "Stiebel Eltron HSBC 300 L cool",
                        "seoDescription": "Beim Integralspeicher HSBC 300 L cool sind Trinkwarmwasser- und Pufferspeicher platzsparend übereinander in einem Gerät angeordnet. Hier alle Infos!",
                        "defaultImage": {
                            "id": "0690N00000NWsTNQA1",
                            "name": "Offertenbild Stiebel Eltron HSBC 300 L - Heizungsmacher AG",
                            "alt": "Stiebel Eltron HSBC 300 L Kompaktspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014zaqOAAQ",
                                "name": "Hauptbild Stiebel Eltron HSBC 300 L - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "WPL 09 ICS classic",
                        "price": 12579,
                        "id": "01t5p00000CQ9x7AAD",
                        "description": "Diese Luft-Wasser-Wärmepumpe ist für die kompakte Innenaufstellung konzipiert. Hohe Flexibilität bei Aufstellung und Installation Invertertechnologie für hohe Effizienz und niedrige Energiekosten. \r\n- Integrierter Wärmepumpenmanager\r\n- Vorlauftemperaturen von bis zu 60 °C\r\n- Max. Heizleistung bei A-7/W35: 4,23 kW\r\n- Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aJKOAA2",
                                "type": "pdf",
                                "size": 142945,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt WPL 09 ICS classic - Heizungsmacher AG",
                                "modified": "2022-06-07T06:06:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000013VmHHAA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron WPL 09 ICS classic ➤ Luft/Wasser-Wärmepumpe ✔️ Kompakte Innenaufstellung ✔️ Hohe Effizienz ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013VmEAAA0",
                            "name": "Hauptbild WPL 09 17 ICS classic - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000013VmHIAA0",
                                "name": "Offertenbild Stiebel Eltron WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000013VmJ8AAK",
                                "name": "Bild 2 WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WPL 17 ICS classic",
                        "price": 14718,
                        "id": "01t0N00000A3Cd0QAF",
                        "description": "Diese Luft-Wasser-Wärmepumpe ist für die kompakte Innenaufstellung konzipiert. Hohe Flexibilität bei Aufstellung und Installation Invertertechnologie für hohe Effizienz und niedrige Energiekosten.\r\n- Integrierter Wärmepumpenmanager\r\n- Vorlauftemperaturen von bis zu 60 °C\r\n- Max. Heizleistung bei A-7/W35: 8.02 kW\r\n- Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aJKjAAM",
                                "type": "pdf",
                                "size": 142325,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt WPL 17 ICS classic - Heizungsmacher AG",
                                "modified": "2022-06-07T06:08:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000013VmHhAAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron WPL 17 ICS classic ➤ Luft/Wasser-Wärmepumpe ✔️ Hohe Flexibilität ✔️ Invertertechnologie für hohe Effizienz ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmHbAAK",
                            "name": "Hauptbild WPL 09 17 ICS classic - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000013VmHgAAK",
                                "name": "Offertenbild Stiebel Eltron WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000013VmJ9AAK",
                                "name": "Bild 2 WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Batteriespeicher RESU10",
                        "price": 5320,
                        "id": "01t0N00000BCkSzQAL",
                        "description": "Die kompakte und leichtgewichtige Natur des RESU ist erstklassig. Das System ist besonders einfach zu installieren. Hochwertiger Niedervolt-Akku vom Markenhersteller LG. \r\n\r\nNutzbare Kapazität: 8.8 kWh bei 90% Entladetiefe.\r\nMax. DC-Ausgangsleistung: 5.0 kW",
                        "attributes": "<ul><li>Nutzbare Kapazität: 8.8 kWh bei 90% Entladetiefe.</li><li>DC-Ausgangsleistung: 5.0 kW</li><li>DC-Nennspannung: 51.80 V</li><li>Batterietechnologie: Lithium-Nickel-Mangan-Kobaltoxid</li><li>Länge: 452 mm</li><li>Breite: 227 mm</li><li>Höhe: 483 mm</li><li>Gewicht: 75 kg</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014kOzQAAU",
                                "type": "pdf",
                                "size": 281238,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt LG Chem RESU 65 10 12 - Heizungsmacher AG",
                                "modified": "2022-07-04T22:01:50.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wRYKAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "LG Chem",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "LG Chem Batteriespeicher RESU10 ➤ Kompakt und leichtgewichtig ✔️ Einfache Installation ✔️ Hochwertiger Niedervolt-Akku ✔️",
                        "defaultImage": {
                            "id": "0695p00000hP44YAAS",
                            "name": "RESU 10",
                            "alt": "LG Chem Batteriespeicher RESU10"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5ONEQA3",
                                "name": "Bild 1 LG Chem Akku RESU 6.5-10 - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Batteriespeicher RESU12",
                        "price": 6840,
                        "id": "01t5p00000CRBInAAP",
                        "description": "Die kompakte und leichtgewichtige Natur des RESU ist erstklassig. Das System ist besonders einfach zu installieren. Hochwertiger Niedervolt-Akku vom Markenhersteller LG. \r\n\r\nNutzbare Kapazität: 11.7 kWh bei 90% Entladetiefe.\r\nMax. DC-Ausgangsleistung: 5.0 kW",
                        "attributes": "<ul><li>Nutzbare Kapazität: 11.7 kWh bei 90% Entladetiefe.</li><li>DC-Ausgangsleistung: 5.0 kW</li><li>DC-Nennspannung: 51.80 V</li><li>Batterietechnologie: Lithium-Nickel-Mangan-Kobaltoxid</li><li>Länge: 452 mm</li><li>Breite: 227 mm</li><li>Höhe: 625 mm</li><li>Gewicht: 75 kg</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014kOzQAAU",
                                "type": "pdf",
                                "size": 281238,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt LG Chem RESU 65 10 12 - Heizungsmacher AG",
                                "modified": "2022-07-04T22:01:50.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wRYKAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "LG Chem",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "LG Chem Batteriespeicher RESU12 ➤ Kompakt & leichtgewichtig ✔️ Einfache Installation ✔️ Hochwertiger Niedervolt-Akku ✔️",
                        "defaultImage": {
                            "id": "0695p00000wr8GfAAI",
                            "name": "LG RESU 12",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5ONEQA3",
                                "name": "Bild 1 LG Chem Akku RESU 6.5-10 - Solarmacher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE5K-RWS 48V",
                        "price": 2710.1,
                        "id": "01t0N00000BBqAFQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 5 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE5K-RWS 48V\r\n ➤ Einfache Installation ✔️ Dreiphasige Installationen mit Batteriespeicher 5 kVA ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LG4QAN",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LG9QAN",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE7K-RWS 48V",
                        "price": 2946.3,
                        "id": "01t0N00000BBqAKQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 7 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE7K-RWS 48V\r\n ➤ Ein einziger Wechselrichter ✔️ Dreiphasige Installationen ✔️",
                        "defaultImage": {
                            "id": "0695p00001Q1a7MAAR",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGYQA3",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE8K-RWS 48V",
                        "price": 3029.2,
                        "id": "01t0N00000BBqAUQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 8 kVA\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE8K-RWS 48V ➤ Dreiphasige Installationen mit Batteriespeicher 8 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LGZQA3",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGAQA3",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE10K-RWS 48V",
                        "price": 3206.9,
                        "id": "01t0N00000BBqAZQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 10 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE10K-RWS 48V ➤ Dreiphasige Installationen mit Batteriespeicher 10 kVA ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LGnQAN",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGaQAN",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Kombispeicher SBS 601 W",
                        "price": 3770,
                        "id": "01t0N0000098rATQAY",
                        "description": "SBS 601 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung. \r\n\r\nNenninhalt: 613 Liter\r\nDurchmesser ohne Isolation: 750mm\r\nDurchmesser mit Isolation: 970mm\r\nHöhe mit Isolation: 1775mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMKJQA3",
                                "type": "pdf",
                                "size": 299445,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 601 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:23:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYwvQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 601 W",
                        "seoKeyword": "Stiebel Eltron SBS 601 W",
                        "seoDescription": "SBS 601 Heizungs-Pufferspeicher ✅ integrierte Trinkwarmwasserbereitung ✅ hygienische Warmwasseraufbereitung. Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiZ2UQAV",
                            "name": "Hauptbild SBS 600 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 601 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072G5jQAE",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 601 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYufQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 601 W"
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher SBS 801 W",
                        "price": 3959,
                        "id": "01t0N0000098rAdQAI",
                        "description": "SBS 801 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung.\r\n\r\nNenninhalt: 759 Liter\r\nDurchmesser ohne Isolation: 790mm\r\nDurchmesser mit Isolation: 1010mm\r\nHöhe mit Isolation: 1940mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMHFQA3",
                                "type": "pdf",
                                "size": 299276,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 801 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:13:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYviQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 801 W",
                        "seoKeyword": "SBS 801 W",
                        "seoDescription": "Stiebel Eltron SBS 801 Heizungs-Pufferspeicher ✅ Trinkwarmwasserbereitung im Durchflussbetrieb ✅ hygienische Warmwasseraufbereitung. Mehr dazu hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYvoQAF",
                            "name": "Hauptild SBS 800 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 801 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072GQIQA2",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 801 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYvnQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 801 W"
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher SBS 1001 W",
                        "price": 4676,
                        "id": "01t0N0000098rAnQAI",
                        "description": "SBS 1001 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung.\r\n\r\nNenninhalt: 941 Liter\r\nDurchmesser ohne Isolation: 790mm\r\nDurchmesser mit Isolation: 1010mm\r\nHöhe mit Isolation: 2350mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMGhQAN",
                                "type": "pdf",
                                "size": 299337,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 1001 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:15:54.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYtjQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 1001 W",
                        "seoKeyword": "Kombispeicher SBS 1001 W",
                        "seoDescription": "SBS 1001 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Garantierte hygienische Warmwasseraufbereitung.",
                        "defaultImage": {
                            "id": "0690N00000PiYugQAF",
                            "name": "Hauptbild SBS 1000 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 1001 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072H69QAE",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 1001 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYtiQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 1001 W"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Meyer Burger Black 390 Wp",
                        "price": 252,
                        "id": "01t5p00000D3R3VAAV",
                        "description": "Das neue Meyer Burger Heterojunction Modul bietet bis zu 20 Prozent mehr Energieertrag. Produktion der Solarzellen und -module nach höchsten Standards ausschließlich in Deutschland mit Schweizer Technologie.\r\n\r\nDie Meyer Burger HJT-Zellen nehmen deutlich mehr Sonnenenergie als herkömmliche Zellen auf und der umgewandelte elektrische Strom fliesst mit weniger Widerstand durch das Modul. Auch bei schwachem Sonnenlicht, beispielsweise wenn es bewölkt ist.\r\n\r\nZusätzliche Effizienzgewinne bringt die Verbindung der Zellen durch unsere patentierte SmartWire-Technologie. Denn die hauchdünnen Verbindungsdrähte verringern die Abschattung auf den Solarzellen um bis zu 30 Prozent. Die Module nehmen so mehr Energie auf und produzieren länger Strom. Für spürbar mehr Leistung pro Fläche auf dem Dach. Und nicht nur das.\r\n\r\nSWCT verbessert auch die Zellstabilität und macht die Module weniger anfällig für Mikrorisse, einem der häufigsten Gründe für Energieverluste bei Solarmodulen.\r\n\r\nModulwirkungsgrad: 21.5 %\r\nLänge: 1767 mm\r\nBreite: 1041 mm\r\nHöhe: 35 mm\r\nGewicht: 19.7 kg",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0lJvAAJ",
                                "type": "pdf",
                                "size": 630080,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Meyer Burger Black - Heizungsmacher AG",
                                "modified": "2023-06-09T11:28:37.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001I3hTOAAZ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Meyer Burger",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Made-in-Germany"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Meyer Burger Black 390 Wp Photovoltaikmodul - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001I3hTiAAJ",
                            "name": "Hauptbild Meyer Burger Photovoltaikmodul Black - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher PU 200",
                        "price": 752,
                        "id": "01t5p00000CMHlMAAX",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 190 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 60 x 121 cm (43 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 200 - Alle Produktinformationen bei Heizungsmacher ansehen, Vergleiche ziehen und persönliche Beratung erhalten!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yxTaAAI",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 300",
                        "price": 912,
                        "id": "01t5p00000CLuqtAAD",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 282 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 65 x 147 cm (61 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 300 - Bei Heizungsmacher finden Sie nicht nur spezifische Produktdetails, sondern erhalten auch eine umfassende Beratung!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXRaAAM",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 400",
                        "price": 1195,
                        "id": "01t5p00000D1MfQAAV",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 377 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 140 cm (70 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 400 - Sichern Sie sich jetzt bei Heizungsmacher wertvolle Infos über die Produktdetails und fordern Sie eine Beratung an!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Fe5E1AAJ",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 500",
                        "price": 1224,
                        "id": "01t5p00000CwnWpAAJ",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 479 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 174 cm (80 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 500 - Alle Produktdetails im detaillierten Überblick bei Heizungsmacher und direkte Expertenberatung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001AJL5cAAH",
                                "name": "hpa Puffer-neu",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PSF 1000",
                        "price": 2998,
                        "id": "01tVj000006Qp8IIAS",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, Isolierung aus Hightech Faservlies. Skaimantel mit Reissverschluss, inklusive Rosetten und Abdeckhaube. Silber.\r\n\r\nVolumen: 887 Liter\r\nMasse ohne Isolierung: ⌀xH: 79 x 204 cm (111kg) (ohne Isolierung)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B2QcnIAF",
                                "type": "pdf",
                                "size": 339263,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PSF - Heizungsmacher AG",
                                "modified": "2024-10-26T13:01:01.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj00000AsdD7IAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 500 - Alle Produktdetails im detaillierten Überblick bei Heizungsmacher und direkte Expertenberatung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj00000AscowIAB",
                                "name": "PSF1000 Offertbild",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 300",
                        "price": 1955,
                        "id": "01t5p00000CMHmTAAX",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 304 Liter (3.2 m² Registeroberfläche)\r\nMasse mit/ohne Isolierung: ⌀xH: 65 x 157 cm (141 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 300 - Hier bei Heizungsmacher genau über das Produkt informieren und eine umfassende Beratung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yy3eAAA",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 400",
                        "price": 2318,
                        "id": "01t5p00000CLutmAAD",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 408 Liter (4.3 m² Registeroberfläche)\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 150 cm (179 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 400 - Bei Heizungsmacher alle Produktdetails erhalten und eine kompetente und persönliche Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXbyAAE",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 500",
                        "price": 2977,
                        "id": "01t5p00000D1R1IAAV",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 498 Liter (5.4 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 180 cm (217 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p00001DLYPqAAP",
                            "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXZ9AAM",
                                "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 600",
                        "price": 3069,
                        "id": "01tVj000005aQ9BIAU",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 559 Liter (5.4 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 200 cm (228kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000009AEWDIA4",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 800",
                        "price": 4898,
                        "id": "01tVj000003BxB8IAK",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. Mehrschicht-Isolierung aus 80 mm Hartschaum + 20 mm Faservlies. PS-Mantel und Hakenleiste, inklusive Rosetten und Abdeckhaube. Brandschutzklasse B2. Silber\r\n\r\nVolumen: 830 Liter (6.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 199 cm (291 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000004q6LeIAI",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG (2)",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 1000",
                        "price": 5680,
                        "id": "01t5p00000CxBO2AAN",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. Mehrschicht-Isolierung aus 80 mm Hartschaum + 20 mm Faservlies. PS-Mantel und Hakenleiste, inklusive Rosetten und Abdeckhaube. Brandschutzklasse B2. Silber\r\n\r\nVolumen: 925 Liter (6.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 219 cm (308 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 1000 - Bei Heizungsmacher tiefgehende Produktdetails einsehen und sofort einen Expertenrat anfordern!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001ALDEBAA5",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG (1)",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WSW196i.2-6",
                        "price": 11039,
                        "id": "01t5p00000CMH8DAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 2-5.85 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-6 - Hier bei Heizungsmacher detailierte Produktdetails finden und bei Bedarf eine gezielte Beratung erhalten!",
                        "defaultImage": {
                            "id": "0695p000014yujQAAQ",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-8",
                        "price": 11230,
                        "id": "01t5p00000CMH8yAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 2-7.61 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-8 - Bei Heizungsmacher finden Sie genaue Produktdetails und können sich ausführlich informieren und beraten lassen!",
                        "defaultImage": {
                            "id": "0695p000014yujRAAQ",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-12",
                        "price": 12467,
                        "id": "01t5p00000CMH9DAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 3-12 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-12 - Erfahren Sie hier bei Heizungsmacher mehr über das Produkt und erhalten Sie um eine professionelle Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yujzAAA",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-16",
                        "price": 12657,
                        "id": "01t5p00000CMH9VAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 4-15 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-16 - Hier bei Heizungsmacher detaillierte Produktdetails ansehen und professionelle Beratung sichern!",
                        "defaultImage": {
                            "id": "0695p000014yulvAAA",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WSW196i.2-6 TP50",
                        "price": 12134,
                        "id": "01t5p00000CMH96AAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal. \r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 61x60x178cm\r\nHeizleistung: 2-5.85 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-6 TP50 - Bei Heizungsmacher mehr über die umfassenden Produktdetails erfahren und individuelle Beratung verlangen!",
                        "defaultImage": {
                            "id": "0695p000014yuitAAA",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yukOAAQ",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-8 TP50",
                        "price": 12322,
                        "id": "01t5p00000CMH9fAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 2-7.61 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-8 TP50 - Schauen Sie sich bei Heizungsmacher die Produktdetails an und erhalten Sie eine professionelle Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yul2AAA",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yul3AAA",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-12 TP50",
                        "price": 13451,
                        "id": "01t5p00000CMH9pAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 3-12 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-12 TP50 - Bei Heizungsmacher detaillierte Produktdetails einsehen und qualifizierte Beratung schnell erhalten!",
                        "defaultImage": {
                            "id": "0695p000014zvUsAAI",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-16 TP50",
                        "price": 13639,
                        "id": "01t5p00000CMH9vAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 4-15 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-16 TP50 - Entdecken Sie bei Heizungsmacher präzise Produktdetails und sichern Sie fundierte Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yulHAAQ",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yulMAAQ",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe Vitocal 250-A06",
                        "price": 9725,
                        "id": "01t5p00000DDEbZAAX",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 0.02 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C.\r\n\r\nTyp: \t250-A AWO-M-E-AC 251.A06\r\nLeistung bei A-7/W35: 5.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Wärmepumpe Vitocal 250-A8 - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001T830nAAB",
                            "name": "Hauptbild Viessmann Vitocal 250-A08 - Heizungsmacher AG (1)",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T820XAAR",
                                "name": "Offertenbild Viessmann 250-A klein - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A08",
                        "price": 10438,
                        "id": "01t5p00000Cy8UoAAJ",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 0.02 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C.\r\n\r\nTyp: \t250-A AWO-M-E-AC 251.A08\r\nLeistung bei A-7/W35: 6.5 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Wärmepumpe Vitocal 250-A8 - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001I3GGvAAN",
                            "name": "Hauptbild Viessmann Vitocal 250-A08 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001MDACyAAP",
                                "name": "Offertenbild Viessmann 250-A klein - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A10",
                        "price": 12271,
                        "id": "01t5p00000CPr4LAAT",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 3 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C – und das bei einer Außentemperatur von bis zu –15 °C. \r\n\r\nTyp: AWO-E-AC-AF 251.A10\r\nLeistung bei A-7/W35: 9.7 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Viessmann Vitocal 250-A10 ➤ Viessmann Wärmepumpentechnik ✔️ Modernes Design ✔️ „Grünes“ Kältemittel R290 (Propan) ✔️",
                        "defaultImage": {
                            "id": "0695p00001I3G8XAAV",
                            "name": "Hauptbild Viessmann Vitocal 250-A - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001I3G8hAAF",
                                "name": "Offertbild Vitocal 250-A 2-fach",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A13",
                        "price": 12944,
                        "id": "01t5p00000CPr4IAAT",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 3 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C – und das bei einer Außentemperatur von bis zu –15 °C. \r\n\r\nTyp: AWO-E-AC-AF 251.A13\r\nLeistung bei A-7/W35: 11.1 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Viessmann Vitocal 250-A13 ➤ Viessmann Wärmepumpentechnik ✔️ Modernes Design ✔️ „Grünes“ Kältemittel R290 (Propan ✔️",
                        "defaultImage": {
                            "id": "0695p00000zgeHKAAY",
                            "name": "Hauptbild Viessmann Vitocal 250-A - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000ppXzNAAU",
                                "name": "Offertbild Vitocal 250-A 2-fach",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Viessmann Vitocal 250-A Hydraulikeinheit",
                        "price": 2909,
                        "id": "01t5p00000CPr4XAAT",
                        "description": "Mit neuer Hydro AutoControl Hydraulik zur Bereitstellung der Notwendigen\r\nAbtauenergie und Sicherstellung der erforderlichen Mindest-Umlaufmenge im Wärmepumpen Kreis\r\n- Mit eingebauter Hocheffizienz-Umwälzpumpe für den Heizkreis\r\n- Abtaupuffer 16 l\r\n- Bypassfunktion\r\n- Heizwasser Durchlauferhitzer\r\n- 4-3-Wegeventil\r\n- Volumenstromsensor\r\n\r\nBedienung über 7-Zoll-Farb-Touchdisplay mit Klartext- und Grafikanzeige,\r\nInbetriebnahme-Assistenten, Anzeigen für Energieverbräuche sowie\r\nalternativ Bedienung über mobiles Endgerät, Internetfähig durch integrierte\r\nWLAN-Schnittstelle für Bedienung und Service über Viessmann Apps.\r\n\r\nFür 1 Heizkreis.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0K2EAAV",
                                "type": "pdf",
                                "size": 233279,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Viessmann Vitocal 250A - Heizungsmacher AG",
                                "modified": "2023-06-06T05:52:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GN8AAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T822xAAB",
                            "name": "Hauptbild Viessmann Vitocal 250-A Hydraulikeinheit - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000ppYNyAAM",
                                "name": "Offertbild Vitocal 250-A Hydrailikeinheit 1HK",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T820YAAR",
                                "name": "Hauptbild Viessmann Vitocal 250-A Inneneinheit - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher Vitocal 252-A",
                        "price": 4802,
                        "id": "01t5p00000CPr4iAAD",
                        "description": "Die bodenstehende Kompakt-Einheit ist die intelligente Kombination aus effizienter Wärmepumpentechnik und hohem Warmwasserkomfort – dank integriertem 190-Liter-Warmwasserspeicher in einem Gehäuse. \r\n\r\nMit neuer Hydro AutoControl Hydraulik zur Bereitstellung der Notwendigen\r\nAbtauenergie und Sicherstellung der erforderlichen Mindest-Umlaufmenge im Wärmepumpen Kreis\r\n- Mit eingebauter Hocheffizienz-Umwälzpumpe für den Heizkreis\r\n- Abtaupuffer 16 l\r\n- Bypassfunktion\r\n- Heizwasser Durchlauferhitzer\r\n- 4-3-Wegeventil\r\n- Volumenstromsensor\r\n\r\nBedienung über 7-Zoll-Farb-Touchdisplay mit Klartext- und Grafikanzeige,\r\nInbetriebnahme-Assistenten, Anzeigen für Energieverbräuche sowie\r\nalternativ Bedienung über mobiles Endgerät, Internetfähig durch integrierte\r\nWLAN-Schnittstelle für Bedienung und Service über Viessmann Apps.\r\n\r\nFür 1 Heizkreis.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0JwQAAV",
                                "type": "pdf",
                                "size": 260604,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Viessmann Vitocal 252A - Heizungsmacher AG",
                                "modified": "2023-06-06T05:41:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXn1AAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Kompaktspeicher Vitocal 252-A - Bei Heizungsmacher alle Produktdetails kennenlernen und zielgerichtete Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00000ppYXQAA2",
                            "name": "Offertbild Vitocal 250-A Kompakteinheit 1HK",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014ygjwAAA",
                                "name": "viessmann-waermepumpe-252A-2C_5",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yh1zAAA",
                                "name": "Vitocal 252-A_Inneneinheit_Schnitt",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "SMA Hybrid Sunny Tripower 5.0 Smart Energy",
                        "price": 2212,
                        "id": "01t5p00000CRAkyAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 5.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 5 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8AAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 6.0 Smart Energy",
                        "price": 2548,
                        "id": "01t5p00000CRAkUAAX",
                        "description": "Der SMA Hybrid-Wechselrichter STP 6.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 6 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8FAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 8.0 Smart Energy",
                        "price": 2980,
                        "id": "01t5p00000CRAkjAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 8.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 8 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SMA Solar Technology AG",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8BAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 10.0 Smart Energy",
                        "price": 3220,
                        "id": "01t5p00000CRAkkAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 10.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 10 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8PAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Home Hub Wechselrichter SE5K-RWB 48V",
                        "price": 2880,
                        "id": "01t5p00000CpanfAAB",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 5 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE5K-RWB 48V ➤ Kein MPP Tracker ✔️ Zwei Eingänge für SolarEdge Modul Optimierer ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmEDAA0",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Hub Wechselrichter SE8K-RWB 48V",
                        "price": 3192,
                        "id": "01t5p00000CpaoFAAR",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 8 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE8K-RWB 48V ➤ Dreiphasige Installationen mit Batteriespeicher 8 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmEIAA0",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Hub Wechselrichter SE10K-RWB 48V",
                        "price": 3360,
                        "id": "01t5p00000CpaoiAAB",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 10 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE10K-RWB 48V ➤ Dreiphasige Installationen mit Batteriespeicher 10 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmE8AAK",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Home Batterie 4.6 kWh",
                        "price": 3081,
                        "id": "01t5p00000CpapUAAR",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 4.6 kWh\r\nAnzahl Module: 1 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 4.6 kWh ➤ Optimierte Speicherlösung ✔️ dreiphasig mit optimalem Ersatzstrom✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013Vk5TAAS",
                            "name": "Hauptbild SolarEdge Home Battery 4-6kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 9.2 kWh",
                        "price": 5842,
                        "id": "01t5p00000CpapnAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 9.2 kWh\r\nAnzahl Module: 2 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 9.2 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit Ersatzstrom ✔️ Skalierbar von 4.6 – 23kWh ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk5EAAS",
                            "name": "Hauptbild SolarEdge Home Battery 9-2kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 13.8 kWh",
                        "price": 8603,
                        "id": "01t5p00000CpaplAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 13.8 kWh\r\nAnzahl Module: 3 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 13.8 kWh ➤ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️ Hier entlang!",
                        "defaultImage": {
                            "id": "0695p000013Vk3tAAC",
                            "name": "Hauptbild SolarEdge Home Battery 13-8kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 18.4 kWh",
                        "price": 11535,
                        "id": "01t5p00000CpapWAAR",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 18.4 kWh\r\nAnzahl Module: 4 Stk. (2+2)\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 18.4 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk3sAAC",
                            "name": "Hauptbild SolarEdge Home Battery 18-4kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 23.0 kWh",
                        "price": 14265,
                        "id": "01t5p00000CpaoaAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 23.0 kWh\r\nAnzahl Module: 5 Stk. (3+2)\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 23.0 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk3rAAC",
                            "name": "Hauptbild SolarEdge Home Battery 23kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Home Backup Interface (Ersatzstrom)",
                        "price": 1314,
                        "id": "01t5p00000CpaqUAAR",
                        "description": "Mit dem Home Backup-Interface bietet SolarEdge eine Ersatzstromlösung mit automatischer Umschaltung sollte das Stromnetz einmal ausfallen.\r\n- automatische Umschaltung < 10 Sekunden\r\n- Ersatzstrom für das ganze Haus (WP nicht empfohlen)\r\n- Schwarzstartfähig\r\n- Nur in Kombination mit Batterien möglich\r\nInkl. Verkabelung 5x6/10.0mm2 3LNPE bis 6m.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aGtJAAU",
                                "type": "pdf",
                                "size": 216111,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Backup-Interface - Heizungsmacher AG",
                                "modified": "2022-06-06T08:41:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk6vAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Ersatzstrom"
                            }
                        ],
                        "seoTitle": "SolarEdge Home Backup Interface",
                        "seoKeyword": "SolarEdge Home Backup Interface",
                        "seoDescription": "SolarEdge Home Backup Interface ➤ Automatische Umschaltung ✔️ Schwarzstartfähig ✔️ Dreiphasige Ersatzstromlösung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013Vk7ZAAS",
                            "name": "Hauptbild SolarEdge Home Backup Interface - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "WLW186i-5 AR E",
                        "price": 8169,
                        "id": "01t5p00000D3tn2AAB",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 110 x 54 x 80 cm\r\nLeistung bei A-7/W35: 5.4 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBimAAAT",
                            "name": "Offertenbild Buderus WSW 186i AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-7 AR E",
                        "price": 8693,
                        "id": "01t5p00000D3tmiAAB",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 110 x 54 x 80 cm\r\nLeistung bei A-7/W35: 6.7 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBilvAAD",
                            "name": "Offertenbild Buderus WSW 186i AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-10 AR E",
                        "price": 10813,
                        "id": "01t5p00000DDEO5AAP",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 135 x 54 x 105 cm\r\nLeistung bei A-7/W35: 9.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T80VvAAJ",
                            "name": "Offertenbild Buderus WSW 186i 10-12 AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-12 AR E",
                        "price": 11523,
                        "id": "01t5p00000DDET2AAP",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 135 x 54 x 105 cm\r\nLeistung bei A-7/W35: 11.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T80xwAAB",
                            "name": "Offertenbild Buderus WSW 186i 10-12 AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Inneneinheit wandhängend",
                        "price": 2519,
                        "id": "01t5p00000D3uQXAAZ",
                        "description": "Bei einer Sanierung eignet sich eine wandhängende Inneneinheit. Denn sie kann mit einem nebenstehenden Warmwasser- und Pufferspeicher flexibel kombiniert werden.\r\n- Eingebaute Hocheffizienz-Umwälzpumpe\r\n- Notbetrieb Durchlauferhitzer\r\n- 3-Weg-Umschaltventil für Heizung und Brauchwarmwasser\r\n- Touch-Systembedieneinheit \r\nLogamatic BC400 mit Funkmodul MX300\r\n\r\nMasse (L x B x T): 720 x 400 x 297 mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBkrXAAT",
                            "name": "Buderus Inneneinheit Wand offen",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001OBks0AAD",
                                "name": "Offertenbild Buderus WSW 186i AR Inneneinheit Wand - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Inneneinheit mit 70 L Puffer",
                        "price": 4037,
                        "id": "01t5p00000D3uQYAAZ",
                        "description": "Die kompakte Variante mit integriertem 70 Liter Pufferspeicher benötigt wenig Platz im Haus und eignet sich mit einem geeigneten Heizverteilsystem als Vollintegrierte Lösung. In Kombination mit einem externen Warmwasserspeicher bietet die Inneneinheit höchsten Warmwasserkomfort. \r\n\r\n- Eingebaute Hocheffizienz-Umwälzpumpe\r\n- Notbetrieb Durchlauferhitzer\r\n- Umschalt-Wegeventil für Heizung und Brauchwarmwasser\r\n- Touch-Systembedieneinheit \r\nLogamatic BC400 mit Funkmodul MX300\r\n\r\nMasse (L x B x T): 1275 x 600 x 600 mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBkrWAAT",
                            "name": "Buderus Inneneinheit TP70 offen",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001OBkrbAAD",
                                "name": "Offertenbild Buderus WSW 186i AR Inneneinheit TP70 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            }
        ]
    },
    "configuration": {
        "productCategories": [
            {
                "nameFr": "HeizungenFr",
                "nameEn": "HeizungenEn",
                "nameDe": "HeizungenDe",
                "name": "Heizungen",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "waermepumpen",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "luft-wasser-aussenaufstellung",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "sole-wasser-erdsonde",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "effizienz-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "kombi-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "kompakt-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "luft-wasser-innenaufstellung",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "regler",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Speicher",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Regler-ALT",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Pumpen und Heizkreisgruppen",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Sonstiges",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Erdsondenbohrung",
                        "level": 1,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": "SolarFr",
                "nameEn": "SolarEn",
                "nameDe": "SolarDe",
                "name": "Solar",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Standard",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Batterieanschluss",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Integrierte-Batterie",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Optimizer",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Ersatzstrom",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Batteriespeicher",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Niedervolt",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Hochvolt",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Photovoltaikmodul",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Standard-Module",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Hochleistungs-Module",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Made-in-Germany",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Monitoring",
                        "level": 1,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "waermepumpen",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "luft-wasser-aussenaufstellung",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "sole-wasser-erdsonde",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "effizienz-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "kombi-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "kompakt-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "luft-wasser-innenaufstellung",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "regler",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Speicher",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Regler-ALT",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Pumpen und Heizkreisgruppen",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Sonstiges",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Standard",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Batterieanschluss",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Integrierte-Batterie",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Optimizer",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Ersatzstrom",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Batteriespeicher",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Niedervolt",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Hochvolt",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Photovoltaikmodul",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Standard-Module",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Hochleistungs-Module",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Made-in-Germany",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Erdsondenbohrung",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Monitoring",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "luft-wasser-aussenaufstellung",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "sole-wasser-erdsonde",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "effizienz-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "kombi-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "kompakt-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Standard",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Batterieanschluss",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Integrierte-Batterie",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Standard-Module",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Hochleistungs-Module",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Made-in-Germany",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Niedervolt",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "luft-wasser-innenaufstellung",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "regler",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Optimizer",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Ersatzstrom",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Hochvolt",
                "level": 2,
                "childCategories": []
            }
        ],
        "manufacturers": [
            {
                "name": "alpha innotec",
                "id": "0010N00004FH7LzQAL"
            },
            {
                "name": "Stiebel Eltron",
                "id": "0010N00004FKSvnQAH"
            },
            {
                "name": "Viessmann",
                "id": "0010N00004Pn2D9QAJ"
            },
            {
                "name": "NIBE",
                "id": "0010N00004WO11tQAD"
            },
            {
                "name": "Kostal Solar Electric",
                "id": "0010N00004Z2W1CQAV"
            },
            {
                "name": "BYD",
                "id": "0010N00004aPbKlQAK"
            },
            {
                "name": "HPA",
                "id": "0010N00004aPx4MQAS"
            },
            {
                "name": "BOSCH",
                "id": "0010N00004acd3HQAQ"
            },
            {
                "name": "Belimo",
                "id": "0010N00004acgb9QAA"
            },
            {
                "name": "Grundfos",
                "id": "0010N00004addypQAA"
            },
            {
                "name": "PAW",
                "id": "0010N00004ademzQAA"
            },
            {
                "name": "SolarEdge",
                "id": "0010N00004bkWC8QAM"
            },
            {
                "name": "Wikora",
                "id": "0010N00004bml3WQAQ"
            },
            {
                "name": "AEH Oekotherm AG",
                "id": "0010N00004cdDKUQA2"
            },
            {
                "name": "Griesser AG",
                "id": "0010N00004eKCDiQAO"
            },
            {
                "name": "HakaGerodur AG",
                "id": "0010N00004eKCPeQAO"
            },
            {
                "name": "Buderus",
                "id": "0010N00004gGBuWQAW"
            },
            {
                "name": "R. Nussbaum AG",
                "id": "0010N00004hcWc5QAE"
            },
            {
                "name": "Alera energies AG",
                "id": "0010N00004koyf8QAA"
            },
            {
                "name": "NIBE AB (Sweden - nicht verwenden)",
                "id": "0010N00004kp2DKQAY"
            },
            {
                "name": "Paul Bauder AG",
                "id": "0010N00004p3o7FQAQ"
            },
            {
                "name": "Jinko Solar",
                "id": "0010N00004uFHCkQAO"
            },
            {
                "name": "LG Electronics",
                "id": "0010N00004uFHTYQA4"
            },
            {
                "name": "Sunpower",
                "id": "0010N00004uFInjQAG"
            },
            {
                "name": "SMA Solar Technology AG",
                "id": "0010N00004uFJC6QAO"
            },
            {
                "name": "E3DC",
                "id": "0010N00004uFMPaQAO"
            },
            {
                "name": "Trina Solar",
                "id": "0010N00004uFMfnQAG"
            },
            {
                "name": "K2 Systems",
                "id": "0010N000050Hmj5QAC"
            },
            {
                "name": "Depping AG",
                "id": "0010N00005G7tHoQAJ"
            },
            {
                "name": "CTC",
                "id": "0010N00005IvNUBQA3"
            },
            {
                "name": "LG Chem",
                "id": "0010N00005MykGKQAZ"
            },
            {
                "name": "A. Tschümperlin AG",
                "id": "0010N00005MywvqQAB"
            },
            {
                "name": "Novelan",
                "id": "0010N00005Phfq3QAB"
            },
            {
                "name": "Bucher Blechtechnik AG",
                "id": "0015p00005TtVtkAAF"
            },
            {
                "name": "Dani's Metalldesign GmbH",
                "id": "0015p00005UTfQFAA1"
            },
            {
                "name": "Meyer Burger",
                "id": "0015p00005aeeprAAA"
            },
            {
                "name": "Fronius",
                "id": "0015p00005b5gMdAAI"
            },
            {
                "name": "LAMBDA Wärmepumpen GmbH",
                "id": "0015p00005fuigMAAQ"
            },
            {
                "name": "JA Solar",
                "id": "0015p00005kPHpmAAG"
            },
            {
                "name": "Regli Energy",
                "id": "0015p00005pRYZlAAO"
            },
            {
                "name": "Wärmeaustausch Technologien AG WT",
                "id": "0015p00005qczPHAAY"
            },
            {
                "name": "BRUGG Rohrsystem AG",
                "id": "0015p00005vBc3qAAC"
            },
            {
                "name": "WS Weinmann & Schanz GmbH",
                "id": "0015p000061WrYWAA0"
            },
            {
                "name": "Oekoswiss Supply AG",
                "id": "0015p000066srNiAAI"
            },
            {
                "name": "Dimplex",
                "id": "0015p000068z1lqAAA"
            },
            {
                "name": "Sun2World Sustainable Energy Projects GmbH",
                "id": "0015p000068zR7fAAE"
            },
            {
                "name": "Debrunner Acifer AG",
                "id": "0015p000068zU7wAAE"
            },
            {
                "name": "Glaromat AG",
                "id": "0015p000068zfv8AAA"
            },
            {
                "name": "Forstner Speichertechnik GmbH",
                "id": "001Vj000004LDEdIAO"
            },
            {
                "name": "LONGi",
                "id": "001Vj000004U3J8IAK"
            }
        ],
        "bundleCategories": [
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Heizungen",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": "Wärmepumpen Fr",
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wärmepumpen",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Luft/Wasser",
                                "level": 2,
                                "childCategories": [
                                    {
                                        "nameFr": null,
                                        "nameEn": null,
                                        "nameDe": null,
                                        "name": "Innenaufgestellt",
                                        "level": 3,
                                        "childCategories": []
                                    },
                                    {
                                        "nameFr": null,
                                        "nameEn": null,
                                        "nameDe": null,
                                        "name": "Aussenaufgestellt",
                                        "level": 3,
                                        "childCategories": []
                                    }
                                ]
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Sole/Wasser (Erdsonde)",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Solar",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Photovoltaik",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter mit Batterieanschluss (extern)",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter mit integrierter Batterie",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    }
                ]
            },
            {
                "nameFr": "Wärmepumpen Fr",
                "nameEn": null,
                "nameDe": null,
                "name": "Wärmepumpen",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Luft/Wasser",
                        "level": 2,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Innenaufgestellt",
                                "level": 3,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Aussenaufgestellt",
                                "level": 3,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Sole/Wasser (Erdsonde)",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Photovoltaik",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter mit Batterieanschluss (extern)",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter mit integrierter Batterie",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Luft/Wasser",
                "level": 2,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Innenaufgestellt",
                        "level": 3,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Aussenaufgestellt",
                        "level": 3,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Sole/Wasser (Erdsonde)",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter mit Batterieanschluss (extern)",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter mit integrierter Batterie",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Innenaufgestellt",
                "level": 3,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Aussenaufgestellt",
                "level": 3,
                "childCategories": []
            }
        ]
    }
}} location={props.location}
          />
<ProductList
            {...{
    "type": "productList",
    "id": "294gitxABnUgk7q8D2olfl",
    "updatedAt": "2022-04-03T12:20:35.390Z",
    "title": "Unsere Made in Germany Photovoltaikmodule",
    "category": "Photovoltaikmodul Made-in-Germany",
    "products": {
        "metaProducts": [
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WPL 15 AS",
                        "price": 11455,
                        "id": "01t0N00000982hPQAQ",
                        "description": "Die WPL 15 AS Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 6.86 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz4bQAA",
                                "type": "pdf",
                                "size": 656765,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 15 AS Stiebel Eltron",
                                "modified": "2018-10-18T13:23:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkLRQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 15 AS",
                        "seoKeyword": "WPL 15 AS",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe (WPL 15 AS) zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. Erfahren Sie bei uns mehr!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZHQAZ",
                            "name": "Hauptbild WPL 15 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 15 AS Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 15 ACS (Kühlung)",
                        "price": 12249,
                        "id": "01t0N000009hWNKQA2",
                        "description": "Die WPL 15 ACS Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 6.86 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz41QAA",
                                "type": "pdf",
                                "size": 657801,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 15 ACS Stiebel Eltron",
                                "modified": "2018-10-18T13:23:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkKrQAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 15 ACS (Kühlung)",
                        "seoKeyword": "WPL 15 ACS",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion. ➤ Hier mehr dazu!",
                        "defaultImage": {
                            "id": "0690N00000Cj3YxQAJ",
                            "name": "Hauptbild WPL 15 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 15 ACS Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTdhQAG",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 15 ACS Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 20 A",
                        "price": 14138,
                        "id": "01t0N00000982hFQAQ",
                        "description": "Die WPL 20 A Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 9.54 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz5JQAQ",
                                "type": "pdf",
                                "size": 654783,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 20 A Stiebel Eltron",
                                "modified": "2018-10-18T13:25:31.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkM9QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 20 A",
                        "seoKeyword": "Stiebel Eltron WPL 20 A",
                        "seoDescription": "Die WPL 20 A Inverter ist eine Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZRQAZ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 20 A Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000t71cAAAQ",
                                "name": "Offertenbild Stiebel Eltron WPL 20 25 A - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 20 AC (Kühlung)",
                        "price": 14935,
                        "id": "01t0N000009iEYaQAM",
                        "description": "Die WPL 20 AC Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 9.54 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz6RQAQ",
                                "type": "pdf",
                                "size": 657844,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 20 AC Stiebel Eltron",
                                "modified": "2018-10-18T13:27:01.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkNHQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 20 AC",
                        "seoKeyword": "WPL 20 AC Stiebel eltron",
                        "seoDescription": "Inverter Luft-Wasser-Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. Weitere Informationen bekommen Sie bei uns!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZWQAZ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 20 AC Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTeVQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 20 AC Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 25 A",
                        "price": 15733,
                        "id": "01t0N00000982bwQAA",
                        "description": "Die WPL 25 A Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 12.86 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz8EQAQ",
                                "type": "pdf",
                                "size": 656543,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 25 A Stiebel Eltron",
                                "modified": "2018-10-18T13:31:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkP8QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 25 A",
                        "seoKeyword": "WPL 25 A",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. ➤ Weitere Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZbQAJ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 25 A Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTbHQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 25 A Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 25 AC (Kühlung)",
                        "price": 16530,
                        "id": "01t0N000009jdeBQAQ",
                        "description": "Die WPL 25 AC Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 12.86 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzJlQAI",
                                "type": "pdf",
                                "size": 657686,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 25 AC Stiebel Eltron",
                                "modified": "2018-10-18T13:50:27.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkaLQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 25 AC (Kühlung)",
                        "seoKeyword": "Stiebel Eltron WPL 25 AC",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe (WPL 25 AC) zur kompakten Aussenaufstellung mit niedrigem Schallleistungspegel und aktiver Kühlfunktion. Mehr hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZgQAJ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 25 AC Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTeWQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 25 AC Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher SBP 200 E",
                        "price": 950,
                        "id": "01t0N00000982j3QAA",
                        "description": "Der Standspeicher SBP 200 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit  perfekt isolierender Ummantelung.\r\n\r\nVolumen: 207 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 63 x 153.5 cm (58kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkCgQAI",
                                "type": "pdf",
                                "size": 399066,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 200 E Stiebel Eltron",
                                "modified": "2018-10-18T13:59:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxYeQAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 200 E",
                        "seoKeyword": "Stiebel Eltron SBP 200 E",
                        "seoDescription": "Der Stiebel Eltron Standspeicher eignet sich mit perfekt isolierender Ummantelung als Pufferspeicher für Wärmepumpenanlagen. ➤ Jetzt informieren!",
                        "defaultImage": {
                            "id": "0690N00000CiwNsQAJ",
                            "name": "Stiebel_Eltron_Pufferspeicher_SBP_200_400_700_WEB",
                            "alt": "Stiebel Eltron SBP 200 E Pufferspeicher (200 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGhRQAW",
                                "name": "SE_SBP_200_400E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 200 E Pufferspeicher (200 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher SBP 400 E",
                        "price": 1240,
                        "id": "01t0N00000982jbQAA",
                        "description": "Der Standspeicher SBP 400 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit perfekt isolierender Ummantelung.\r\n\r\nVolumen: 415 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 171 cm (81 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkCuQAI",
                                "type": "pdf",
                                "size": 398895,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 400 E Stiebel Eltron",
                                "modified": "2018-10-18T14:00:29.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxYsQAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 400 E",
                        "seoKeyword": "Pufferspeicher SBP 400 E",
                        "seoDescription": "Der Standspeicher SBP 400 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ✅ Isolierende Ummantelung ✅ Energieklasse B ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000CiwPtQAJ",
                            "name": "Stiebel_Eltron_Pufferspeicher_SBP_200_400_700_WEB",
                            "alt": "Stiebel Eltron SBP 400 E Pufferspeicher (400 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGgsQAG",
                                "name": "SE_SBP_200_400E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 400 E Pufferspeicher (400 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher SBP 700 E",
                        "price": 1733,
                        "id": "01t0N00000982jlQAA",
                        "description": "Der Standspeicher SBP 700 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit  perfekt isolierender Ummantelung.\r\n\r\nVolumen: 720 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 91 x 189 cm (185 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkD4QAI",
                                "type": "pdf",
                                "size": 399136,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 700 E Stiebel Eltron",
                                "modified": "2018-10-18T14:01:33.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxZ2QAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 700 E",
                        "seoKeyword": "Stiebel Eltron Pufferspeicher",
                        "seoDescription": "Der Standspeicher eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit perfekt isolierender Ummantelung Energieklasse B.",
                        "defaultImage": {
                            "id": "0690N00000Ck2h7QAB",
                            "name": "Stiebel Eltron SBP 700 E (Hauptbild)",
                            "alt": "Stiebel Eltron SBP 700 E Pufferspeicher (700 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGgEQAW",
                                "name": "SE_SBP_700E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 700 E Pufferspeicher (700 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher SBB 312.B WP",
                        "price": 2320,
                        "id": "01t0N00000982lYQAQ",
                        "description": "Standspeicher SBB 312.B mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. Wärmedämmung als PU-Direktumschäumung.\r\n\r\nVolumen: 309 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 173.8 cm (194 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzX7QAI",
                                "type": "pdf",
                                "size": 200096,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 312.B Stiebel Eltron",
                                "modified": "2021-08-10T08:24:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zknQQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 312.B WP",
                        "seoKeyword": "Stiebel Eltron Trinkwasserspeicher",
                        "seoDescription": "Standspeicher mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000CiwVhQAJ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": "Stiebel Eltron SBB 312.B WP Trinkwarmwasserspeicher (300 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UH0iQAG",
                                "name": "SE_SBB_312B_WP_Trink_Warmwasserspeicher",
                                "alt": "Stiebel Eltron SBB 312.B WP Trinkwarmwasserspeicher (300 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher  SBB 411.B WP",
                        "price": 2646,
                        "id": "01t0N00000982liQAA",
                        "description": "Standspeicher SBB 411.B mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. Wärmedämmung als PU-Direktumschäumung.\r\n\r\nVolumen: 395 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 185 cm (192 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzrBQAQ",
                                "type": "pdf",
                                "size": 201522,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:43.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zl7PQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 411.B WP",
                        "seoKeyword": "Stiebel Eltron Trinkwarmwasserspeicher",
                        "seoDescription": "SBB 411.B Standspeicher ➤ Innen liegenden Glattrohr-Wärmeübertrager ✔️ Emailliert & Verkalkungsunempfindlich ✔️ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000CiwWBQAZ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": "Stiebel Eltron SBB 411.B WP Trinkwarmwasserspeicher (400 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UH1HQAW",
                                "name": "SE_SBB_411B_WP_Trink_Warmwasserspeicher",
                                "alt": "Stiebel Eltron SBB 411.B WP Trinkwarmwasserspeicher (400 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher SBB 501 WP",
                        "price": 3081,
                        "id": "01t5p00000CQhLAAA1",
                        "description": "Standspeicher SBB 501 WP SOL mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und besitzen eine hohe Trinkwarmwasserverfügbarkeit durch abgestimmte Ein- und Ausströmtechnik.\r\n\r\nVolumen: 495 Liter\r\nMasse ohne Isolierung: ⌀xH: 69 x 198.8 cm (222 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vKByWAAW",
                                "type": "pdf",
                                "size": 208855,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 501 Stiebel Eltron - Heizungsmacher AG",
                                "modified": "2022-01-11T07:01:49.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000uizjOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 501 WP",
                        "seoKeyword": "Stiebel Eltron Trinkwarmwasserspeicher",
                        "seoDescription": "SBB 501.B Standspeicher ➤ Innen liegenden Glattrohr-Wärmeübertrager ✔️ Emailliert & Verkalkungsunempfindlich ✔️ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0695p00000uiziCAAQ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000uiziDAAQ",
                                "name": "SE_SBB_411B_WP_Trink_Warmwasserspeicher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Erweiterung WPE",
                        "price": 830,
                        "id": "01t0N000009jMZKQA2",
                        "description": "Funktionserweiterungsmodul für den WPM.\r\nRegelung von zwei zusätzlichen gemischten Heizkreisen.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzvjQAA",
                                "type": "pdf",
                                "size": 915359,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPE Stiebel Eltron",
                                "modified": "2018-10-18T15:04:24.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zlBlQAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron Erweiterung WPE ➤ FFunktionserweiterungsmodul für den WPM ✔️ Regelung von zwei zusätzlichen gemischten Heizkreisen ✔️",
                        "defaultImage": {
                            "id": "0690N00000Cj3ajQAB",
                            "name": "Hauptbild WPE Stiebel Eltron",
                            "alt": "Stiebel Eltron Wärmepumpenregler WPE Erweiterung"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000ANFQPQA5",
                                "name": "SE_WPE_Erweiterung_2",
                                "alt": "Stiebel Eltron Wärmepumpenregler WPE Erweiterung"
                            },
                            {
                                "id": "0690N000006zlDIQAY",
                                "name": "Übersicht WPM Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpenregler WPM",
                        "price": 1131,
                        "id": "01t0N00000982mHQAQ",
                        "description": "Der Wärmepumpen-Manager WPM ist für die steuer- und regelungstechnischen Abläufe der Wärmepumpe zuständig. Mit dem Gerät können Einstellungen vorgenommen und Informationen zum Betrieb der Wärmepumpe angezeigt werden.\r\n-Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xc8cQAA",
                                "type": "pdf",
                                "size": 915254,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:05:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwTQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8dQAA",
                                "type": "pdf",
                                "size": 1610169,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Meldungsliste WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwUQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8hQAA",
                                "type": "pdf",
                                "size": 1880046,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Inbetriebnahme WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:55.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwYQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8mQAA",
                                "type": "pdf",
                                "size": 4618726,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installation WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:39.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwdQAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8rQAA",
                                "type": "pdf",
                                "size": 5067872,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:08.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwiQAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron Wärmepumpenregler WPM ➤ Wärmepumpen-Manager ✔️ Steuer- und regelungstechnische Abläufe ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0690N00000Cj3aUQAR",
                            "name": "Hauptbild WPM 4 Stiebel Eltron",
                            "alt": "Stiebel Eltron Wärmepumpenregler WPM"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000ANFRDQA5",
                                "name": "SE_WPM_4_2",
                                "alt": "Stiebel Eltron Wärmepumpenregler WPM"
                            },
                            {
                                "id": "0690N000006zlDIQAY",
                                "name": "Übersicht WPM Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WPL 19",
                        "price": 15407,
                        "id": "01t0N0000098r9XQAQ",
                        "description": "Die innenaufgestellte Luft-Wasser-Wärmepumpe WPL 19 besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Trotz minimaler Schallemissionen plus besonders leisem Nachtmodus erreicht die Pumpe eine hohe Vorlauftemperatur von +65°C. Das Gerät erreicht bei einer Aussentemperatur von -7°C eine Wärmeleistung von 9.91 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdttkQAB",
                                "type": "pdf",
                                "size": 252022,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron WPL 19 I Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:39:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsuiQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OdtvUQAR",
                                "type": "pdf",
                                "size": 28899489,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation Stiebel Eltron WPL 19 24 Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:38:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NWstzQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 19",
                        "seoKeyword": "Stiebel Eltron WPL 19",
                        "seoDescription": "Die innenaufgestellte WPL 19 Luft-Wasser-Wärmepumpe besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Alle Vorteile hier!",
                        "defaultImage": {
                            "id": "0690N00000PiwOTQAZ",
                            "name": "Hauptbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                            "alt": "Stiebel Eltron WPL 19 Wärmepumpe (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwOYQAZ",
                                "name": "Offertbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                                "alt": "Stiebel Eltron WPL 19 Wärmepumpe (innen)"
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 24",
                        "price": 16969,
                        "id": "01t0N0000098r9cQAA",
                        "description": "Die innenaufgestellte Luft-Wasser-Wärmepumpe WPL 24 besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Trotz minimaler Schallemissionen plus besonders leisem Nachtmodus erreicht die Pumpe eine hohe Vorlauftemperatur von +65°C. Das Gerät erreicht bei einer Aussentemperatur von -7°C eine Wärmeleistung von 13,45 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdttlQAB",
                                "type": "pdf",
                                "size": 252431,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron WPL 24 I Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:40:28.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsujQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OdtvUQAR",
                                "type": "pdf",
                                "size": 28899489,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation Stiebel Eltron WPL 19 24 Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:38:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NWstzQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 24",
                        "seoKeyword": "Stiebel Eltron WPL 24",
                        "seoDescription": "Die innenaufgestellte Luft-Wasser-Wärmepumpe besticht durch exzellente Energieeffizienz dank moderner Invertertechnik. Alle weiteren Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000PiwOsQAJ",
                            "name": "Hauptbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                            "alt": "Stiebel Eltron WPL 24 Wärmepumpe (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwOtQAJ",
                                "name": "Offertbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                                "alt": "Stiebel Eltron WPL 24 Wärmepumpe (innen)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kombispeicher HS-BM 560L WT1",
                        "price": 5700,
                        "id": "01t0N000009ODegQAG",
                        "description": "Der Hygiene-Systemspeicher HS-BM056-18 WT1 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nVolumen: 560 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 89 (65) x 198 cm (157kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002mugQIAQ",
                                "type": "pdf",
                                "size": 1532093,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-056-18 WT1",
                                "modified": "2024-02-23T15:38:05.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jJblIAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 560L",
                        "seoKeyword": "Stiebel Eltron Kombispeicher",
                        "seoDescription": "Der multivalente Hygiene-Systemspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie. 560l Fassungsvermögen ➤ Hier weitere Infos!",
                        "defaultImage": {
                            "id": "0690N00000PiYtNQAV",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 560L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtOQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 560L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 560L WT2",
                        "price": 6473,
                        "id": "01tVj0000024OnNIAU",
                        "description": "Der Hygiene-Systemspeicher HS-BM056-18 WT2 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nMit zusätzlichem Register für einen zweiten Wärmeerzeuger (1.5 m2).\r\n\r\nVolumen: 560 Liter \r\nMasse mit (ohne) Isolierung: ⌀xH: 89 (65) x 198 cm (162 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n7teIAA",
                                "type": "pdf",
                                "size": 627868,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-056-18 WT2",
                                "modified": "2024-02-23T15:39:56.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jWsEIAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 560L",
                        "seoKeyword": "Stiebel Eltron Kombispeicher",
                        "seoDescription": "Der multivalente Hygiene-Systemspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie. 560l Fassungsvermögen ➤ Hier weitere Infos!",
                        "defaultImage": {
                            "id": "069Vj000002jKnsIAE",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000002jS75IAE",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 820L WT1",
                        "price": 6630,
                        "id": "01t0N000009OEaHQAW",
                        "description": "Der Hygiene-Systemspeicher HS-BM080-18 WT1 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nVolumen: 820 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 101 (77) x 203 cm (181 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n00yIAA",
                                "type": "pdf",
                                "size": 1537486,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-080-18 WT1",
                                "modified": "2024-02-23T15:38:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jP1AIAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 820 L",
                        "seoKeyword": "Stiebel Eltron HS-BM",
                        "seoDescription": "Der Hygiene-Systemspeicher HS-BM von Stiebel Eltron gilt als Herzstück einer Heizanlage. Alles zu seiner innovativen Technologie erfahren Sie hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYthQAF",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 820L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtQQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 820L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 820L WT2",
                        "price": 7530,
                        "id": "01t0N000009jqE4QAI",
                        "description": "Der Hygiene-Systemspeicher  HS-BM080-18 WT2 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nMit zusätzlichem Register für einen zweiten Wärmeerzeuger (2.6 m2).\r\n\r\nVolumen: 820 Liter \r\nMasse mit (ohne) Isolierung: ⌀xH: 1'100 (77) x 203 cm (191 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n7yUIAQ",
                                "type": "pdf",
                                "size": 634799,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-080-18 WT2",
                                "modified": "2024-02-23T15:39:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jWx4IAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "069Vj000002jVjFIAU",
                            "name": "Hauptbild Forstner HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000002jRPbIAM",
                                "name": "Offertbild Forstner HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 960L WT1",
                        "price": 8424,
                        "id": "01t0N00000AJECNQA5",
                        "description": "Der Hygiene-Systemspeicher HS-BM096-18 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nNenninhalt: 960 Liter\r\nDurchmesser ohne (mit) Isolation: 790mm (1030mm)\r\nHöhe mit Isolation: 2250mm\r\n\r\nVolumen: 960 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 103 (79) x 225 cm (198 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n4kVIAQ",
                                "type": "pdf",
                                "size": 1531211,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-096-18 WT1",
                                "modified": "2024-02-23T15:40:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jTr9IAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 960L",
                        "seoKeyword": "Stienel Eltron HS-BM 960",
                        "seoDescription": "Der Kombispeicher HS-BM 960 gilt als Herzstück einer Heizanlage. Alle Infos zur innovativen Technologie dieses multivalenten Schichtenspeichers hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYtLQAV",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 960L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtKQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 960L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Mutltifunktionsspeicher MFS 600 S",
                        "price": 4373,
                        "id": "01t0N0000099NBDQA2",
                        "description": "Der Multifunktionsspeicher MFS 600 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 572 Liter (7.5 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 80 (65) x 193 cm (140 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfFKQAZ",
                                "type": "pdf",
                                "size": 126296,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 600 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:22:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULydQAH",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 600 S",
                        "seoKeyword": "Alpha Innotec MFS 600 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 600 S vereint Puffer & Trinkwarmwasser in einem. Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip.",
                        "defaultImage": {
                            "id": "0690N00000NULnRQAX",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 600 S Multifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8HKQA1",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 600 S Multifunktionsspeicher"
                            }
                        ]
                    },
                    {
                        "title": "Mutltifunktionsspeicher MFS 830 S",
                        "price": 4909,
                        "id": "01t0N000009O5azQAC",
                        "description": "Der Multifunktionsspeicher MFS 830 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 846 Liter (8.7 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 199 cm (200 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfFtQAJ",
                                "type": "pdf",
                                "size": 140839,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 830 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:26:32.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULzCQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 830 S",
                        "seoKeyword": "Alpha Innotec 830 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 830 S von Alpha Innotec vereint Puffer & Trinkwarmwasser. Die Aufbereitung erfolgt im Durchlauferhitzerprinzip.",
                        "defaultImage": {
                            "id": "0690N00000NY8IhQAL",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 830 S Mutltifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8HLQA1",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 830 S Mutltifunktionsspeicher"
                            }
                        ]
                    },
                    {
                        "title": "Mutltifunktionsspeicher MFS 1000 S",
                        "price": 6069,
                        "id": "01t0N000009P7xAQAS",
                        "description": "Der Multifunktionsspeicher MFS 1000 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 928 Liter (10.9 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 214 cm (230 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfC6QAJ",
                                "type": "pdf",
                                "size": 129902,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 1000 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:27:17.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULvLQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 1000 S",
                        "seoKeyword": "MFS 1000 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 1000 S von Alpha Innotec vereint Puffer und Trinkwarmwasser in einem. ➤ Alle Infos dazu bei uns!",
                        "defaultImage": {
                            "id": "0690N00000NULvKQAX",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 1000 S Mutltifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8JaQAL",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 1000 S Mutltifunktionsspeicher"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher TPS 200",
                        "price": 1088,
                        "id": "01t0N000009PKl6QAG",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 200).\r\n\r\nVolumen: 200 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 60 x 130 cm (60 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsRQAV",
                                "type": "pdf",
                                "size": 112737,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 200 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:14:21.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXwQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 200",
                        "seoKeyword": "alpha innotec TPS 200",
                        "seoDescription": "Pufferspeicher zur Optimierung der Laufzeit & Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung.",
                        "defaultImage": {
                            "id": "0690N00000PiYY1QAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 200 - Heizungsmacher AG",
                            "alt": "NIBE TPS 200 Pufferspeicher (200 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYY0QAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 200 - Heizungsmacher AG",
                                "alt": "NIBE TPS 200 Pufferspeicher (200 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 300.11",
                        "price": 1435,
                        "id": "01t0N000009MIH7QAO",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 300.11).\r\n\r\nVolumen: 301 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 66 x 174 cm (93 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000wzrTqAAI",
                                "type": "pdf",
                                "size": 146385,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 300-11 - Heizungsmacher AG",
                                "modified": "2022-02-22T15:31:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYYPQA3",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Pufferspeicher TPS 300",
                        "seoKeyword": "Alpha Innotec TPS 300",
                        "seoDescription": "Der Alpha Innotec TPS 300 Pufferspeicher zur Optimierung der Laufzeit & Vergrösserung des Systemvolumens. ➤ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiYUoQAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 300 - Heizungsmacher AG",
                            "alt": "NIBE TPS 300 Pufferspeicher (300 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYUnQAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 300 - Heizungsmacher AG",
                                "alt": "NIBE TPS 300 Pufferspeicher (300 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 500.1",
                        "price": 1660,
                        "id": "01t0N000009MTuPQAW",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 500).\r\n\r\nVolumen: 485 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 76 x 193 cm (111 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLt5QAF",
                                "type": "pdf",
                                "size": 138757,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 500-1 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:18:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYYZQA3",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 500.1",
                        "seoKeyword": "Alpha Innotec TPS 500",
                        "seoDescription": "TPS 500.1 Pufferspeicher ➤ Optimierung der Laufzeit ✔️ Eingeschweisste Tauchhülsen ✔️  Abnehmbare Wärmedämmung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0690N00000PiYYfQAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                            "alt": "NIBE TPS 500.1 Pufferspeicher (500 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYYeQAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                                "alt": "NIBE TPS 500.1 Pufferspeicher (500 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 500.3",
                        "price": 1918,
                        "id": "01t5p00000BksjIAAR",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und abnehmbarer Wärmedämmung (baugleich Alpha Innotec TPS 500).\r\n\r\nDurchmesser ohne Isolation: 650mm\r\nKippmass ohne Isolation: 1840mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 500.3",
                        "seoKeyword": "Alpha Innotec TPS 500",
                        "seoDescription": "TPS 500.3 Pufferspeicher ➤ Optimierung der Laufzeit ✔️ Eingeschweisste Tauchhülsen ✔️  Abnehmbare Wärmedämmung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p00000j6rA1AAI",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                            "alt": "NIBE TPS 500.3 Pufferspeicher (500 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000j6rA6AAI",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                                "alt": "NIBE TPS 500.3 Pufferspeicher (500 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S2125-8",
                        "price": 10075,
                        "id": "01t0N000009M6nDQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE S2125-8 deckt einen Leistungsbereich bis 5.3 kW ab (bei A-7/W55). Leiser Betrieb von 50 dB(A) Nacht-Schallleistungspegel und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nDas in der NIBE S2125 eingesetzte Kältemittel ist umweltfreundlich und nachhaltig. Bei reinem Verdichterbetrieb werden hohe Ladetemperaturen von bis zu 75 °C erreicht. Ebenso können bei Außentemperaturen von –25 °C noch Temperaturen von bis zu 65 °C erzeugt werden. Dies ermöglicht eine ganzjährig effiziente Betriebsweise mit hohem Brauchwasserkomfort und den Betrieb mit bestehenden Heizkörpern.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014miVLAAY",
                                "type": "pdf",
                                "size": 722379,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S2125 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:39:43.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDrAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-8",
                        "seoKeyword": "Nibe F2120-8",
                        "seoDescription": "Die NIBE F2120-8 deckt einen Leistungsbereich bis 5.5 kW ab (bei A-7/W55). Merkmale sind ihr leiser Betrieb & die geringen Betriebskosten. Hier mehr!",
                        "defaultImage": {
                            "id": "0695p000014yhDvAAI",
                            "name": "Hauptbild NIBE S2125 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yhD3AAI",
                                "name": "Offertenbild NIBE S2125-8 -12 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhDwAAI",
                                "name": "Bild 1 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhDxAAI",
                                "name": "Bild 2 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S2125-12",
                        "price": 11146,
                        "id": "01t0N000009M6nNQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE S2125-12 deckt einen Leistungsbereich bis 8.2 kW ab (bei A-7/W55). Leiser Betrieb von 50 dB(A) Nacht-Schallleistungspegel und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nDas in der NIBE S2125 eingesetzte Kältemittel ist umweltfreundlich und nachhaltig. Bei reinem Verdichterbetrieb werden hohe Ladetemperaturen von bis zu 75 °C erreicht. Ebenso können bei Außentemperaturen von –25 °C noch Temperaturen von bis zu 65 °C erzeugt werden. Dies ermöglicht eine ganzjährig effiziente Betriebsweise mit hohem Brauchwasserkomfort und den Betrieb mit bestehenden Heizkörpern.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014midSAAQ",
                                "type": "pdf",
                                "size": 722379,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S2125 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:42:06.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhGpAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-12",
                        "seoKeyword": "Nibe F2120-12",
                        "seoDescription": "Die Monoblock-Ausseneinheit NIBE F2120-12 deckt einen Leistungsbereich bis 7.6 kW ab. ✅ Leiser Betrieb ✅ minimale Betriebskosten. Hier mehr!",
                        "defaultImage": {
                            "id": "0695p000014yhDsAAI",
                            "name": "Hauptbild NIBE S2125 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yhGqAAI",
                                "name": "Offertenbild NIBE S2125-8 -12 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhGuAAI",
                                "name": "Bild 1 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhGvAAI",
                                "name": "Bild 2 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe F2120-16",
                        "price": 14280,
                        "id": "01t0N000009M6nXQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE F2120-16 deckt einen Leistungsbereich bis 10.9 kW ab (bei A-7/W55). Flüsterleiser Betrieb dank einem Schallleistungspegel von 55 dB(A) und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nMeilenstein in der Effizienz mit einem SCOP grösser als 5.0. Vorlauftemperatur bis 65°C ideal für die Sanierung. Keine Vignettenpflicht da weniger als 3 kg Kältemittel.",
                        "attributes": "<ul><li>Hocheffizienz-Wärmepumpe für den Heizungstausch.</li><li>Erreicht Effizienzwerte die bisher nur mit Erdwärme möglich waren.</li><li>Bisher unerreichter Betriebsbereich mit bis zu 65 °C Ladetemperatur und 63 °C bei –25 °C Aussenlufttemperatur</li><li>Minimaler Geräuschpegel, auch bei voller Leistung.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014miabAAA",
                                "type": "pdf",
                                "size": 433933,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE F2120 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:45:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDyAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-16",
                        "seoKeyword": "Nibe F2120-16",
                        "seoDescription": "Die Monoblock-Ausseneinheit NIBE F2120-16 deckt einen Leistungsbereich bis 10.9 kW ab. Leiser Betrieb dank einem Schallleistungspegel von 55 dB(A).",
                        "defaultImage": {
                            "id": "0690N00000I36q6QAB",
                            "name": "Hauptbild NIBE F2120 -8 -12 -16 -20",
                            "alt": "NIBE F2120-16 Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I36pdQAB",
                                "name": "Offertenbild NIBE F2120 -8 -12 -16 -20",
                                "alt": "NIBE F2120-16 Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0695p000014yhKhAAI",
                                "name": "Ansicht 1 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKiAAI",
                                "name": "Ansicht 2 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKjAAI",
                                "name": "Ansicht 3 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe F2120-20",
                        "price": 15926,
                        "id": "01t0N000009M6ncQAC",
                        "description": "Die Monoblock-Ausseneinheit NIBE F2120-20 deckt einen Leistungsbereich bis 14.1 kW ab (bei A-7/W55). Flüsterleiser Betrieb dank einem Schallleistungspegel von 55 dB(A) und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nMeilenstein in der Effizienz mit einem SCOP grösser als 5.0. Vorlauftemperatur bis 65°C ideal für die Sanierung. Keine Vignettenpflicht da weniger als 3 kg Kältemittel.",
                        "attributes": "<ul><li>Hocheffizienz-Wärmepumpe für den Heizungstausch.</li><li>Erreicht Effizienzwerte die bisher nur mit Erdwärme möglich waren.</li><li>Bisher unerreichter Betriebsbereich mit bis zu 65 °C Ladetemperatur und 63 °C bei –25 °C Aussenlufttemperatur</li><li>Minimaler Geräuschpegel, auch bei voller Leistung.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mihUAAQ",
                                "type": "pdf",
                                "size": 433933,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE F2120 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:45:53.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDtAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-20",
                        "seoKeyword": "Nibe F2120-20",
                        "seoDescription": "Die NIBE F2120-20 deckt einen Leistungsbereich bis 14.1 kW ab. Flüsterleiser Betrieb und minimale Betriebskosten durch Inverter-Technologie.",
                        "defaultImage": {
                            "id": "0690N00000I36pXQAR",
                            "name": "Hauptbild NIBE F2120 -8 -12 -16 -20",
                            "alt": "NIBE F2120-20 Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I36pcQAB",
                                "name": "Offertenbild NIBE F2120 -8 -12 -16 -20",
                                "alt": "NIBE F2120-20 Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0695p000014yhDuAAI",
                                "name": "Ansicht 2 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKkAAI",
                                "name": "Ansicht 1 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKlAAI",
                                "name": "Ansicht 3 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher VVM S320",
                        "price": 6822,
                        "id": "01t0N00000BBwk3QAD",
                        "description": "Kompakte Inneneinheit NIBE VVM S320 mit sehr hohem Vorfertigungsgrad für Standardanwendungen im Einfamilienhaus. Das intelligente, anwenderfreundliche Regelgerät der NIBE VVM S320 ermöglicht eine effiziente Wärme- und Brauchwasserversorgung.\r\nVVM S320 wird in Kombination mit einer Luft-/Wasser-Wärmepumpe NIBE F2120 als zentrales Bindeglied zwischen Wärmeaufnahme, -speicherung und Wärmeabgabe eingesetzt. \r\n\r\nDie kompakte Inneneinheit ist mit einem 180 Liter Brauchwasserspeicher, einem Pufferspeicher sowie mit einem Ausdehnungsgefäß ausgestattet. Zusätzlich sind die hocheffizienz Lade- und Entladepumpen sowie eine elektrische Notheizung bereits integriert. \r\n\r\nMit integriertem WLAN fügt sich die S-Serie wie selbstverständlich in Ihr vernetztes Zuhause ein. Die intelligente Technologie passt das Raumklima automatisch an, wobei Sie via Smartphone oder Tablet die volle Kontrolle behalten. Bei minimalem Energieverbrauch genießen Sie höchsten Wohnkomfort und schonen die Umwelt.",
                        "attributes": "<ul><li>In Kombination mit einer NIBE Luft-/Wasser-Wärmepumpe bildet die NIBE VVM S320 Inneneinheit ein integriertes System für das Einfamilienhaus.</li><li>Anwenderfreundliche Touchscreen-Bedienung, integrierte Drahtlosverbindung sowie die energiesparende Smart-Technologie sorgen für höchsten Komfort.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000JA0v7QAD",
                                "type": "pdf",
                                "size": 1641419,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:16:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37aiQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000JA0vCQAT",
                                "type": "pdf",
                                "size": 514870,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:16:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37anQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000JA0vHQAT",
                                "type": "pdf",
                                "size": 4505784,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:15:55.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37asQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": "NIBE Kompaktspeicher VVM S320 (NEU)",
                        "seoKeyword": "VVM S320 Nibe",
                        "seoDescription": "Kompakte Inneneinheit NIBE VVM S320 mit sehr hohem Vorfertigungsgrad für Standardanwendungen im Einfamilienhaus. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000I37aVQAR",
                            "name": "Hauptbild NIBE VVM S320 - Heizungsmacher AG",
                            "alt": "NIBE VVM S320 Kompaktspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I37aTQAR",
                                "name": "Ansicht 1 NIBE VVM S320 - Heizungsmacher AG",
                                "alt": "NIBE VVM S320 Kompaktspeicher"
                            },
                            {
                                "id": "0690N00000I37aUQAR",
                                "name": "Ansicht 2 NIBE VVM S320 - Heizungsmacher AG",
                                "alt": "Moderne Küche mit Kompaktspeicher VVM S320 von NIBE"
                            },
                            {
                                "id": "0690N00000I37aYQAR",
                                "name": "Offertenbild NIBE VVM S320 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher WWS 405",
                        "price": 2765,
                        "id": "01t0N000009MBvoQAG",
                        "description": "Der Trinkwarmwasserspeicher WWS 405 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert durch hochwirksame Wärmedämmung die Wärmeverluste (Baugleich Alpha InnoTec). \r\n\r\nVolumen: 362 Liter (5.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 163 cm / Isolierung nicht abnehmbar\r\nKippmass: 172cm",
                        "attributes": "<ul><li>Durchmesser (mit Isolation, nicht demontierbar): 75cm</li><li>Höhe: 163cm</li><li>Kippmass: 172cm</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsTQAV",
                                "type": "pdf",
                                "size": 131119,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Trinkwarmwasserspeicher WWS 405-2 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:44:38.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXxQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLv0QAF",
                                "type": "pdf",
                                "size": 4424427,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Trinkwarmwasserspeicher WWS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:42:37.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYaGQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha innotec Trinkwarmwasserspeicher WWS 405.2",
                        "seoKeyword": "Alpha innotec WWS 405.2",
                        "seoDescription": "Der Trinkwarmwasserspeicher WWS 405 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert Wärmeverluste.",
                        "defaultImage": {
                            "id": "0690N00000Piwd5QAB",
                            "name": "Hauptbild NIBE alpha innotec WWS 405 - Heizungsmacher AG",
                            "alt": "NIBE WWS 405 Trinkwarmwasserspeicher (362 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000Piwd6QAB",
                                "name": "Offertbild NIBE alpha innotec WWS 405 - Heizungsmacher AG",
                                "alt": "NIBE WWS 405 Trinkwarmwasserspeicher (362 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WWS 507",
                        "price": 3522,
                        "id": "01t0N000009NrkwQAC",
                        "description": "Der Trinkwarmwasserspeicher WWS 507 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert durch hochwirksame Wärmedämmung die Wärmeverluste (Baugleich Alpha InnoTec).\r\n\r\nDurchmesser (mit Isolation, nicht demontierbar): 75cm\r\nKippmass: 203cm\r\nRegisterfläche: 7.0m2",
                        "attributes": "<ul><li>Durchmesser (mit Isolation, nicht demontierbar): 75cm</li><li>Höhe: 196cm</li><li>Kippmass: 203cm</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsUQAV",
                                "type": "pdf",
                                "size": 169890,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Trinkwarmwasserspeicher WWS 507-2 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:45:47.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXyQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLv0QAF",
                                "type": "pdf",
                                "size": 4424427,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Trinkwarmwasserspeicher WWS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:42:37.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYaGQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Trinkwarmwasserspeicher WWS 507.2",
                        "seoKeyword": "Alpha Innotec wws 507.2",
                        "seoDescription": "Der Trinkwarmwasserspeicher WWS 507 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert Wärmeverluste.",
                        "defaultImage": {
                            "id": "0690N00000PiwkKQAR",
                            "name": "Hauptbild NIBE alpha innotec WWS 507 - Heizungsmacher AG",
                            "alt": "NIBE WWS 507 Trinkwarmwasserspeicher (432 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwkLQAR",
                                "name": "Offertbild NIBE alpha innotec WWS 507 - Heizungsmacher AG",
                                "alt": "NIBE WWS 507 Trinkwarmwasserspeicher (432 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe LICV 82R1/3",
                        "price": 13860,
                        "id": "01t0N000009MCeHQAW",
                        "description": "Mit der neuen LICV 8.2R1/3 (innen) von NIBE (baugleich Novelan und Alpha Innotec LWCV) entscheiden Sie sich für eine vielseitige Modullösung. Sehr leise 38 dB(A) Schallleistung im Nachtbetrieb. 6.4 kW Heizleistung bei einer Aussentemperatur von -7°C.\r\n\r\nDie Geräte sind werkseitig mit folgenden Komponenten ausgestattet:\r\n– Wärmepumpenregler Luxtronik 2.1\r\n– Energieeffizienz-Umwälzpumpe für Heizung\r\n– Umschaltventil Heizung/ Trinkwasser\r\n– Überströmventil für den Heizkreis\r\n– Pufferspeicher 82 Liter für Heizung\r\n– Elektroheizelement zur Unterstützung der Heizung und Brauchwarmwasserladung\r\n– Ausdehnungsgefäss (18l)\r\n– Wärmemengenerfassung\r\n– Schwingungsentkopplung für den Heizkreis",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vLylYAAS",
                                "type": "pdf",
                                "size": 599943,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE LICV - Heizungsmacher AG",
                                "modified": "2022-01-21T09:11:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000ukjnzAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe LICV 82R1/3",
                        "seoKeyword": "Nibe LICV",
                        "seoDescription": "Mit der neuen LICV-Serie von NIBE entscheiden Sie sich für eine vielseitige und sehr leise Modullösung. ➤ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiwBUQAZ",
                            "name": "Offertbild LICV - Heizungsmacher AG",
                            "alt": "NIBE Wärmepumpe LICV 8.2R1/3 (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwBPQAZ",
                                "name": "Bild 1 LICV - Heizungsmacher AG",
                                "alt": "Einzelteile der NIBE Wärmepumpe LICV 8.2R1/3 (innen)"
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe LICV 122R1/3",
                        "price": 15408,
                        "id": "01t0N000009NbvBQAS",
                        "description": "Mit der neuen LICV 12.2R1/3 (innen) von NIBE (baugleich Novelan und Alpha Innotec LWCV) entscheiden Sie sich für eine vielseitige Modullösung. Leise 43dB(A) Schallleistung im Nachtbetrieb. 8.5 kW Heizleistung bei einer Aussentemperatur von -7°C. Die Geräte sind werkseitig mit folgenden Komponenten ausgestattet:\r\n– Wärmepumpenregler Luxtronik 2.1\r\n– Energieeffizienz-Umwälzpumpe für Heizung\r\n– Umschaltventil Heizung/ Trinkwasser\r\n– Überströmventil für den Heizkreis\r\n– Pufferspeicher 82 Liter für Heizung\r\n– Elektroheizelement zur Unterstützung der Heizung und Brauchwarmwasserladung\r\n– Ausdehnungsgefäss (12l)\r\n– Wärmemengenerfassung\r\n– Schwingungsentkopplung für den Heizkreis",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vLylYAAS",
                                "type": "pdf",
                                "size": 599943,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE LICV - Heizungsmacher AG",
                                "modified": "2022-01-21T09:11:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000ukjnzAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe LICV 122R1/3",
                        "seoKeyword": "Nibe LICV 122R1/3",
                        "seoDescription": "Mit der neuen LICV-Serie von NIBE entscheiden Sie sich für eine vielseitige Modullösung. Alle Vorteile erfahren Sie bei uns!",
                        "defaultImage": {
                            "id": "0690N00000PiwAqQAJ",
                            "name": "Offertbild LICV - Heizungsmacher AG",
                            "alt": "NIBE Wärmepumpe LICV 12.2R1/3 (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000Piw86QAB",
                                "name": "Bild 2 LICV Einzelteile - Heizungsmacher AG",
                                "alt": "Einzelteile der NIBE Wärmepumpe LICV 12.2R1/3 (innen)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hydraulikmodul Vitocal 200-A",
                        "price": 2666,
                        "id": "01t0N000009OlebQAC",
                        "description": "Inneneinheit mit Wärmepumpenregelung Vitotronic 200, Hocheffizienz- Umwälzpumpe für den Sekundärkreis, eingebautem Heizwasser- Durchlauferhitzer (Notheizung), 3- Wege- Umschaltventil und Sicherheitsgruppe. Inklusive benötigter Temperatursensoren und Vitoconnect 100, Typ OPTO1.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MekCDQAZ",
                                "type": "pdf",
                                "size": 343061,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Viessmann Vitocal 200-A - Heizungsmacher AG",
                                "modified": "2020-04-22T12:33:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NVQsmQAH",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Hydraulikmodul Vitocal 200-A ➤ Inneneinheit mit Wärmepumpenregelung ✔️ Hocheffizienz- Umwälzpumpe ✔️ Temperatursensoren ✔️",
                        "defaultImage": {
                            "id": "0690N0000072DZrQAM",
                            "name": "Hauptbild Viessmann Hydraulikmodul Vitocal-200",
                            "alt": "Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072DZvQAM",
                                "name": "Schnitt Viessmann Hydraulikmodul Vitocal-200",
                                "alt": "Schnitt Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                            },
                            {
                                "id": "0690N00000NVQrPQAX",
                                "name": "Offertbild Viessmann Hydraulikeinheit Vitocal-200 - Heizungsmacher AG",
                                "alt": "Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                            },
                            {
                                "id": "0690N00000NV8bOQAT",
                                "name": "Vicare-App",
                                "alt": null
                            },
                            {
                                "id": "0690N00000NV8bTQAT",
                                "name": "Vitotronic-Regelung",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hochleistungs-Photovoltaikmodul MAXEON (430Wp)",
                        "price": 386,
                        "id": "01t5p00000CRBDmAAP",
                        "description": "Mit über 30-Jahre Produkterfahrung gehört SunPower zu den Pionierunternehmen der Solarbranche und konnte schon mit Projekten für die NASA oder Solar Impulse seine Qualität unter Beweis stellen. Solarexperten wissen, dass SunPower Module die widerstandsfähigsten auf dem Markt sind. Mit 25 Jahre Produkt-und Leistungs Garantie ist SunPower die Benchmark bei Garantieleistungen und dadurch die Referenz für Zuverlässigkeit und Langlebigkeit. Die Maxeon® Solarzellen sind grundlegend anders und besser. Durch die Kupferbasis bieten die Solarzellen eine beispiellose Zuverlässigkeit und ermöglichen die Entwicklung von Modulen mit einem der weltweit höchsten Wirkungsgrade im Markt.\r\n\r\nModulwirkungsgrad: 22.70 %\r\nLänge: 1812 mm\r\nBreite: 1046 mm",
                        "attributes": "<ul><li>Modulleistung<ul><li>430 Wp</li></ul></li><li>Typ<ul><li><span style=\"font-size: 13px;\">SunPower MAXEON 3</span></li></ul></li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001Apgs7AAB",
                                "type": "pdf",
                                "size": 734161,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Sunpower Maxeon 3 430 Wp - Heizungsmacher AG",
                                "modified": "2022-10-21T10:58:41.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001AxU7JAAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Sunpower",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Hochleistungs-Module"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Sunpower Hochleistungs-Photovoltaikmodul MAXEON (430Wp) ➤ Kupferbasis bietet eine beispiellose Zuverlässigkeit ✔️ SunPower MAXEON 3 ✔️",
                        "defaultImage": {
                            "id": "0695p00000wr3MOAAY",
                            "name": "Hauptbild Sunpower Maxeon 3 430 Wp - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Hochleistungs-Photovoltaikmodul MAXEON (420Wp Schwarz)",
                        "price": 386,
                        "id": "01t5p00000CRpq2AAD",
                        "description": "Mit über 30-Jahre Produkterfahrung gehört SunPower zu den Pionierunternehmen der Solarbranche und konnte schon mit Projekten für die NASA oder Solar Impulse seine Qualität unter Beweis stellen. Solarexperten wissen, dass SunPower Module die widerstandsfähigsten auf dem Markt sind. Mit 25 Jahre Produkt-und Leistungs Garantie ist SunPower die Benchmark bei Garantieleistungen und dadurch die Referenz für Zuverlässigkeit und Langlebigkeit. Die Maxeon® Solarzellen sind grundlegend anders und besser. Durch die Kupferbasis bieten die Solarzellen eine beispiellose Zuverlässigkeit und ermöglichen die Entwicklung von Modulen mit einem der weltweit höchsten Wirkungsgrade im Markt.\r\n\r\nModulwirkungsgrad: 22.20 %\r\nLänge: 1812 mm\r\nBreite: 1046 mm",
                        "attributes": "<ul><li>Modulleistung<ul><li>420 Wp</li></ul></li><li>Typ<ul><li><span style=\"font-size: 13px;\">SunPower MAXEON 3 BLACK</span></li></ul></li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001ApgwXAAR",
                                "type": "pdf",
                                "size": 693284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Sunpower Maxeon 3 420 Wp black - Heizungsmacher AG",
                                "modified": "2022-10-21T10:58:02.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001AxUBeAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Sunpower",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Hochleistungs-Module"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Sunpower Hochleistungs-Photovoltaikmodul MAXEON (420Wp Schwarz) ➤ SunPower MAXEON 3 BLACK ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p00000zfEU6AAM",
                            "name": "Sunpower Maxeon 3 420 Wp Black - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpenregler SMO S40",
                        "price": 1425,
                        "id": "01t5p00000BozQFAAZ",
                        "description": "Der neue SMO S40 ist ein äusserst flexibel einsetzbares Regelgerät für NIBE Wärmepumpen, das sehr viele Anwendungsmöglichkeiten abdeckt. Umfangreiche, übersichtliche Einstellungen und die integrierte Internetverbindung via www.myuplink.com runden das Einsatzspektrum ab.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000XXJ7KAAX",
                                "type": "pdf",
                                "size": 617382,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE SMO S40 - Heizungsmacher AG",
                                "modified": "2020-12-08T08:39:46.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jB4AAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p00000XXJ7LAAX",
                                "type": "pdf",
                                "size": 188054,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE MyUplink Internetverbindung - Heizungsmacher AG",
                                "modified": "2020-12-08T08:39:32.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jB5AAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p00000XXJ7UAAX",
                                "type": "pdf",
                                "size": 3917056,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE SMO S40 - Heizungsmacher AG",
                                "modified": "2020-12-08T08:37:44.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jBEAAZ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "NIBE Wärmepumpenregler SMO S40 ➤ Flexibel einsetzbares Regelgerät ✔️ Viele Anwendungsmöglichkeiten ✔️ NIBE ✔️",
                        "defaultImage": {
                            "id": "0695p00000X9jBAAAZ",
                            "name": "Hauptbild NIBE SMO S40 - Heizungsmacher AG",
                            "alt": "NIBE SMO S40 Wärmepumpenregler"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000X9jB9AAJ",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": "Smartphone mit Applikation zum NIBE SMO S40 Wärmepumpenregler"
                            },
                            {
                                "id": "0695p00000X9jBJAAZ",
                                "name": "Offertenbild NIBE SMO S40 Regler - Heizungsmacher AG",
                                "alt": "NIBE SMO S40 Wärmepumpenregler"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S1155-6",
                        "price": 11310,
                        "id": "01t0N00000BCnC8QAL",
                        "description": "NIBE S1155-6 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 139</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-6",
                        "seoKeyword": "Nibe s1155-6",
                        "seoDescription": "Die NIBE S1155 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Mehr dazu hier!",
                        "defaultImage": {
                            "id": "0690N00000L5U9bQAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-6"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U9gQAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-6"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-6 PC",
                        "price": 13284,
                        "id": "01t0N00000BCnCFQA1",
                        "description": "NIBE S1155-6 PC ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung, Kühlung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 139</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "NIBE Wärmepumpe S1155-6 PC - Erfahren Sie bei Heizungsmacher alle Infos über dieses Produkt und fordern Sie sofort einen Expertenrat an!",
                        "defaultImage": {
                            "id": "0690N00000L5U9qQAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U9vQAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-12",
                        "price": 13675,
                        "id": "01t0N00000BCnByQAL",
                        "description": "NIBE S1155-12 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 3–12 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 167</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-12",
                        "seoKeyword": "Nibe S1155-12",
                        "seoDescription": "NIBE S1155 ist eine intelligente Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird nach Bedarf gewählt.",
                        "defaultImage": {
                            "id": "0690N00000L5U97QAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-12"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U98QAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-12"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-16",
                        "price": 14149,
                        "id": "01t0N00000BCc7aQAD",
                        "description": "NIBE S1155-16 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 4–16 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620 </li><li>Leergewicht (kg)172 </li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-16",
                        "seoKeyword": "Nibe S1155-16",
                        "seoDescription": "NIBE S1155 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Alle Infos bei uns!",
                        "defaultImage": {
                            "id": "0690N00000L4RGIQA3",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-16"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L4RInQAN",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-16"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S1255-6",
                        "price": 14396,
                        "id": "01t0N00000BCnCkQAL",
                        "description": "NIBE S1255-6 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 220</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-6",
                        "seoKeyword": "Nibe S1255-6",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung. Hier alle Infos!",
                        "defaultImage": {
                            "id": "0690N00000L5UDjQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDkQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-12",
                        "price": 15716,
                        "id": "01t0N00000BCnChQAL",
                        "description": "NIBE S1255-12 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 3–12 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 250</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-12",
                        "seoKeyword": "Nibe s1255-12",
                        "seoDescription": "NIBE S1255 ist eine intelligente Erdwärmepumpe mit integrierter Brauchwasserbereitung. Die Wärmepumpe bietet höchstes Einsparpotenzial. ➤ Mehr hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UDnQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-12"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDsQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-12"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-6 PC",
                        "price": 16331,
                        "id": "01t0N00000BCnCrQAL",
                        "description": "NIBE S1255-6 PC ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Kühlung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 220</li><li>Mit integrierter Kühlung</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-6 PC",
                        "seoKeyword": "Nibe s1255 PC",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe mit integrierter Brauchwasserbereitung. ➤ Weitere Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UDdQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6 PC"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDiQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6 PC"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-16",
                        "price": 17109,
                        "id": "01t0N00000BCnCNQA1",
                        "description": "NIBE S1255-16 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 4–16 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 255 </li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-16",
                        "seoKeyword": "Nibe S2155-16",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung. Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UCLQA3",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-16"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UCMQA3",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-16"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher HSBC 300 L",
                        "price": 5655,
                        "id": "01t0N000009MR6PQAW",
                        "description": "Beim Integralspeicher HSBC 300 L cool stehen nicht zwei Einzelspeicher nebeneinander, sondern Trinkwarmwasser- und Pufferspeicher sind übereinander in einem Gerät angeordnet. Der Platzbedarf wird so quasi halbiert und eine Aufstellung auf engstem Raum wird möglich – für mehr Nutzfläche.\r\n\r\nPräzise abgestimmt auf die Nutzung mit einer Wärmepumpe deckt der HSBC L cool alles ab, was man in einem Einfamilienhaus in Bezug auf Wärme und Trinkwarmwasser benötigt.\r\n\r\nNenninhalt Trinkwarmwasser: 270 Liter\r\nNenninhalt Pufferspeicher: 100 Liter",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdtNMQAZ",
                                "type": "pdf",
                                "size": 129463,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron HSBC 300 L Kompaktspeicher - Heizungsmacher AG",
                                "modified": "2022-05-17T09:31:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsRSQA1",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kompaktspeicher HSBC 300 L cool",
                        "seoKeyword": "Stiebel Eltron HSBC 300 L cool",
                        "seoDescription": "Beim Integralspeicher HSBC 300 L cool sind Trinkwarmwasser- und Pufferspeicher platzsparend übereinander in einem Gerät angeordnet. Hier alle Infos!",
                        "defaultImage": {
                            "id": "0690N00000NWsTNQA1",
                            "name": "Offertenbild Stiebel Eltron HSBC 300 L - Heizungsmacher AG",
                            "alt": "Stiebel Eltron HSBC 300 L Kompaktspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014zaqOAAQ",
                                "name": "Hauptbild Stiebel Eltron HSBC 300 L - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "WPL 09 ICS classic",
                        "price": 12579,
                        "id": "01t5p00000CQ9x7AAD",
                        "description": "Diese Luft-Wasser-Wärmepumpe ist für die kompakte Innenaufstellung konzipiert. Hohe Flexibilität bei Aufstellung und Installation Invertertechnologie für hohe Effizienz und niedrige Energiekosten. \r\n- Integrierter Wärmepumpenmanager\r\n- Vorlauftemperaturen von bis zu 60 °C\r\n- Max. Heizleistung bei A-7/W35: 4,23 kW\r\n- Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aJKOAA2",
                                "type": "pdf",
                                "size": 142945,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt WPL 09 ICS classic - Heizungsmacher AG",
                                "modified": "2022-06-07T06:06:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000013VmHHAA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron WPL 09 ICS classic ➤ Luft/Wasser-Wärmepumpe ✔️ Kompakte Innenaufstellung ✔️ Hohe Effizienz ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013VmEAAA0",
                            "name": "Hauptbild WPL 09 17 ICS classic - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000013VmHIAA0",
                                "name": "Offertenbild Stiebel Eltron WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000013VmJ8AAK",
                                "name": "Bild 2 WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WPL 17 ICS classic",
                        "price": 14718,
                        "id": "01t0N00000A3Cd0QAF",
                        "description": "Diese Luft-Wasser-Wärmepumpe ist für die kompakte Innenaufstellung konzipiert. Hohe Flexibilität bei Aufstellung und Installation Invertertechnologie für hohe Effizienz und niedrige Energiekosten.\r\n- Integrierter Wärmepumpenmanager\r\n- Vorlauftemperaturen von bis zu 60 °C\r\n- Max. Heizleistung bei A-7/W35: 8.02 kW\r\n- Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aJKjAAM",
                                "type": "pdf",
                                "size": 142325,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt WPL 17 ICS classic - Heizungsmacher AG",
                                "modified": "2022-06-07T06:08:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000013VmHhAAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron WPL 17 ICS classic ➤ Luft/Wasser-Wärmepumpe ✔️ Hohe Flexibilität ✔️ Invertertechnologie für hohe Effizienz ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmHbAAK",
                            "name": "Hauptbild WPL 09 17 ICS classic - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000013VmHgAAK",
                                "name": "Offertenbild Stiebel Eltron WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000013VmJ9AAK",
                                "name": "Bild 2 WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Batteriespeicher RESU10",
                        "price": 5320,
                        "id": "01t0N00000BCkSzQAL",
                        "description": "Die kompakte und leichtgewichtige Natur des RESU ist erstklassig. Das System ist besonders einfach zu installieren. Hochwertiger Niedervolt-Akku vom Markenhersteller LG. \r\n\r\nNutzbare Kapazität: 8.8 kWh bei 90% Entladetiefe.\r\nMax. DC-Ausgangsleistung: 5.0 kW",
                        "attributes": "<ul><li>Nutzbare Kapazität: 8.8 kWh bei 90% Entladetiefe.</li><li>DC-Ausgangsleistung: 5.0 kW</li><li>DC-Nennspannung: 51.80 V</li><li>Batterietechnologie: Lithium-Nickel-Mangan-Kobaltoxid</li><li>Länge: 452 mm</li><li>Breite: 227 mm</li><li>Höhe: 483 mm</li><li>Gewicht: 75 kg</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014kOzQAAU",
                                "type": "pdf",
                                "size": 281238,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt LG Chem RESU 65 10 12 - Heizungsmacher AG",
                                "modified": "2022-07-04T22:01:50.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wRYKAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "LG Chem",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "LG Chem Batteriespeicher RESU10 ➤ Kompakt und leichtgewichtig ✔️ Einfache Installation ✔️ Hochwertiger Niedervolt-Akku ✔️",
                        "defaultImage": {
                            "id": "0695p00000hP44YAAS",
                            "name": "RESU 10",
                            "alt": "LG Chem Batteriespeicher RESU10"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5ONEQA3",
                                "name": "Bild 1 LG Chem Akku RESU 6.5-10 - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Batteriespeicher RESU12",
                        "price": 6840,
                        "id": "01t5p00000CRBInAAP",
                        "description": "Die kompakte und leichtgewichtige Natur des RESU ist erstklassig. Das System ist besonders einfach zu installieren. Hochwertiger Niedervolt-Akku vom Markenhersteller LG. \r\n\r\nNutzbare Kapazität: 11.7 kWh bei 90% Entladetiefe.\r\nMax. DC-Ausgangsleistung: 5.0 kW",
                        "attributes": "<ul><li>Nutzbare Kapazität: 11.7 kWh bei 90% Entladetiefe.</li><li>DC-Ausgangsleistung: 5.0 kW</li><li>DC-Nennspannung: 51.80 V</li><li>Batterietechnologie: Lithium-Nickel-Mangan-Kobaltoxid</li><li>Länge: 452 mm</li><li>Breite: 227 mm</li><li>Höhe: 625 mm</li><li>Gewicht: 75 kg</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014kOzQAAU",
                                "type": "pdf",
                                "size": 281238,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt LG Chem RESU 65 10 12 - Heizungsmacher AG",
                                "modified": "2022-07-04T22:01:50.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wRYKAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "LG Chem",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "LG Chem Batteriespeicher RESU12 ➤ Kompakt & leichtgewichtig ✔️ Einfache Installation ✔️ Hochwertiger Niedervolt-Akku ✔️",
                        "defaultImage": {
                            "id": "0695p00000wr8GfAAI",
                            "name": "LG RESU 12",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5ONEQA3",
                                "name": "Bild 1 LG Chem Akku RESU 6.5-10 - Solarmacher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE5K-RWS 48V",
                        "price": 2710.1,
                        "id": "01t0N00000BBqAFQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 5 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE5K-RWS 48V\r\n ➤ Einfache Installation ✔️ Dreiphasige Installationen mit Batteriespeicher 5 kVA ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LG4QAN",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LG9QAN",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE7K-RWS 48V",
                        "price": 2946.3,
                        "id": "01t0N00000BBqAKQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 7 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE7K-RWS 48V\r\n ➤ Ein einziger Wechselrichter ✔️ Dreiphasige Installationen ✔️",
                        "defaultImage": {
                            "id": "0695p00001Q1a7MAAR",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGYQA3",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE8K-RWS 48V",
                        "price": 3029.2,
                        "id": "01t0N00000BBqAUQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 8 kVA\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE8K-RWS 48V ➤ Dreiphasige Installationen mit Batteriespeicher 8 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LGZQA3",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGAQA3",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE10K-RWS 48V",
                        "price": 3206.9,
                        "id": "01t0N00000BBqAZQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 10 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE10K-RWS 48V ➤ Dreiphasige Installationen mit Batteriespeicher 10 kVA ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LGnQAN",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGaQAN",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Kombispeicher SBS 601 W",
                        "price": 3770,
                        "id": "01t0N0000098rATQAY",
                        "description": "SBS 601 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung. \r\n\r\nNenninhalt: 613 Liter\r\nDurchmesser ohne Isolation: 750mm\r\nDurchmesser mit Isolation: 970mm\r\nHöhe mit Isolation: 1775mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMKJQA3",
                                "type": "pdf",
                                "size": 299445,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 601 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:23:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYwvQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 601 W",
                        "seoKeyword": "Stiebel Eltron SBS 601 W",
                        "seoDescription": "SBS 601 Heizungs-Pufferspeicher ✅ integrierte Trinkwarmwasserbereitung ✅ hygienische Warmwasseraufbereitung. Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiZ2UQAV",
                            "name": "Hauptbild SBS 600 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 601 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072G5jQAE",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 601 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYufQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 601 W"
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher SBS 801 W",
                        "price": 3959,
                        "id": "01t0N0000098rAdQAI",
                        "description": "SBS 801 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung.\r\n\r\nNenninhalt: 759 Liter\r\nDurchmesser ohne Isolation: 790mm\r\nDurchmesser mit Isolation: 1010mm\r\nHöhe mit Isolation: 1940mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMHFQA3",
                                "type": "pdf",
                                "size": 299276,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 801 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:13:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYviQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 801 W",
                        "seoKeyword": "SBS 801 W",
                        "seoDescription": "Stiebel Eltron SBS 801 Heizungs-Pufferspeicher ✅ Trinkwarmwasserbereitung im Durchflussbetrieb ✅ hygienische Warmwasseraufbereitung. Mehr dazu hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYvoQAF",
                            "name": "Hauptild SBS 800 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 801 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072GQIQA2",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 801 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYvnQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 801 W"
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher SBS 1001 W",
                        "price": 4676,
                        "id": "01t0N0000098rAnQAI",
                        "description": "SBS 1001 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung.\r\n\r\nNenninhalt: 941 Liter\r\nDurchmesser ohne Isolation: 790mm\r\nDurchmesser mit Isolation: 1010mm\r\nHöhe mit Isolation: 2350mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMGhQAN",
                                "type": "pdf",
                                "size": 299337,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 1001 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:15:54.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYtjQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 1001 W",
                        "seoKeyword": "Kombispeicher SBS 1001 W",
                        "seoDescription": "SBS 1001 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Garantierte hygienische Warmwasseraufbereitung.",
                        "defaultImage": {
                            "id": "0690N00000PiYugQAF",
                            "name": "Hauptbild SBS 1000 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 1001 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072H69QAE",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 1001 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYtiQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 1001 W"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Meyer Burger Black 390 Wp",
                        "price": 252,
                        "id": "01t5p00000D3R3VAAV",
                        "description": "Das neue Meyer Burger Heterojunction Modul bietet bis zu 20 Prozent mehr Energieertrag. Produktion der Solarzellen und -module nach höchsten Standards ausschließlich in Deutschland mit Schweizer Technologie.\r\n\r\nDie Meyer Burger HJT-Zellen nehmen deutlich mehr Sonnenenergie als herkömmliche Zellen auf und der umgewandelte elektrische Strom fliesst mit weniger Widerstand durch das Modul. Auch bei schwachem Sonnenlicht, beispielsweise wenn es bewölkt ist.\r\n\r\nZusätzliche Effizienzgewinne bringt die Verbindung der Zellen durch unsere patentierte SmartWire-Technologie. Denn die hauchdünnen Verbindungsdrähte verringern die Abschattung auf den Solarzellen um bis zu 30 Prozent. Die Module nehmen so mehr Energie auf und produzieren länger Strom. Für spürbar mehr Leistung pro Fläche auf dem Dach. Und nicht nur das.\r\n\r\nSWCT verbessert auch die Zellstabilität und macht die Module weniger anfällig für Mikrorisse, einem der häufigsten Gründe für Energieverluste bei Solarmodulen.\r\n\r\nModulwirkungsgrad: 21.5 %\r\nLänge: 1767 mm\r\nBreite: 1041 mm\r\nHöhe: 35 mm\r\nGewicht: 19.7 kg",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0lJvAAJ",
                                "type": "pdf",
                                "size": 630080,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Meyer Burger Black - Heizungsmacher AG",
                                "modified": "2023-06-09T11:28:37.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001I3hTOAAZ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Meyer Burger",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Made-in-Germany"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Meyer Burger Black 390 Wp Photovoltaikmodul - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001I3hTiAAJ",
                            "name": "Hauptbild Meyer Burger Photovoltaikmodul Black - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher PU 200",
                        "price": 752,
                        "id": "01t5p00000CMHlMAAX",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 190 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 60 x 121 cm (43 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 200 - Alle Produktinformationen bei Heizungsmacher ansehen, Vergleiche ziehen und persönliche Beratung erhalten!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yxTaAAI",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 300",
                        "price": 912,
                        "id": "01t5p00000CLuqtAAD",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 282 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 65 x 147 cm (61 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 300 - Bei Heizungsmacher finden Sie nicht nur spezifische Produktdetails, sondern erhalten auch eine umfassende Beratung!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXRaAAM",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 400",
                        "price": 1195,
                        "id": "01t5p00000D1MfQAAV",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 377 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 140 cm (70 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 400 - Sichern Sie sich jetzt bei Heizungsmacher wertvolle Infos über die Produktdetails und fordern Sie eine Beratung an!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Fe5E1AAJ",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 500",
                        "price": 1224,
                        "id": "01t5p00000CwnWpAAJ",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 479 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 174 cm (80 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 500 - Alle Produktdetails im detaillierten Überblick bei Heizungsmacher und direkte Expertenberatung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001AJL5cAAH",
                                "name": "hpa Puffer-neu",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PSF 1000",
                        "price": 2998,
                        "id": "01tVj000006Qp8IIAS",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, Isolierung aus Hightech Faservlies. Skaimantel mit Reissverschluss, inklusive Rosetten und Abdeckhaube. Silber.\r\n\r\nVolumen: 887 Liter\r\nMasse ohne Isolierung: ⌀xH: 79 x 204 cm (111kg) (ohne Isolierung)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B2QcnIAF",
                                "type": "pdf",
                                "size": 339263,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PSF - Heizungsmacher AG",
                                "modified": "2024-10-26T13:01:01.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj00000AsdD7IAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 500 - Alle Produktdetails im detaillierten Überblick bei Heizungsmacher und direkte Expertenberatung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj00000AscowIAB",
                                "name": "PSF1000 Offertbild",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 300",
                        "price": 1955,
                        "id": "01t5p00000CMHmTAAX",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 304 Liter (3.2 m² Registeroberfläche)\r\nMasse mit/ohne Isolierung: ⌀xH: 65 x 157 cm (141 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 300 - Hier bei Heizungsmacher genau über das Produkt informieren und eine umfassende Beratung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yy3eAAA",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 400",
                        "price": 2318,
                        "id": "01t5p00000CLutmAAD",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 408 Liter (4.3 m² Registeroberfläche)\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 150 cm (179 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 400 - Bei Heizungsmacher alle Produktdetails erhalten und eine kompetente und persönliche Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXbyAAE",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 500",
                        "price": 2977,
                        "id": "01t5p00000D1R1IAAV",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 498 Liter (5.4 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 180 cm (217 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p00001DLYPqAAP",
                            "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXZ9AAM",
                                "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 600",
                        "price": 3069,
                        "id": "01tVj000005aQ9BIAU",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 559 Liter (5.4 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 200 cm (228kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000009AEWDIA4",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 800",
                        "price": 4898,
                        "id": "01tVj000003BxB8IAK",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. Mehrschicht-Isolierung aus 80 mm Hartschaum + 20 mm Faservlies. PS-Mantel und Hakenleiste, inklusive Rosetten und Abdeckhaube. Brandschutzklasse B2. Silber\r\n\r\nVolumen: 830 Liter (6.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 199 cm (291 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000004q6LeIAI",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG (2)",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 1000",
                        "price": 5680,
                        "id": "01t5p00000CxBO2AAN",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. Mehrschicht-Isolierung aus 80 mm Hartschaum + 20 mm Faservlies. PS-Mantel und Hakenleiste, inklusive Rosetten und Abdeckhaube. Brandschutzklasse B2. Silber\r\n\r\nVolumen: 925 Liter (6.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 219 cm (308 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 1000 - Bei Heizungsmacher tiefgehende Produktdetails einsehen und sofort einen Expertenrat anfordern!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001ALDEBAA5",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG (1)",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WSW196i.2-6",
                        "price": 11039,
                        "id": "01t5p00000CMH8DAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 2-5.85 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-6 - Hier bei Heizungsmacher detailierte Produktdetails finden und bei Bedarf eine gezielte Beratung erhalten!",
                        "defaultImage": {
                            "id": "0695p000014yujQAAQ",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-8",
                        "price": 11230,
                        "id": "01t5p00000CMH8yAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 2-7.61 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-8 - Bei Heizungsmacher finden Sie genaue Produktdetails und können sich ausführlich informieren und beraten lassen!",
                        "defaultImage": {
                            "id": "0695p000014yujRAAQ",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-12",
                        "price": 12467,
                        "id": "01t5p00000CMH9DAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 3-12 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-12 - Erfahren Sie hier bei Heizungsmacher mehr über das Produkt und erhalten Sie um eine professionelle Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yujzAAA",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-16",
                        "price": 12657,
                        "id": "01t5p00000CMH9VAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 4-15 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-16 - Hier bei Heizungsmacher detaillierte Produktdetails ansehen und professionelle Beratung sichern!",
                        "defaultImage": {
                            "id": "0695p000014yulvAAA",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WSW196i.2-6 TP50",
                        "price": 12134,
                        "id": "01t5p00000CMH96AAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal. \r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 61x60x178cm\r\nHeizleistung: 2-5.85 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-6 TP50 - Bei Heizungsmacher mehr über die umfassenden Produktdetails erfahren und individuelle Beratung verlangen!",
                        "defaultImage": {
                            "id": "0695p000014yuitAAA",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yukOAAQ",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-8 TP50",
                        "price": 12322,
                        "id": "01t5p00000CMH9fAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 2-7.61 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-8 TP50 - Schauen Sie sich bei Heizungsmacher die Produktdetails an und erhalten Sie eine professionelle Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yul2AAA",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yul3AAA",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-12 TP50",
                        "price": 13451,
                        "id": "01t5p00000CMH9pAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 3-12 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-12 TP50 - Bei Heizungsmacher detaillierte Produktdetails einsehen und qualifizierte Beratung schnell erhalten!",
                        "defaultImage": {
                            "id": "0695p000014zvUsAAI",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-16 TP50",
                        "price": 13639,
                        "id": "01t5p00000CMH9vAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 4-15 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-16 TP50 - Entdecken Sie bei Heizungsmacher präzise Produktdetails und sichern Sie fundierte Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yulHAAQ",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yulMAAQ",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe Vitocal 250-A06",
                        "price": 9725,
                        "id": "01t5p00000DDEbZAAX",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 0.02 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C.\r\n\r\nTyp: \t250-A AWO-M-E-AC 251.A06\r\nLeistung bei A-7/W35: 5.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Wärmepumpe Vitocal 250-A8 - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001T830nAAB",
                            "name": "Hauptbild Viessmann Vitocal 250-A08 - Heizungsmacher AG (1)",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T820XAAR",
                                "name": "Offertenbild Viessmann 250-A klein - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A08",
                        "price": 10438,
                        "id": "01t5p00000Cy8UoAAJ",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 0.02 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C.\r\n\r\nTyp: \t250-A AWO-M-E-AC 251.A08\r\nLeistung bei A-7/W35: 6.5 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Wärmepumpe Vitocal 250-A8 - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001I3GGvAAN",
                            "name": "Hauptbild Viessmann Vitocal 250-A08 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001MDACyAAP",
                                "name": "Offertenbild Viessmann 250-A klein - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A10",
                        "price": 12271,
                        "id": "01t5p00000CPr4LAAT",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 3 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C – und das bei einer Außentemperatur von bis zu –15 °C. \r\n\r\nTyp: AWO-E-AC-AF 251.A10\r\nLeistung bei A-7/W35: 9.7 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Viessmann Vitocal 250-A10 ➤ Viessmann Wärmepumpentechnik ✔️ Modernes Design ✔️ „Grünes“ Kältemittel R290 (Propan) ✔️",
                        "defaultImage": {
                            "id": "0695p00001I3G8XAAV",
                            "name": "Hauptbild Viessmann Vitocal 250-A - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001I3G8hAAF",
                                "name": "Offertbild Vitocal 250-A 2-fach",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A13",
                        "price": 12944,
                        "id": "01t5p00000CPr4IAAT",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 3 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C – und das bei einer Außentemperatur von bis zu –15 °C. \r\n\r\nTyp: AWO-E-AC-AF 251.A13\r\nLeistung bei A-7/W35: 11.1 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Viessmann Vitocal 250-A13 ➤ Viessmann Wärmepumpentechnik ✔️ Modernes Design ✔️ „Grünes“ Kältemittel R290 (Propan ✔️",
                        "defaultImage": {
                            "id": "0695p00000zgeHKAAY",
                            "name": "Hauptbild Viessmann Vitocal 250-A - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000ppXzNAAU",
                                "name": "Offertbild Vitocal 250-A 2-fach",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Viessmann Vitocal 250-A Hydraulikeinheit",
                        "price": 2909,
                        "id": "01t5p00000CPr4XAAT",
                        "description": "Mit neuer Hydro AutoControl Hydraulik zur Bereitstellung der Notwendigen\r\nAbtauenergie und Sicherstellung der erforderlichen Mindest-Umlaufmenge im Wärmepumpen Kreis\r\n- Mit eingebauter Hocheffizienz-Umwälzpumpe für den Heizkreis\r\n- Abtaupuffer 16 l\r\n- Bypassfunktion\r\n- Heizwasser Durchlauferhitzer\r\n- 4-3-Wegeventil\r\n- Volumenstromsensor\r\n\r\nBedienung über 7-Zoll-Farb-Touchdisplay mit Klartext- und Grafikanzeige,\r\nInbetriebnahme-Assistenten, Anzeigen für Energieverbräuche sowie\r\nalternativ Bedienung über mobiles Endgerät, Internetfähig durch integrierte\r\nWLAN-Schnittstelle für Bedienung und Service über Viessmann Apps.\r\n\r\nFür 1 Heizkreis.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0K2EAAV",
                                "type": "pdf",
                                "size": 233279,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Viessmann Vitocal 250A - Heizungsmacher AG",
                                "modified": "2023-06-06T05:52:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GN8AAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T822xAAB",
                            "name": "Hauptbild Viessmann Vitocal 250-A Hydraulikeinheit - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000ppYNyAAM",
                                "name": "Offertbild Vitocal 250-A Hydrailikeinheit 1HK",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T820YAAR",
                                "name": "Hauptbild Viessmann Vitocal 250-A Inneneinheit - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher Vitocal 252-A",
                        "price": 4802,
                        "id": "01t5p00000CPr4iAAD",
                        "description": "Die bodenstehende Kompakt-Einheit ist die intelligente Kombination aus effizienter Wärmepumpentechnik und hohem Warmwasserkomfort – dank integriertem 190-Liter-Warmwasserspeicher in einem Gehäuse. \r\n\r\nMit neuer Hydro AutoControl Hydraulik zur Bereitstellung der Notwendigen\r\nAbtauenergie und Sicherstellung der erforderlichen Mindest-Umlaufmenge im Wärmepumpen Kreis\r\n- Mit eingebauter Hocheffizienz-Umwälzpumpe für den Heizkreis\r\n- Abtaupuffer 16 l\r\n- Bypassfunktion\r\n- Heizwasser Durchlauferhitzer\r\n- 4-3-Wegeventil\r\n- Volumenstromsensor\r\n\r\nBedienung über 7-Zoll-Farb-Touchdisplay mit Klartext- und Grafikanzeige,\r\nInbetriebnahme-Assistenten, Anzeigen für Energieverbräuche sowie\r\nalternativ Bedienung über mobiles Endgerät, Internetfähig durch integrierte\r\nWLAN-Schnittstelle für Bedienung und Service über Viessmann Apps.\r\n\r\nFür 1 Heizkreis.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0JwQAAV",
                                "type": "pdf",
                                "size": 260604,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Viessmann Vitocal 252A - Heizungsmacher AG",
                                "modified": "2023-06-06T05:41:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXn1AAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Kompaktspeicher Vitocal 252-A - Bei Heizungsmacher alle Produktdetails kennenlernen und zielgerichtete Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00000ppYXQAA2",
                            "name": "Offertbild Vitocal 250-A Kompakteinheit 1HK",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014ygjwAAA",
                                "name": "viessmann-waermepumpe-252A-2C_5",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yh1zAAA",
                                "name": "Vitocal 252-A_Inneneinheit_Schnitt",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "SMA Hybrid Sunny Tripower 5.0 Smart Energy",
                        "price": 2212,
                        "id": "01t5p00000CRAkyAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 5.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 5 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8AAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 6.0 Smart Energy",
                        "price": 2548,
                        "id": "01t5p00000CRAkUAAX",
                        "description": "Der SMA Hybrid-Wechselrichter STP 6.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 6 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8FAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 8.0 Smart Energy",
                        "price": 2980,
                        "id": "01t5p00000CRAkjAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 8.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 8 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SMA Solar Technology AG",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8BAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 10.0 Smart Energy",
                        "price": 3220,
                        "id": "01t5p00000CRAkkAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 10.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 10 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8PAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Home Hub Wechselrichter SE5K-RWB 48V",
                        "price": 2880,
                        "id": "01t5p00000CpanfAAB",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 5 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE5K-RWB 48V ➤ Kein MPP Tracker ✔️ Zwei Eingänge für SolarEdge Modul Optimierer ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmEDAA0",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Hub Wechselrichter SE8K-RWB 48V",
                        "price": 3192,
                        "id": "01t5p00000CpaoFAAR",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 8 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE8K-RWB 48V ➤ Dreiphasige Installationen mit Batteriespeicher 8 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmEIAA0",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Hub Wechselrichter SE10K-RWB 48V",
                        "price": 3360,
                        "id": "01t5p00000CpaoiAAB",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 10 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE10K-RWB 48V ➤ Dreiphasige Installationen mit Batteriespeicher 10 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmE8AAK",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Home Batterie 4.6 kWh",
                        "price": 3081,
                        "id": "01t5p00000CpapUAAR",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 4.6 kWh\r\nAnzahl Module: 1 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 4.6 kWh ➤ Optimierte Speicherlösung ✔️ dreiphasig mit optimalem Ersatzstrom✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013Vk5TAAS",
                            "name": "Hauptbild SolarEdge Home Battery 4-6kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 9.2 kWh",
                        "price": 5842,
                        "id": "01t5p00000CpapnAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 9.2 kWh\r\nAnzahl Module: 2 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 9.2 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit Ersatzstrom ✔️ Skalierbar von 4.6 – 23kWh ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk5EAAS",
                            "name": "Hauptbild SolarEdge Home Battery 9-2kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 13.8 kWh",
                        "price": 8603,
                        "id": "01t5p00000CpaplAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 13.8 kWh\r\nAnzahl Module: 3 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 13.8 kWh ➤ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️ Hier entlang!",
                        "defaultImage": {
                            "id": "0695p000013Vk3tAAC",
                            "name": "Hauptbild SolarEdge Home Battery 13-8kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 18.4 kWh",
                        "price": 11535,
                        "id": "01t5p00000CpapWAAR",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 18.4 kWh\r\nAnzahl Module: 4 Stk. (2+2)\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 18.4 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk3sAAC",
                            "name": "Hauptbild SolarEdge Home Battery 18-4kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 23.0 kWh",
                        "price": 14265,
                        "id": "01t5p00000CpaoaAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 23.0 kWh\r\nAnzahl Module: 5 Stk. (3+2)\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 23.0 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk3rAAC",
                            "name": "Hauptbild SolarEdge Home Battery 23kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Home Backup Interface (Ersatzstrom)",
                        "price": 1314,
                        "id": "01t5p00000CpaqUAAR",
                        "description": "Mit dem Home Backup-Interface bietet SolarEdge eine Ersatzstromlösung mit automatischer Umschaltung sollte das Stromnetz einmal ausfallen.\r\n- automatische Umschaltung < 10 Sekunden\r\n- Ersatzstrom für das ganze Haus (WP nicht empfohlen)\r\n- Schwarzstartfähig\r\n- Nur in Kombination mit Batterien möglich\r\nInkl. Verkabelung 5x6/10.0mm2 3LNPE bis 6m.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aGtJAAU",
                                "type": "pdf",
                                "size": 216111,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Backup-Interface - Heizungsmacher AG",
                                "modified": "2022-06-06T08:41:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk6vAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Ersatzstrom"
                            }
                        ],
                        "seoTitle": "SolarEdge Home Backup Interface",
                        "seoKeyword": "SolarEdge Home Backup Interface",
                        "seoDescription": "SolarEdge Home Backup Interface ➤ Automatische Umschaltung ✔️ Schwarzstartfähig ✔️ Dreiphasige Ersatzstromlösung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013Vk7ZAAS",
                            "name": "Hauptbild SolarEdge Home Backup Interface - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "WLW186i-5 AR E",
                        "price": 8169,
                        "id": "01t5p00000D3tn2AAB",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 110 x 54 x 80 cm\r\nLeistung bei A-7/W35: 5.4 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBimAAAT",
                            "name": "Offertenbild Buderus WSW 186i AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-7 AR E",
                        "price": 8693,
                        "id": "01t5p00000D3tmiAAB",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 110 x 54 x 80 cm\r\nLeistung bei A-7/W35: 6.7 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBilvAAD",
                            "name": "Offertenbild Buderus WSW 186i AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-10 AR E",
                        "price": 10813,
                        "id": "01t5p00000DDEO5AAP",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 135 x 54 x 105 cm\r\nLeistung bei A-7/W35: 9.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T80VvAAJ",
                            "name": "Offertenbild Buderus WSW 186i 10-12 AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-12 AR E",
                        "price": 11523,
                        "id": "01t5p00000DDET2AAP",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 135 x 54 x 105 cm\r\nLeistung bei A-7/W35: 11.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T80xwAAB",
                            "name": "Offertenbild Buderus WSW 186i 10-12 AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Inneneinheit wandhängend",
                        "price": 2519,
                        "id": "01t5p00000D3uQXAAZ",
                        "description": "Bei einer Sanierung eignet sich eine wandhängende Inneneinheit. Denn sie kann mit einem nebenstehenden Warmwasser- und Pufferspeicher flexibel kombiniert werden.\r\n- Eingebaute Hocheffizienz-Umwälzpumpe\r\n- Notbetrieb Durchlauferhitzer\r\n- 3-Weg-Umschaltventil für Heizung und Brauchwarmwasser\r\n- Touch-Systembedieneinheit \r\nLogamatic BC400 mit Funkmodul MX300\r\n\r\nMasse (L x B x T): 720 x 400 x 297 mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBkrXAAT",
                            "name": "Buderus Inneneinheit Wand offen",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001OBks0AAD",
                                "name": "Offertenbild Buderus WSW 186i AR Inneneinheit Wand - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Inneneinheit mit 70 L Puffer",
                        "price": 4037,
                        "id": "01t5p00000D3uQYAAZ",
                        "description": "Die kompakte Variante mit integriertem 70 Liter Pufferspeicher benötigt wenig Platz im Haus und eignet sich mit einem geeigneten Heizverteilsystem als Vollintegrierte Lösung. In Kombination mit einem externen Warmwasserspeicher bietet die Inneneinheit höchsten Warmwasserkomfort. \r\n\r\n- Eingebaute Hocheffizienz-Umwälzpumpe\r\n- Notbetrieb Durchlauferhitzer\r\n- Umschalt-Wegeventil für Heizung und Brauchwarmwasser\r\n- Touch-Systembedieneinheit \r\nLogamatic BC400 mit Funkmodul MX300\r\n\r\nMasse (L x B x T): 1275 x 600 x 600 mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBkrWAAT",
                            "name": "Buderus Inneneinheit TP70 offen",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001OBkrbAAD",
                                "name": "Offertenbild Buderus WSW 186i AR Inneneinheit TP70 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            }
        ]
    },
    "configuration": {
        "productCategories": [
            {
                "nameFr": "HeizungenFr",
                "nameEn": "HeizungenEn",
                "nameDe": "HeizungenDe",
                "name": "Heizungen",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "waermepumpen",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "luft-wasser-aussenaufstellung",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "sole-wasser-erdsonde",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "effizienz-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "kombi-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "kompakt-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "luft-wasser-innenaufstellung",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "regler",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Speicher",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Regler-ALT",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Pumpen und Heizkreisgruppen",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Sonstiges",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Erdsondenbohrung",
                        "level": 1,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": "SolarFr",
                "nameEn": "SolarEn",
                "nameDe": "SolarDe",
                "name": "Solar",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Standard",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Batterieanschluss",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Integrierte-Batterie",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Optimizer",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Ersatzstrom",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Batteriespeicher",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Niedervolt",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Hochvolt",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Photovoltaikmodul",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Standard-Module",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Hochleistungs-Module",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Made-in-Germany",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Monitoring",
                        "level": 1,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "waermepumpen",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "luft-wasser-aussenaufstellung",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "sole-wasser-erdsonde",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "effizienz-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "kombi-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "kompakt-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "luft-wasser-innenaufstellung",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "regler",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Speicher",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Regler-ALT",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Pumpen und Heizkreisgruppen",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Sonstiges",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Standard",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Batterieanschluss",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Integrierte-Batterie",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Optimizer",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Ersatzstrom",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Batteriespeicher",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Niedervolt",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Hochvolt",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Photovoltaikmodul",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Standard-Module",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Hochleistungs-Module",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Made-in-Germany",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Erdsondenbohrung",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Monitoring",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "luft-wasser-aussenaufstellung",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "sole-wasser-erdsonde",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "effizienz-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "kombi-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "kompakt-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Standard",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Batterieanschluss",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Integrierte-Batterie",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Standard-Module",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Hochleistungs-Module",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Made-in-Germany",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Niedervolt",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "luft-wasser-innenaufstellung",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "regler",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Optimizer",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Ersatzstrom",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Hochvolt",
                "level": 2,
                "childCategories": []
            }
        ],
        "manufacturers": [
            {
                "name": "alpha innotec",
                "id": "0010N00004FH7LzQAL"
            },
            {
                "name": "Stiebel Eltron",
                "id": "0010N00004FKSvnQAH"
            },
            {
                "name": "Viessmann",
                "id": "0010N00004Pn2D9QAJ"
            },
            {
                "name": "NIBE",
                "id": "0010N00004WO11tQAD"
            },
            {
                "name": "Kostal Solar Electric",
                "id": "0010N00004Z2W1CQAV"
            },
            {
                "name": "BYD",
                "id": "0010N00004aPbKlQAK"
            },
            {
                "name": "HPA",
                "id": "0010N00004aPx4MQAS"
            },
            {
                "name": "BOSCH",
                "id": "0010N00004acd3HQAQ"
            },
            {
                "name": "Belimo",
                "id": "0010N00004acgb9QAA"
            },
            {
                "name": "Grundfos",
                "id": "0010N00004addypQAA"
            },
            {
                "name": "PAW",
                "id": "0010N00004ademzQAA"
            },
            {
                "name": "SolarEdge",
                "id": "0010N00004bkWC8QAM"
            },
            {
                "name": "Wikora",
                "id": "0010N00004bml3WQAQ"
            },
            {
                "name": "AEH Oekotherm AG",
                "id": "0010N00004cdDKUQA2"
            },
            {
                "name": "Griesser AG",
                "id": "0010N00004eKCDiQAO"
            },
            {
                "name": "HakaGerodur AG",
                "id": "0010N00004eKCPeQAO"
            },
            {
                "name": "Buderus",
                "id": "0010N00004gGBuWQAW"
            },
            {
                "name": "R. Nussbaum AG",
                "id": "0010N00004hcWc5QAE"
            },
            {
                "name": "Alera energies AG",
                "id": "0010N00004koyf8QAA"
            },
            {
                "name": "NIBE AB (Sweden - nicht verwenden)",
                "id": "0010N00004kp2DKQAY"
            },
            {
                "name": "Paul Bauder AG",
                "id": "0010N00004p3o7FQAQ"
            },
            {
                "name": "Jinko Solar",
                "id": "0010N00004uFHCkQAO"
            },
            {
                "name": "LG Electronics",
                "id": "0010N00004uFHTYQA4"
            },
            {
                "name": "Sunpower",
                "id": "0010N00004uFInjQAG"
            },
            {
                "name": "SMA Solar Technology AG",
                "id": "0010N00004uFJC6QAO"
            },
            {
                "name": "E3DC",
                "id": "0010N00004uFMPaQAO"
            },
            {
                "name": "Trina Solar",
                "id": "0010N00004uFMfnQAG"
            },
            {
                "name": "K2 Systems",
                "id": "0010N000050Hmj5QAC"
            },
            {
                "name": "Depping AG",
                "id": "0010N00005G7tHoQAJ"
            },
            {
                "name": "CTC",
                "id": "0010N00005IvNUBQA3"
            },
            {
                "name": "LG Chem",
                "id": "0010N00005MykGKQAZ"
            },
            {
                "name": "A. Tschümperlin AG",
                "id": "0010N00005MywvqQAB"
            },
            {
                "name": "Novelan",
                "id": "0010N00005Phfq3QAB"
            },
            {
                "name": "Bucher Blechtechnik AG",
                "id": "0015p00005TtVtkAAF"
            },
            {
                "name": "Dani's Metalldesign GmbH",
                "id": "0015p00005UTfQFAA1"
            },
            {
                "name": "Meyer Burger",
                "id": "0015p00005aeeprAAA"
            },
            {
                "name": "Fronius",
                "id": "0015p00005b5gMdAAI"
            },
            {
                "name": "LAMBDA Wärmepumpen GmbH",
                "id": "0015p00005fuigMAAQ"
            },
            {
                "name": "JA Solar",
                "id": "0015p00005kPHpmAAG"
            },
            {
                "name": "Regli Energy",
                "id": "0015p00005pRYZlAAO"
            },
            {
                "name": "Wärmeaustausch Technologien AG WT",
                "id": "0015p00005qczPHAAY"
            },
            {
                "name": "BRUGG Rohrsystem AG",
                "id": "0015p00005vBc3qAAC"
            },
            {
                "name": "WS Weinmann & Schanz GmbH",
                "id": "0015p000061WrYWAA0"
            },
            {
                "name": "Oekoswiss Supply AG",
                "id": "0015p000066srNiAAI"
            },
            {
                "name": "Dimplex",
                "id": "0015p000068z1lqAAA"
            },
            {
                "name": "Sun2World Sustainable Energy Projects GmbH",
                "id": "0015p000068zR7fAAE"
            },
            {
                "name": "Debrunner Acifer AG",
                "id": "0015p000068zU7wAAE"
            },
            {
                "name": "Glaromat AG",
                "id": "0015p000068zfv8AAA"
            },
            {
                "name": "Forstner Speichertechnik GmbH",
                "id": "001Vj000004LDEdIAO"
            },
            {
                "name": "LONGi",
                "id": "001Vj000004U3J8IAK"
            }
        ],
        "bundleCategories": [
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Heizungen",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": "Wärmepumpen Fr",
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wärmepumpen",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Luft/Wasser",
                                "level": 2,
                                "childCategories": [
                                    {
                                        "nameFr": null,
                                        "nameEn": null,
                                        "nameDe": null,
                                        "name": "Innenaufgestellt",
                                        "level": 3,
                                        "childCategories": []
                                    },
                                    {
                                        "nameFr": null,
                                        "nameEn": null,
                                        "nameDe": null,
                                        "name": "Aussenaufgestellt",
                                        "level": 3,
                                        "childCategories": []
                                    }
                                ]
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Sole/Wasser (Erdsonde)",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Solar",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Photovoltaik",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter mit Batterieanschluss (extern)",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter mit integrierter Batterie",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    }
                ]
            },
            {
                "nameFr": "Wärmepumpen Fr",
                "nameEn": null,
                "nameDe": null,
                "name": "Wärmepumpen",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Luft/Wasser",
                        "level": 2,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Innenaufgestellt",
                                "level": 3,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Aussenaufgestellt",
                                "level": 3,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Sole/Wasser (Erdsonde)",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Photovoltaik",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter mit Batterieanschluss (extern)",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter mit integrierter Batterie",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Luft/Wasser",
                "level": 2,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Innenaufgestellt",
                        "level": 3,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Aussenaufgestellt",
                        "level": 3,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Sole/Wasser (Erdsonde)",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter mit Batterieanschluss (extern)",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter mit integrierter Batterie",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Innenaufgestellt",
                "level": 3,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Aussenaufgestellt",
                "level": 3,
                "childCategories": []
            }
        ]
    }
}} location={props.location}
          />
<ProductList
            {...{
    "type": "productList",
    "id": "6sf80mWDjxonwvhmcJC9r9",
    "updatedAt": "2022-03-23T09:05:57.905Z",
    "title": "Unsere Hybrid-Wechselrichter",
    "category": "Wechselrichter Batterieanschluss",
    "products": {
        "metaProducts": [
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WPL 15 AS",
                        "price": 11455,
                        "id": "01t0N00000982hPQAQ",
                        "description": "Die WPL 15 AS Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 6.86 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz4bQAA",
                                "type": "pdf",
                                "size": 656765,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 15 AS Stiebel Eltron",
                                "modified": "2018-10-18T13:23:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkLRQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 15 AS",
                        "seoKeyword": "WPL 15 AS",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe (WPL 15 AS) zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. Erfahren Sie bei uns mehr!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZHQAZ",
                            "name": "Hauptbild WPL 15 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 15 AS Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 15 ACS (Kühlung)",
                        "price": 12249,
                        "id": "01t0N000009hWNKQA2",
                        "description": "Die WPL 15 ACS Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 6.86 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz41QAA",
                                "type": "pdf",
                                "size": 657801,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 15 ACS Stiebel Eltron",
                                "modified": "2018-10-18T13:23:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkKrQAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 15 ACS (Kühlung)",
                        "seoKeyword": "WPL 15 ACS",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion. ➤ Hier mehr dazu!",
                        "defaultImage": {
                            "id": "0690N00000Cj3YxQAJ",
                            "name": "Hauptbild WPL 15 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 15 ACS Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTdhQAG",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 15 ACS Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 20 A",
                        "price": 14138,
                        "id": "01t0N00000982hFQAQ",
                        "description": "Die WPL 20 A Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 9.54 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz5JQAQ",
                                "type": "pdf",
                                "size": 654783,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 20 A Stiebel Eltron",
                                "modified": "2018-10-18T13:25:31.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkM9QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 20 A",
                        "seoKeyword": "Stiebel Eltron WPL 20 A",
                        "seoDescription": "Die WPL 20 A Inverter ist eine Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZRQAZ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 20 A Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000t71cAAAQ",
                                "name": "Offertenbild Stiebel Eltron WPL 20 25 A - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 20 AC (Kühlung)",
                        "price": 14935,
                        "id": "01t0N000009iEYaQAM",
                        "description": "Die WPL 20 AC Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 9.54 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz6RQAQ",
                                "type": "pdf",
                                "size": 657844,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 20 AC Stiebel Eltron",
                                "modified": "2018-10-18T13:27:01.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkNHQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 20 AC",
                        "seoKeyword": "WPL 20 AC Stiebel eltron",
                        "seoDescription": "Inverter Luft-Wasser-Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. Weitere Informationen bekommen Sie bei uns!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZWQAZ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 20 AC Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTeVQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 20 AC Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 25 A",
                        "price": 15733,
                        "id": "01t0N00000982bwQAA",
                        "description": "Die WPL 25 A Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 12.86 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz8EQAQ",
                                "type": "pdf",
                                "size": 656543,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 25 A Stiebel Eltron",
                                "modified": "2018-10-18T13:31:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkP8QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 25 A",
                        "seoKeyword": "WPL 25 A",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. ➤ Weitere Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZbQAJ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 25 A Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTbHQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 25 A Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 25 AC (Kühlung)",
                        "price": 16530,
                        "id": "01t0N000009jdeBQAQ",
                        "description": "Die WPL 25 AC Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 12.86 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzJlQAI",
                                "type": "pdf",
                                "size": 657686,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 25 AC Stiebel Eltron",
                                "modified": "2018-10-18T13:50:27.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkaLQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 25 AC (Kühlung)",
                        "seoKeyword": "Stiebel Eltron WPL 25 AC",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe (WPL 25 AC) zur kompakten Aussenaufstellung mit niedrigem Schallleistungspegel und aktiver Kühlfunktion. Mehr hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZgQAJ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 25 AC Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTeWQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 25 AC Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher SBP 200 E",
                        "price": 950,
                        "id": "01t0N00000982j3QAA",
                        "description": "Der Standspeicher SBP 200 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit  perfekt isolierender Ummantelung.\r\n\r\nVolumen: 207 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 63 x 153.5 cm (58kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkCgQAI",
                                "type": "pdf",
                                "size": 399066,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 200 E Stiebel Eltron",
                                "modified": "2018-10-18T13:59:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxYeQAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 200 E",
                        "seoKeyword": "Stiebel Eltron SBP 200 E",
                        "seoDescription": "Der Stiebel Eltron Standspeicher eignet sich mit perfekt isolierender Ummantelung als Pufferspeicher für Wärmepumpenanlagen. ➤ Jetzt informieren!",
                        "defaultImage": {
                            "id": "0690N00000CiwNsQAJ",
                            "name": "Stiebel_Eltron_Pufferspeicher_SBP_200_400_700_WEB",
                            "alt": "Stiebel Eltron SBP 200 E Pufferspeicher (200 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGhRQAW",
                                "name": "SE_SBP_200_400E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 200 E Pufferspeicher (200 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher SBP 400 E",
                        "price": 1240,
                        "id": "01t0N00000982jbQAA",
                        "description": "Der Standspeicher SBP 400 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit perfekt isolierender Ummantelung.\r\n\r\nVolumen: 415 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 171 cm (81 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkCuQAI",
                                "type": "pdf",
                                "size": 398895,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 400 E Stiebel Eltron",
                                "modified": "2018-10-18T14:00:29.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxYsQAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 400 E",
                        "seoKeyword": "Pufferspeicher SBP 400 E",
                        "seoDescription": "Der Standspeicher SBP 400 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ✅ Isolierende Ummantelung ✅ Energieklasse B ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000CiwPtQAJ",
                            "name": "Stiebel_Eltron_Pufferspeicher_SBP_200_400_700_WEB",
                            "alt": "Stiebel Eltron SBP 400 E Pufferspeicher (400 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGgsQAG",
                                "name": "SE_SBP_200_400E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 400 E Pufferspeicher (400 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher SBP 700 E",
                        "price": 1733,
                        "id": "01t0N00000982jlQAA",
                        "description": "Der Standspeicher SBP 700 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit  perfekt isolierender Ummantelung.\r\n\r\nVolumen: 720 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 91 x 189 cm (185 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkD4QAI",
                                "type": "pdf",
                                "size": 399136,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 700 E Stiebel Eltron",
                                "modified": "2018-10-18T14:01:33.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxZ2QAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 700 E",
                        "seoKeyword": "Stiebel Eltron Pufferspeicher",
                        "seoDescription": "Der Standspeicher eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit perfekt isolierender Ummantelung Energieklasse B.",
                        "defaultImage": {
                            "id": "0690N00000Ck2h7QAB",
                            "name": "Stiebel Eltron SBP 700 E (Hauptbild)",
                            "alt": "Stiebel Eltron SBP 700 E Pufferspeicher (700 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGgEQAW",
                                "name": "SE_SBP_700E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 700 E Pufferspeicher (700 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher SBB 312.B WP",
                        "price": 2320,
                        "id": "01t0N00000982lYQAQ",
                        "description": "Standspeicher SBB 312.B mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. Wärmedämmung als PU-Direktumschäumung.\r\n\r\nVolumen: 309 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 173.8 cm (194 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzX7QAI",
                                "type": "pdf",
                                "size": 200096,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 312.B Stiebel Eltron",
                                "modified": "2021-08-10T08:24:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zknQQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 312.B WP",
                        "seoKeyword": "Stiebel Eltron Trinkwasserspeicher",
                        "seoDescription": "Standspeicher mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000CiwVhQAJ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": "Stiebel Eltron SBB 312.B WP Trinkwarmwasserspeicher (300 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UH0iQAG",
                                "name": "SE_SBB_312B_WP_Trink_Warmwasserspeicher",
                                "alt": "Stiebel Eltron SBB 312.B WP Trinkwarmwasserspeicher (300 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher  SBB 411.B WP",
                        "price": 2646,
                        "id": "01t0N00000982liQAA",
                        "description": "Standspeicher SBB 411.B mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. Wärmedämmung als PU-Direktumschäumung.\r\n\r\nVolumen: 395 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 185 cm (192 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzrBQAQ",
                                "type": "pdf",
                                "size": 201522,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:43.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zl7PQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 411.B WP",
                        "seoKeyword": "Stiebel Eltron Trinkwarmwasserspeicher",
                        "seoDescription": "SBB 411.B Standspeicher ➤ Innen liegenden Glattrohr-Wärmeübertrager ✔️ Emailliert & Verkalkungsunempfindlich ✔️ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000CiwWBQAZ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": "Stiebel Eltron SBB 411.B WP Trinkwarmwasserspeicher (400 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UH1HQAW",
                                "name": "SE_SBB_411B_WP_Trink_Warmwasserspeicher",
                                "alt": "Stiebel Eltron SBB 411.B WP Trinkwarmwasserspeicher (400 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher SBB 501 WP",
                        "price": 3081,
                        "id": "01t5p00000CQhLAAA1",
                        "description": "Standspeicher SBB 501 WP SOL mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und besitzen eine hohe Trinkwarmwasserverfügbarkeit durch abgestimmte Ein- und Ausströmtechnik.\r\n\r\nVolumen: 495 Liter\r\nMasse ohne Isolierung: ⌀xH: 69 x 198.8 cm (222 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vKByWAAW",
                                "type": "pdf",
                                "size": 208855,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 501 Stiebel Eltron - Heizungsmacher AG",
                                "modified": "2022-01-11T07:01:49.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000uizjOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 501 WP",
                        "seoKeyword": "Stiebel Eltron Trinkwarmwasserspeicher",
                        "seoDescription": "SBB 501.B Standspeicher ➤ Innen liegenden Glattrohr-Wärmeübertrager ✔️ Emailliert & Verkalkungsunempfindlich ✔️ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0695p00000uiziCAAQ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000uiziDAAQ",
                                "name": "SE_SBB_411B_WP_Trink_Warmwasserspeicher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Erweiterung WPE",
                        "price": 830,
                        "id": "01t0N000009jMZKQA2",
                        "description": "Funktionserweiterungsmodul für den WPM.\r\nRegelung von zwei zusätzlichen gemischten Heizkreisen.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzvjQAA",
                                "type": "pdf",
                                "size": 915359,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPE Stiebel Eltron",
                                "modified": "2018-10-18T15:04:24.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zlBlQAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron Erweiterung WPE ➤ FFunktionserweiterungsmodul für den WPM ✔️ Regelung von zwei zusätzlichen gemischten Heizkreisen ✔️",
                        "defaultImage": {
                            "id": "0690N00000Cj3ajQAB",
                            "name": "Hauptbild WPE Stiebel Eltron",
                            "alt": "Stiebel Eltron Wärmepumpenregler WPE Erweiterung"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000ANFQPQA5",
                                "name": "SE_WPE_Erweiterung_2",
                                "alt": "Stiebel Eltron Wärmepumpenregler WPE Erweiterung"
                            },
                            {
                                "id": "0690N000006zlDIQAY",
                                "name": "Übersicht WPM Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpenregler WPM",
                        "price": 1131,
                        "id": "01t0N00000982mHQAQ",
                        "description": "Der Wärmepumpen-Manager WPM ist für die steuer- und regelungstechnischen Abläufe der Wärmepumpe zuständig. Mit dem Gerät können Einstellungen vorgenommen und Informationen zum Betrieb der Wärmepumpe angezeigt werden.\r\n-Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xc8cQAA",
                                "type": "pdf",
                                "size": 915254,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:05:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwTQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8dQAA",
                                "type": "pdf",
                                "size": 1610169,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Meldungsliste WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwUQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8hQAA",
                                "type": "pdf",
                                "size": 1880046,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Inbetriebnahme WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:55.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwYQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8mQAA",
                                "type": "pdf",
                                "size": 4618726,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installation WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:39.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwdQAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8rQAA",
                                "type": "pdf",
                                "size": 5067872,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:08.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwiQAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron Wärmepumpenregler WPM ➤ Wärmepumpen-Manager ✔️ Steuer- und regelungstechnische Abläufe ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0690N00000Cj3aUQAR",
                            "name": "Hauptbild WPM 4 Stiebel Eltron",
                            "alt": "Stiebel Eltron Wärmepumpenregler WPM"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000ANFRDQA5",
                                "name": "SE_WPM_4_2",
                                "alt": "Stiebel Eltron Wärmepumpenregler WPM"
                            },
                            {
                                "id": "0690N000006zlDIQAY",
                                "name": "Übersicht WPM Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WPL 19",
                        "price": 15407,
                        "id": "01t0N0000098r9XQAQ",
                        "description": "Die innenaufgestellte Luft-Wasser-Wärmepumpe WPL 19 besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Trotz minimaler Schallemissionen plus besonders leisem Nachtmodus erreicht die Pumpe eine hohe Vorlauftemperatur von +65°C. Das Gerät erreicht bei einer Aussentemperatur von -7°C eine Wärmeleistung von 9.91 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdttkQAB",
                                "type": "pdf",
                                "size": 252022,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron WPL 19 I Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:39:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsuiQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OdtvUQAR",
                                "type": "pdf",
                                "size": 28899489,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation Stiebel Eltron WPL 19 24 Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:38:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NWstzQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 19",
                        "seoKeyword": "Stiebel Eltron WPL 19",
                        "seoDescription": "Die innenaufgestellte WPL 19 Luft-Wasser-Wärmepumpe besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Alle Vorteile hier!",
                        "defaultImage": {
                            "id": "0690N00000PiwOTQAZ",
                            "name": "Hauptbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                            "alt": "Stiebel Eltron WPL 19 Wärmepumpe (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwOYQAZ",
                                "name": "Offertbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                                "alt": "Stiebel Eltron WPL 19 Wärmepumpe (innen)"
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 24",
                        "price": 16969,
                        "id": "01t0N0000098r9cQAA",
                        "description": "Die innenaufgestellte Luft-Wasser-Wärmepumpe WPL 24 besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Trotz minimaler Schallemissionen plus besonders leisem Nachtmodus erreicht die Pumpe eine hohe Vorlauftemperatur von +65°C. Das Gerät erreicht bei einer Aussentemperatur von -7°C eine Wärmeleistung von 13,45 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdttlQAB",
                                "type": "pdf",
                                "size": 252431,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron WPL 24 I Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:40:28.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsujQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OdtvUQAR",
                                "type": "pdf",
                                "size": 28899489,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation Stiebel Eltron WPL 19 24 Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:38:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NWstzQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 24",
                        "seoKeyword": "Stiebel Eltron WPL 24",
                        "seoDescription": "Die innenaufgestellte Luft-Wasser-Wärmepumpe besticht durch exzellente Energieeffizienz dank moderner Invertertechnik. Alle weiteren Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000PiwOsQAJ",
                            "name": "Hauptbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                            "alt": "Stiebel Eltron WPL 24 Wärmepumpe (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwOtQAJ",
                                "name": "Offertbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                                "alt": "Stiebel Eltron WPL 24 Wärmepumpe (innen)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kombispeicher HS-BM 560L WT1",
                        "price": 5700,
                        "id": "01t0N000009ODegQAG",
                        "description": "Der Hygiene-Systemspeicher HS-BM056-18 WT1 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nVolumen: 560 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 89 (65) x 198 cm (157kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002mugQIAQ",
                                "type": "pdf",
                                "size": 1532093,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-056-18 WT1",
                                "modified": "2024-02-23T15:38:05.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jJblIAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 560L",
                        "seoKeyword": "Stiebel Eltron Kombispeicher",
                        "seoDescription": "Der multivalente Hygiene-Systemspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie. 560l Fassungsvermögen ➤ Hier weitere Infos!",
                        "defaultImage": {
                            "id": "0690N00000PiYtNQAV",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 560L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtOQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 560L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 560L WT2",
                        "price": 6473,
                        "id": "01tVj0000024OnNIAU",
                        "description": "Der Hygiene-Systemspeicher HS-BM056-18 WT2 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nMit zusätzlichem Register für einen zweiten Wärmeerzeuger (1.5 m2).\r\n\r\nVolumen: 560 Liter \r\nMasse mit (ohne) Isolierung: ⌀xH: 89 (65) x 198 cm (162 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n7teIAA",
                                "type": "pdf",
                                "size": 627868,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-056-18 WT2",
                                "modified": "2024-02-23T15:39:56.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jWsEIAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 560L",
                        "seoKeyword": "Stiebel Eltron Kombispeicher",
                        "seoDescription": "Der multivalente Hygiene-Systemspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie. 560l Fassungsvermögen ➤ Hier weitere Infos!",
                        "defaultImage": {
                            "id": "069Vj000002jKnsIAE",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000002jS75IAE",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 820L WT1",
                        "price": 6630,
                        "id": "01t0N000009OEaHQAW",
                        "description": "Der Hygiene-Systemspeicher HS-BM080-18 WT1 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nVolumen: 820 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 101 (77) x 203 cm (181 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n00yIAA",
                                "type": "pdf",
                                "size": 1537486,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-080-18 WT1",
                                "modified": "2024-02-23T15:38:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jP1AIAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 820 L",
                        "seoKeyword": "Stiebel Eltron HS-BM",
                        "seoDescription": "Der Hygiene-Systemspeicher HS-BM von Stiebel Eltron gilt als Herzstück einer Heizanlage. Alles zu seiner innovativen Technologie erfahren Sie hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYthQAF",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 820L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtQQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 820L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 820L WT2",
                        "price": 7530,
                        "id": "01t0N000009jqE4QAI",
                        "description": "Der Hygiene-Systemspeicher  HS-BM080-18 WT2 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nMit zusätzlichem Register für einen zweiten Wärmeerzeuger (2.6 m2).\r\n\r\nVolumen: 820 Liter \r\nMasse mit (ohne) Isolierung: ⌀xH: 1'100 (77) x 203 cm (191 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n7yUIAQ",
                                "type": "pdf",
                                "size": 634799,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-080-18 WT2",
                                "modified": "2024-02-23T15:39:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jWx4IAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "069Vj000002jVjFIAU",
                            "name": "Hauptbild Forstner HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000002jRPbIAM",
                                "name": "Offertbild Forstner HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 960L WT1",
                        "price": 8424,
                        "id": "01t0N00000AJECNQA5",
                        "description": "Der Hygiene-Systemspeicher HS-BM096-18 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nNenninhalt: 960 Liter\r\nDurchmesser ohne (mit) Isolation: 790mm (1030mm)\r\nHöhe mit Isolation: 2250mm\r\n\r\nVolumen: 960 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 103 (79) x 225 cm (198 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n4kVIAQ",
                                "type": "pdf",
                                "size": 1531211,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-096-18 WT1",
                                "modified": "2024-02-23T15:40:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jTr9IAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 960L",
                        "seoKeyword": "Stienel Eltron HS-BM 960",
                        "seoDescription": "Der Kombispeicher HS-BM 960 gilt als Herzstück einer Heizanlage. Alle Infos zur innovativen Technologie dieses multivalenten Schichtenspeichers hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYtLQAV",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 960L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtKQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 960L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Mutltifunktionsspeicher MFS 600 S",
                        "price": 4373,
                        "id": "01t0N0000099NBDQA2",
                        "description": "Der Multifunktionsspeicher MFS 600 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 572 Liter (7.5 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 80 (65) x 193 cm (140 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfFKQAZ",
                                "type": "pdf",
                                "size": 126296,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 600 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:22:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULydQAH",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 600 S",
                        "seoKeyword": "Alpha Innotec MFS 600 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 600 S vereint Puffer & Trinkwarmwasser in einem. Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip.",
                        "defaultImage": {
                            "id": "0690N00000NULnRQAX",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 600 S Multifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8HKQA1",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 600 S Multifunktionsspeicher"
                            }
                        ]
                    },
                    {
                        "title": "Mutltifunktionsspeicher MFS 830 S",
                        "price": 4909,
                        "id": "01t0N000009O5azQAC",
                        "description": "Der Multifunktionsspeicher MFS 830 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 846 Liter (8.7 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 199 cm (200 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfFtQAJ",
                                "type": "pdf",
                                "size": 140839,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 830 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:26:32.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULzCQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 830 S",
                        "seoKeyword": "Alpha Innotec 830 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 830 S von Alpha Innotec vereint Puffer & Trinkwarmwasser. Die Aufbereitung erfolgt im Durchlauferhitzerprinzip.",
                        "defaultImage": {
                            "id": "0690N00000NY8IhQAL",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 830 S Mutltifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8HLQA1",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 830 S Mutltifunktionsspeicher"
                            }
                        ]
                    },
                    {
                        "title": "Mutltifunktionsspeicher MFS 1000 S",
                        "price": 6069,
                        "id": "01t0N000009P7xAQAS",
                        "description": "Der Multifunktionsspeicher MFS 1000 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 928 Liter (10.9 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 214 cm (230 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfC6QAJ",
                                "type": "pdf",
                                "size": 129902,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 1000 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:27:17.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULvLQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 1000 S",
                        "seoKeyword": "MFS 1000 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 1000 S von Alpha Innotec vereint Puffer und Trinkwarmwasser in einem. ➤ Alle Infos dazu bei uns!",
                        "defaultImage": {
                            "id": "0690N00000NULvKQAX",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 1000 S Mutltifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8JaQAL",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 1000 S Mutltifunktionsspeicher"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher TPS 200",
                        "price": 1088,
                        "id": "01t0N000009PKl6QAG",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 200).\r\n\r\nVolumen: 200 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 60 x 130 cm (60 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsRQAV",
                                "type": "pdf",
                                "size": 112737,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 200 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:14:21.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXwQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 200",
                        "seoKeyword": "alpha innotec TPS 200",
                        "seoDescription": "Pufferspeicher zur Optimierung der Laufzeit & Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung.",
                        "defaultImage": {
                            "id": "0690N00000PiYY1QAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 200 - Heizungsmacher AG",
                            "alt": "NIBE TPS 200 Pufferspeicher (200 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYY0QAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 200 - Heizungsmacher AG",
                                "alt": "NIBE TPS 200 Pufferspeicher (200 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 300.11",
                        "price": 1435,
                        "id": "01t0N000009MIH7QAO",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 300.11).\r\n\r\nVolumen: 301 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 66 x 174 cm (93 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000wzrTqAAI",
                                "type": "pdf",
                                "size": 146385,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 300-11 - Heizungsmacher AG",
                                "modified": "2022-02-22T15:31:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYYPQA3",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Pufferspeicher TPS 300",
                        "seoKeyword": "Alpha Innotec TPS 300",
                        "seoDescription": "Der Alpha Innotec TPS 300 Pufferspeicher zur Optimierung der Laufzeit & Vergrösserung des Systemvolumens. ➤ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiYUoQAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 300 - Heizungsmacher AG",
                            "alt": "NIBE TPS 300 Pufferspeicher (300 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYUnQAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 300 - Heizungsmacher AG",
                                "alt": "NIBE TPS 300 Pufferspeicher (300 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 500.1",
                        "price": 1660,
                        "id": "01t0N000009MTuPQAW",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 500).\r\n\r\nVolumen: 485 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 76 x 193 cm (111 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLt5QAF",
                                "type": "pdf",
                                "size": 138757,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 500-1 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:18:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYYZQA3",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 500.1",
                        "seoKeyword": "Alpha Innotec TPS 500",
                        "seoDescription": "TPS 500.1 Pufferspeicher ➤ Optimierung der Laufzeit ✔️ Eingeschweisste Tauchhülsen ✔️  Abnehmbare Wärmedämmung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0690N00000PiYYfQAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                            "alt": "NIBE TPS 500.1 Pufferspeicher (500 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYYeQAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                                "alt": "NIBE TPS 500.1 Pufferspeicher (500 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 500.3",
                        "price": 1918,
                        "id": "01t5p00000BksjIAAR",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und abnehmbarer Wärmedämmung (baugleich Alpha Innotec TPS 500).\r\n\r\nDurchmesser ohne Isolation: 650mm\r\nKippmass ohne Isolation: 1840mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 500.3",
                        "seoKeyword": "Alpha Innotec TPS 500",
                        "seoDescription": "TPS 500.3 Pufferspeicher ➤ Optimierung der Laufzeit ✔️ Eingeschweisste Tauchhülsen ✔️  Abnehmbare Wärmedämmung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p00000j6rA1AAI",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                            "alt": "NIBE TPS 500.3 Pufferspeicher (500 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000j6rA6AAI",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                                "alt": "NIBE TPS 500.3 Pufferspeicher (500 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S2125-8",
                        "price": 10075,
                        "id": "01t0N000009M6nDQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE S2125-8 deckt einen Leistungsbereich bis 5.3 kW ab (bei A-7/W55). Leiser Betrieb von 50 dB(A) Nacht-Schallleistungspegel und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nDas in der NIBE S2125 eingesetzte Kältemittel ist umweltfreundlich und nachhaltig. Bei reinem Verdichterbetrieb werden hohe Ladetemperaturen von bis zu 75 °C erreicht. Ebenso können bei Außentemperaturen von –25 °C noch Temperaturen von bis zu 65 °C erzeugt werden. Dies ermöglicht eine ganzjährig effiziente Betriebsweise mit hohem Brauchwasserkomfort und den Betrieb mit bestehenden Heizkörpern.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014miVLAAY",
                                "type": "pdf",
                                "size": 722379,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S2125 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:39:43.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDrAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-8",
                        "seoKeyword": "Nibe F2120-8",
                        "seoDescription": "Die NIBE F2120-8 deckt einen Leistungsbereich bis 5.5 kW ab (bei A-7/W55). Merkmale sind ihr leiser Betrieb & die geringen Betriebskosten. Hier mehr!",
                        "defaultImage": {
                            "id": "0695p000014yhDvAAI",
                            "name": "Hauptbild NIBE S2125 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yhD3AAI",
                                "name": "Offertenbild NIBE S2125-8 -12 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhDwAAI",
                                "name": "Bild 1 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhDxAAI",
                                "name": "Bild 2 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S2125-12",
                        "price": 11146,
                        "id": "01t0N000009M6nNQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE S2125-12 deckt einen Leistungsbereich bis 8.2 kW ab (bei A-7/W55). Leiser Betrieb von 50 dB(A) Nacht-Schallleistungspegel und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nDas in der NIBE S2125 eingesetzte Kältemittel ist umweltfreundlich und nachhaltig. Bei reinem Verdichterbetrieb werden hohe Ladetemperaturen von bis zu 75 °C erreicht. Ebenso können bei Außentemperaturen von –25 °C noch Temperaturen von bis zu 65 °C erzeugt werden. Dies ermöglicht eine ganzjährig effiziente Betriebsweise mit hohem Brauchwasserkomfort und den Betrieb mit bestehenden Heizkörpern.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014midSAAQ",
                                "type": "pdf",
                                "size": 722379,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S2125 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:42:06.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhGpAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-12",
                        "seoKeyword": "Nibe F2120-12",
                        "seoDescription": "Die Monoblock-Ausseneinheit NIBE F2120-12 deckt einen Leistungsbereich bis 7.6 kW ab. ✅ Leiser Betrieb ✅ minimale Betriebskosten. Hier mehr!",
                        "defaultImage": {
                            "id": "0695p000014yhDsAAI",
                            "name": "Hauptbild NIBE S2125 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yhGqAAI",
                                "name": "Offertenbild NIBE S2125-8 -12 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhGuAAI",
                                "name": "Bild 1 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhGvAAI",
                                "name": "Bild 2 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe F2120-16",
                        "price": 14280,
                        "id": "01t0N000009M6nXQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE F2120-16 deckt einen Leistungsbereich bis 10.9 kW ab (bei A-7/W55). Flüsterleiser Betrieb dank einem Schallleistungspegel von 55 dB(A) und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nMeilenstein in der Effizienz mit einem SCOP grösser als 5.0. Vorlauftemperatur bis 65°C ideal für die Sanierung. Keine Vignettenpflicht da weniger als 3 kg Kältemittel.",
                        "attributes": "<ul><li>Hocheffizienz-Wärmepumpe für den Heizungstausch.</li><li>Erreicht Effizienzwerte die bisher nur mit Erdwärme möglich waren.</li><li>Bisher unerreichter Betriebsbereich mit bis zu 65 °C Ladetemperatur und 63 °C bei –25 °C Aussenlufttemperatur</li><li>Minimaler Geräuschpegel, auch bei voller Leistung.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014miabAAA",
                                "type": "pdf",
                                "size": 433933,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE F2120 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:45:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDyAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-16",
                        "seoKeyword": "Nibe F2120-16",
                        "seoDescription": "Die Monoblock-Ausseneinheit NIBE F2120-16 deckt einen Leistungsbereich bis 10.9 kW ab. Leiser Betrieb dank einem Schallleistungspegel von 55 dB(A).",
                        "defaultImage": {
                            "id": "0690N00000I36q6QAB",
                            "name": "Hauptbild NIBE F2120 -8 -12 -16 -20",
                            "alt": "NIBE F2120-16 Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I36pdQAB",
                                "name": "Offertenbild NIBE F2120 -8 -12 -16 -20",
                                "alt": "NIBE F2120-16 Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0695p000014yhKhAAI",
                                "name": "Ansicht 1 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKiAAI",
                                "name": "Ansicht 2 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKjAAI",
                                "name": "Ansicht 3 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe F2120-20",
                        "price": 15926,
                        "id": "01t0N000009M6ncQAC",
                        "description": "Die Monoblock-Ausseneinheit NIBE F2120-20 deckt einen Leistungsbereich bis 14.1 kW ab (bei A-7/W55). Flüsterleiser Betrieb dank einem Schallleistungspegel von 55 dB(A) und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nMeilenstein in der Effizienz mit einem SCOP grösser als 5.0. Vorlauftemperatur bis 65°C ideal für die Sanierung. Keine Vignettenpflicht da weniger als 3 kg Kältemittel.",
                        "attributes": "<ul><li>Hocheffizienz-Wärmepumpe für den Heizungstausch.</li><li>Erreicht Effizienzwerte die bisher nur mit Erdwärme möglich waren.</li><li>Bisher unerreichter Betriebsbereich mit bis zu 65 °C Ladetemperatur und 63 °C bei –25 °C Aussenlufttemperatur</li><li>Minimaler Geräuschpegel, auch bei voller Leistung.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mihUAAQ",
                                "type": "pdf",
                                "size": 433933,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE F2120 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:45:53.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDtAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-20",
                        "seoKeyword": "Nibe F2120-20",
                        "seoDescription": "Die NIBE F2120-20 deckt einen Leistungsbereich bis 14.1 kW ab. Flüsterleiser Betrieb und minimale Betriebskosten durch Inverter-Technologie.",
                        "defaultImage": {
                            "id": "0690N00000I36pXQAR",
                            "name": "Hauptbild NIBE F2120 -8 -12 -16 -20",
                            "alt": "NIBE F2120-20 Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I36pcQAB",
                                "name": "Offertenbild NIBE F2120 -8 -12 -16 -20",
                                "alt": "NIBE F2120-20 Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0695p000014yhDuAAI",
                                "name": "Ansicht 2 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKkAAI",
                                "name": "Ansicht 1 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKlAAI",
                                "name": "Ansicht 3 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher VVM S320",
                        "price": 6822,
                        "id": "01t0N00000BBwk3QAD",
                        "description": "Kompakte Inneneinheit NIBE VVM S320 mit sehr hohem Vorfertigungsgrad für Standardanwendungen im Einfamilienhaus. Das intelligente, anwenderfreundliche Regelgerät der NIBE VVM S320 ermöglicht eine effiziente Wärme- und Brauchwasserversorgung.\r\nVVM S320 wird in Kombination mit einer Luft-/Wasser-Wärmepumpe NIBE F2120 als zentrales Bindeglied zwischen Wärmeaufnahme, -speicherung und Wärmeabgabe eingesetzt. \r\n\r\nDie kompakte Inneneinheit ist mit einem 180 Liter Brauchwasserspeicher, einem Pufferspeicher sowie mit einem Ausdehnungsgefäß ausgestattet. Zusätzlich sind die hocheffizienz Lade- und Entladepumpen sowie eine elektrische Notheizung bereits integriert. \r\n\r\nMit integriertem WLAN fügt sich die S-Serie wie selbstverständlich in Ihr vernetztes Zuhause ein. Die intelligente Technologie passt das Raumklima automatisch an, wobei Sie via Smartphone oder Tablet die volle Kontrolle behalten. Bei minimalem Energieverbrauch genießen Sie höchsten Wohnkomfort und schonen die Umwelt.",
                        "attributes": "<ul><li>In Kombination mit einer NIBE Luft-/Wasser-Wärmepumpe bildet die NIBE VVM S320 Inneneinheit ein integriertes System für das Einfamilienhaus.</li><li>Anwenderfreundliche Touchscreen-Bedienung, integrierte Drahtlosverbindung sowie die energiesparende Smart-Technologie sorgen für höchsten Komfort.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000JA0v7QAD",
                                "type": "pdf",
                                "size": 1641419,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:16:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37aiQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000JA0vCQAT",
                                "type": "pdf",
                                "size": 514870,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:16:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37anQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000JA0vHQAT",
                                "type": "pdf",
                                "size": 4505784,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:15:55.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37asQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": "NIBE Kompaktspeicher VVM S320 (NEU)",
                        "seoKeyword": "VVM S320 Nibe",
                        "seoDescription": "Kompakte Inneneinheit NIBE VVM S320 mit sehr hohem Vorfertigungsgrad für Standardanwendungen im Einfamilienhaus. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000I37aVQAR",
                            "name": "Hauptbild NIBE VVM S320 - Heizungsmacher AG",
                            "alt": "NIBE VVM S320 Kompaktspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I37aTQAR",
                                "name": "Ansicht 1 NIBE VVM S320 - Heizungsmacher AG",
                                "alt": "NIBE VVM S320 Kompaktspeicher"
                            },
                            {
                                "id": "0690N00000I37aUQAR",
                                "name": "Ansicht 2 NIBE VVM S320 - Heizungsmacher AG",
                                "alt": "Moderne Küche mit Kompaktspeicher VVM S320 von NIBE"
                            },
                            {
                                "id": "0690N00000I37aYQAR",
                                "name": "Offertenbild NIBE VVM S320 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher WWS 405",
                        "price": 2765,
                        "id": "01t0N000009MBvoQAG",
                        "description": "Der Trinkwarmwasserspeicher WWS 405 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert durch hochwirksame Wärmedämmung die Wärmeverluste (Baugleich Alpha InnoTec). \r\n\r\nVolumen: 362 Liter (5.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 163 cm / Isolierung nicht abnehmbar\r\nKippmass: 172cm",
                        "attributes": "<ul><li>Durchmesser (mit Isolation, nicht demontierbar): 75cm</li><li>Höhe: 163cm</li><li>Kippmass: 172cm</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsTQAV",
                                "type": "pdf",
                                "size": 131119,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Trinkwarmwasserspeicher WWS 405-2 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:44:38.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXxQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLv0QAF",
                                "type": "pdf",
                                "size": 4424427,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Trinkwarmwasserspeicher WWS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:42:37.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYaGQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha innotec Trinkwarmwasserspeicher WWS 405.2",
                        "seoKeyword": "Alpha innotec WWS 405.2",
                        "seoDescription": "Der Trinkwarmwasserspeicher WWS 405 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert Wärmeverluste.",
                        "defaultImage": {
                            "id": "0690N00000Piwd5QAB",
                            "name": "Hauptbild NIBE alpha innotec WWS 405 - Heizungsmacher AG",
                            "alt": "NIBE WWS 405 Trinkwarmwasserspeicher (362 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000Piwd6QAB",
                                "name": "Offertbild NIBE alpha innotec WWS 405 - Heizungsmacher AG",
                                "alt": "NIBE WWS 405 Trinkwarmwasserspeicher (362 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WWS 507",
                        "price": 3522,
                        "id": "01t0N000009NrkwQAC",
                        "description": "Der Trinkwarmwasserspeicher WWS 507 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert durch hochwirksame Wärmedämmung die Wärmeverluste (Baugleich Alpha InnoTec).\r\n\r\nDurchmesser (mit Isolation, nicht demontierbar): 75cm\r\nKippmass: 203cm\r\nRegisterfläche: 7.0m2",
                        "attributes": "<ul><li>Durchmesser (mit Isolation, nicht demontierbar): 75cm</li><li>Höhe: 196cm</li><li>Kippmass: 203cm</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsUQAV",
                                "type": "pdf",
                                "size": 169890,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Trinkwarmwasserspeicher WWS 507-2 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:45:47.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXyQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLv0QAF",
                                "type": "pdf",
                                "size": 4424427,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Trinkwarmwasserspeicher WWS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:42:37.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYaGQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Trinkwarmwasserspeicher WWS 507.2",
                        "seoKeyword": "Alpha Innotec wws 507.2",
                        "seoDescription": "Der Trinkwarmwasserspeicher WWS 507 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert Wärmeverluste.",
                        "defaultImage": {
                            "id": "0690N00000PiwkKQAR",
                            "name": "Hauptbild NIBE alpha innotec WWS 507 - Heizungsmacher AG",
                            "alt": "NIBE WWS 507 Trinkwarmwasserspeicher (432 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwkLQAR",
                                "name": "Offertbild NIBE alpha innotec WWS 507 - Heizungsmacher AG",
                                "alt": "NIBE WWS 507 Trinkwarmwasserspeicher (432 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe LICV 82R1/3",
                        "price": 13860,
                        "id": "01t0N000009MCeHQAW",
                        "description": "Mit der neuen LICV 8.2R1/3 (innen) von NIBE (baugleich Novelan und Alpha Innotec LWCV) entscheiden Sie sich für eine vielseitige Modullösung. Sehr leise 38 dB(A) Schallleistung im Nachtbetrieb. 6.4 kW Heizleistung bei einer Aussentemperatur von -7°C.\r\n\r\nDie Geräte sind werkseitig mit folgenden Komponenten ausgestattet:\r\n– Wärmepumpenregler Luxtronik 2.1\r\n– Energieeffizienz-Umwälzpumpe für Heizung\r\n– Umschaltventil Heizung/ Trinkwasser\r\n– Überströmventil für den Heizkreis\r\n– Pufferspeicher 82 Liter für Heizung\r\n– Elektroheizelement zur Unterstützung der Heizung und Brauchwarmwasserladung\r\n– Ausdehnungsgefäss (18l)\r\n– Wärmemengenerfassung\r\n– Schwingungsentkopplung für den Heizkreis",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vLylYAAS",
                                "type": "pdf",
                                "size": 599943,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE LICV - Heizungsmacher AG",
                                "modified": "2022-01-21T09:11:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000ukjnzAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe LICV 82R1/3",
                        "seoKeyword": "Nibe LICV",
                        "seoDescription": "Mit der neuen LICV-Serie von NIBE entscheiden Sie sich für eine vielseitige und sehr leise Modullösung. ➤ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiwBUQAZ",
                            "name": "Offertbild LICV - Heizungsmacher AG",
                            "alt": "NIBE Wärmepumpe LICV 8.2R1/3 (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwBPQAZ",
                                "name": "Bild 1 LICV - Heizungsmacher AG",
                                "alt": "Einzelteile der NIBE Wärmepumpe LICV 8.2R1/3 (innen)"
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe LICV 122R1/3",
                        "price": 15408,
                        "id": "01t0N000009NbvBQAS",
                        "description": "Mit der neuen LICV 12.2R1/3 (innen) von NIBE (baugleich Novelan und Alpha Innotec LWCV) entscheiden Sie sich für eine vielseitige Modullösung. Leise 43dB(A) Schallleistung im Nachtbetrieb. 8.5 kW Heizleistung bei einer Aussentemperatur von -7°C. Die Geräte sind werkseitig mit folgenden Komponenten ausgestattet:\r\n– Wärmepumpenregler Luxtronik 2.1\r\n– Energieeffizienz-Umwälzpumpe für Heizung\r\n– Umschaltventil Heizung/ Trinkwasser\r\n– Überströmventil für den Heizkreis\r\n– Pufferspeicher 82 Liter für Heizung\r\n– Elektroheizelement zur Unterstützung der Heizung und Brauchwarmwasserladung\r\n– Ausdehnungsgefäss (12l)\r\n– Wärmemengenerfassung\r\n– Schwingungsentkopplung für den Heizkreis",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vLylYAAS",
                                "type": "pdf",
                                "size": 599943,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE LICV - Heizungsmacher AG",
                                "modified": "2022-01-21T09:11:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000ukjnzAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe LICV 122R1/3",
                        "seoKeyword": "Nibe LICV 122R1/3",
                        "seoDescription": "Mit der neuen LICV-Serie von NIBE entscheiden Sie sich für eine vielseitige Modullösung. Alle Vorteile erfahren Sie bei uns!",
                        "defaultImage": {
                            "id": "0690N00000PiwAqQAJ",
                            "name": "Offertbild LICV - Heizungsmacher AG",
                            "alt": "NIBE Wärmepumpe LICV 12.2R1/3 (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000Piw86QAB",
                                "name": "Bild 2 LICV Einzelteile - Heizungsmacher AG",
                                "alt": "Einzelteile der NIBE Wärmepumpe LICV 12.2R1/3 (innen)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hydraulikmodul Vitocal 200-A",
                        "price": 2666,
                        "id": "01t0N000009OlebQAC",
                        "description": "Inneneinheit mit Wärmepumpenregelung Vitotronic 200, Hocheffizienz- Umwälzpumpe für den Sekundärkreis, eingebautem Heizwasser- Durchlauferhitzer (Notheizung), 3- Wege- Umschaltventil und Sicherheitsgruppe. Inklusive benötigter Temperatursensoren und Vitoconnect 100, Typ OPTO1.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MekCDQAZ",
                                "type": "pdf",
                                "size": 343061,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Viessmann Vitocal 200-A - Heizungsmacher AG",
                                "modified": "2020-04-22T12:33:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NVQsmQAH",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Hydraulikmodul Vitocal 200-A ➤ Inneneinheit mit Wärmepumpenregelung ✔️ Hocheffizienz- Umwälzpumpe ✔️ Temperatursensoren ✔️",
                        "defaultImage": {
                            "id": "0690N0000072DZrQAM",
                            "name": "Hauptbild Viessmann Hydraulikmodul Vitocal-200",
                            "alt": "Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072DZvQAM",
                                "name": "Schnitt Viessmann Hydraulikmodul Vitocal-200",
                                "alt": "Schnitt Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                            },
                            {
                                "id": "0690N00000NVQrPQAX",
                                "name": "Offertbild Viessmann Hydraulikeinheit Vitocal-200 - Heizungsmacher AG",
                                "alt": "Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                            },
                            {
                                "id": "0690N00000NV8bOQAT",
                                "name": "Vicare-App",
                                "alt": null
                            },
                            {
                                "id": "0690N00000NV8bTQAT",
                                "name": "Vitotronic-Regelung",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hochleistungs-Photovoltaikmodul MAXEON (430Wp)",
                        "price": 386,
                        "id": "01t5p00000CRBDmAAP",
                        "description": "Mit über 30-Jahre Produkterfahrung gehört SunPower zu den Pionierunternehmen der Solarbranche und konnte schon mit Projekten für die NASA oder Solar Impulse seine Qualität unter Beweis stellen. Solarexperten wissen, dass SunPower Module die widerstandsfähigsten auf dem Markt sind. Mit 25 Jahre Produkt-und Leistungs Garantie ist SunPower die Benchmark bei Garantieleistungen und dadurch die Referenz für Zuverlässigkeit und Langlebigkeit. Die Maxeon® Solarzellen sind grundlegend anders und besser. Durch die Kupferbasis bieten die Solarzellen eine beispiellose Zuverlässigkeit und ermöglichen die Entwicklung von Modulen mit einem der weltweit höchsten Wirkungsgrade im Markt.\r\n\r\nModulwirkungsgrad: 22.70 %\r\nLänge: 1812 mm\r\nBreite: 1046 mm",
                        "attributes": "<ul><li>Modulleistung<ul><li>430 Wp</li></ul></li><li>Typ<ul><li><span style=\"font-size: 13px;\">SunPower MAXEON 3</span></li></ul></li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001Apgs7AAB",
                                "type": "pdf",
                                "size": 734161,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Sunpower Maxeon 3 430 Wp - Heizungsmacher AG",
                                "modified": "2022-10-21T10:58:41.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001AxU7JAAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Sunpower",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Hochleistungs-Module"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Sunpower Hochleistungs-Photovoltaikmodul MAXEON (430Wp) ➤ Kupferbasis bietet eine beispiellose Zuverlässigkeit ✔️ SunPower MAXEON 3 ✔️",
                        "defaultImage": {
                            "id": "0695p00000wr3MOAAY",
                            "name": "Hauptbild Sunpower Maxeon 3 430 Wp - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Hochleistungs-Photovoltaikmodul MAXEON (420Wp Schwarz)",
                        "price": 386,
                        "id": "01t5p00000CRpq2AAD",
                        "description": "Mit über 30-Jahre Produkterfahrung gehört SunPower zu den Pionierunternehmen der Solarbranche und konnte schon mit Projekten für die NASA oder Solar Impulse seine Qualität unter Beweis stellen. Solarexperten wissen, dass SunPower Module die widerstandsfähigsten auf dem Markt sind. Mit 25 Jahre Produkt-und Leistungs Garantie ist SunPower die Benchmark bei Garantieleistungen und dadurch die Referenz für Zuverlässigkeit und Langlebigkeit. Die Maxeon® Solarzellen sind grundlegend anders und besser. Durch die Kupferbasis bieten die Solarzellen eine beispiellose Zuverlässigkeit und ermöglichen die Entwicklung von Modulen mit einem der weltweit höchsten Wirkungsgrade im Markt.\r\n\r\nModulwirkungsgrad: 22.20 %\r\nLänge: 1812 mm\r\nBreite: 1046 mm",
                        "attributes": "<ul><li>Modulleistung<ul><li>420 Wp</li></ul></li><li>Typ<ul><li><span style=\"font-size: 13px;\">SunPower MAXEON 3 BLACK</span></li></ul></li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001ApgwXAAR",
                                "type": "pdf",
                                "size": 693284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Sunpower Maxeon 3 420 Wp black - Heizungsmacher AG",
                                "modified": "2022-10-21T10:58:02.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001AxUBeAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Sunpower",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Hochleistungs-Module"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Sunpower Hochleistungs-Photovoltaikmodul MAXEON (420Wp Schwarz) ➤ SunPower MAXEON 3 BLACK ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p00000zfEU6AAM",
                            "name": "Sunpower Maxeon 3 420 Wp Black - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpenregler SMO S40",
                        "price": 1425,
                        "id": "01t5p00000BozQFAAZ",
                        "description": "Der neue SMO S40 ist ein äusserst flexibel einsetzbares Regelgerät für NIBE Wärmepumpen, das sehr viele Anwendungsmöglichkeiten abdeckt. Umfangreiche, übersichtliche Einstellungen und die integrierte Internetverbindung via www.myuplink.com runden das Einsatzspektrum ab.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000XXJ7KAAX",
                                "type": "pdf",
                                "size": 617382,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE SMO S40 - Heizungsmacher AG",
                                "modified": "2020-12-08T08:39:46.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jB4AAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p00000XXJ7LAAX",
                                "type": "pdf",
                                "size": 188054,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE MyUplink Internetverbindung - Heizungsmacher AG",
                                "modified": "2020-12-08T08:39:32.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jB5AAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p00000XXJ7UAAX",
                                "type": "pdf",
                                "size": 3917056,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE SMO S40 - Heizungsmacher AG",
                                "modified": "2020-12-08T08:37:44.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jBEAAZ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "NIBE Wärmepumpenregler SMO S40 ➤ Flexibel einsetzbares Regelgerät ✔️ Viele Anwendungsmöglichkeiten ✔️ NIBE ✔️",
                        "defaultImage": {
                            "id": "0695p00000X9jBAAAZ",
                            "name": "Hauptbild NIBE SMO S40 - Heizungsmacher AG",
                            "alt": "NIBE SMO S40 Wärmepumpenregler"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000X9jB9AAJ",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": "Smartphone mit Applikation zum NIBE SMO S40 Wärmepumpenregler"
                            },
                            {
                                "id": "0695p00000X9jBJAAZ",
                                "name": "Offertenbild NIBE SMO S40 Regler - Heizungsmacher AG",
                                "alt": "NIBE SMO S40 Wärmepumpenregler"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S1155-6",
                        "price": 11310,
                        "id": "01t0N00000BCnC8QAL",
                        "description": "NIBE S1155-6 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 139</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-6",
                        "seoKeyword": "Nibe s1155-6",
                        "seoDescription": "Die NIBE S1155 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Mehr dazu hier!",
                        "defaultImage": {
                            "id": "0690N00000L5U9bQAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-6"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U9gQAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-6"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-6 PC",
                        "price": 13284,
                        "id": "01t0N00000BCnCFQA1",
                        "description": "NIBE S1155-6 PC ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung, Kühlung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 139</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "NIBE Wärmepumpe S1155-6 PC - Erfahren Sie bei Heizungsmacher alle Infos über dieses Produkt und fordern Sie sofort einen Expertenrat an!",
                        "defaultImage": {
                            "id": "0690N00000L5U9qQAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U9vQAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-12",
                        "price": 13675,
                        "id": "01t0N00000BCnByQAL",
                        "description": "NIBE S1155-12 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 3–12 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 167</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-12",
                        "seoKeyword": "Nibe S1155-12",
                        "seoDescription": "NIBE S1155 ist eine intelligente Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird nach Bedarf gewählt.",
                        "defaultImage": {
                            "id": "0690N00000L5U97QAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-12"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U98QAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-12"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-16",
                        "price": 14149,
                        "id": "01t0N00000BCc7aQAD",
                        "description": "NIBE S1155-16 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 4–16 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620 </li><li>Leergewicht (kg)172 </li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-16",
                        "seoKeyword": "Nibe S1155-16",
                        "seoDescription": "NIBE S1155 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Alle Infos bei uns!",
                        "defaultImage": {
                            "id": "0690N00000L4RGIQA3",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-16"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L4RInQAN",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-16"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S1255-6",
                        "price": 14396,
                        "id": "01t0N00000BCnCkQAL",
                        "description": "NIBE S1255-6 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 220</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-6",
                        "seoKeyword": "Nibe S1255-6",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung. Hier alle Infos!",
                        "defaultImage": {
                            "id": "0690N00000L5UDjQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDkQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-12",
                        "price": 15716,
                        "id": "01t0N00000BCnChQAL",
                        "description": "NIBE S1255-12 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 3–12 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 250</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-12",
                        "seoKeyword": "Nibe s1255-12",
                        "seoDescription": "NIBE S1255 ist eine intelligente Erdwärmepumpe mit integrierter Brauchwasserbereitung. Die Wärmepumpe bietet höchstes Einsparpotenzial. ➤ Mehr hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UDnQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-12"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDsQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-12"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-6 PC",
                        "price": 16331,
                        "id": "01t0N00000BCnCrQAL",
                        "description": "NIBE S1255-6 PC ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Kühlung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 220</li><li>Mit integrierter Kühlung</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-6 PC",
                        "seoKeyword": "Nibe s1255 PC",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe mit integrierter Brauchwasserbereitung. ➤ Weitere Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UDdQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6 PC"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDiQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6 PC"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-16",
                        "price": 17109,
                        "id": "01t0N00000BCnCNQA1",
                        "description": "NIBE S1255-16 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 4–16 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 255 </li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-16",
                        "seoKeyword": "Nibe S2155-16",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung. Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UCLQA3",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-16"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UCMQA3",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-16"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher HSBC 300 L",
                        "price": 5655,
                        "id": "01t0N000009MR6PQAW",
                        "description": "Beim Integralspeicher HSBC 300 L cool stehen nicht zwei Einzelspeicher nebeneinander, sondern Trinkwarmwasser- und Pufferspeicher sind übereinander in einem Gerät angeordnet. Der Platzbedarf wird so quasi halbiert und eine Aufstellung auf engstem Raum wird möglich – für mehr Nutzfläche.\r\n\r\nPräzise abgestimmt auf die Nutzung mit einer Wärmepumpe deckt der HSBC L cool alles ab, was man in einem Einfamilienhaus in Bezug auf Wärme und Trinkwarmwasser benötigt.\r\n\r\nNenninhalt Trinkwarmwasser: 270 Liter\r\nNenninhalt Pufferspeicher: 100 Liter",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdtNMQAZ",
                                "type": "pdf",
                                "size": 129463,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron HSBC 300 L Kompaktspeicher - Heizungsmacher AG",
                                "modified": "2022-05-17T09:31:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsRSQA1",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kompaktspeicher HSBC 300 L cool",
                        "seoKeyword": "Stiebel Eltron HSBC 300 L cool",
                        "seoDescription": "Beim Integralspeicher HSBC 300 L cool sind Trinkwarmwasser- und Pufferspeicher platzsparend übereinander in einem Gerät angeordnet. Hier alle Infos!",
                        "defaultImage": {
                            "id": "0690N00000NWsTNQA1",
                            "name": "Offertenbild Stiebel Eltron HSBC 300 L - Heizungsmacher AG",
                            "alt": "Stiebel Eltron HSBC 300 L Kompaktspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014zaqOAAQ",
                                "name": "Hauptbild Stiebel Eltron HSBC 300 L - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "WPL 09 ICS classic",
                        "price": 12579,
                        "id": "01t5p00000CQ9x7AAD",
                        "description": "Diese Luft-Wasser-Wärmepumpe ist für die kompakte Innenaufstellung konzipiert. Hohe Flexibilität bei Aufstellung und Installation Invertertechnologie für hohe Effizienz und niedrige Energiekosten. \r\n- Integrierter Wärmepumpenmanager\r\n- Vorlauftemperaturen von bis zu 60 °C\r\n- Max. Heizleistung bei A-7/W35: 4,23 kW\r\n- Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aJKOAA2",
                                "type": "pdf",
                                "size": 142945,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt WPL 09 ICS classic - Heizungsmacher AG",
                                "modified": "2022-06-07T06:06:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000013VmHHAA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron WPL 09 ICS classic ➤ Luft/Wasser-Wärmepumpe ✔️ Kompakte Innenaufstellung ✔️ Hohe Effizienz ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013VmEAAA0",
                            "name": "Hauptbild WPL 09 17 ICS classic - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000013VmHIAA0",
                                "name": "Offertenbild Stiebel Eltron WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000013VmJ8AAK",
                                "name": "Bild 2 WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WPL 17 ICS classic",
                        "price": 14718,
                        "id": "01t0N00000A3Cd0QAF",
                        "description": "Diese Luft-Wasser-Wärmepumpe ist für die kompakte Innenaufstellung konzipiert. Hohe Flexibilität bei Aufstellung und Installation Invertertechnologie für hohe Effizienz und niedrige Energiekosten.\r\n- Integrierter Wärmepumpenmanager\r\n- Vorlauftemperaturen von bis zu 60 °C\r\n- Max. Heizleistung bei A-7/W35: 8.02 kW\r\n- Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aJKjAAM",
                                "type": "pdf",
                                "size": 142325,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt WPL 17 ICS classic - Heizungsmacher AG",
                                "modified": "2022-06-07T06:08:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000013VmHhAAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron WPL 17 ICS classic ➤ Luft/Wasser-Wärmepumpe ✔️ Hohe Flexibilität ✔️ Invertertechnologie für hohe Effizienz ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmHbAAK",
                            "name": "Hauptbild WPL 09 17 ICS classic - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000013VmHgAAK",
                                "name": "Offertenbild Stiebel Eltron WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000013VmJ9AAK",
                                "name": "Bild 2 WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Batteriespeicher RESU10",
                        "price": 5320,
                        "id": "01t0N00000BCkSzQAL",
                        "description": "Die kompakte und leichtgewichtige Natur des RESU ist erstklassig. Das System ist besonders einfach zu installieren. Hochwertiger Niedervolt-Akku vom Markenhersteller LG. \r\n\r\nNutzbare Kapazität: 8.8 kWh bei 90% Entladetiefe.\r\nMax. DC-Ausgangsleistung: 5.0 kW",
                        "attributes": "<ul><li>Nutzbare Kapazität: 8.8 kWh bei 90% Entladetiefe.</li><li>DC-Ausgangsleistung: 5.0 kW</li><li>DC-Nennspannung: 51.80 V</li><li>Batterietechnologie: Lithium-Nickel-Mangan-Kobaltoxid</li><li>Länge: 452 mm</li><li>Breite: 227 mm</li><li>Höhe: 483 mm</li><li>Gewicht: 75 kg</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014kOzQAAU",
                                "type": "pdf",
                                "size": 281238,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt LG Chem RESU 65 10 12 - Heizungsmacher AG",
                                "modified": "2022-07-04T22:01:50.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wRYKAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "LG Chem",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "LG Chem Batteriespeicher RESU10 ➤ Kompakt und leichtgewichtig ✔️ Einfache Installation ✔️ Hochwertiger Niedervolt-Akku ✔️",
                        "defaultImage": {
                            "id": "0695p00000hP44YAAS",
                            "name": "RESU 10",
                            "alt": "LG Chem Batteriespeicher RESU10"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5ONEQA3",
                                "name": "Bild 1 LG Chem Akku RESU 6.5-10 - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Batteriespeicher RESU12",
                        "price": 6840,
                        "id": "01t5p00000CRBInAAP",
                        "description": "Die kompakte und leichtgewichtige Natur des RESU ist erstklassig. Das System ist besonders einfach zu installieren. Hochwertiger Niedervolt-Akku vom Markenhersteller LG. \r\n\r\nNutzbare Kapazität: 11.7 kWh bei 90% Entladetiefe.\r\nMax. DC-Ausgangsleistung: 5.0 kW",
                        "attributes": "<ul><li>Nutzbare Kapazität: 11.7 kWh bei 90% Entladetiefe.</li><li>DC-Ausgangsleistung: 5.0 kW</li><li>DC-Nennspannung: 51.80 V</li><li>Batterietechnologie: Lithium-Nickel-Mangan-Kobaltoxid</li><li>Länge: 452 mm</li><li>Breite: 227 mm</li><li>Höhe: 625 mm</li><li>Gewicht: 75 kg</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014kOzQAAU",
                                "type": "pdf",
                                "size": 281238,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt LG Chem RESU 65 10 12 - Heizungsmacher AG",
                                "modified": "2022-07-04T22:01:50.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wRYKAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "LG Chem",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "LG Chem Batteriespeicher RESU12 ➤ Kompakt & leichtgewichtig ✔️ Einfache Installation ✔️ Hochwertiger Niedervolt-Akku ✔️",
                        "defaultImage": {
                            "id": "0695p00000wr8GfAAI",
                            "name": "LG RESU 12",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5ONEQA3",
                                "name": "Bild 1 LG Chem Akku RESU 6.5-10 - Solarmacher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE5K-RWS 48V",
                        "price": 2710.1,
                        "id": "01t0N00000BBqAFQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 5 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE5K-RWS 48V\r\n ➤ Einfache Installation ✔️ Dreiphasige Installationen mit Batteriespeicher 5 kVA ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LG4QAN",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LG9QAN",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE7K-RWS 48V",
                        "price": 2946.3,
                        "id": "01t0N00000BBqAKQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 7 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE7K-RWS 48V\r\n ➤ Ein einziger Wechselrichter ✔️ Dreiphasige Installationen ✔️",
                        "defaultImage": {
                            "id": "0695p00001Q1a7MAAR",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGYQA3",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE8K-RWS 48V",
                        "price": 3029.2,
                        "id": "01t0N00000BBqAUQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 8 kVA\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE8K-RWS 48V ➤ Dreiphasige Installationen mit Batteriespeicher 8 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LGZQA3",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGAQA3",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE10K-RWS 48V",
                        "price": 3206.9,
                        "id": "01t0N00000BBqAZQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 10 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE10K-RWS 48V ➤ Dreiphasige Installationen mit Batteriespeicher 10 kVA ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LGnQAN",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGaQAN",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Kombispeicher SBS 601 W",
                        "price": 3770,
                        "id": "01t0N0000098rATQAY",
                        "description": "SBS 601 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung. \r\n\r\nNenninhalt: 613 Liter\r\nDurchmesser ohne Isolation: 750mm\r\nDurchmesser mit Isolation: 970mm\r\nHöhe mit Isolation: 1775mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMKJQA3",
                                "type": "pdf",
                                "size": 299445,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 601 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:23:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYwvQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 601 W",
                        "seoKeyword": "Stiebel Eltron SBS 601 W",
                        "seoDescription": "SBS 601 Heizungs-Pufferspeicher ✅ integrierte Trinkwarmwasserbereitung ✅ hygienische Warmwasseraufbereitung. Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiZ2UQAV",
                            "name": "Hauptbild SBS 600 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 601 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072G5jQAE",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 601 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYufQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 601 W"
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher SBS 801 W",
                        "price": 3959,
                        "id": "01t0N0000098rAdQAI",
                        "description": "SBS 801 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung.\r\n\r\nNenninhalt: 759 Liter\r\nDurchmesser ohne Isolation: 790mm\r\nDurchmesser mit Isolation: 1010mm\r\nHöhe mit Isolation: 1940mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMHFQA3",
                                "type": "pdf",
                                "size": 299276,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 801 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:13:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYviQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 801 W",
                        "seoKeyword": "SBS 801 W",
                        "seoDescription": "Stiebel Eltron SBS 801 Heizungs-Pufferspeicher ✅ Trinkwarmwasserbereitung im Durchflussbetrieb ✅ hygienische Warmwasseraufbereitung. Mehr dazu hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYvoQAF",
                            "name": "Hauptild SBS 800 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 801 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072GQIQA2",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 801 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYvnQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 801 W"
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher SBS 1001 W",
                        "price": 4676,
                        "id": "01t0N0000098rAnQAI",
                        "description": "SBS 1001 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung.\r\n\r\nNenninhalt: 941 Liter\r\nDurchmesser ohne Isolation: 790mm\r\nDurchmesser mit Isolation: 1010mm\r\nHöhe mit Isolation: 2350mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMGhQAN",
                                "type": "pdf",
                                "size": 299337,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 1001 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:15:54.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYtjQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 1001 W",
                        "seoKeyword": "Kombispeicher SBS 1001 W",
                        "seoDescription": "SBS 1001 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Garantierte hygienische Warmwasseraufbereitung.",
                        "defaultImage": {
                            "id": "0690N00000PiYugQAF",
                            "name": "Hauptbild SBS 1000 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 1001 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072H69QAE",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 1001 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYtiQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 1001 W"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Meyer Burger Black 390 Wp",
                        "price": 252,
                        "id": "01t5p00000D3R3VAAV",
                        "description": "Das neue Meyer Burger Heterojunction Modul bietet bis zu 20 Prozent mehr Energieertrag. Produktion der Solarzellen und -module nach höchsten Standards ausschließlich in Deutschland mit Schweizer Technologie.\r\n\r\nDie Meyer Burger HJT-Zellen nehmen deutlich mehr Sonnenenergie als herkömmliche Zellen auf und der umgewandelte elektrische Strom fliesst mit weniger Widerstand durch das Modul. Auch bei schwachem Sonnenlicht, beispielsweise wenn es bewölkt ist.\r\n\r\nZusätzliche Effizienzgewinne bringt die Verbindung der Zellen durch unsere patentierte SmartWire-Technologie. Denn die hauchdünnen Verbindungsdrähte verringern die Abschattung auf den Solarzellen um bis zu 30 Prozent. Die Module nehmen so mehr Energie auf und produzieren länger Strom. Für spürbar mehr Leistung pro Fläche auf dem Dach. Und nicht nur das.\r\n\r\nSWCT verbessert auch die Zellstabilität und macht die Module weniger anfällig für Mikrorisse, einem der häufigsten Gründe für Energieverluste bei Solarmodulen.\r\n\r\nModulwirkungsgrad: 21.5 %\r\nLänge: 1767 mm\r\nBreite: 1041 mm\r\nHöhe: 35 mm\r\nGewicht: 19.7 kg",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0lJvAAJ",
                                "type": "pdf",
                                "size": 630080,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Meyer Burger Black - Heizungsmacher AG",
                                "modified": "2023-06-09T11:28:37.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001I3hTOAAZ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Meyer Burger",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Made-in-Germany"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Meyer Burger Black 390 Wp Photovoltaikmodul - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001I3hTiAAJ",
                            "name": "Hauptbild Meyer Burger Photovoltaikmodul Black - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher PU 200",
                        "price": 752,
                        "id": "01t5p00000CMHlMAAX",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 190 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 60 x 121 cm (43 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 200 - Alle Produktinformationen bei Heizungsmacher ansehen, Vergleiche ziehen und persönliche Beratung erhalten!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yxTaAAI",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 300",
                        "price": 912,
                        "id": "01t5p00000CLuqtAAD",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 282 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 65 x 147 cm (61 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 300 - Bei Heizungsmacher finden Sie nicht nur spezifische Produktdetails, sondern erhalten auch eine umfassende Beratung!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXRaAAM",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 400",
                        "price": 1195,
                        "id": "01t5p00000D1MfQAAV",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 377 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 140 cm (70 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 400 - Sichern Sie sich jetzt bei Heizungsmacher wertvolle Infos über die Produktdetails und fordern Sie eine Beratung an!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Fe5E1AAJ",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 500",
                        "price": 1224,
                        "id": "01t5p00000CwnWpAAJ",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 479 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 174 cm (80 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 500 - Alle Produktdetails im detaillierten Überblick bei Heizungsmacher und direkte Expertenberatung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001AJL5cAAH",
                                "name": "hpa Puffer-neu",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PSF 1000",
                        "price": 2998,
                        "id": "01tVj000006Qp8IIAS",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, Isolierung aus Hightech Faservlies. Skaimantel mit Reissverschluss, inklusive Rosetten und Abdeckhaube. Silber.\r\n\r\nVolumen: 887 Liter\r\nMasse ohne Isolierung: ⌀xH: 79 x 204 cm (111kg) (ohne Isolierung)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B2QcnIAF",
                                "type": "pdf",
                                "size": 339263,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PSF - Heizungsmacher AG",
                                "modified": "2024-10-26T13:01:01.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj00000AsdD7IAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 500 - Alle Produktdetails im detaillierten Überblick bei Heizungsmacher und direkte Expertenberatung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj00000AscowIAB",
                                "name": "PSF1000 Offertbild",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 300",
                        "price": 1955,
                        "id": "01t5p00000CMHmTAAX",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 304 Liter (3.2 m² Registeroberfläche)\r\nMasse mit/ohne Isolierung: ⌀xH: 65 x 157 cm (141 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 300 - Hier bei Heizungsmacher genau über das Produkt informieren und eine umfassende Beratung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yy3eAAA",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 400",
                        "price": 2318,
                        "id": "01t5p00000CLutmAAD",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 408 Liter (4.3 m² Registeroberfläche)\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 150 cm (179 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 400 - Bei Heizungsmacher alle Produktdetails erhalten und eine kompetente und persönliche Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXbyAAE",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 500",
                        "price": 2977,
                        "id": "01t5p00000D1R1IAAV",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 498 Liter (5.4 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 180 cm (217 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p00001DLYPqAAP",
                            "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXZ9AAM",
                                "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 600",
                        "price": 3069,
                        "id": "01tVj000005aQ9BIAU",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 559 Liter (5.4 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 200 cm (228kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000009AEWDIA4",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 800",
                        "price": 4898,
                        "id": "01tVj000003BxB8IAK",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. Mehrschicht-Isolierung aus 80 mm Hartschaum + 20 mm Faservlies. PS-Mantel und Hakenleiste, inklusive Rosetten und Abdeckhaube. Brandschutzklasse B2. Silber\r\n\r\nVolumen: 830 Liter (6.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 199 cm (291 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000004q6LeIAI",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG (2)",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 1000",
                        "price": 5680,
                        "id": "01t5p00000CxBO2AAN",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. Mehrschicht-Isolierung aus 80 mm Hartschaum + 20 mm Faservlies. PS-Mantel und Hakenleiste, inklusive Rosetten und Abdeckhaube. Brandschutzklasse B2. Silber\r\n\r\nVolumen: 925 Liter (6.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 219 cm (308 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 1000 - Bei Heizungsmacher tiefgehende Produktdetails einsehen und sofort einen Expertenrat anfordern!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001ALDEBAA5",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG (1)",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WSW196i.2-6",
                        "price": 11039,
                        "id": "01t5p00000CMH8DAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 2-5.85 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-6 - Hier bei Heizungsmacher detailierte Produktdetails finden und bei Bedarf eine gezielte Beratung erhalten!",
                        "defaultImage": {
                            "id": "0695p000014yujQAAQ",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-8",
                        "price": 11230,
                        "id": "01t5p00000CMH8yAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 2-7.61 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-8 - Bei Heizungsmacher finden Sie genaue Produktdetails und können sich ausführlich informieren und beraten lassen!",
                        "defaultImage": {
                            "id": "0695p000014yujRAAQ",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-12",
                        "price": 12467,
                        "id": "01t5p00000CMH9DAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 3-12 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-12 - Erfahren Sie hier bei Heizungsmacher mehr über das Produkt und erhalten Sie um eine professionelle Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yujzAAA",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-16",
                        "price": 12657,
                        "id": "01t5p00000CMH9VAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 4-15 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-16 - Hier bei Heizungsmacher detaillierte Produktdetails ansehen und professionelle Beratung sichern!",
                        "defaultImage": {
                            "id": "0695p000014yulvAAA",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WSW196i.2-6 TP50",
                        "price": 12134,
                        "id": "01t5p00000CMH96AAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal. \r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 61x60x178cm\r\nHeizleistung: 2-5.85 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-6 TP50 - Bei Heizungsmacher mehr über die umfassenden Produktdetails erfahren und individuelle Beratung verlangen!",
                        "defaultImage": {
                            "id": "0695p000014yuitAAA",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yukOAAQ",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-8 TP50",
                        "price": 12322,
                        "id": "01t5p00000CMH9fAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 2-7.61 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-8 TP50 - Schauen Sie sich bei Heizungsmacher die Produktdetails an und erhalten Sie eine professionelle Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yul2AAA",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yul3AAA",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-12 TP50",
                        "price": 13451,
                        "id": "01t5p00000CMH9pAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 3-12 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-12 TP50 - Bei Heizungsmacher detaillierte Produktdetails einsehen und qualifizierte Beratung schnell erhalten!",
                        "defaultImage": {
                            "id": "0695p000014zvUsAAI",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-16 TP50",
                        "price": 13639,
                        "id": "01t5p00000CMH9vAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 4-15 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-16 TP50 - Entdecken Sie bei Heizungsmacher präzise Produktdetails und sichern Sie fundierte Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yulHAAQ",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yulMAAQ",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe Vitocal 250-A06",
                        "price": 9725,
                        "id": "01t5p00000DDEbZAAX",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 0.02 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C.\r\n\r\nTyp: \t250-A AWO-M-E-AC 251.A06\r\nLeistung bei A-7/W35: 5.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Wärmepumpe Vitocal 250-A8 - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001T830nAAB",
                            "name": "Hauptbild Viessmann Vitocal 250-A08 - Heizungsmacher AG (1)",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T820XAAR",
                                "name": "Offertenbild Viessmann 250-A klein - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A08",
                        "price": 10438,
                        "id": "01t5p00000Cy8UoAAJ",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 0.02 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C.\r\n\r\nTyp: \t250-A AWO-M-E-AC 251.A08\r\nLeistung bei A-7/W35: 6.5 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Wärmepumpe Vitocal 250-A8 - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001I3GGvAAN",
                            "name": "Hauptbild Viessmann Vitocal 250-A08 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001MDACyAAP",
                                "name": "Offertenbild Viessmann 250-A klein - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A10",
                        "price": 12271,
                        "id": "01t5p00000CPr4LAAT",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 3 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C – und das bei einer Außentemperatur von bis zu –15 °C. \r\n\r\nTyp: AWO-E-AC-AF 251.A10\r\nLeistung bei A-7/W35: 9.7 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Viessmann Vitocal 250-A10 ➤ Viessmann Wärmepumpentechnik ✔️ Modernes Design ✔️ „Grünes“ Kältemittel R290 (Propan) ✔️",
                        "defaultImage": {
                            "id": "0695p00001I3G8XAAV",
                            "name": "Hauptbild Viessmann Vitocal 250-A - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001I3G8hAAF",
                                "name": "Offertbild Vitocal 250-A 2-fach",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A13",
                        "price": 12944,
                        "id": "01t5p00000CPr4IAAT",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 3 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C – und das bei einer Außentemperatur von bis zu –15 °C. \r\n\r\nTyp: AWO-E-AC-AF 251.A13\r\nLeistung bei A-7/W35: 11.1 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Viessmann Vitocal 250-A13 ➤ Viessmann Wärmepumpentechnik ✔️ Modernes Design ✔️ „Grünes“ Kältemittel R290 (Propan ✔️",
                        "defaultImage": {
                            "id": "0695p00000zgeHKAAY",
                            "name": "Hauptbild Viessmann Vitocal 250-A - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000ppXzNAAU",
                                "name": "Offertbild Vitocal 250-A 2-fach",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Viessmann Vitocal 250-A Hydraulikeinheit",
                        "price": 2909,
                        "id": "01t5p00000CPr4XAAT",
                        "description": "Mit neuer Hydro AutoControl Hydraulik zur Bereitstellung der Notwendigen\r\nAbtauenergie und Sicherstellung der erforderlichen Mindest-Umlaufmenge im Wärmepumpen Kreis\r\n- Mit eingebauter Hocheffizienz-Umwälzpumpe für den Heizkreis\r\n- Abtaupuffer 16 l\r\n- Bypassfunktion\r\n- Heizwasser Durchlauferhitzer\r\n- 4-3-Wegeventil\r\n- Volumenstromsensor\r\n\r\nBedienung über 7-Zoll-Farb-Touchdisplay mit Klartext- und Grafikanzeige,\r\nInbetriebnahme-Assistenten, Anzeigen für Energieverbräuche sowie\r\nalternativ Bedienung über mobiles Endgerät, Internetfähig durch integrierte\r\nWLAN-Schnittstelle für Bedienung und Service über Viessmann Apps.\r\n\r\nFür 1 Heizkreis.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0K2EAAV",
                                "type": "pdf",
                                "size": 233279,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Viessmann Vitocal 250A - Heizungsmacher AG",
                                "modified": "2023-06-06T05:52:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GN8AAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T822xAAB",
                            "name": "Hauptbild Viessmann Vitocal 250-A Hydraulikeinheit - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000ppYNyAAM",
                                "name": "Offertbild Vitocal 250-A Hydrailikeinheit 1HK",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T820YAAR",
                                "name": "Hauptbild Viessmann Vitocal 250-A Inneneinheit - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher Vitocal 252-A",
                        "price": 4802,
                        "id": "01t5p00000CPr4iAAD",
                        "description": "Die bodenstehende Kompakt-Einheit ist die intelligente Kombination aus effizienter Wärmepumpentechnik und hohem Warmwasserkomfort – dank integriertem 190-Liter-Warmwasserspeicher in einem Gehäuse. \r\n\r\nMit neuer Hydro AutoControl Hydraulik zur Bereitstellung der Notwendigen\r\nAbtauenergie und Sicherstellung der erforderlichen Mindest-Umlaufmenge im Wärmepumpen Kreis\r\n- Mit eingebauter Hocheffizienz-Umwälzpumpe für den Heizkreis\r\n- Abtaupuffer 16 l\r\n- Bypassfunktion\r\n- Heizwasser Durchlauferhitzer\r\n- 4-3-Wegeventil\r\n- Volumenstromsensor\r\n\r\nBedienung über 7-Zoll-Farb-Touchdisplay mit Klartext- und Grafikanzeige,\r\nInbetriebnahme-Assistenten, Anzeigen für Energieverbräuche sowie\r\nalternativ Bedienung über mobiles Endgerät, Internetfähig durch integrierte\r\nWLAN-Schnittstelle für Bedienung und Service über Viessmann Apps.\r\n\r\nFür 1 Heizkreis.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0JwQAAV",
                                "type": "pdf",
                                "size": 260604,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Viessmann Vitocal 252A - Heizungsmacher AG",
                                "modified": "2023-06-06T05:41:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXn1AAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Kompaktspeicher Vitocal 252-A - Bei Heizungsmacher alle Produktdetails kennenlernen und zielgerichtete Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00000ppYXQAA2",
                            "name": "Offertbild Vitocal 250-A Kompakteinheit 1HK",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014ygjwAAA",
                                "name": "viessmann-waermepumpe-252A-2C_5",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yh1zAAA",
                                "name": "Vitocal 252-A_Inneneinheit_Schnitt",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "SMA Hybrid Sunny Tripower 5.0 Smart Energy",
                        "price": 2212,
                        "id": "01t5p00000CRAkyAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 5.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 5 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8AAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 6.0 Smart Energy",
                        "price": 2548,
                        "id": "01t5p00000CRAkUAAX",
                        "description": "Der SMA Hybrid-Wechselrichter STP 6.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 6 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8FAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 8.0 Smart Energy",
                        "price": 2980,
                        "id": "01t5p00000CRAkjAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 8.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 8 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SMA Solar Technology AG",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8BAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 10.0 Smart Energy",
                        "price": 3220,
                        "id": "01t5p00000CRAkkAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 10.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 10 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8PAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Home Hub Wechselrichter SE5K-RWB 48V",
                        "price": 2880,
                        "id": "01t5p00000CpanfAAB",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 5 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE5K-RWB 48V ➤ Kein MPP Tracker ✔️ Zwei Eingänge für SolarEdge Modul Optimierer ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmEDAA0",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Hub Wechselrichter SE8K-RWB 48V",
                        "price": 3192,
                        "id": "01t5p00000CpaoFAAR",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 8 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE8K-RWB 48V ➤ Dreiphasige Installationen mit Batteriespeicher 8 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmEIAA0",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Hub Wechselrichter SE10K-RWB 48V",
                        "price": 3360,
                        "id": "01t5p00000CpaoiAAB",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 10 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE10K-RWB 48V ➤ Dreiphasige Installationen mit Batteriespeicher 10 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmE8AAK",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Home Batterie 4.6 kWh",
                        "price": 3081,
                        "id": "01t5p00000CpapUAAR",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 4.6 kWh\r\nAnzahl Module: 1 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 4.6 kWh ➤ Optimierte Speicherlösung ✔️ dreiphasig mit optimalem Ersatzstrom✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013Vk5TAAS",
                            "name": "Hauptbild SolarEdge Home Battery 4-6kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 9.2 kWh",
                        "price": 5842,
                        "id": "01t5p00000CpapnAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 9.2 kWh\r\nAnzahl Module: 2 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 9.2 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit Ersatzstrom ✔️ Skalierbar von 4.6 – 23kWh ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk5EAAS",
                            "name": "Hauptbild SolarEdge Home Battery 9-2kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 13.8 kWh",
                        "price": 8603,
                        "id": "01t5p00000CpaplAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 13.8 kWh\r\nAnzahl Module: 3 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 13.8 kWh ➤ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️ Hier entlang!",
                        "defaultImage": {
                            "id": "0695p000013Vk3tAAC",
                            "name": "Hauptbild SolarEdge Home Battery 13-8kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 18.4 kWh",
                        "price": 11535,
                        "id": "01t5p00000CpapWAAR",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 18.4 kWh\r\nAnzahl Module: 4 Stk. (2+2)\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 18.4 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk3sAAC",
                            "name": "Hauptbild SolarEdge Home Battery 18-4kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 23.0 kWh",
                        "price": 14265,
                        "id": "01t5p00000CpaoaAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 23.0 kWh\r\nAnzahl Module: 5 Stk. (3+2)\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 23.0 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk3rAAC",
                            "name": "Hauptbild SolarEdge Home Battery 23kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Home Backup Interface (Ersatzstrom)",
                        "price": 1314,
                        "id": "01t5p00000CpaqUAAR",
                        "description": "Mit dem Home Backup-Interface bietet SolarEdge eine Ersatzstromlösung mit automatischer Umschaltung sollte das Stromnetz einmal ausfallen.\r\n- automatische Umschaltung < 10 Sekunden\r\n- Ersatzstrom für das ganze Haus (WP nicht empfohlen)\r\n- Schwarzstartfähig\r\n- Nur in Kombination mit Batterien möglich\r\nInkl. Verkabelung 5x6/10.0mm2 3LNPE bis 6m.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aGtJAAU",
                                "type": "pdf",
                                "size": 216111,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Backup-Interface - Heizungsmacher AG",
                                "modified": "2022-06-06T08:41:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk6vAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Ersatzstrom"
                            }
                        ],
                        "seoTitle": "SolarEdge Home Backup Interface",
                        "seoKeyword": "SolarEdge Home Backup Interface",
                        "seoDescription": "SolarEdge Home Backup Interface ➤ Automatische Umschaltung ✔️ Schwarzstartfähig ✔️ Dreiphasige Ersatzstromlösung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013Vk7ZAAS",
                            "name": "Hauptbild SolarEdge Home Backup Interface - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "WLW186i-5 AR E",
                        "price": 8169,
                        "id": "01t5p00000D3tn2AAB",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 110 x 54 x 80 cm\r\nLeistung bei A-7/W35: 5.4 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBimAAAT",
                            "name": "Offertenbild Buderus WSW 186i AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-7 AR E",
                        "price": 8693,
                        "id": "01t5p00000D3tmiAAB",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 110 x 54 x 80 cm\r\nLeistung bei A-7/W35: 6.7 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBilvAAD",
                            "name": "Offertenbild Buderus WSW 186i AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-10 AR E",
                        "price": 10813,
                        "id": "01t5p00000DDEO5AAP",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 135 x 54 x 105 cm\r\nLeistung bei A-7/W35: 9.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T80VvAAJ",
                            "name": "Offertenbild Buderus WSW 186i 10-12 AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-12 AR E",
                        "price": 11523,
                        "id": "01t5p00000DDET2AAP",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 135 x 54 x 105 cm\r\nLeistung bei A-7/W35: 11.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T80xwAAB",
                            "name": "Offertenbild Buderus WSW 186i 10-12 AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Inneneinheit wandhängend",
                        "price": 2519,
                        "id": "01t5p00000D3uQXAAZ",
                        "description": "Bei einer Sanierung eignet sich eine wandhängende Inneneinheit. Denn sie kann mit einem nebenstehenden Warmwasser- und Pufferspeicher flexibel kombiniert werden.\r\n- Eingebaute Hocheffizienz-Umwälzpumpe\r\n- Notbetrieb Durchlauferhitzer\r\n- 3-Weg-Umschaltventil für Heizung und Brauchwarmwasser\r\n- Touch-Systembedieneinheit \r\nLogamatic BC400 mit Funkmodul MX300\r\n\r\nMasse (L x B x T): 720 x 400 x 297 mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBkrXAAT",
                            "name": "Buderus Inneneinheit Wand offen",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001OBks0AAD",
                                "name": "Offertenbild Buderus WSW 186i AR Inneneinheit Wand - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Inneneinheit mit 70 L Puffer",
                        "price": 4037,
                        "id": "01t5p00000D3uQYAAZ",
                        "description": "Die kompakte Variante mit integriertem 70 Liter Pufferspeicher benötigt wenig Platz im Haus und eignet sich mit einem geeigneten Heizverteilsystem als Vollintegrierte Lösung. In Kombination mit einem externen Warmwasserspeicher bietet die Inneneinheit höchsten Warmwasserkomfort. \r\n\r\n- Eingebaute Hocheffizienz-Umwälzpumpe\r\n- Notbetrieb Durchlauferhitzer\r\n- Umschalt-Wegeventil für Heizung und Brauchwarmwasser\r\n- Touch-Systembedieneinheit \r\nLogamatic BC400 mit Funkmodul MX300\r\n\r\nMasse (L x B x T): 1275 x 600 x 600 mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBkrWAAT",
                            "name": "Buderus Inneneinheit TP70 offen",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001OBkrbAAD",
                                "name": "Offertenbild Buderus WSW 186i AR Inneneinheit TP70 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            }
        ]
    },
    "configuration": {
        "productCategories": [
            {
                "nameFr": "HeizungenFr",
                "nameEn": "HeizungenEn",
                "nameDe": "HeizungenDe",
                "name": "Heizungen",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "waermepumpen",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "luft-wasser-aussenaufstellung",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "sole-wasser-erdsonde",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "effizienz-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "kombi-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "kompakt-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "luft-wasser-innenaufstellung",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "regler",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Speicher",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Regler-ALT",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Pumpen und Heizkreisgruppen",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Sonstiges",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Erdsondenbohrung",
                        "level": 1,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": "SolarFr",
                "nameEn": "SolarEn",
                "nameDe": "SolarDe",
                "name": "Solar",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Standard",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Batterieanschluss",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Integrierte-Batterie",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Optimizer",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Ersatzstrom",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Batteriespeicher",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Niedervolt",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Hochvolt",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Photovoltaikmodul",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Standard-Module",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Hochleistungs-Module",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Made-in-Germany",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Monitoring",
                        "level": 1,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "waermepumpen",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "luft-wasser-aussenaufstellung",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "sole-wasser-erdsonde",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "effizienz-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "kombi-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "kompakt-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "luft-wasser-innenaufstellung",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "regler",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Speicher",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Regler-ALT",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Pumpen und Heizkreisgruppen",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Sonstiges",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Standard",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Batterieanschluss",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Integrierte-Batterie",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Optimizer",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Ersatzstrom",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Batteriespeicher",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Niedervolt",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Hochvolt",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Photovoltaikmodul",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Standard-Module",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Hochleistungs-Module",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Made-in-Germany",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Erdsondenbohrung",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Monitoring",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "luft-wasser-aussenaufstellung",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "sole-wasser-erdsonde",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "effizienz-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "kombi-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "kompakt-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Standard",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Batterieanschluss",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Integrierte-Batterie",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Standard-Module",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Hochleistungs-Module",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Made-in-Germany",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Niedervolt",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "luft-wasser-innenaufstellung",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "regler",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Optimizer",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Ersatzstrom",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Hochvolt",
                "level": 2,
                "childCategories": []
            }
        ],
        "manufacturers": [
            {
                "name": "alpha innotec",
                "id": "0010N00004FH7LzQAL"
            },
            {
                "name": "Stiebel Eltron",
                "id": "0010N00004FKSvnQAH"
            },
            {
                "name": "Viessmann",
                "id": "0010N00004Pn2D9QAJ"
            },
            {
                "name": "NIBE",
                "id": "0010N00004WO11tQAD"
            },
            {
                "name": "Kostal Solar Electric",
                "id": "0010N00004Z2W1CQAV"
            },
            {
                "name": "BYD",
                "id": "0010N00004aPbKlQAK"
            },
            {
                "name": "HPA",
                "id": "0010N00004aPx4MQAS"
            },
            {
                "name": "BOSCH",
                "id": "0010N00004acd3HQAQ"
            },
            {
                "name": "Belimo",
                "id": "0010N00004acgb9QAA"
            },
            {
                "name": "Grundfos",
                "id": "0010N00004addypQAA"
            },
            {
                "name": "PAW",
                "id": "0010N00004ademzQAA"
            },
            {
                "name": "SolarEdge",
                "id": "0010N00004bkWC8QAM"
            },
            {
                "name": "Wikora",
                "id": "0010N00004bml3WQAQ"
            },
            {
                "name": "AEH Oekotherm AG",
                "id": "0010N00004cdDKUQA2"
            },
            {
                "name": "Griesser AG",
                "id": "0010N00004eKCDiQAO"
            },
            {
                "name": "HakaGerodur AG",
                "id": "0010N00004eKCPeQAO"
            },
            {
                "name": "Buderus",
                "id": "0010N00004gGBuWQAW"
            },
            {
                "name": "R. Nussbaum AG",
                "id": "0010N00004hcWc5QAE"
            },
            {
                "name": "Alera energies AG",
                "id": "0010N00004koyf8QAA"
            },
            {
                "name": "NIBE AB (Sweden - nicht verwenden)",
                "id": "0010N00004kp2DKQAY"
            },
            {
                "name": "Paul Bauder AG",
                "id": "0010N00004p3o7FQAQ"
            },
            {
                "name": "Jinko Solar",
                "id": "0010N00004uFHCkQAO"
            },
            {
                "name": "LG Electronics",
                "id": "0010N00004uFHTYQA4"
            },
            {
                "name": "Sunpower",
                "id": "0010N00004uFInjQAG"
            },
            {
                "name": "SMA Solar Technology AG",
                "id": "0010N00004uFJC6QAO"
            },
            {
                "name": "E3DC",
                "id": "0010N00004uFMPaQAO"
            },
            {
                "name": "Trina Solar",
                "id": "0010N00004uFMfnQAG"
            },
            {
                "name": "K2 Systems",
                "id": "0010N000050Hmj5QAC"
            },
            {
                "name": "Depping AG",
                "id": "0010N00005G7tHoQAJ"
            },
            {
                "name": "CTC",
                "id": "0010N00005IvNUBQA3"
            },
            {
                "name": "LG Chem",
                "id": "0010N00005MykGKQAZ"
            },
            {
                "name": "A. Tschümperlin AG",
                "id": "0010N00005MywvqQAB"
            },
            {
                "name": "Novelan",
                "id": "0010N00005Phfq3QAB"
            },
            {
                "name": "Bucher Blechtechnik AG",
                "id": "0015p00005TtVtkAAF"
            },
            {
                "name": "Dani's Metalldesign GmbH",
                "id": "0015p00005UTfQFAA1"
            },
            {
                "name": "Meyer Burger",
                "id": "0015p00005aeeprAAA"
            },
            {
                "name": "Fronius",
                "id": "0015p00005b5gMdAAI"
            },
            {
                "name": "LAMBDA Wärmepumpen GmbH",
                "id": "0015p00005fuigMAAQ"
            },
            {
                "name": "JA Solar",
                "id": "0015p00005kPHpmAAG"
            },
            {
                "name": "Regli Energy",
                "id": "0015p00005pRYZlAAO"
            },
            {
                "name": "Wärmeaustausch Technologien AG WT",
                "id": "0015p00005qczPHAAY"
            },
            {
                "name": "BRUGG Rohrsystem AG",
                "id": "0015p00005vBc3qAAC"
            },
            {
                "name": "WS Weinmann & Schanz GmbH",
                "id": "0015p000061WrYWAA0"
            },
            {
                "name": "Oekoswiss Supply AG",
                "id": "0015p000066srNiAAI"
            },
            {
                "name": "Dimplex",
                "id": "0015p000068z1lqAAA"
            },
            {
                "name": "Sun2World Sustainable Energy Projects GmbH",
                "id": "0015p000068zR7fAAE"
            },
            {
                "name": "Debrunner Acifer AG",
                "id": "0015p000068zU7wAAE"
            },
            {
                "name": "Glaromat AG",
                "id": "0015p000068zfv8AAA"
            },
            {
                "name": "Forstner Speichertechnik GmbH",
                "id": "001Vj000004LDEdIAO"
            },
            {
                "name": "LONGi",
                "id": "001Vj000004U3J8IAK"
            }
        ],
        "bundleCategories": [
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Heizungen",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": "Wärmepumpen Fr",
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wärmepumpen",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Luft/Wasser",
                                "level": 2,
                                "childCategories": [
                                    {
                                        "nameFr": null,
                                        "nameEn": null,
                                        "nameDe": null,
                                        "name": "Innenaufgestellt",
                                        "level": 3,
                                        "childCategories": []
                                    },
                                    {
                                        "nameFr": null,
                                        "nameEn": null,
                                        "nameDe": null,
                                        "name": "Aussenaufgestellt",
                                        "level": 3,
                                        "childCategories": []
                                    }
                                ]
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Sole/Wasser (Erdsonde)",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Solar",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Photovoltaik",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter mit Batterieanschluss (extern)",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter mit integrierter Batterie",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    }
                ]
            },
            {
                "nameFr": "Wärmepumpen Fr",
                "nameEn": null,
                "nameDe": null,
                "name": "Wärmepumpen",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Luft/Wasser",
                        "level": 2,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Innenaufgestellt",
                                "level": 3,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Aussenaufgestellt",
                                "level": 3,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Sole/Wasser (Erdsonde)",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Photovoltaik",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter mit Batterieanschluss (extern)",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter mit integrierter Batterie",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Luft/Wasser",
                "level": 2,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Innenaufgestellt",
                        "level": 3,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Aussenaufgestellt",
                        "level": 3,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Sole/Wasser (Erdsonde)",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter mit Batterieanschluss (extern)",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter mit integrierter Batterie",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Innenaufgestellt",
                "level": 3,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Aussenaufgestellt",
                "level": 3,
                "childCategories": []
            }
        ]
    }
}} location={props.location}
          />
<ProductList
            {...{
    "type": "productList",
    "id": "9bhpRlgQF5zyOSHns5rZB",
    "updatedAt": "2022-03-23T09:06:19.962Z",
    "title": "Unsere Batteriespeicher",
    "category": "Batteriespeicher Niedervolt",
    "products": {
        "metaProducts": [
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WPL 15 AS",
                        "price": 11455,
                        "id": "01t0N00000982hPQAQ",
                        "description": "Die WPL 15 AS Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 6.86 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz4bQAA",
                                "type": "pdf",
                                "size": 656765,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 15 AS Stiebel Eltron",
                                "modified": "2018-10-18T13:23:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkLRQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 15 AS",
                        "seoKeyword": "WPL 15 AS",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe (WPL 15 AS) zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. Erfahren Sie bei uns mehr!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZHQAZ",
                            "name": "Hauptbild WPL 15 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 15 AS Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 15 ACS (Kühlung)",
                        "price": 12249,
                        "id": "01t0N000009hWNKQA2",
                        "description": "Die WPL 15 ACS Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 6.86 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz41QAA",
                                "type": "pdf",
                                "size": 657801,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 15 ACS Stiebel Eltron",
                                "modified": "2018-10-18T13:23:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkKrQAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 15 ACS (Kühlung)",
                        "seoKeyword": "WPL 15 ACS",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion. ➤ Hier mehr dazu!",
                        "defaultImage": {
                            "id": "0690N00000Cj3YxQAJ",
                            "name": "Hauptbild WPL 15 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 15 ACS Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTdhQAG",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 15 ACS Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 20 A",
                        "price": 14138,
                        "id": "01t0N00000982hFQAQ",
                        "description": "Die WPL 20 A Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 9.54 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz5JQAQ",
                                "type": "pdf",
                                "size": 654783,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 20 A Stiebel Eltron",
                                "modified": "2018-10-18T13:25:31.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkM9QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 20 A",
                        "seoKeyword": "Stiebel Eltron WPL 20 A",
                        "seoDescription": "Die WPL 20 A Inverter ist eine Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZRQAZ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 20 A Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000t71cAAAQ",
                                "name": "Offertenbild Stiebel Eltron WPL 20 25 A - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 20 AC (Kühlung)",
                        "price": 14935,
                        "id": "01t0N000009iEYaQAM",
                        "description": "Die WPL 20 AC Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 9.54 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz6RQAQ",
                                "type": "pdf",
                                "size": 657844,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 20 AC Stiebel Eltron",
                                "modified": "2018-10-18T13:27:01.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkNHQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 20 AC",
                        "seoKeyword": "WPL 20 AC Stiebel eltron",
                        "seoDescription": "Inverter Luft-Wasser-Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. Weitere Informationen bekommen Sie bei uns!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZWQAZ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 20 AC Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTeVQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 20 AC Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 25 A",
                        "price": 15733,
                        "id": "01t0N00000982bwQAA",
                        "description": "Die WPL 25 A Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 12.86 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz8EQAQ",
                                "type": "pdf",
                                "size": 656543,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 25 A Stiebel Eltron",
                                "modified": "2018-10-18T13:31:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkP8QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 25 A",
                        "seoKeyword": "WPL 25 A",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. ➤ Weitere Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZbQAJ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 25 A Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTbHQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 25 A Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 25 AC (Kühlung)",
                        "price": 16530,
                        "id": "01t0N000009jdeBQAQ",
                        "description": "Die WPL 25 AC Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 12.86 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzJlQAI",
                                "type": "pdf",
                                "size": 657686,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 25 AC Stiebel Eltron",
                                "modified": "2018-10-18T13:50:27.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkaLQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 25 AC (Kühlung)",
                        "seoKeyword": "Stiebel Eltron WPL 25 AC",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe (WPL 25 AC) zur kompakten Aussenaufstellung mit niedrigem Schallleistungspegel und aktiver Kühlfunktion. Mehr hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZgQAJ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 25 AC Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTeWQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 25 AC Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher SBP 200 E",
                        "price": 950,
                        "id": "01t0N00000982j3QAA",
                        "description": "Der Standspeicher SBP 200 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit  perfekt isolierender Ummantelung.\r\n\r\nVolumen: 207 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 63 x 153.5 cm (58kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkCgQAI",
                                "type": "pdf",
                                "size": 399066,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 200 E Stiebel Eltron",
                                "modified": "2018-10-18T13:59:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxYeQAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 200 E",
                        "seoKeyword": "Stiebel Eltron SBP 200 E",
                        "seoDescription": "Der Stiebel Eltron Standspeicher eignet sich mit perfekt isolierender Ummantelung als Pufferspeicher für Wärmepumpenanlagen. ➤ Jetzt informieren!",
                        "defaultImage": {
                            "id": "0690N00000CiwNsQAJ",
                            "name": "Stiebel_Eltron_Pufferspeicher_SBP_200_400_700_WEB",
                            "alt": "Stiebel Eltron SBP 200 E Pufferspeicher (200 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGhRQAW",
                                "name": "SE_SBP_200_400E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 200 E Pufferspeicher (200 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher SBP 400 E",
                        "price": 1240,
                        "id": "01t0N00000982jbQAA",
                        "description": "Der Standspeicher SBP 400 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit perfekt isolierender Ummantelung.\r\n\r\nVolumen: 415 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 171 cm (81 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkCuQAI",
                                "type": "pdf",
                                "size": 398895,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 400 E Stiebel Eltron",
                                "modified": "2018-10-18T14:00:29.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxYsQAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 400 E",
                        "seoKeyword": "Pufferspeicher SBP 400 E",
                        "seoDescription": "Der Standspeicher SBP 400 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ✅ Isolierende Ummantelung ✅ Energieklasse B ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000CiwPtQAJ",
                            "name": "Stiebel_Eltron_Pufferspeicher_SBP_200_400_700_WEB",
                            "alt": "Stiebel Eltron SBP 400 E Pufferspeicher (400 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGgsQAG",
                                "name": "SE_SBP_200_400E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 400 E Pufferspeicher (400 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher SBP 700 E",
                        "price": 1733,
                        "id": "01t0N00000982jlQAA",
                        "description": "Der Standspeicher SBP 700 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit  perfekt isolierender Ummantelung.\r\n\r\nVolumen: 720 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 91 x 189 cm (185 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkD4QAI",
                                "type": "pdf",
                                "size": 399136,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 700 E Stiebel Eltron",
                                "modified": "2018-10-18T14:01:33.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxZ2QAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 700 E",
                        "seoKeyword": "Stiebel Eltron Pufferspeicher",
                        "seoDescription": "Der Standspeicher eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit perfekt isolierender Ummantelung Energieklasse B.",
                        "defaultImage": {
                            "id": "0690N00000Ck2h7QAB",
                            "name": "Stiebel Eltron SBP 700 E (Hauptbild)",
                            "alt": "Stiebel Eltron SBP 700 E Pufferspeicher (700 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGgEQAW",
                                "name": "SE_SBP_700E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 700 E Pufferspeicher (700 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher SBB 312.B WP",
                        "price": 2320,
                        "id": "01t0N00000982lYQAQ",
                        "description": "Standspeicher SBB 312.B mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. Wärmedämmung als PU-Direktumschäumung.\r\n\r\nVolumen: 309 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 173.8 cm (194 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzX7QAI",
                                "type": "pdf",
                                "size": 200096,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 312.B Stiebel Eltron",
                                "modified": "2021-08-10T08:24:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zknQQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 312.B WP",
                        "seoKeyword": "Stiebel Eltron Trinkwasserspeicher",
                        "seoDescription": "Standspeicher mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000CiwVhQAJ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": "Stiebel Eltron SBB 312.B WP Trinkwarmwasserspeicher (300 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UH0iQAG",
                                "name": "SE_SBB_312B_WP_Trink_Warmwasserspeicher",
                                "alt": "Stiebel Eltron SBB 312.B WP Trinkwarmwasserspeicher (300 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher  SBB 411.B WP",
                        "price": 2646,
                        "id": "01t0N00000982liQAA",
                        "description": "Standspeicher SBB 411.B mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. Wärmedämmung als PU-Direktumschäumung.\r\n\r\nVolumen: 395 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 185 cm (192 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzrBQAQ",
                                "type": "pdf",
                                "size": 201522,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:43.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zl7PQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 411.B WP",
                        "seoKeyword": "Stiebel Eltron Trinkwarmwasserspeicher",
                        "seoDescription": "SBB 411.B Standspeicher ➤ Innen liegenden Glattrohr-Wärmeübertrager ✔️ Emailliert & Verkalkungsunempfindlich ✔️ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000CiwWBQAZ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": "Stiebel Eltron SBB 411.B WP Trinkwarmwasserspeicher (400 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UH1HQAW",
                                "name": "SE_SBB_411B_WP_Trink_Warmwasserspeicher",
                                "alt": "Stiebel Eltron SBB 411.B WP Trinkwarmwasserspeicher (400 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher SBB 501 WP",
                        "price": 3081,
                        "id": "01t5p00000CQhLAAA1",
                        "description": "Standspeicher SBB 501 WP SOL mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und besitzen eine hohe Trinkwarmwasserverfügbarkeit durch abgestimmte Ein- und Ausströmtechnik.\r\n\r\nVolumen: 495 Liter\r\nMasse ohne Isolierung: ⌀xH: 69 x 198.8 cm (222 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vKByWAAW",
                                "type": "pdf",
                                "size": 208855,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 501 Stiebel Eltron - Heizungsmacher AG",
                                "modified": "2022-01-11T07:01:49.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000uizjOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 501 WP",
                        "seoKeyword": "Stiebel Eltron Trinkwarmwasserspeicher",
                        "seoDescription": "SBB 501.B Standspeicher ➤ Innen liegenden Glattrohr-Wärmeübertrager ✔️ Emailliert & Verkalkungsunempfindlich ✔️ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0695p00000uiziCAAQ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000uiziDAAQ",
                                "name": "SE_SBB_411B_WP_Trink_Warmwasserspeicher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Erweiterung WPE",
                        "price": 830,
                        "id": "01t0N000009jMZKQA2",
                        "description": "Funktionserweiterungsmodul für den WPM.\r\nRegelung von zwei zusätzlichen gemischten Heizkreisen.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzvjQAA",
                                "type": "pdf",
                                "size": 915359,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPE Stiebel Eltron",
                                "modified": "2018-10-18T15:04:24.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zlBlQAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron Erweiterung WPE ➤ FFunktionserweiterungsmodul für den WPM ✔️ Regelung von zwei zusätzlichen gemischten Heizkreisen ✔️",
                        "defaultImage": {
                            "id": "0690N00000Cj3ajQAB",
                            "name": "Hauptbild WPE Stiebel Eltron",
                            "alt": "Stiebel Eltron Wärmepumpenregler WPE Erweiterung"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000ANFQPQA5",
                                "name": "SE_WPE_Erweiterung_2",
                                "alt": "Stiebel Eltron Wärmepumpenregler WPE Erweiterung"
                            },
                            {
                                "id": "0690N000006zlDIQAY",
                                "name": "Übersicht WPM Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpenregler WPM",
                        "price": 1131,
                        "id": "01t0N00000982mHQAQ",
                        "description": "Der Wärmepumpen-Manager WPM ist für die steuer- und regelungstechnischen Abläufe der Wärmepumpe zuständig. Mit dem Gerät können Einstellungen vorgenommen und Informationen zum Betrieb der Wärmepumpe angezeigt werden.\r\n-Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xc8cQAA",
                                "type": "pdf",
                                "size": 915254,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:05:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwTQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8dQAA",
                                "type": "pdf",
                                "size": 1610169,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Meldungsliste WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwUQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8hQAA",
                                "type": "pdf",
                                "size": 1880046,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Inbetriebnahme WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:55.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwYQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8mQAA",
                                "type": "pdf",
                                "size": 4618726,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installation WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:39.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwdQAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8rQAA",
                                "type": "pdf",
                                "size": 5067872,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:08.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwiQAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron Wärmepumpenregler WPM ➤ Wärmepumpen-Manager ✔️ Steuer- und regelungstechnische Abläufe ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0690N00000Cj3aUQAR",
                            "name": "Hauptbild WPM 4 Stiebel Eltron",
                            "alt": "Stiebel Eltron Wärmepumpenregler WPM"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000ANFRDQA5",
                                "name": "SE_WPM_4_2",
                                "alt": "Stiebel Eltron Wärmepumpenregler WPM"
                            },
                            {
                                "id": "0690N000006zlDIQAY",
                                "name": "Übersicht WPM Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WPL 19",
                        "price": 15407,
                        "id": "01t0N0000098r9XQAQ",
                        "description": "Die innenaufgestellte Luft-Wasser-Wärmepumpe WPL 19 besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Trotz minimaler Schallemissionen plus besonders leisem Nachtmodus erreicht die Pumpe eine hohe Vorlauftemperatur von +65°C. Das Gerät erreicht bei einer Aussentemperatur von -7°C eine Wärmeleistung von 9.91 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdttkQAB",
                                "type": "pdf",
                                "size": 252022,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron WPL 19 I Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:39:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsuiQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OdtvUQAR",
                                "type": "pdf",
                                "size": 28899489,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation Stiebel Eltron WPL 19 24 Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:38:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NWstzQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 19",
                        "seoKeyword": "Stiebel Eltron WPL 19",
                        "seoDescription": "Die innenaufgestellte WPL 19 Luft-Wasser-Wärmepumpe besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Alle Vorteile hier!",
                        "defaultImage": {
                            "id": "0690N00000PiwOTQAZ",
                            "name": "Hauptbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                            "alt": "Stiebel Eltron WPL 19 Wärmepumpe (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwOYQAZ",
                                "name": "Offertbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                                "alt": "Stiebel Eltron WPL 19 Wärmepumpe (innen)"
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 24",
                        "price": 16969,
                        "id": "01t0N0000098r9cQAA",
                        "description": "Die innenaufgestellte Luft-Wasser-Wärmepumpe WPL 24 besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Trotz minimaler Schallemissionen plus besonders leisem Nachtmodus erreicht die Pumpe eine hohe Vorlauftemperatur von +65°C. Das Gerät erreicht bei einer Aussentemperatur von -7°C eine Wärmeleistung von 13,45 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdttlQAB",
                                "type": "pdf",
                                "size": 252431,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron WPL 24 I Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:40:28.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsujQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OdtvUQAR",
                                "type": "pdf",
                                "size": 28899489,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation Stiebel Eltron WPL 19 24 Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:38:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NWstzQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 24",
                        "seoKeyword": "Stiebel Eltron WPL 24",
                        "seoDescription": "Die innenaufgestellte Luft-Wasser-Wärmepumpe besticht durch exzellente Energieeffizienz dank moderner Invertertechnik. Alle weiteren Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000PiwOsQAJ",
                            "name": "Hauptbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                            "alt": "Stiebel Eltron WPL 24 Wärmepumpe (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwOtQAJ",
                                "name": "Offertbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                                "alt": "Stiebel Eltron WPL 24 Wärmepumpe (innen)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kombispeicher HS-BM 560L WT1",
                        "price": 5700,
                        "id": "01t0N000009ODegQAG",
                        "description": "Der Hygiene-Systemspeicher HS-BM056-18 WT1 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nVolumen: 560 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 89 (65) x 198 cm (157kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002mugQIAQ",
                                "type": "pdf",
                                "size": 1532093,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-056-18 WT1",
                                "modified": "2024-02-23T15:38:05.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jJblIAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 560L",
                        "seoKeyword": "Stiebel Eltron Kombispeicher",
                        "seoDescription": "Der multivalente Hygiene-Systemspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie. 560l Fassungsvermögen ➤ Hier weitere Infos!",
                        "defaultImage": {
                            "id": "0690N00000PiYtNQAV",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 560L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtOQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 560L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 560L WT2",
                        "price": 6473,
                        "id": "01tVj0000024OnNIAU",
                        "description": "Der Hygiene-Systemspeicher HS-BM056-18 WT2 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nMit zusätzlichem Register für einen zweiten Wärmeerzeuger (1.5 m2).\r\n\r\nVolumen: 560 Liter \r\nMasse mit (ohne) Isolierung: ⌀xH: 89 (65) x 198 cm (162 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n7teIAA",
                                "type": "pdf",
                                "size": 627868,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-056-18 WT2",
                                "modified": "2024-02-23T15:39:56.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jWsEIAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 560L",
                        "seoKeyword": "Stiebel Eltron Kombispeicher",
                        "seoDescription": "Der multivalente Hygiene-Systemspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie. 560l Fassungsvermögen ➤ Hier weitere Infos!",
                        "defaultImage": {
                            "id": "069Vj000002jKnsIAE",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000002jS75IAE",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 820L WT1",
                        "price": 6630,
                        "id": "01t0N000009OEaHQAW",
                        "description": "Der Hygiene-Systemspeicher HS-BM080-18 WT1 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nVolumen: 820 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 101 (77) x 203 cm (181 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n00yIAA",
                                "type": "pdf",
                                "size": 1537486,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-080-18 WT1",
                                "modified": "2024-02-23T15:38:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jP1AIAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 820 L",
                        "seoKeyword": "Stiebel Eltron HS-BM",
                        "seoDescription": "Der Hygiene-Systemspeicher HS-BM von Stiebel Eltron gilt als Herzstück einer Heizanlage. Alles zu seiner innovativen Technologie erfahren Sie hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYthQAF",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 820L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtQQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 820L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 820L WT2",
                        "price": 7530,
                        "id": "01t0N000009jqE4QAI",
                        "description": "Der Hygiene-Systemspeicher  HS-BM080-18 WT2 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nMit zusätzlichem Register für einen zweiten Wärmeerzeuger (2.6 m2).\r\n\r\nVolumen: 820 Liter \r\nMasse mit (ohne) Isolierung: ⌀xH: 1'100 (77) x 203 cm (191 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n7yUIAQ",
                                "type": "pdf",
                                "size": 634799,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-080-18 WT2",
                                "modified": "2024-02-23T15:39:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jWx4IAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "069Vj000002jVjFIAU",
                            "name": "Hauptbild Forstner HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000002jRPbIAM",
                                "name": "Offertbild Forstner HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 960L WT1",
                        "price": 8424,
                        "id": "01t0N00000AJECNQA5",
                        "description": "Der Hygiene-Systemspeicher HS-BM096-18 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nNenninhalt: 960 Liter\r\nDurchmesser ohne (mit) Isolation: 790mm (1030mm)\r\nHöhe mit Isolation: 2250mm\r\n\r\nVolumen: 960 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 103 (79) x 225 cm (198 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n4kVIAQ",
                                "type": "pdf",
                                "size": 1531211,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-096-18 WT1",
                                "modified": "2024-02-23T15:40:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jTr9IAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 960L",
                        "seoKeyword": "Stienel Eltron HS-BM 960",
                        "seoDescription": "Der Kombispeicher HS-BM 960 gilt als Herzstück einer Heizanlage. Alle Infos zur innovativen Technologie dieses multivalenten Schichtenspeichers hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYtLQAV",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 960L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtKQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 960L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Mutltifunktionsspeicher MFS 600 S",
                        "price": 4373,
                        "id": "01t0N0000099NBDQA2",
                        "description": "Der Multifunktionsspeicher MFS 600 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 572 Liter (7.5 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 80 (65) x 193 cm (140 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfFKQAZ",
                                "type": "pdf",
                                "size": 126296,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 600 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:22:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULydQAH",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 600 S",
                        "seoKeyword": "Alpha Innotec MFS 600 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 600 S vereint Puffer & Trinkwarmwasser in einem. Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip.",
                        "defaultImage": {
                            "id": "0690N00000NULnRQAX",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 600 S Multifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8HKQA1",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 600 S Multifunktionsspeicher"
                            }
                        ]
                    },
                    {
                        "title": "Mutltifunktionsspeicher MFS 830 S",
                        "price": 4909,
                        "id": "01t0N000009O5azQAC",
                        "description": "Der Multifunktionsspeicher MFS 830 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 846 Liter (8.7 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 199 cm (200 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfFtQAJ",
                                "type": "pdf",
                                "size": 140839,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 830 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:26:32.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULzCQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 830 S",
                        "seoKeyword": "Alpha Innotec 830 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 830 S von Alpha Innotec vereint Puffer & Trinkwarmwasser. Die Aufbereitung erfolgt im Durchlauferhitzerprinzip.",
                        "defaultImage": {
                            "id": "0690N00000NY8IhQAL",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 830 S Mutltifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8HLQA1",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 830 S Mutltifunktionsspeicher"
                            }
                        ]
                    },
                    {
                        "title": "Mutltifunktionsspeicher MFS 1000 S",
                        "price": 6069,
                        "id": "01t0N000009P7xAQAS",
                        "description": "Der Multifunktionsspeicher MFS 1000 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 928 Liter (10.9 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 214 cm (230 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfC6QAJ",
                                "type": "pdf",
                                "size": 129902,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 1000 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:27:17.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULvLQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 1000 S",
                        "seoKeyword": "MFS 1000 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 1000 S von Alpha Innotec vereint Puffer und Trinkwarmwasser in einem. ➤ Alle Infos dazu bei uns!",
                        "defaultImage": {
                            "id": "0690N00000NULvKQAX",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 1000 S Mutltifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8JaQAL",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 1000 S Mutltifunktionsspeicher"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher TPS 200",
                        "price": 1088,
                        "id": "01t0N000009PKl6QAG",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 200).\r\n\r\nVolumen: 200 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 60 x 130 cm (60 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsRQAV",
                                "type": "pdf",
                                "size": 112737,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 200 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:14:21.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXwQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 200",
                        "seoKeyword": "alpha innotec TPS 200",
                        "seoDescription": "Pufferspeicher zur Optimierung der Laufzeit & Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung.",
                        "defaultImage": {
                            "id": "0690N00000PiYY1QAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 200 - Heizungsmacher AG",
                            "alt": "NIBE TPS 200 Pufferspeicher (200 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYY0QAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 200 - Heizungsmacher AG",
                                "alt": "NIBE TPS 200 Pufferspeicher (200 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 300.11",
                        "price": 1435,
                        "id": "01t0N000009MIH7QAO",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 300.11).\r\n\r\nVolumen: 301 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 66 x 174 cm (93 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000wzrTqAAI",
                                "type": "pdf",
                                "size": 146385,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 300-11 - Heizungsmacher AG",
                                "modified": "2022-02-22T15:31:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYYPQA3",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Pufferspeicher TPS 300",
                        "seoKeyword": "Alpha Innotec TPS 300",
                        "seoDescription": "Der Alpha Innotec TPS 300 Pufferspeicher zur Optimierung der Laufzeit & Vergrösserung des Systemvolumens. ➤ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiYUoQAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 300 - Heizungsmacher AG",
                            "alt": "NIBE TPS 300 Pufferspeicher (300 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYUnQAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 300 - Heizungsmacher AG",
                                "alt": "NIBE TPS 300 Pufferspeicher (300 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 500.1",
                        "price": 1660,
                        "id": "01t0N000009MTuPQAW",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 500).\r\n\r\nVolumen: 485 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 76 x 193 cm (111 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLt5QAF",
                                "type": "pdf",
                                "size": 138757,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 500-1 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:18:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYYZQA3",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 500.1",
                        "seoKeyword": "Alpha Innotec TPS 500",
                        "seoDescription": "TPS 500.1 Pufferspeicher ➤ Optimierung der Laufzeit ✔️ Eingeschweisste Tauchhülsen ✔️  Abnehmbare Wärmedämmung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0690N00000PiYYfQAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                            "alt": "NIBE TPS 500.1 Pufferspeicher (500 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYYeQAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                                "alt": "NIBE TPS 500.1 Pufferspeicher (500 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 500.3",
                        "price": 1918,
                        "id": "01t5p00000BksjIAAR",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und abnehmbarer Wärmedämmung (baugleich Alpha Innotec TPS 500).\r\n\r\nDurchmesser ohne Isolation: 650mm\r\nKippmass ohne Isolation: 1840mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 500.3",
                        "seoKeyword": "Alpha Innotec TPS 500",
                        "seoDescription": "TPS 500.3 Pufferspeicher ➤ Optimierung der Laufzeit ✔️ Eingeschweisste Tauchhülsen ✔️  Abnehmbare Wärmedämmung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p00000j6rA1AAI",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                            "alt": "NIBE TPS 500.3 Pufferspeicher (500 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000j6rA6AAI",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                                "alt": "NIBE TPS 500.3 Pufferspeicher (500 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S2125-8",
                        "price": 10075,
                        "id": "01t0N000009M6nDQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE S2125-8 deckt einen Leistungsbereich bis 5.3 kW ab (bei A-7/W55). Leiser Betrieb von 50 dB(A) Nacht-Schallleistungspegel und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nDas in der NIBE S2125 eingesetzte Kältemittel ist umweltfreundlich und nachhaltig. Bei reinem Verdichterbetrieb werden hohe Ladetemperaturen von bis zu 75 °C erreicht. Ebenso können bei Außentemperaturen von –25 °C noch Temperaturen von bis zu 65 °C erzeugt werden. Dies ermöglicht eine ganzjährig effiziente Betriebsweise mit hohem Brauchwasserkomfort und den Betrieb mit bestehenden Heizkörpern.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014miVLAAY",
                                "type": "pdf",
                                "size": 722379,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S2125 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:39:43.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDrAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-8",
                        "seoKeyword": "Nibe F2120-8",
                        "seoDescription": "Die NIBE F2120-8 deckt einen Leistungsbereich bis 5.5 kW ab (bei A-7/W55). Merkmale sind ihr leiser Betrieb & die geringen Betriebskosten. Hier mehr!",
                        "defaultImage": {
                            "id": "0695p000014yhDvAAI",
                            "name": "Hauptbild NIBE S2125 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yhD3AAI",
                                "name": "Offertenbild NIBE S2125-8 -12 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhDwAAI",
                                "name": "Bild 1 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhDxAAI",
                                "name": "Bild 2 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S2125-12",
                        "price": 11146,
                        "id": "01t0N000009M6nNQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE S2125-12 deckt einen Leistungsbereich bis 8.2 kW ab (bei A-7/W55). Leiser Betrieb von 50 dB(A) Nacht-Schallleistungspegel und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nDas in der NIBE S2125 eingesetzte Kältemittel ist umweltfreundlich und nachhaltig. Bei reinem Verdichterbetrieb werden hohe Ladetemperaturen von bis zu 75 °C erreicht. Ebenso können bei Außentemperaturen von –25 °C noch Temperaturen von bis zu 65 °C erzeugt werden. Dies ermöglicht eine ganzjährig effiziente Betriebsweise mit hohem Brauchwasserkomfort und den Betrieb mit bestehenden Heizkörpern.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014midSAAQ",
                                "type": "pdf",
                                "size": 722379,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S2125 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:42:06.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhGpAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-12",
                        "seoKeyword": "Nibe F2120-12",
                        "seoDescription": "Die Monoblock-Ausseneinheit NIBE F2120-12 deckt einen Leistungsbereich bis 7.6 kW ab. ✅ Leiser Betrieb ✅ minimale Betriebskosten. Hier mehr!",
                        "defaultImage": {
                            "id": "0695p000014yhDsAAI",
                            "name": "Hauptbild NIBE S2125 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yhGqAAI",
                                "name": "Offertenbild NIBE S2125-8 -12 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhGuAAI",
                                "name": "Bild 1 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhGvAAI",
                                "name": "Bild 2 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe F2120-16",
                        "price": 14280,
                        "id": "01t0N000009M6nXQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE F2120-16 deckt einen Leistungsbereich bis 10.9 kW ab (bei A-7/W55). Flüsterleiser Betrieb dank einem Schallleistungspegel von 55 dB(A) und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nMeilenstein in der Effizienz mit einem SCOP grösser als 5.0. Vorlauftemperatur bis 65°C ideal für die Sanierung. Keine Vignettenpflicht da weniger als 3 kg Kältemittel.",
                        "attributes": "<ul><li>Hocheffizienz-Wärmepumpe für den Heizungstausch.</li><li>Erreicht Effizienzwerte die bisher nur mit Erdwärme möglich waren.</li><li>Bisher unerreichter Betriebsbereich mit bis zu 65 °C Ladetemperatur und 63 °C bei –25 °C Aussenlufttemperatur</li><li>Minimaler Geräuschpegel, auch bei voller Leistung.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014miabAAA",
                                "type": "pdf",
                                "size": 433933,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE F2120 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:45:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDyAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-16",
                        "seoKeyword": "Nibe F2120-16",
                        "seoDescription": "Die Monoblock-Ausseneinheit NIBE F2120-16 deckt einen Leistungsbereich bis 10.9 kW ab. Leiser Betrieb dank einem Schallleistungspegel von 55 dB(A).",
                        "defaultImage": {
                            "id": "0690N00000I36q6QAB",
                            "name": "Hauptbild NIBE F2120 -8 -12 -16 -20",
                            "alt": "NIBE F2120-16 Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I36pdQAB",
                                "name": "Offertenbild NIBE F2120 -8 -12 -16 -20",
                                "alt": "NIBE F2120-16 Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0695p000014yhKhAAI",
                                "name": "Ansicht 1 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKiAAI",
                                "name": "Ansicht 2 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKjAAI",
                                "name": "Ansicht 3 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe F2120-20",
                        "price": 15926,
                        "id": "01t0N000009M6ncQAC",
                        "description": "Die Monoblock-Ausseneinheit NIBE F2120-20 deckt einen Leistungsbereich bis 14.1 kW ab (bei A-7/W55). Flüsterleiser Betrieb dank einem Schallleistungspegel von 55 dB(A) und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nMeilenstein in der Effizienz mit einem SCOP grösser als 5.0. Vorlauftemperatur bis 65°C ideal für die Sanierung. Keine Vignettenpflicht da weniger als 3 kg Kältemittel.",
                        "attributes": "<ul><li>Hocheffizienz-Wärmepumpe für den Heizungstausch.</li><li>Erreicht Effizienzwerte die bisher nur mit Erdwärme möglich waren.</li><li>Bisher unerreichter Betriebsbereich mit bis zu 65 °C Ladetemperatur und 63 °C bei –25 °C Aussenlufttemperatur</li><li>Minimaler Geräuschpegel, auch bei voller Leistung.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mihUAAQ",
                                "type": "pdf",
                                "size": 433933,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE F2120 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:45:53.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDtAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-20",
                        "seoKeyword": "Nibe F2120-20",
                        "seoDescription": "Die NIBE F2120-20 deckt einen Leistungsbereich bis 14.1 kW ab. Flüsterleiser Betrieb und minimale Betriebskosten durch Inverter-Technologie.",
                        "defaultImage": {
                            "id": "0690N00000I36pXQAR",
                            "name": "Hauptbild NIBE F2120 -8 -12 -16 -20",
                            "alt": "NIBE F2120-20 Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I36pcQAB",
                                "name": "Offertenbild NIBE F2120 -8 -12 -16 -20",
                                "alt": "NIBE F2120-20 Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0695p000014yhDuAAI",
                                "name": "Ansicht 2 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKkAAI",
                                "name": "Ansicht 1 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKlAAI",
                                "name": "Ansicht 3 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher VVM S320",
                        "price": 6822,
                        "id": "01t0N00000BBwk3QAD",
                        "description": "Kompakte Inneneinheit NIBE VVM S320 mit sehr hohem Vorfertigungsgrad für Standardanwendungen im Einfamilienhaus. Das intelligente, anwenderfreundliche Regelgerät der NIBE VVM S320 ermöglicht eine effiziente Wärme- und Brauchwasserversorgung.\r\nVVM S320 wird in Kombination mit einer Luft-/Wasser-Wärmepumpe NIBE F2120 als zentrales Bindeglied zwischen Wärmeaufnahme, -speicherung und Wärmeabgabe eingesetzt. \r\n\r\nDie kompakte Inneneinheit ist mit einem 180 Liter Brauchwasserspeicher, einem Pufferspeicher sowie mit einem Ausdehnungsgefäß ausgestattet. Zusätzlich sind die hocheffizienz Lade- und Entladepumpen sowie eine elektrische Notheizung bereits integriert. \r\n\r\nMit integriertem WLAN fügt sich die S-Serie wie selbstverständlich in Ihr vernetztes Zuhause ein. Die intelligente Technologie passt das Raumklima automatisch an, wobei Sie via Smartphone oder Tablet die volle Kontrolle behalten. Bei minimalem Energieverbrauch genießen Sie höchsten Wohnkomfort und schonen die Umwelt.",
                        "attributes": "<ul><li>In Kombination mit einer NIBE Luft-/Wasser-Wärmepumpe bildet die NIBE VVM S320 Inneneinheit ein integriertes System für das Einfamilienhaus.</li><li>Anwenderfreundliche Touchscreen-Bedienung, integrierte Drahtlosverbindung sowie die energiesparende Smart-Technologie sorgen für höchsten Komfort.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000JA0v7QAD",
                                "type": "pdf",
                                "size": 1641419,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:16:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37aiQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000JA0vCQAT",
                                "type": "pdf",
                                "size": 514870,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:16:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37anQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000JA0vHQAT",
                                "type": "pdf",
                                "size": 4505784,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:15:55.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37asQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": "NIBE Kompaktspeicher VVM S320 (NEU)",
                        "seoKeyword": "VVM S320 Nibe",
                        "seoDescription": "Kompakte Inneneinheit NIBE VVM S320 mit sehr hohem Vorfertigungsgrad für Standardanwendungen im Einfamilienhaus. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000I37aVQAR",
                            "name": "Hauptbild NIBE VVM S320 - Heizungsmacher AG",
                            "alt": "NIBE VVM S320 Kompaktspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I37aTQAR",
                                "name": "Ansicht 1 NIBE VVM S320 - Heizungsmacher AG",
                                "alt": "NIBE VVM S320 Kompaktspeicher"
                            },
                            {
                                "id": "0690N00000I37aUQAR",
                                "name": "Ansicht 2 NIBE VVM S320 - Heizungsmacher AG",
                                "alt": "Moderne Küche mit Kompaktspeicher VVM S320 von NIBE"
                            },
                            {
                                "id": "0690N00000I37aYQAR",
                                "name": "Offertenbild NIBE VVM S320 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher WWS 405",
                        "price": 2765,
                        "id": "01t0N000009MBvoQAG",
                        "description": "Der Trinkwarmwasserspeicher WWS 405 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert durch hochwirksame Wärmedämmung die Wärmeverluste (Baugleich Alpha InnoTec). \r\n\r\nVolumen: 362 Liter (5.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 163 cm / Isolierung nicht abnehmbar\r\nKippmass: 172cm",
                        "attributes": "<ul><li>Durchmesser (mit Isolation, nicht demontierbar): 75cm</li><li>Höhe: 163cm</li><li>Kippmass: 172cm</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsTQAV",
                                "type": "pdf",
                                "size": 131119,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Trinkwarmwasserspeicher WWS 405-2 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:44:38.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXxQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLv0QAF",
                                "type": "pdf",
                                "size": 4424427,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Trinkwarmwasserspeicher WWS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:42:37.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYaGQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha innotec Trinkwarmwasserspeicher WWS 405.2",
                        "seoKeyword": "Alpha innotec WWS 405.2",
                        "seoDescription": "Der Trinkwarmwasserspeicher WWS 405 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert Wärmeverluste.",
                        "defaultImage": {
                            "id": "0690N00000Piwd5QAB",
                            "name": "Hauptbild NIBE alpha innotec WWS 405 - Heizungsmacher AG",
                            "alt": "NIBE WWS 405 Trinkwarmwasserspeicher (362 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000Piwd6QAB",
                                "name": "Offertbild NIBE alpha innotec WWS 405 - Heizungsmacher AG",
                                "alt": "NIBE WWS 405 Trinkwarmwasserspeicher (362 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WWS 507",
                        "price": 3522,
                        "id": "01t0N000009NrkwQAC",
                        "description": "Der Trinkwarmwasserspeicher WWS 507 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert durch hochwirksame Wärmedämmung die Wärmeverluste (Baugleich Alpha InnoTec).\r\n\r\nDurchmesser (mit Isolation, nicht demontierbar): 75cm\r\nKippmass: 203cm\r\nRegisterfläche: 7.0m2",
                        "attributes": "<ul><li>Durchmesser (mit Isolation, nicht demontierbar): 75cm</li><li>Höhe: 196cm</li><li>Kippmass: 203cm</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsUQAV",
                                "type": "pdf",
                                "size": 169890,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Trinkwarmwasserspeicher WWS 507-2 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:45:47.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXyQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLv0QAF",
                                "type": "pdf",
                                "size": 4424427,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Trinkwarmwasserspeicher WWS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:42:37.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYaGQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Trinkwarmwasserspeicher WWS 507.2",
                        "seoKeyword": "Alpha Innotec wws 507.2",
                        "seoDescription": "Der Trinkwarmwasserspeicher WWS 507 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert Wärmeverluste.",
                        "defaultImage": {
                            "id": "0690N00000PiwkKQAR",
                            "name": "Hauptbild NIBE alpha innotec WWS 507 - Heizungsmacher AG",
                            "alt": "NIBE WWS 507 Trinkwarmwasserspeicher (432 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwkLQAR",
                                "name": "Offertbild NIBE alpha innotec WWS 507 - Heizungsmacher AG",
                                "alt": "NIBE WWS 507 Trinkwarmwasserspeicher (432 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe LICV 82R1/3",
                        "price": 13860,
                        "id": "01t0N000009MCeHQAW",
                        "description": "Mit der neuen LICV 8.2R1/3 (innen) von NIBE (baugleich Novelan und Alpha Innotec LWCV) entscheiden Sie sich für eine vielseitige Modullösung. Sehr leise 38 dB(A) Schallleistung im Nachtbetrieb. 6.4 kW Heizleistung bei einer Aussentemperatur von -7°C.\r\n\r\nDie Geräte sind werkseitig mit folgenden Komponenten ausgestattet:\r\n– Wärmepumpenregler Luxtronik 2.1\r\n– Energieeffizienz-Umwälzpumpe für Heizung\r\n– Umschaltventil Heizung/ Trinkwasser\r\n– Überströmventil für den Heizkreis\r\n– Pufferspeicher 82 Liter für Heizung\r\n– Elektroheizelement zur Unterstützung der Heizung und Brauchwarmwasserladung\r\n– Ausdehnungsgefäss (18l)\r\n– Wärmemengenerfassung\r\n– Schwingungsentkopplung für den Heizkreis",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vLylYAAS",
                                "type": "pdf",
                                "size": 599943,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE LICV - Heizungsmacher AG",
                                "modified": "2022-01-21T09:11:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000ukjnzAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe LICV 82R1/3",
                        "seoKeyword": "Nibe LICV",
                        "seoDescription": "Mit der neuen LICV-Serie von NIBE entscheiden Sie sich für eine vielseitige und sehr leise Modullösung. ➤ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiwBUQAZ",
                            "name": "Offertbild LICV - Heizungsmacher AG",
                            "alt": "NIBE Wärmepumpe LICV 8.2R1/3 (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwBPQAZ",
                                "name": "Bild 1 LICV - Heizungsmacher AG",
                                "alt": "Einzelteile der NIBE Wärmepumpe LICV 8.2R1/3 (innen)"
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe LICV 122R1/3",
                        "price": 15408,
                        "id": "01t0N000009NbvBQAS",
                        "description": "Mit der neuen LICV 12.2R1/3 (innen) von NIBE (baugleich Novelan und Alpha Innotec LWCV) entscheiden Sie sich für eine vielseitige Modullösung. Leise 43dB(A) Schallleistung im Nachtbetrieb. 8.5 kW Heizleistung bei einer Aussentemperatur von -7°C. Die Geräte sind werkseitig mit folgenden Komponenten ausgestattet:\r\n– Wärmepumpenregler Luxtronik 2.1\r\n– Energieeffizienz-Umwälzpumpe für Heizung\r\n– Umschaltventil Heizung/ Trinkwasser\r\n– Überströmventil für den Heizkreis\r\n– Pufferspeicher 82 Liter für Heizung\r\n– Elektroheizelement zur Unterstützung der Heizung und Brauchwarmwasserladung\r\n– Ausdehnungsgefäss (12l)\r\n– Wärmemengenerfassung\r\n– Schwingungsentkopplung für den Heizkreis",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vLylYAAS",
                                "type": "pdf",
                                "size": 599943,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE LICV - Heizungsmacher AG",
                                "modified": "2022-01-21T09:11:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000ukjnzAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe LICV 122R1/3",
                        "seoKeyword": "Nibe LICV 122R1/3",
                        "seoDescription": "Mit der neuen LICV-Serie von NIBE entscheiden Sie sich für eine vielseitige Modullösung. Alle Vorteile erfahren Sie bei uns!",
                        "defaultImage": {
                            "id": "0690N00000PiwAqQAJ",
                            "name": "Offertbild LICV - Heizungsmacher AG",
                            "alt": "NIBE Wärmepumpe LICV 12.2R1/3 (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000Piw86QAB",
                                "name": "Bild 2 LICV Einzelteile - Heizungsmacher AG",
                                "alt": "Einzelteile der NIBE Wärmepumpe LICV 12.2R1/3 (innen)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hydraulikmodul Vitocal 200-A",
                        "price": 2666,
                        "id": "01t0N000009OlebQAC",
                        "description": "Inneneinheit mit Wärmepumpenregelung Vitotronic 200, Hocheffizienz- Umwälzpumpe für den Sekundärkreis, eingebautem Heizwasser- Durchlauferhitzer (Notheizung), 3- Wege- Umschaltventil und Sicherheitsgruppe. Inklusive benötigter Temperatursensoren und Vitoconnect 100, Typ OPTO1.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MekCDQAZ",
                                "type": "pdf",
                                "size": 343061,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Viessmann Vitocal 200-A - Heizungsmacher AG",
                                "modified": "2020-04-22T12:33:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NVQsmQAH",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Hydraulikmodul Vitocal 200-A ➤ Inneneinheit mit Wärmepumpenregelung ✔️ Hocheffizienz- Umwälzpumpe ✔️ Temperatursensoren ✔️",
                        "defaultImage": {
                            "id": "0690N0000072DZrQAM",
                            "name": "Hauptbild Viessmann Hydraulikmodul Vitocal-200",
                            "alt": "Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072DZvQAM",
                                "name": "Schnitt Viessmann Hydraulikmodul Vitocal-200",
                                "alt": "Schnitt Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                            },
                            {
                                "id": "0690N00000NVQrPQAX",
                                "name": "Offertbild Viessmann Hydraulikeinheit Vitocal-200 - Heizungsmacher AG",
                                "alt": "Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                            },
                            {
                                "id": "0690N00000NV8bOQAT",
                                "name": "Vicare-App",
                                "alt": null
                            },
                            {
                                "id": "0690N00000NV8bTQAT",
                                "name": "Vitotronic-Regelung",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hochleistungs-Photovoltaikmodul MAXEON (430Wp)",
                        "price": 386,
                        "id": "01t5p00000CRBDmAAP",
                        "description": "Mit über 30-Jahre Produkterfahrung gehört SunPower zu den Pionierunternehmen der Solarbranche und konnte schon mit Projekten für die NASA oder Solar Impulse seine Qualität unter Beweis stellen. Solarexperten wissen, dass SunPower Module die widerstandsfähigsten auf dem Markt sind. Mit 25 Jahre Produkt-und Leistungs Garantie ist SunPower die Benchmark bei Garantieleistungen und dadurch die Referenz für Zuverlässigkeit und Langlebigkeit. Die Maxeon® Solarzellen sind grundlegend anders und besser. Durch die Kupferbasis bieten die Solarzellen eine beispiellose Zuverlässigkeit und ermöglichen die Entwicklung von Modulen mit einem der weltweit höchsten Wirkungsgrade im Markt.\r\n\r\nModulwirkungsgrad: 22.70 %\r\nLänge: 1812 mm\r\nBreite: 1046 mm",
                        "attributes": "<ul><li>Modulleistung<ul><li>430 Wp</li></ul></li><li>Typ<ul><li><span style=\"font-size: 13px;\">SunPower MAXEON 3</span></li></ul></li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001Apgs7AAB",
                                "type": "pdf",
                                "size": 734161,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Sunpower Maxeon 3 430 Wp - Heizungsmacher AG",
                                "modified": "2022-10-21T10:58:41.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001AxU7JAAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Sunpower",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Hochleistungs-Module"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Sunpower Hochleistungs-Photovoltaikmodul MAXEON (430Wp) ➤ Kupferbasis bietet eine beispiellose Zuverlässigkeit ✔️ SunPower MAXEON 3 ✔️",
                        "defaultImage": {
                            "id": "0695p00000wr3MOAAY",
                            "name": "Hauptbild Sunpower Maxeon 3 430 Wp - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Hochleistungs-Photovoltaikmodul MAXEON (420Wp Schwarz)",
                        "price": 386,
                        "id": "01t5p00000CRpq2AAD",
                        "description": "Mit über 30-Jahre Produkterfahrung gehört SunPower zu den Pionierunternehmen der Solarbranche und konnte schon mit Projekten für die NASA oder Solar Impulse seine Qualität unter Beweis stellen. Solarexperten wissen, dass SunPower Module die widerstandsfähigsten auf dem Markt sind. Mit 25 Jahre Produkt-und Leistungs Garantie ist SunPower die Benchmark bei Garantieleistungen und dadurch die Referenz für Zuverlässigkeit und Langlebigkeit. Die Maxeon® Solarzellen sind grundlegend anders und besser. Durch die Kupferbasis bieten die Solarzellen eine beispiellose Zuverlässigkeit und ermöglichen die Entwicklung von Modulen mit einem der weltweit höchsten Wirkungsgrade im Markt.\r\n\r\nModulwirkungsgrad: 22.20 %\r\nLänge: 1812 mm\r\nBreite: 1046 mm",
                        "attributes": "<ul><li>Modulleistung<ul><li>420 Wp</li></ul></li><li>Typ<ul><li><span style=\"font-size: 13px;\">SunPower MAXEON 3 BLACK</span></li></ul></li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001ApgwXAAR",
                                "type": "pdf",
                                "size": 693284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Sunpower Maxeon 3 420 Wp black - Heizungsmacher AG",
                                "modified": "2022-10-21T10:58:02.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001AxUBeAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Sunpower",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Hochleistungs-Module"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Sunpower Hochleistungs-Photovoltaikmodul MAXEON (420Wp Schwarz) ➤ SunPower MAXEON 3 BLACK ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p00000zfEU6AAM",
                            "name": "Sunpower Maxeon 3 420 Wp Black - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpenregler SMO S40",
                        "price": 1425,
                        "id": "01t5p00000BozQFAAZ",
                        "description": "Der neue SMO S40 ist ein äusserst flexibel einsetzbares Regelgerät für NIBE Wärmepumpen, das sehr viele Anwendungsmöglichkeiten abdeckt. Umfangreiche, übersichtliche Einstellungen und die integrierte Internetverbindung via www.myuplink.com runden das Einsatzspektrum ab.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000XXJ7KAAX",
                                "type": "pdf",
                                "size": 617382,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE SMO S40 - Heizungsmacher AG",
                                "modified": "2020-12-08T08:39:46.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jB4AAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p00000XXJ7LAAX",
                                "type": "pdf",
                                "size": 188054,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE MyUplink Internetverbindung - Heizungsmacher AG",
                                "modified": "2020-12-08T08:39:32.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jB5AAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p00000XXJ7UAAX",
                                "type": "pdf",
                                "size": 3917056,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE SMO S40 - Heizungsmacher AG",
                                "modified": "2020-12-08T08:37:44.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jBEAAZ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "NIBE Wärmepumpenregler SMO S40 ➤ Flexibel einsetzbares Regelgerät ✔️ Viele Anwendungsmöglichkeiten ✔️ NIBE ✔️",
                        "defaultImage": {
                            "id": "0695p00000X9jBAAAZ",
                            "name": "Hauptbild NIBE SMO S40 - Heizungsmacher AG",
                            "alt": "NIBE SMO S40 Wärmepumpenregler"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000X9jB9AAJ",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": "Smartphone mit Applikation zum NIBE SMO S40 Wärmepumpenregler"
                            },
                            {
                                "id": "0695p00000X9jBJAAZ",
                                "name": "Offertenbild NIBE SMO S40 Regler - Heizungsmacher AG",
                                "alt": "NIBE SMO S40 Wärmepumpenregler"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S1155-6",
                        "price": 11310,
                        "id": "01t0N00000BCnC8QAL",
                        "description": "NIBE S1155-6 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 139</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-6",
                        "seoKeyword": "Nibe s1155-6",
                        "seoDescription": "Die NIBE S1155 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Mehr dazu hier!",
                        "defaultImage": {
                            "id": "0690N00000L5U9bQAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-6"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U9gQAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-6"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-6 PC",
                        "price": 13284,
                        "id": "01t0N00000BCnCFQA1",
                        "description": "NIBE S1155-6 PC ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung, Kühlung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 139</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "NIBE Wärmepumpe S1155-6 PC - Erfahren Sie bei Heizungsmacher alle Infos über dieses Produkt und fordern Sie sofort einen Expertenrat an!",
                        "defaultImage": {
                            "id": "0690N00000L5U9qQAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U9vQAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-12",
                        "price": 13675,
                        "id": "01t0N00000BCnByQAL",
                        "description": "NIBE S1155-12 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 3–12 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 167</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-12",
                        "seoKeyword": "Nibe S1155-12",
                        "seoDescription": "NIBE S1155 ist eine intelligente Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird nach Bedarf gewählt.",
                        "defaultImage": {
                            "id": "0690N00000L5U97QAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-12"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U98QAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-12"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-16",
                        "price": 14149,
                        "id": "01t0N00000BCc7aQAD",
                        "description": "NIBE S1155-16 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 4–16 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620 </li><li>Leergewicht (kg)172 </li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-16",
                        "seoKeyword": "Nibe S1155-16",
                        "seoDescription": "NIBE S1155 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Alle Infos bei uns!",
                        "defaultImage": {
                            "id": "0690N00000L4RGIQA3",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-16"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L4RInQAN",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-16"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S1255-6",
                        "price": 14396,
                        "id": "01t0N00000BCnCkQAL",
                        "description": "NIBE S1255-6 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 220</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-6",
                        "seoKeyword": "Nibe S1255-6",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung. Hier alle Infos!",
                        "defaultImage": {
                            "id": "0690N00000L5UDjQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDkQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-12",
                        "price": 15716,
                        "id": "01t0N00000BCnChQAL",
                        "description": "NIBE S1255-12 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 3–12 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 250</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-12",
                        "seoKeyword": "Nibe s1255-12",
                        "seoDescription": "NIBE S1255 ist eine intelligente Erdwärmepumpe mit integrierter Brauchwasserbereitung. Die Wärmepumpe bietet höchstes Einsparpotenzial. ➤ Mehr hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UDnQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-12"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDsQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-12"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-6 PC",
                        "price": 16331,
                        "id": "01t0N00000BCnCrQAL",
                        "description": "NIBE S1255-6 PC ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Kühlung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 220</li><li>Mit integrierter Kühlung</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-6 PC",
                        "seoKeyword": "Nibe s1255 PC",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe mit integrierter Brauchwasserbereitung. ➤ Weitere Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UDdQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6 PC"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDiQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6 PC"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-16",
                        "price": 17109,
                        "id": "01t0N00000BCnCNQA1",
                        "description": "NIBE S1255-16 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 4–16 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 255 </li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-16",
                        "seoKeyword": "Nibe S2155-16",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung. Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UCLQA3",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-16"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UCMQA3",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-16"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher HSBC 300 L",
                        "price": 5655,
                        "id": "01t0N000009MR6PQAW",
                        "description": "Beim Integralspeicher HSBC 300 L cool stehen nicht zwei Einzelspeicher nebeneinander, sondern Trinkwarmwasser- und Pufferspeicher sind übereinander in einem Gerät angeordnet. Der Platzbedarf wird so quasi halbiert und eine Aufstellung auf engstem Raum wird möglich – für mehr Nutzfläche.\r\n\r\nPräzise abgestimmt auf die Nutzung mit einer Wärmepumpe deckt der HSBC L cool alles ab, was man in einem Einfamilienhaus in Bezug auf Wärme und Trinkwarmwasser benötigt.\r\n\r\nNenninhalt Trinkwarmwasser: 270 Liter\r\nNenninhalt Pufferspeicher: 100 Liter",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdtNMQAZ",
                                "type": "pdf",
                                "size": 129463,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron HSBC 300 L Kompaktspeicher - Heizungsmacher AG",
                                "modified": "2022-05-17T09:31:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsRSQA1",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kompaktspeicher HSBC 300 L cool",
                        "seoKeyword": "Stiebel Eltron HSBC 300 L cool",
                        "seoDescription": "Beim Integralspeicher HSBC 300 L cool sind Trinkwarmwasser- und Pufferspeicher platzsparend übereinander in einem Gerät angeordnet. Hier alle Infos!",
                        "defaultImage": {
                            "id": "0690N00000NWsTNQA1",
                            "name": "Offertenbild Stiebel Eltron HSBC 300 L - Heizungsmacher AG",
                            "alt": "Stiebel Eltron HSBC 300 L Kompaktspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014zaqOAAQ",
                                "name": "Hauptbild Stiebel Eltron HSBC 300 L - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "WPL 09 ICS classic",
                        "price": 12579,
                        "id": "01t5p00000CQ9x7AAD",
                        "description": "Diese Luft-Wasser-Wärmepumpe ist für die kompakte Innenaufstellung konzipiert. Hohe Flexibilität bei Aufstellung und Installation Invertertechnologie für hohe Effizienz und niedrige Energiekosten. \r\n- Integrierter Wärmepumpenmanager\r\n- Vorlauftemperaturen von bis zu 60 °C\r\n- Max. Heizleistung bei A-7/W35: 4,23 kW\r\n- Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aJKOAA2",
                                "type": "pdf",
                                "size": 142945,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt WPL 09 ICS classic - Heizungsmacher AG",
                                "modified": "2022-06-07T06:06:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000013VmHHAA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron WPL 09 ICS classic ➤ Luft/Wasser-Wärmepumpe ✔️ Kompakte Innenaufstellung ✔️ Hohe Effizienz ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013VmEAAA0",
                            "name": "Hauptbild WPL 09 17 ICS classic - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000013VmHIAA0",
                                "name": "Offertenbild Stiebel Eltron WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000013VmJ8AAK",
                                "name": "Bild 2 WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WPL 17 ICS classic",
                        "price": 14718,
                        "id": "01t0N00000A3Cd0QAF",
                        "description": "Diese Luft-Wasser-Wärmepumpe ist für die kompakte Innenaufstellung konzipiert. Hohe Flexibilität bei Aufstellung und Installation Invertertechnologie für hohe Effizienz und niedrige Energiekosten.\r\n- Integrierter Wärmepumpenmanager\r\n- Vorlauftemperaturen von bis zu 60 °C\r\n- Max. Heizleistung bei A-7/W35: 8.02 kW\r\n- Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aJKjAAM",
                                "type": "pdf",
                                "size": 142325,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt WPL 17 ICS classic - Heizungsmacher AG",
                                "modified": "2022-06-07T06:08:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000013VmHhAAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron WPL 17 ICS classic ➤ Luft/Wasser-Wärmepumpe ✔️ Hohe Flexibilität ✔️ Invertertechnologie für hohe Effizienz ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmHbAAK",
                            "name": "Hauptbild WPL 09 17 ICS classic - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000013VmHgAAK",
                                "name": "Offertenbild Stiebel Eltron WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000013VmJ9AAK",
                                "name": "Bild 2 WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Batteriespeicher RESU10",
                        "price": 5320,
                        "id": "01t0N00000BCkSzQAL",
                        "description": "Die kompakte und leichtgewichtige Natur des RESU ist erstklassig. Das System ist besonders einfach zu installieren. Hochwertiger Niedervolt-Akku vom Markenhersteller LG. \r\n\r\nNutzbare Kapazität: 8.8 kWh bei 90% Entladetiefe.\r\nMax. DC-Ausgangsleistung: 5.0 kW",
                        "attributes": "<ul><li>Nutzbare Kapazität: 8.8 kWh bei 90% Entladetiefe.</li><li>DC-Ausgangsleistung: 5.0 kW</li><li>DC-Nennspannung: 51.80 V</li><li>Batterietechnologie: Lithium-Nickel-Mangan-Kobaltoxid</li><li>Länge: 452 mm</li><li>Breite: 227 mm</li><li>Höhe: 483 mm</li><li>Gewicht: 75 kg</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014kOzQAAU",
                                "type": "pdf",
                                "size": 281238,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt LG Chem RESU 65 10 12 - Heizungsmacher AG",
                                "modified": "2022-07-04T22:01:50.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wRYKAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "LG Chem",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "LG Chem Batteriespeicher RESU10 ➤ Kompakt und leichtgewichtig ✔️ Einfache Installation ✔️ Hochwertiger Niedervolt-Akku ✔️",
                        "defaultImage": {
                            "id": "0695p00000hP44YAAS",
                            "name": "RESU 10",
                            "alt": "LG Chem Batteriespeicher RESU10"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5ONEQA3",
                                "name": "Bild 1 LG Chem Akku RESU 6.5-10 - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Batteriespeicher RESU12",
                        "price": 6840,
                        "id": "01t5p00000CRBInAAP",
                        "description": "Die kompakte und leichtgewichtige Natur des RESU ist erstklassig. Das System ist besonders einfach zu installieren. Hochwertiger Niedervolt-Akku vom Markenhersteller LG. \r\n\r\nNutzbare Kapazität: 11.7 kWh bei 90% Entladetiefe.\r\nMax. DC-Ausgangsleistung: 5.0 kW",
                        "attributes": "<ul><li>Nutzbare Kapazität: 11.7 kWh bei 90% Entladetiefe.</li><li>DC-Ausgangsleistung: 5.0 kW</li><li>DC-Nennspannung: 51.80 V</li><li>Batterietechnologie: Lithium-Nickel-Mangan-Kobaltoxid</li><li>Länge: 452 mm</li><li>Breite: 227 mm</li><li>Höhe: 625 mm</li><li>Gewicht: 75 kg</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014kOzQAAU",
                                "type": "pdf",
                                "size": 281238,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt LG Chem RESU 65 10 12 - Heizungsmacher AG",
                                "modified": "2022-07-04T22:01:50.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wRYKAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "LG Chem",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "LG Chem Batteriespeicher RESU12 ➤ Kompakt & leichtgewichtig ✔️ Einfache Installation ✔️ Hochwertiger Niedervolt-Akku ✔️",
                        "defaultImage": {
                            "id": "0695p00000wr8GfAAI",
                            "name": "LG RESU 12",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5ONEQA3",
                                "name": "Bild 1 LG Chem Akku RESU 6.5-10 - Solarmacher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE5K-RWS 48V",
                        "price": 2710.1,
                        "id": "01t0N00000BBqAFQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 5 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE5K-RWS 48V\r\n ➤ Einfache Installation ✔️ Dreiphasige Installationen mit Batteriespeicher 5 kVA ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LG4QAN",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LG9QAN",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE7K-RWS 48V",
                        "price": 2946.3,
                        "id": "01t0N00000BBqAKQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 7 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE7K-RWS 48V\r\n ➤ Ein einziger Wechselrichter ✔️ Dreiphasige Installationen ✔️",
                        "defaultImage": {
                            "id": "0695p00001Q1a7MAAR",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGYQA3",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE8K-RWS 48V",
                        "price": 3029.2,
                        "id": "01t0N00000BBqAUQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 8 kVA\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE8K-RWS 48V ➤ Dreiphasige Installationen mit Batteriespeicher 8 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LGZQA3",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGAQA3",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE10K-RWS 48V",
                        "price": 3206.9,
                        "id": "01t0N00000BBqAZQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 10 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE10K-RWS 48V ➤ Dreiphasige Installationen mit Batteriespeicher 10 kVA ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LGnQAN",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGaQAN",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Kombispeicher SBS 601 W",
                        "price": 3770,
                        "id": "01t0N0000098rATQAY",
                        "description": "SBS 601 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung. \r\n\r\nNenninhalt: 613 Liter\r\nDurchmesser ohne Isolation: 750mm\r\nDurchmesser mit Isolation: 970mm\r\nHöhe mit Isolation: 1775mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMKJQA3",
                                "type": "pdf",
                                "size": 299445,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 601 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:23:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYwvQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 601 W",
                        "seoKeyword": "Stiebel Eltron SBS 601 W",
                        "seoDescription": "SBS 601 Heizungs-Pufferspeicher ✅ integrierte Trinkwarmwasserbereitung ✅ hygienische Warmwasseraufbereitung. Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiZ2UQAV",
                            "name": "Hauptbild SBS 600 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 601 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072G5jQAE",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 601 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYufQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 601 W"
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher SBS 801 W",
                        "price": 3959,
                        "id": "01t0N0000098rAdQAI",
                        "description": "SBS 801 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung.\r\n\r\nNenninhalt: 759 Liter\r\nDurchmesser ohne Isolation: 790mm\r\nDurchmesser mit Isolation: 1010mm\r\nHöhe mit Isolation: 1940mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMHFQA3",
                                "type": "pdf",
                                "size": 299276,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 801 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:13:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYviQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 801 W",
                        "seoKeyword": "SBS 801 W",
                        "seoDescription": "Stiebel Eltron SBS 801 Heizungs-Pufferspeicher ✅ Trinkwarmwasserbereitung im Durchflussbetrieb ✅ hygienische Warmwasseraufbereitung. Mehr dazu hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYvoQAF",
                            "name": "Hauptild SBS 800 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 801 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072GQIQA2",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 801 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYvnQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 801 W"
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher SBS 1001 W",
                        "price": 4676,
                        "id": "01t0N0000098rAnQAI",
                        "description": "SBS 1001 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung.\r\n\r\nNenninhalt: 941 Liter\r\nDurchmesser ohne Isolation: 790mm\r\nDurchmesser mit Isolation: 1010mm\r\nHöhe mit Isolation: 2350mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMGhQAN",
                                "type": "pdf",
                                "size": 299337,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 1001 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:15:54.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYtjQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 1001 W",
                        "seoKeyword": "Kombispeicher SBS 1001 W",
                        "seoDescription": "SBS 1001 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Garantierte hygienische Warmwasseraufbereitung.",
                        "defaultImage": {
                            "id": "0690N00000PiYugQAF",
                            "name": "Hauptbild SBS 1000 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 1001 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072H69QAE",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 1001 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYtiQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 1001 W"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Meyer Burger Black 390 Wp",
                        "price": 252,
                        "id": "01t5p00000D3R3VAAV",
                        "description": "Das neue Meyer Burger Heterojunction Modul bietet bis zu 20 Prozent mehr Energieertrag. Produktion der Solarzellen und -module nach höchsten Standards ausschließlich in Deutschland mit Schweizer Technologie.\r\n\r\nDie Meyer Burger HJT-Zellen nehmen deutlich mehr Sonnenenergie als herkömmliche Zellen auf und der umgewandelte elektrische Strom fliesst mit weniger Widerstand durch das Modul. Auch bei schwachem Sonnenlicht, beispielsweise wenn es bewölkt ist.\r\n\r\nZusätzliche Effizienzgewinne bringt die Verbindung der Zellen durch unsere patentierte SmartWire-Technologie. Denn die hauchdünnen Verbindungsdrähte verringern die Abschattung auf den Solarzellen um bis zu 30 Prozent. Die Module nehmen so mehr Energie auf und produzieren länger Strom. Für spürbar mehr Leistung pro Fläche auf dem Dach. Und nicht nur das.\r\n\r\nSWCT verbessert auch die Zellstabilität und macht die Module weniger anfällig für Mikrorisse, einem der häufigsten Gründe für Energieverluste bei Solarmodulen.\r\n\r\nModulwirkungsgrad: 21.5 %\r\nLänge: 1767 mm\r\nBreite: 1041 mm\r\nHöhe: 35 mm\r\nGewicht: 19.7 kg",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0lJvAAJ",
                                "type": "pdf",
                                "size": 630080,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Meyer Burger Black - Heizungsmacher AG",
                                "modified": "2023-06-09T11:28:37.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001I3hTOAAZ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Meyer Burger",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Made-in-Germany"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Meyer Burger Black 390 Wp Photovoltaikmodul - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001I3hTiAAJ",
                            "name": "Hauptbild Meyer Burger Photovoltaikmodul Black - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher PU 200",
                        "price": 752,
                        "id": "01t5p00000CMHlMAAX",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 190 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 60 x 121 cm (43 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 200 - Alle Produktinformationen bei Heizungsmacher ansehen, Vergleiche ziehen und persönliche Beratung erhalten!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yxTaAAI",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 300",
                        "price": 912,
                        "id": "01t5p00000CLuqtAAD",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 282 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 65 x 147 cm (61 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 300 - Bei Heizungsmacher finden Sie nicht nur spezifische Produktdetails, sondern erhalten auch eine umfassende Beratung!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXRaAAM",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 400",
                        "price": 1195,
                        "id": "01t5p00000D1MfQAAV",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 377 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 140 cm (70 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 400 - Sichern Sie sich jetzt bei Heizungsmacher wertvolle Infos über die Produktdetails und fordern Sie eine Beratung an!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Fe5E1AAJ",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 500",
                        "price": 1224,
                        "id": "01t5p00000CwnWpAAJ",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 479 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 174 cm (80 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 500 - Alle Produktdetails im detaillierten Überblick bei Heizungsmacher und direkte Expertenberatung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001AJL5cAAH",
                                "name": "hpa Puffer-neu",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PSF 1000",
                        "price": 2998,
                        "id": "01tVj000006Qp8IIAS",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, Isolierung aus Hightech Faservlies. Skaimantel mit Reissverschluss, inklusive Rosetten und Abdeckhaube. Silber.\r\n\r\nVolumen: 887 Liter\r\nMasse ohne Isolierung: ⌀xH: 79 x 204 cm (111kg) (ohne Isolierung)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B2QcnIAF",
                                "type": "pdf",
                                "size": 339263,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PSF - Heizungsmacher AG",
                                "modified": "2024-10-26T13:01:01.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj00000AsdD7IAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 500 - Alle Produktdetails im detaillierten Überblick bei Heizungsmacher und direkte Expertenberatung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj00000AscowIAB",
                                "name": "PSF1000 Offertbild",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 300",
                        "price": 1955,
                        "id": "01t5p00000CMHmTAAX",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 304 Liter (3.2 m² Registeroberfläche)\r\nMasse mit/ohne Isolierung: ⌀xH: 65 x 157 cm (141 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 300 - Hier bei Heizungsmacher genau über das Produkt informieren und eine umfassende Beratung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yy3eAAA",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 400",
                        "price": 2318,
                        "id": "01t5p00000CLutmAAD",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 408 Liter (4.3 m² Registeroberfläche)\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 150 cm (179 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 400 - Bei Heizungsmacher alle Produktdetails erhalten und eine kompetente und persönliche Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXbyAAE",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 500",
                        "price": 2977,
                        "id": "01t5p00000D1R1IAAV",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 498 Liter (5.4 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 180 cm (217 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p00001DLYPqAAP",
                            "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXZ9AAM",
                                "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 600",
                        "price": 3069,
                        "id": "01tVj000005aQ9BIAU",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 559 Liter (5.4 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 200 cm (228kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000009AEWDIA4",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 800",
                        "price": 4898,
                        "id": "01tVj000003BxB8IAK",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. Mehrschicht-Isolierung aus 80 mm Hartschaum + 20 mm Faservlies. PS-Mantel und Hakenleiste, inklusive Rosetten und Abdeckhaube. Brandschutzklasse B2. Silber\r\n\r\nVolumen: 830 Liter (6.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 199 cm (291 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000004q6LeIAI",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG (2)",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 1000",
                        "price": 5680,
                        "id": "01t5p00000CxBO2AAN",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. Mehrschicht-Isolierung aus 80 mm Hartschaum + 20 mm Faservlies. PS-Mantel und Hakenleiste, inklusive Rosetten und Abdeckhaube. Brandschutzklasse B2. Silber\r\n\r\nVolumen: 925 Liter (6.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 219 cm (308 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 1000 - Bei Heizungsmacher tiefgehende Produktdetails einsehen und sofort einen Expertenrat anfordern!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001ALDEBAA5",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG (1)",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WSW196i.2-6",
                        "price": 11039,
                        "id": "01t5p00000CMH8DAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 2-5.85 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-6 - Hier bei Heizungsmacher detailierte Produktdetails finden und bei Bedarf eine gezielte Beratung erhalten!",
                        "defaultImage": {
                            "id": "0695p000014yujQAAQ",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-8",
                        "price": 11230,
                        "id": "01t5p00000CMH8yAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 2-7.61 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-8 - Bei Heizungsmacher finden Sie genaue Produktdetails und können sich ausführlich informieren und beraten lassen!",
                        "defaultImage": {
                            "id": "0695p000014yujRAAQ",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-12",
                        "price": 12467,
                        "id": "01t5p00000CMH9DAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 3-12 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-12 - Erfahren Sie hier bei Heizungsmacher mehr über das Produkt und erhalten Sie um eine professionelle Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yujzAAA",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-16",
                        "price": 12657,
                        "id": "01t5p00000CMH9VAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 4-15 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-16 - Hier bei Heizungsmacher detaillierte Produktdetails ansehen und professionelle Beratung sichern!",
                        "defaultImage": {
                            "id": "0695p000014yulvAAA",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WSW196i.2-6 TP50",
                        "price": 12134,
                        "id": "01t5p00000CMH96AAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal. \r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 61x60x178cm\r\nHeizleistung: 2-5.85 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-6 TP50 - Bei Heizungsmacher mehr über die umfassenden Produktdetails erfahren und individuelle Beratung verlangen!",
                        "defaultImage": {
                            "id": "0695p000014yuitAAA",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yukOAAQ",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-8 TP50",
                        "price": 12322,
                        "id": "01t5p00000CMH9fAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 2-7.61 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-8 TP50 - Schauen Sie sich bei Heizungsmacher die Produktdetails an und erhalten Sie eine professionelle Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yul2AAA",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yul3AAA",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-12 TP50",
                        "price": 13451,
                        "id": "01t5p00000CMH9pAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 3-12 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-12 TP50 - Bei Heizungsmacher detaillierte Produktdetails einsehen und qualifizierte Beratung schnell erhalten!",
                        "defaultImage": {
                            "id": "0695p000014zvUsAAI",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-16 TP50",
                        "price": 13639,
                        "id": "01t5p00000CMH9vAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 4-15 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-16 TP50 - Entdecken Sie bei Heizungsmacher präzise Produktdetails und sichern Sie fundierte Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yulHAAQ",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yulMAAQ",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe Vitocal 250-A06",
                        "price": 9725,
                        "id": "01t5p00000DDEbZAAX",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 0.02 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C.\r\n\r\nTyp: \t250-A AWO-M-E-AC 251.A06\r\nLeistung bei A-7/W35: 5.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Wärmepumpe Vitocal 250-A8 - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001T830nAAB",
                            "name": "Hauptbild Viessmann Vitocal 250-A08 - Heizungsmacher AG (1)",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T820XAAR",
                                "name": "Offertenbild Viessmann 250-A klein - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A08",
                        "price": 10438,
                        "id": "01t5p00000Cy8UoAAJ",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 0.02 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C.\r\n\r\nTyp: \t250-A AWO-M-E-AC 251.A08\r\nLeistung bei A-7/W35: 6.5 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Wärmepumpe Vitocal 250-A8 - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001I3GGvAAN",
                            "name": "Hauptbild Viessmann Vitocal 250-A08 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001MDACyAAP",
                                "name": "Offertenbild Viessmann 250-A klein - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A10",
                        "price": 12271,
                        "id": "01t5p00000CPr4LAAT",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 3 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C – und das bei einer Außentemperatur von bis zu –15 °C. \r\n\r\nTyp: AWO-E-AC-AF 251.A10\r\nLeistung bei A-7/W35: 9.7 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Viessmann Vitocal 250-A10 ➤ Viessmann Wärmepumpentechnik ✔️ Modernes Design ✔️ „Grünes“ Kältemittel R290 (Propan) ✔️",
                        "defaultImage": {
                            "id": "0695p00001I3G8XAAV",
                            "name": "Hauptbild Viessmann Vitocal 250-A - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001I3G8hAAF",
                                "name": "Offertbild Vitocal 250-A 2-fach",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A13",
                        "price": 12944,
                        "id": "01t5p00000CPr4IAAT",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 3 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C – und das bei einer Außentemperatur von bis zu –15 °C. \r\n\r\nTyp: AWO-E-AC-AF 251.A13\r\nLeistung bei A-7/W35: 11.1 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Viessmann Vitocal 250-A13 ➤ Viessmann Wärmepumpentechnik ✔️ Modernes Design ✔️ „Grünes“ Kältemittel R290 (Propan ✔️",
                        "defaultImage": {
                            "id": "0695p00000zgeHKAAY",
                            "name": "Hauptbild Viessmann Vitocal 250-A - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000ppXzNAAU",
                                "name": "Offertbild Vitocal 250-A 2-fach",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Viessmann Vitocal 250-A Hydraulikeinheit",
                        "price": 2909,
                        "id": "01t5p00000CPr4XAAT",
                        "description": "Mit neuer Hydro AutoControl Hydraulik zur Bereitstellung der Notwendigen\r\nAbtauenergie und Sicherstellung der erforderlichen Mindest-Umlaufmenge im Wärmepumpen Kreis\r\n- Mit eingebauter Hocheffizienz-Umwälzpumpe für den Heizkreis\r\n- Abtaupuffer 16 l\r\n- Bypassfunktion\r\n- Heizwasser Durchlauferhitzer\r\n- 4-3-Wegeventil\r\n- Volumenstromsensor\r\n\r\nBedienung über 7-Zoll-Farb-Touchdisplay mit Klartext- und Grafikanzeige,\r\nInbetriebnahme-Assistenten, Anzeigen für Energieverbräuche sowie\r\nalternativ Bedienung über mobiles Endgerät, Internetfähig durch integrierte\r\nWLAN-Schnittstelle für Bedienung und Service über Viessmann Apps.\r\n\r\nFür 1 Heizkreis.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0K2EAAV",
                                "type": "pdf",
                                "size": 233279,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Viessmann Vitocal 250A - Heizungsmacher AG",
                                "modified": "2023-06-06T05:52:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GN8AAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T822xAAB",
                            "name": "Hauptbild Viessmann Vitocal 250-A Hydraulikeinheit - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000ppYNyAAM",
                                "name": "Offertbild Vitocal 250-A Hydrailikeinheit 1HK",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T820YAAR",
                                "name": "Hauptbild Viessmann Vitocal 250-A Inneneinheit - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher Vitocal 252-A",
                        "price": 4802,
                        "id": "01t5p00000CPr4iAAD",
                        "description": "Die bodenstehende Kompakt-Einheit ist die intelligente Kombination aus effizienter Wärmepumpentechnik und hohem Warmwasserkomfort – dank integriertem 190-Liter-Warmwasserspeicher in einem Gehäuse. \r\n\r\nMit neuer Hydro AutoControl Hydraulik zur Bereitstellung der Notwendigen\r\nAbtauenergie und Sicherstellung der erforderlichen Mindest-Umlaufmenge im Wärmepumpen Kreis\r\n- Mit eingebauter Hocheffizienz-Umwälzpumpe für den Heizkreis\r\n- Abtaupuffer 16 l\r\n- Bypassfunktion\r\n- Heizwasser Durchlauferhitzer\r\n- 4-3-Wegeventil\r\n- Volumenstromsensor\r\n\r\nBedienung über 7-Zoll-Farb-Touchdisplay mit Klartext- und Grafikanzeige,\r\nInbetriebnahme-Assistenten, Anzeigen für Energieverbräuche sowie\r\nalternativ Bedienung über mobiles Endgerät, Internetfähig durch integrierte\r\nWLAN-Schnittstelle für Bedienung und Service über Viessmann Apps.\r\n\r\nFür 1 Heizkreis.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0JwQAAV",
                                "type": "pdf",
                                "size": 260604,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Viessmann Vitocal 252A - Heizungsmacher AG",
                                "modified": "2023-06-06T05:41:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXn1AAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Kompaktspeicher Vitocal 252-A - Bei Heizungsmacher alle Produktdetails kennenlernen und zielgerichtete Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00000ppYXQAA2",
                            "name": "Offertbild Vitocal 250-A Kompakteinheit 1HK",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014ygjwAAA",
                                "name": "viessmann-waermepumpe-252A-2C_5",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yh1zAAA",
                                "name": "Vitocal 252-A_Inneneinheit_Schnitt",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "SMA Hybrid Sunny Tripower 5.0 Smart Energy",
                        "price": 2212,
                        "id": "01t5p00000CRAkyAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 5.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 5 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8AAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 6.0 Smart Energy",
                        "price": 2548,
                        "id": "01t5p00000CRAkUAAX",
                        "description": "Der SMA Hybrid-Wechselrichter STP 6.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 6 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8FAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 8.0 Smart Energy",
                        "price": 2980,
                        "id": "01t5p00000CRAkjAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 8.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 8 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SMA Solar Technology AG",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8BAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 10.0 Smart Energy",
                        "price": 3220,
                        "id": "01t5p00000CRAkkAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 10.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 10 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8PAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Home Hub Wechselrichter SE5K-RWB 48V",
                        "price": 2880,
                        "id": "01t5p00000CpanfAAB",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 5 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE5K-RWB 48V ➤ Kein MPP Tracker ✔️ Zwei Eingänge für SolarEdge Modul Optimierer ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmEDAA0",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Hub Wechselrichter SE8K-RWB 48V",
                        "price": 3192,
                        "id": "01t5p00000CpaoFAAR",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 8 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE8K-RWB 48V ➤ Dreiphasige Installationen mit Batteriespeicher 8 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmEIAA0",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Hub Wechselrichter SE10K-RWB 48V",
                        "price": 3360,
                        "id": "01t5p00000CpaoiAAB",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 10 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE10K-RWB 48V ➤ Dreiphasige Installationen mit Batteriespeicher 10 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmE8AAK",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Home Batterie 4.6 kWh",
                        "price": 3081,
                        "id": "01t5p00000CpapUAAR",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 4.6 kWh\r\nAnzahl Module: 1 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 4.6 kWh ➤ Optimierte Speicherlösung ✔️ dreiphasig mit optimalem Ersatzstrom✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013Vk5TAAS",
                            "name": "Hauptbild SolarEdge Home Battery 4-6kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 9.2 kWh",
                        "price": 5842,
                        "id": "01t5p00000CpapnAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 9.2 kWh\r\nAnzahl Module: 2 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 9.2 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit Ersatzstrom ✔️ Skalierbar von 4.6 – 23kWh ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk5EAAS",
                            "name": "Hauptbild SolarEdge Home Battery 9-2kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 13.8 kWh",
                        "price": 8603,
                        "id": "01t5p00000CpaplAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 13.8 kWh\r\nAnzahl Module: 3 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 13.8 kWh ➤ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️ Hier entlang!",
                        "defaultImage": {
                            "id": "0695p000013Vk3tAAC",
                            "name": "Hauptbild SolarEdge Home Battery 13-8kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 18.4 kWh",
                        "price": 11535,
                        "id": "01t5p00000CpapWAAR",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 18.4 kWh\r\nAnzahl Module: 4 Stk. (2+2)\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 18.4 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk3sAAC",
                            "name": "Hauptbild SolarEdge Home Battery 18-4kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 23.0 kWh",
                        "price": 14265,
                        "id": "01t5p00000CpaoaAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 23.0 kWh\r\nAnzahl Module: 5 Stk. (3+2)\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 23.0 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk3rAAC",
                            "name": "Hauptbild SolarEdge Home Battery 23kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Home Backup Interface (Ersatzstrom)",
                        "price": 1314,
                        "id": "01t5p00000CpaqUAAR",
                        "description": "Mit dem Home Backup-Interface bietet SolarEdge eine Ersatzstromlösung mit automatischer Umschaltung sollte das Stromnetz einmal ausfallen.\r\n- automatische Umschaltung < 10 Sekunden\r\n- Ersatzstrom für das ganze Haus (WP nicht empfohlen)\r\n- Schwarzstartfähig\r\n- Nur in Kombination mit Batterien möglich\r\nInkl. Verkabelung 5x6/10.0mm2 3LNPE bis 6m.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aGtJAAU",
                                "type": "pdf",
                                "size": 216111,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Backup-Interface - Heizungsmacher AG",
                                "modified": "2022-06-06T08:41:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk6vAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Ersatzstrom"
                            }
                        ],
                        "seoTitle": "SolarEdge Home Backup Interface",
                        "seoKeyword": "SolarEdge Home Backup Interface",
                        "seoDescription": "SolarEdge Home Backup Interface ➤ Automatische Umschaltung ✔️ Schwarzstartfähig ✔️ Dreiphasige Ersatzstromlösung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013Vk7ZAAS",
                            "name": "Hauptbild SolarEdge Home Backup Interface - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "WLW186i-5 AR E",
                        "price": 8169,
                        "id": "01t5p00000D3tn2AAB",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 110 x 54 x 80 cm\r\nLeistung bei A-7/W35: 5.4 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBimAAAT",
                            "name": "Offertenbild Buderus WSW 186i AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-7 AR E",
                        "price": 8693,
                        "id": "01t5p00000D3tmiAAB",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 110 x 54 x 80 cm\r\nLeistung bei A-7/W35: 6.7 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBilvAAD",
                            "name": "Offertenbild Buderus WSW 186i AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-10 AR E",
                        "price": 10813,
                        "id": "01t5p00000DDEO5AAP",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 135 x 54 x 105 cm\r\nLeistung bei A-7/W35: 9.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T80VvAAJ",
                            "name": "Offertenbild Buderus WSW 186i 10-12 AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-12 AR E",
                        "price": 11523,
                        "id": "01t5p00000DDET2AAP",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 135 x 54 x 105 cm\r\nLeistung bei A-7/W35: 11.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T80xwAAB",
                            "name": "Offertenbild Buderus WSW 186i 10-12 AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Inneneinheit wandhängend",
                        "price": 2519,
                        "id": "01t5p00000D3uQXAAZ",
                        "description": "Bei einer Sanierung eignet sich eine wandhängende Inneneinheit. Denn sie kann mit einem nebenstehenden Warmwasser- und Pufferspeicher flexibel kombiniert werden.\r\n- Eingebaute Hocheffizienz-Umwälzpumpe\r\n- Notbetrieb Durchlauferhitzer\r\n- 3-Weg-Umschaltventil für Heizung und Brauchwarmwasser\r\n- Touch-Systembedieneinheit \r\nLogamatic BC400 mit Funkmodul MX300\r\n\r\nMasse (L x B x T): 720 x 400 x 297 mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBkrXAAT",
                            "name": "Buderus Inneneinheit Wand offen",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001OBks0AAD",
                                "name": "Offertenbild Buderus WSW 186i AR Inneneinheit Wand - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Inneneinheit mit 70 L Puffer",
                        "price": 4037,
                        "id": "01t5p00000D3uQYAAZ",
                        "description": "Die kompakte Variante mit integriertem 70 Liter Pufferspeicher benötigt wenig Platz im Haus und eignet sich mit einem geeigneten Heizverteilsystem als Vollintegrierte Lösung. In Kombination mit einem externen Warmwasserspeicher bietet die Inneneinheit höchsten Warmwasserkomfort. \r\n\r\n- Eingebaute Hocheffizienz-Umwälzpumpe\r\n- Notbetrieb Durchlauferhitzer\r\n- Umschalt-Wegeventil für Heizung und Brauchwarmwasser\r\n- Touch-Systembedieneinheit \r\nLogamatic BC400 mit Funkmodul MX300\r\n\r\nMasse (L x B x T): 1275 x 600 x 600 mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBkrWAAT",
                            "name": "Buderus Inneneinheit TP70 offen",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001OBkrbAAD",
                                "name": "Offertenbild Buderus WSW 186i AR Inneneinheit TP70 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            }
        ]
    },
    "configuration": {
        "productCategories": [
            {
                "nameFr": "HeizungenFr",
                "nameEn": "HeizungenEn",
                "nameDe": "HeizungenDe",
                "name": "Heizungen",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "waermepumpen",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "luft-wasser-aussenaufstellung",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "sole-wasser-erdsonde",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "effizienz-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "kombi-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "kompakt-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "luft-wasser-innenaufstellung",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "regler",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Speicher",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Regler-ALT",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Pumpen und Heizkreisgruppen",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Sonstiges",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Erdsondenbohrung",
                        "level": 1,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": "SolarFr",
                "nameEn": "SolarEn",
                "nameDe": "SolarDe",
                "name": "Solar",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Standard",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Batterieanschluss",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Integrierte-Batterie",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Optimizer",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Ersatzstrom",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Batteriespeicher",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Niedervolt",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Hochvolt",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Photovoltaikmodul",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Standard-Module",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Hochleistungs-Module",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Made-in-Germany",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Monitoring",
                        "level": 1,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "waermepumpen",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "luft-wasser-aussenaufstellung",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "sole-wasser-erdsonde",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "effizienz-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "kombi-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "kompakt-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "luft-wasser-innenaufstellung",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "regler",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Speicher",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Regler-ALT",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Pumpen und Heizkreisgruppen",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Sonstiges",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Standard",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Batterieanschluss",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Integrierte-Batterie",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Optimizer",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Ersatzstrom",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Batteriespeicher",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Niedervolt",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Hochvolt",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Photovoltaikmodul",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Standard-Module",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Hochleistungs-Module",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Made-in-Germany",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Erdsondenbohrung",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Monitoring",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "luft-wasser-aussenaufstellung",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "sole-wasser-erdsonde",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "effizienz-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "kombi-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "kompakt-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Standard",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Batterieanschluss",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Integrierte-Batterie",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Standard-Module",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Hochleistungs-Module",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Made-in-Germany",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Niedervolt",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "luft-wasser-innenaufstellung",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "regler",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Optimizer",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Ersatzstrom",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Hochvolt",
                "level": 2,
                "childCategories": []
            }
        ],
        "manufacturers": [
            {
                "name": "alpha innotec",
                "id": "0010N00004FH7LzQAL"
            },
            {
                "name": "Stiebel Eltron",
                "id": "0010N00004FKSvnQAH"
            },
            {
                "name": "Viessmann",
                "id": "0010N00004Pn2D9QAJ"
            },
            {
                "name": "NIBE",
                "id": "0010N00004WO11tQAD"
            },
            {
                "name": "Kostal Solar Electric",
                "id": "0010N00004Z2W1CQAV"
            },
            {
                "name": "BYD",
                "id": "0010N00004aPbKlQAK"
            },
            {
                "name": "HPA",
                "id": "0010N00004aPx4MQAS"
            },
            {
                "name": "BOSCH",
                "id": "0010N00004acd3HQAQ"
            },
            {
                "name": "Belimo",
                "id": "0010N00004acgb9QAA"
            },
            {
                "name": "Grundfos",
                "id": "0010N00004addypQAA"
            },
            {
                "name": "PAW",
                "id": "0010N00004ademzQAA"
            },
            {
                "name": "SolarEdge",
                "id": "0010N00004bkWC8QAM"
            },
            {
                "name": "Wikora",
                "id": "0010N00004bml3WQAQ"
            },
            {
                "name": "AEH Oekotherm AG",
                "id": "0010N00004cdDKUQA2"
            },
            {
                "name": "Griesser AG",
                "id": "0010N00004eKCDiQAO"
            },
            {
                "name": "HakaGerodur AG",
                "id": "0010N00004eKCPeQAO"
            },
            {
                "name": "Buderus",
                "id": "0010N00004gGBuWQAW"
            },
            {
                "name": "R. Nussbaum AG",
                "id": "0010N00004hcWc5QAE"
            },
            {
                "name": "Alera energies AG",
                "id": "0010N00004koyf8QAA"
            },
            {
                "name": "NIBE AB (Sweden - nicht verwenden)",
                "id": "0010N00004kp2DKQAY"
            },
            {
                "name": "Paul Bauder AG",
                "id": "0010N00004p3o7FQAQ"
            },
            {
                "name": "Jinko Solar",
                "id": "0010N00004uFHCkQAO"
            },
            {
                "name": "LG Electronics",
                "id": "0010N00004uFHTYQA4"
            },
            {
                "name": "Sunpower",
                "id": "0010N00004uFInjQAG"
            },
            {
                "name": "SMA Solar Technology AG",
                "id": "0010N00004uFJC6QAO"
            },
            {
                "name": "E3DC",
                "id": "0010N00004uFMPaQAO"
            },
            {
                "name": "Trina Solar",
                "id": "0010N00004uFMfnQAG"
            },
            {
                "name": "K2 Systems",
                "id": "0010N000050Hmj5QAC"
            },
            {
                "name": "Depping AG",
                "id": "0010N00005G7tHoQAJ"
            },
            {
                "name": "CTC",
                "id": "0010N00005IvNUBQA3"
            },
            {
                "name": "LG Chem",
                "id": "0010N00005MykGKQAZ"
            },
            {
                "name": "A. Tschümperlin AG",
                "id": "0010N00005MywvqQAB"
            },
            {
                "name": "Novelan",
                "id": "0010N00005Phfq3QAB"
            },
            {
                "name": "Bucher Blechtechnik AG",
                "id": "0015p00005TtVtkAAF"
            },
            {
                "name": "Dani's Metalldesign GmbH",
                "id": "0015p00005UTfQFAA1"
            },
            {
                "name": "Meyer Burger",
                "id": "0015p00005aeeprAAA"
            },
            {
                "name": "Fronius",
                "id": "0015p00005b5gMdAAI"
            },
            {
                "name": "LAMBDA Wärmepumpen GmbH",
                "id": "0015p00005fuigMAAQ"
            },
            {
                "name": "JA Solar",
                "id": "0015p00005kPHpmAAG"
            },
            {
                "name": "Regli Energy",
                "id": "0015p00005pRYZlAAO"
            },
            {
                "name": "Wärmeaustausch Technologien AG WT",
                "id": "0015p00005qczPHAAY"
            },
            {
                "name": "BRUGG Rohrsystem AG",
                "id": "0015p00005vBc3qAAC"
            },
            {
                "name": "WS Weinmann & Schanz GmbH",
                "id": "0015p000061WrYWAA0"
            },
            {
                "name": "Oekoswiss Supply AG",
                "id": "0015p000066srNiAAI"
            },
            {
                "name": "Dimplex",
                "id": "0015p000068z1lqAAA"
            },
            {
                "name": "Sun2World Sustainable Energy Projects GmbH",
                "id": "0015p000068zR7fAAE"
            },
            {
                "name": "Debrunner Acifer AG",
                "id": "0015p000068zU7wAAE"
            },
            {
                "name": "Glaromat AG",
                "id": "0015p000068zfv8AAA"
            },
            {
                "name": "Forstner Speichertechnik GmbH",
                "id": "001Vj000004LDEdIAO"
            },
            {
                "name": "LONGi",
                "id": "001Vj000004U3J8IAK"
            }
        ],
        "bundleCategories": [
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Heizungen",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": "Wärmepumpen Fr",
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wärmepumpen",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Luft/Wasser",
                                "level": 2,
                                "childCategories": [
                                    {
                                        "nameFr": null,
                                        "nameEn": null,
                                        "nameDe": null,
                                        "name": "Innenaufgestellt",
                                        "level": 3,
                                        "childCategories": []
                                    },
                                    {
                                        "nameFr": null,
                                        "nameEn": null,
                                        "nameDe": null,
                                        "name": "Aussenaufgestellt",
                                        "level": 3,
                                        "childCategories": []
                                    }
                                ]
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Sole/Wasser (Erdsonde)",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Solar",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Photovoltaik",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter mit Batterieanschluss (extern)",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter mit integrierter Batterie",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    }
                ]
            },
            {
                "nameFr": "Wärmepumpen Fr",
                "nameEn": null,
                "nameDe": null,
                "name": "Wärmepumpen",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Luft/Wasser",
                        "level": 2,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Innenaufgestellt",
                                "level": 3,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Aussenaufgestellt",
                                "level": 3,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Sole/Wasser (Erdsonde)",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Photovoltaik",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter mit Batterieanschluss (extern)",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter mit integrierter Batterie",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Luft/Wasser",
                "level": 2,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Innenaufgestellt",
                        "level": 3,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Aussenaufgestellt",
                        "level": 3,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Sole/Wasser (Erdsonde)",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter mit Batterieanschluss (extern)",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter mit integrierter Batterie",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Innenaufgestellt",
                "level": 3,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Aussenaufgestellt",
                "level": 3,
                "childCategories": []
            }
        ]
    }
}} location={props.location}
          />
<ProductList
            {...{
    "type": "productList",
    "id": "2M8MjU5O8FJexiO4ZTAs3",
    "updatedAt": "2022-06-06T08:38:35.134Z",
    "title": "Unsere Ersatzstromlösung",
    "category": "Wechselrichter Ersatzstrom",
    "products": {
        "metaProducts": [
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WPL 15 AS",
                        "price": 11455,
                        "id": "01t0N00000982hPQAQ",
                        "description": "Die WPL 15 AS Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 6.86 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz4bQAA",
                                "type": "pdf",
                                "size": 656765,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 15 AS Stiebel Eltron",
                                "modified": "2018-10-18T13:23:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkLRQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 15 AS",
                        "seoKeyword": "WPL 15 AS",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe (WPL 15 AS) zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. Erfahren Sie bei uns mehr!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZHQAZ",
                            "name": "Hauptbild WPL 15 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 15 AS Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 15 ACS (Kühlung)",
                        "price": 12249,
                        "id": "01t0N000009hWNKQA2",
                        "description": "Die WPL 15 ACS Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 6.86 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz41QAA",
                                "type": "pdf",
                                "size": 657801,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 15 ACS Stiebel Eltron",
                                "modified": "2018-10-18T13:23:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkKrQAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 15 ACS (Kühlung)",
                        "seoKeyword": "WPL 15 ACS",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion. ➤ Hier mehr dazu!",
                        "defaultImage": {
                            "id": "0690N00000Cj3YxQAJ",
                            "name": "Hauptbild WPL 15 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 15 ACS Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTdhQAG",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 15 ACS Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 20 A",
                        "price": 14138,
                        "id": "01t0N00000982hFQAQ",
                        "description": "Die WPL 20 A Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 9.54 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz5JQAQ",
                                "type": "pdf",
                                "size": 654783,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 20 A Stiebel Eltron",
                                "modified": "2018-10-18T13:25:31.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkM9QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 20 A",
                        "seoKeyword": "Stiebel Eltron WPL 20 A",
                        "seoDescription": "Die WPL 20 A Inverter ist eine Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZRQAZ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 20 A Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000t71cAAAQ",
                                "name": "Offertenbild Stiebel Eltron WPL 20 25 A - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 20 AC (Kühlung)",
                        "price": 14935,
                        "id": "01t0N000009iEYaQAM",
                        "description": "Die WPL 20 AC Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 9.54 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz6RQAQ",
                                "type": "pdf",
                                "size": 657844,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 20 AC Stiebel Eltron",
                                "modified": "2018-10-18T13:27:01.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkNHQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 20 AC",
                        "seoKeyword": "WPL 20 AC Stiebel eltron",
                        "seoDescription": "Inverter Luft-Wasser-Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. Weitere Informationen bekommen Sie bei uns!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZWQAZ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 20 AC Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTeVQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 20 AC Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 25 A",
                        "price": 15733,
                        "id": "01t0N00000982bwQAA",
                        "description": "Die WPL 25 A Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 12.86 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xz8EQAQ",
                                "type": "pdf",
                                "size": 656543,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 25 A Stiebel Eltron",
                                "modified": "2018-10-18T13:31:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkP8QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 25 A",
                        "seoKeyword": "WPL 25 A",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe zur kompakten Aussenaufstellung mit sehr niedrigem Schallleistungspegel. ➤ Weitere Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZbQAJ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 25 A Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTbHQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 25 A Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 25 AC (Kühlung)",
                        "price": 16530,
                        "id": "01t0N000009jdeBQAQ",
                        "description": "Die WPL 25 AC Inverter Luft-Wasser Wärmepumpe überzeugt mit ihrer kompakten Aussenaufstellung und sehr niedrigem Schallleistungspegel und aktiver Kühlfunktion.\r\n\r\nBis 65 °C Heizungsvorlauftemperatur, Wärmeleistung bei einer Aussentemperatur von -7°C = 12.86 kW.\r\n\r\nHINWEIS: Bei einer aktivierten Kühlfunktion ist es leider nicht möglich, Fördergelder zu beantragen (fehlendes WPSM-Zertifikat).",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzJlQAI",
                                "type": "pdf",
                                "size": 657686,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPL 25 AC Stiebel Eltron",
                                "modified": "2018-10-18T13:50:27.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zkaLQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz38QAA",
                                "type": "pdf",
                                "size": 14184284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungs- und Installationsanleitung WPL 15-25 Stiebel Eltron",
                                "modified": "2018-10-18T13:19:58.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkK3QAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xz3NQAQ",
                                "type": "pdf",
                                "size": 9482716,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Planungsmappe WPL 15-25",
                                "modified": "2018-10-18T13:20:02.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zkKIQAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 25 AC (Kühlung)",
                        "seoKeyword": "Stiebel Eltron WPL 25 AC",
                        "seoDescription": "Inverter Luft-Wasser Wärmepumpe (WPL 25 AC) zur kompakten Aussenaufstellung mit niedrigem Schallleistungspegel und aktiver Kühlfunktion. Mehr hier!",
                        "defaultImage": {
                            "id": "0690N00000Cj3ZgQAJ",
                            "name": "Hauptbild WPL 20-25 Stiebel Eltron",
                            "alt": "Stiebel Eltron WPL 25 AC Wärmepumpe (aussen, Kühlung)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UTeWQAW",
                                "name": "STE_WPL_aussen_15_20_25",
                                "alt": "Stiebel Eltron WPL 25 AC Wärmepumpe (aussen, Kühlung)"
                            },
                            {
                                "id": "0690N000006zkKDQAY",
                                "name": "Bild 1 WPL Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher SBP 200 E",
                        "price": 950,
                        "id": "01t0N00000982j3QAA",
                        "description": "Der Standspeicher SBP 200 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit  perfekt isolierender Ummantelung.\r\n\r\nVolumen: 207 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 63 x 153.5 cm (58kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkCgQAI",
                                "type": "pdf",
                                "size": 399066,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 200 E Stiebel Eltron",
                                "modified": "2018-10-18T13:59:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxYeQAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 200 E",
                        "seoKeyword": "Stiebel Eltron SBP 200 E",
                        "seoDescription": "Der Stiebel Eltron Standspeicher eignet sich mit perfekt isolierender Ummantelung als Pufferspeicher für Wärmepumpenanlagen. ➤ Jetzt informieren!",
                        "defaultImage": {
                            "id": "0690N00000CiwNsQAJ",
                            "name": "Stiebel_Eltron_Pufferspeicher_SBP_200_400_700_WEB",
                            "alt": "Stiebel Eltron SBP 200 E Pufferspeicher (200 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGhRQAW",
                                "name": "SE_SBP_200_400E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 200 E Pufferspeicher (200 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher SBP 400 E",
                        "price": 1240,
                        "id": "01t0N00000982jbQAA",
                        "description": "Der Standspeicher SBP 400 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit perfekt isolierender Ummantelung.\r\n\r\nVolumen: 415 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 171 cm (81 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkCuQAI",
                                "type": "pdf",
                                "size": 398895,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 400 E Stiebel Eltron",
                                "modified": "2018-10-18T14:00:29.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxYsQAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 400 E",
                        "seoKeyword": "Pufferspeicher SBP 400 E",
                        "seoDescription": "Der Standspeicher SBP 400 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ✅ Isolierende Ummantelung ✅ Energieklasse B ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000CiwPtQAJ",
                            "name": "Stiebel_Eltron_Pufferspeicher_SBP_200_400_700_WEB",
                            "alt": "Stiebel Eltron SBP 400 E Pufferspeicher (400 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGgsQAG",
                                "name": "SE_SBP_200_400E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 400 E Pufferspeicher (400 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher SBP 700 E",
                        "price": 1733,
                        "id": "01t0N00000982jlQAA",
                        "description": "Der Standspeicher SBP 700 E eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit  perfekt isolierender Ummantelung.\r\n\r\nVolumen: 720 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 91 x 189 cm (185 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006vkD4QAI",
                                "type": "pdf",
                                "size": 399136,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBP 700 E Stiebel Eltron",
                                "modified": "2018-10-18T14:01:33.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006PxZ2QAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006vkCQQAY",
                                "type": "pdf",
                                "size": 10515588,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBP 200 400 700 E Stiebel Eltron",
                                "modified": "2018-10-13T07:40:52.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006PxYOQA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Pufferspeicher SBP 700 E",
                        "seoKeyword": "Stiebel Eltron Pufferspeicher",
                        "seoDescription": "Der Standspeicher eignet sich als Pufferspeicher für Wärmepumpenanlagen ideal mit perfekt isolierender Ummantelung Energieklasse B.",
                        "defaultImage": {
                            "id": "0690N00000Ck2h7QAB",
                            "name": "Stiebel Eltron SBP 700 E (Hauptbild)",
                            "alt": "Stiebel Eltron SBP 700 E Pufferspeicher (700 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UGgEQAW",
                                "name": "SE_SBP_700E_Pufferspeicher",
                                "alt": "Stiebel Eltron SBP 700 E Pufferspeicher (700 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher SBB 312.B WP",
                        "price": 2320,
                        "id": "01t0N00000982lYQAQ",
                        "description": "Standspeicher SBB 312.B mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. Wärmedämmung als PU-Direktumschäumung.\r\n\r\nVolumen: 309 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 173.8 cm (194 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzX7QAI",
                                "type": "pdf",
                                "size": 200096,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 312.B Stiebel Eltron",
                                "modified": "2021-08-10T08:24:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zknQQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 312.B WP",
                        "seoKeyword": "Stiebel Eltron Trinkwasserspeicher",
                        "seoDescription": "Standspeicher mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000CiwVhQAJ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": "Stiebel Eltron SBB 312.B WP Trinkwarmwasserspeicher (300 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UH0iQAG",
                                "name": "SE_SBB_312B_WP_Trink_Warmwasserspeicher",
                                "alt": "Stiebel Eltron SBB 312.B WP Trinkwarmwasserspeicher (300 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher  SBB 411.B WP",
                        "price": 2646,
                        "id": "01t0N00000982liQAA",
                        "description": "Standspeicher SBB 411.B mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und verkalkungsunempfindlich. Wärmedämmung als PU-Direktumschäumung.\r\n\r\nVolumen: 395 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 185 cm (192 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzrBQAQ",
                                "type": "pdf",
                                "size": 201522,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:43.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zl7PQAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 411.B WP",
                        "seoKeyword": "Stiebel Eltron Trinkwarmwasserspeicher",
                        "seoDescription": "SBB 411.B Standspeicher ➤ Innen liegenden Glattrohr-Wärmeübertrager ✔️ Emailliert & Verkalkungsunempfindlich ✔️ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000CiwWBQAZ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": "Stiebel Eltron SBB 411.B WP Trinkwarmwasserspeicher (400 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N000009UH1HQAW",
                                "name": "SE_SBB_411B_WP_Trink_Warmwasserspeicher",
                                "alt": "Stiebel Eltron SBB 411.B WP Trinkwarmwasserspeicher (400 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher SBB 501 WP",
                        "price": 3081,
                        "id": "01t5p00000CQhLAAA1",
                        "description": "Standspeicher SBB 501 WP SOL mit innen liegenden Glattrohr-Wärmeübertrager sind emailliert und besitzen eine hohe Trinkwarmwasserverfügbarkeit durch abgestimmte Ein- und Ausströmtechnik.\r\n\r\nVolumen: 495 Liter\r\nMasse ohne Isolierung: ⌀xH: 69 x 198.8 cm (222 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vKByWAAW",
                                "type": "pdf",
                                "size": 208855,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt SBB 501 Stiebel Eltron - Heizungsmacher AG",
                                "modified": "2022-01-11T07:01:49.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000uizjOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xzrQQAQ",
                                "type": "pdf",
                                "size": 16597224,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation SBB 312-411.B Stiebel Eltron",
                                "modified": "2018-10-18T14:56:54.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N000006zl7eQAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Trinkwarmwasserspeicher SBB 501 WP",
                        "seoKeyword": "Stiebel Eltron Trinkwarmwasserspeicher",
                        "seoDescription": "SBB 501.B Standspeicher ➤ Innen liegenden Glattrohr-Wärmeübertrager ✔️ Emailliert & Verkalkungsunempfindlich ✔️ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0695p00000uiziCAAQ",
                            "name": "StiebelEltron_Trinkwarmwasserspeicher_SBB_WEB",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000uiziDAAQ",
                                "name": "SE_SBB_411B_WP_Trink_Warmwasserspeicher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Erweiterung WPE",
                        "price": 830,
                        "id": "01t0N000009jMZKQA2",
                        "description": "Funktionserweiterungsmodul für den WPM.\r\nRegelung von zwei zusätzlichen gemischten Heizkreisen.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xzvjQAA",
                                "type": "pdf",
                                "size": 915359,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPE Stiebel Eltron",
                                "modified": "2018-10-18T15:04:24.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zlBlQAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron Erweiterung WPE ➤ FFunktionserweiterungsmodul für den WPM ✔️ Regelung von zwei zusätzlichen gemischten Heizkreisen ✔️",
                        "defaultImage": {
                            "id": "0690N00000Cj3ajQAB",
                            "name": "Hauptbild WPE Stiebel Eltron",
                            "alt": "Stiebel Eltron Wärmepumpenregler WPE Erweiterung"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000ANFQPQA5",
                                "name": "SE_WPE_Erweiterung_2",
                                "alt": "Stiebel Eltron Wärmepumpenregler WPE Erweiterung"
                            },
                            {
                                "id": "0690N000006zlDIQAY",
                                "name": "Übersicht WPM Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpenregler WPM",
                        "price": 1131,
                        "id": "01t0N00000982mHQAQ",
                        "description": "Der Wärmepumpen-Manager WPM ist für die steuer- und regelungstechnischen Abläufe der Wärmepumpe zuständig. Mit dem Gerät können Einstellungen vorgenommen und Informationen zum Betrieb der Wärmepumpe angezeigt werden.\r\n-Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N000006xc8cQAA",
                                "type": "pdf",
                                "size": 915254,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Produktdatenblatt WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:05:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwTQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8dQAA",
                                "type": "pdf",
                                "size": 1610169,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Meldungsliste WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwUQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8hQAA",
                                "type": "pdf",
                                "size": 1880046,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Inbetriebnahme WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:55.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwYQAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8mQAA",
                                "type": "pdf",
                                "size": 4618726,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installation WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:39.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwdQAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N000006xc8rQAA",
                                "type": "pdf",
                                "size": 5067872,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung WPM Stiebel Eltron",
                                "modified": "2018-10-18T15:06:08.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N000006zNwiQAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron Wärmepumpenregler WPM ➤ Wärmepumpen-Manager ✔️ Steuer- und regelungstechnische Abläufe ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0690N00000Cj3aUQAR",
                            "name": "Hauptbild WPM 4 Stiebel Eltron",
                            "alt": "Stiebel Eltron Wärmepumpenregler WPM"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000ANFRDQA5",
                                "name": "SE_WPM_4_2",
                                "alt": "Stiebel Eltron Wärmepumpenregler WPM"
                            },
                            {
                                "id": "0690N000006zlDIQAY",
                                "name": "Übersicht WPM Stiebel Eltron",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WPL 19",
                        "price": 15407,
                        "id": "01t0N0000098r9XQAQ",
                        "description": "Die innenaufgestellte Luft-Wasser-Wärmepumpe WPL 19 besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Trotz minimaler Schallemissionen plus besonders leisem Nachtmodus erreicht die Pumpe eine hohe Vorlauftemperatur von +65°C. Das Gerät erreicht bei einer Aussentemperatur von -7°C eine Wärmeleistung von 9.91 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdttkQAB",
                                "type": "pdf",
                                "size": 252022,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron WPL 19 I Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:39:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsuiQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OdtvUQAR",
                                "type": "pdf",
                                "size": 28899489,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation Stiebel Eltron WPL 19 24 Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:38:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NWstzQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 19",
                        "seoKeyword": "Stiebel Eltron WPL 19",
                        "seoDescription": "Die innenaufgestellte WPL 19 Luft-Wasser-Wärmepumpe besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Alle Vorteile hier!",
                        "defaultImage": {
                            "id": "0690N00000PiwOTQAZ",
                            "name": "Hauptbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                            "alt": "Stiebel Eltron WPL 19 Wärmepumpe (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwOYQAZ",
                                "name": "Offertbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                                "alt": "Stiebel Eltron WPL 19 Wärmepumpe (innen)"
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WPL 24",
                        "price": 16969,
                        "id": "01t0N0000098r9cQAA",
                        "description": "Die innenaufgestellte Luft-Wasser-Wärmepumpe WPL 24 besticht durch ihre exzellente Energieeffizienz dank moderner Invertertechnik. Trotz minimaler Schallemissionen plus besonders leisem Nachtmodus erreicht die Pumpe eine hohe Vorlauftemperatur von +65°C. Das Gerät erreicht bei einer Aussentemperatur von -7°C eine Wärmeleistung von 13,45 kW.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdttlQAB",
                                "type": "pdf",
                                "size": 252431,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron WPL 24 I Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:40:28.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsujQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OdtvUQAR",
                                "type": "pdf",
                                "size": 28899489,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienung und Installation Stiebel Eltron WPL 19 24 Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-05-15T13:38:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NWstzQAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Wärmepumpe WPL 24",
                        "seoKeyword": "Stiebel Eltron WPL 24",
                        "seoDescription": "Die innenaufgestellte Luft-Wasser-Wärmepumpe besticht durch exzellente Energieeffizienz dank moderner Invertertechnik. Alle weiteren Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000PiwOsQAJ",
                            "name": "Hauptbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                            "alt": "Stiebel Eltron WPL 24 Wärmepumpe (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwOtQAJ",
                                "name": "Offertbild Stiebel Eltron WPL 19 24 I - Heizungsmacher AG",
                                "alt": "Stiebel Eltron WPL 24 Wärmepumpe (innen)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kombispeicher HS-BM 560L WT1",
                        "price": 5700,
                        "id": "01t0N000009ODegQAG",
                        "description": "Der Hygiene-Systemspeicher HS-BM056-18 WT1 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nVolumen: 560 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 89 (65) x 198 cm (157kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002mugQIAQ",
                                "type": "pdf",
                                "size": 1532093,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-056-18 WT1",
                                "modified": "2024-02-23T15:38:05.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jJblIAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 560L",
                        "seoKeyword": "Stiebel Eltron Kombispeicher",
                        "seoDescription": "Der multivalente Hygiene-Systemspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie. 560l Fassungsvermögen ➤ Hier weitere Infos!",
                        "defaultImage": {
                            "id": "0690N00000PiYtNQAV",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 560L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtOQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 560L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 560L WT2",
                        "price": 6473,
                        "id": "01tVj0000024OnNIAU",
                        "description": "Der Hygiene-Systemspeicher HS-BM056-18 WT2 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nMit zusätzlichem Register für einen zweiten Wärmeerzeuger (1.5 m2).\r\n\r\nVolumen: 560 Liter \r\nMasse mit (ohne) Isolierung: ⌀xH: 89 (65) x 198 cm (162 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n7teIAA",
                                "type": "pdf",
                                "size": 627868,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-056-18 WT2",
                                "modified": "2024-02-23T15:39:56.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jWsEIAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 560L",
                        "seoKeyword": "Stiebel Eltron Kombispeicher",
                        "seoDescription": "Der multivalente Hygiene-Systemspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie. 560l Fassungsvermögen ➤ Hier weitere Infos!",
                        "defaultImage": {
                            "id": "069Vj000002jKnsIAE",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000002jS75IAE",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 820L WT1",
                        "price": 6630,
                        "id": "01t0N000009OEaHQAW",
                        "description": "Der Hygiene-Systemspeicher HS-BM080-18 WT1 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nVolumen: 820 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 101 (77) x 203 cm (181 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n00yIAA",
                                "type": "pdf",
                                "size": 1537486,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-080-18 WT1",
                                "modified": "2024-02-23T15:38:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jP1AIAU",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 820 L",
                        "seoKeyword": "Stiebel Eltron HS-BM",
                        "seoDescription": "Der Hygiene-Systemspeicher HS-BM von Stiebel Eltron gilt als Herzstück einer Heizanlage. Alles zu seiner innovativen Technologie erfahren Sie hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYthQAF",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 820L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtQQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 820L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 820L WT2",
                        "price": 7530,
                        "id": "01t0N000009jqE4QAI",
                        "description": "Der Hygiene-Systemspeicher  HS-BM080-18 WT2 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nMit zusätzlichem Register für einen zweiten Wärmeerzeuger (2.6 m2).\r\n\r\nVolumen: 820 Liter \r\nMasse mit (ohne) Isolierung: ⌀xH: 1'100 (77) x 203 cm (191 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n7yUIAQ",
                                "type": "pdf",
                                "size": 634799,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-080-18 WT2",
                                "modified": "2024-02-23T15:39:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jWx4IAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "069Vj000002jVjFIAU",
                            "name": "Hauptbild Forstner HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000002jRPbIAM",
                                "name": "Offertbild Forstner HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher HS-BM 960L WT1",
                        "price": 8424,
                        "id": "01t0N00000AJECNQA5",
                        "description": "Der Hygiene-Systemspeicher HS-BM096-18 gilt als Herzstück einer Heizanlage. Dieser multivalente Schichtenspeicher besticht mit seiner Einzigartigkeit und innovativen Technologie zur intelligenten Einbindung von Wärmeerzeuger und Trinkwassererwärmung in einer kompakten Einheit. Die Trinkwarmwasser-Aufbereitung erfolgt im hygienischen Durchlaufprinzip mit einem innenliegenden Wellrohr.\r\n\r\nNenninhalt: 960 Liter\r\nDurchmesser ohne (mit) Isolation: 790mm (1030mm)\r\nHöhe mit Isolation: 2250mm\r\n\r\nVolumen: 960 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 103 (79) x 225 cm (198 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000002n4kVIAQ",
                                "type": "pdf",
                                "size": 1531211,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Forstner HS-BM-096-18 WT1",
                                "modified": "2024-02-23T15:40:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj000002jTr9IAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Forstner Speichertechnik GmbH",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher HS-BM 960L",
                        "seoKeyword": "Stienel Eltron HS-BM 960",
                        "seoDescription": "Der Kombispeicher HS-BM 960 gilt als Herzstück einer Heizanlage. Alle Infos zur innovativen Technologie dieses multivalenten Schichtenspeichers hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYtLQAV",
                            "name": "Hauptbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher HS-BM 960L WT1"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYtKQAV",
                                "name": "Offertbild Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher HS-BM 960L WT1"
                            },
                            {
                                "id": "0690N00000PiYtIQAV",
                                "name": "Bild 2 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000PiYtJQAV",
                                "name": "Bild 1 Stiebel Eltron HS-BM Kombispeicher - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Mutltifunktionsspeicher MFS 600 S",
                        "price": 4373,
                        "id": "01t0N0000099NBDQA2",
                        "description": "Der Multifunktionsspeicher MFS 600 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 572 Liter (7.5 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 80 (65) x 193 cm (140 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfFKQAZ",
                                "type": "pdf",
                                "size": 126296,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 600 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:22:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULydQAH",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 600 S",
                        "seoKeyword": "Alpha Innotec MFS 600 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 600 S vereint Puffer & Trinkwarmwasser in einem. Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip.",
                        "defaultImage": {
                            "id": "0690N00000NULnRQAX",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 600 S Multifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8HKQA1",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 600 S Multifunktionsspeicher"
                            }
                        ]
                    },
                    {
                        "title": "Mutltifunktionsspeicher MFS 830 S",
                        "price": 4909,
                        "id": "01t0N000009O5azQAC",
                        "description": "Der Multifunktionsspeicher MFS 830 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 846 Liter (8.7 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 199 cm (200 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfFtQAJ",
                                "type": "pdf",
                                "size": 140839,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 830 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:26:32.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULzCQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 830 S",
                        "seoKeyword": "Alpha Innotec 830 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 830 S von Alpha Innotec vereint Puffer & Trinkwarmwasser. Die Aufbereitung erfolgt im Durchlauferhitzerprinzip.",
                        "defaultImage": {
                            "id": "0690N00000NY8IhQAL",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 830 S Mutltifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8HLQA1",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 830 S Mutltifunktionsspeicher"
                            }
                        ]
                    },
                    {
                        "title": "Mutltifunktionsspeicher MFS 1000 S",
                        "price": 6069,
                        "id": "01t0N000009P7xAQAS",
                        "description": "Der Multifunktionsspeicher MFS 1000 S von NIBE vereint Puffer und Trinkwarmwasser in einem. Die Warmwasseraufbereitung erfolgt hygienisch im Durchlauferhitzerprinzip mit einem innenliegenden Wellrohr (Baugleich Alpha Innotec).\r\n\r\nVolumen: 928 Liter (10.9 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 214 cm (230 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MdfC6QAJ",
                                "type": "pdf",
                                "size": 129902,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt MFS 1000 S Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:27:17.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NULvLQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mdf3xQAB",
                                "type": "pdf",
                                "size": 4175439,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung MFS 600 830 1000 Multifunktionsspeicher - Heizungsmacher AG",
                                "modified": "2020-04-02T14:21:31.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000NULnMQAX",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Mutltifunktionsspeicher MFS 1000 S",
                        "seoKeyword": "MFS 1000 S",
                        "seoDescription": "Der Multifunktionsspeicher MFS 1000 S von Alpha Innotec vereint Puffer und Trinkwarmwasser in einem. ➤ Alle Infos dazu bei uns!",
                        "defaultImage": {
                            "id": "0690N00000NULvKQAX",
                            "name": "Hauptbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                            "alt": "NIBE MFS 1000 S Mutltifunktionsspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000NY8JaQAL",
                                "name": "Offertenbild NIBE MFS 600 800 1000 - Heizungsmacher AG",
                                "alt": "NIBE MFS 1000 S Mutltifunktionsspeicher"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher TPS 200",
                        "price": 1088,
                        "id": "01t0N000009PKl6QAG",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 200).\r\n\r\nVolumen: 200 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 60 x 130 cm (60 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsRQAV",
                                "type": "pdf",
                                "size": 112737,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 200 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:14:21.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXwQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 200",
                        "seoKeyword": "alpha innotec TPS 200",
                        "seoDescription": "Pufferspeicher zur Optimierung der Laufzeit & Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung.",
                        "defaultImage": {
                            "id": "0690N00000PiYY1QAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 200 - Heizungsmacher AG",
                            "alt": "NIBE TPS 200 Pufferspeicher (200 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYY0QAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 200 - Heizungsmacher AG",
                                "alt": "NIBE TPS 200 Pufferspeicher (200 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 300.11",
                        "price": 1435,
                        "id": "01t0N000009MIH7QAO",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 300.11).\r\n\r\nVolumen: 301 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 66 x 174 cm (93 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000wzrTqAAI",
                                "type": "pdf",
                                "size": 146385,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 300-11 - Heizungsmacher AG",
                                "modified": "2022-02-22T15:31:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYYPQA3",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Pufferspeicher TPS 300",
                        "seoKeyword": "Alpha Innotec TPS 300",
                        "seoDescription": "Der Alpha Innotec TPS 300 Pufferspeicher zur Optimierung der Laufzeit & Vergrösserung des Systemvolumens. ➤ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiYUoQAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 300 - Heizungsmacher AG",
                            "alt": "NIBE TPS 300 Pufferspeicher (300 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYUnQAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 300 - Heizungsmacher AG",
                                "alt": "NIBE TPS 300 Pufferspeicher (300 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 500.1",
                        "price": 1660,
                        "id": "01t0N000009MTuPQAW",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und fest installierter Wärmedämmung (baugleich Alpha Innotec TPS 500).\r\n\r\nVolumen: 485 Liter\r\nMasse mit (ohne) Isolierung: ⌀xH: 76 x 193 cm (111 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLt5QAF",
                                "type": "pdf",
                                "size": 138757,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Pufferspeicher TPS 500-1 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:18:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYYZQA3",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 500.1",
                        "seoKeyword": "Alpha Innotec TPS 500",
                        "seoDescription": "TPS 500.1 Pufferspeicher ➤ Optimierung der Laufzeit ✔️ Eingeschweisste Tauchhülsen ✔️  Abnehmbare Wärmedämmung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0690N00000PiYYfQAN",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                            "alt": "NIBE TPS 500.1 Pufferspeicher (500 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiYYeQAN",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                                "alt": "NIBE TPS 500.1 Pufferspeicher (500 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher TPS 500.3",
                        "price": 1918,
                        "id": "01t5p00000BksjIAAR",
                        "description": "Pufferspeicher zur Optimierung der Laufzeit und Vergrösserung des Systemvolumens. Mit eingeschweissten Tauchhülsen und abnehmbarer Wärmedämmung (baugleich Alpha Innotec TPS 500).\r\n\r\nDurchmesser ohne Isolation: 650mm\r\nKippmass ohne Isolation: 1840mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsQQAV",
                                "type": "pdf",
                                "size": 3165954,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Pufferspeicher TPS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:10:36.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYXvQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "alpha innotec Pufferspeicher TPS 500.3",
                        "seoKeyword": "Alpha Innotec TPS 500",
                        "seoDescription": "TPS 500.3 Pufferspeicher ➤ Optimierung der Laufzeit ✔️ Eingeschweisste Tauchhülsen ✔️  Abnehmbare Wärmedämmung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p00000j6rA1AAI",
                            "name": "Hauptbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                            "alt": "NIBE TPS 500.3 Pufferspeicher (500 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000j6rA6AAI",
                                "name": "Offertbild Pufferspeicher NIBE alpha innotec TPS 500 - Heizungsmacher AG",
                                "alt": "NIBE TPS 500.3 Pufferspeicher (500 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S2125-8",
                        "price": 10075,
                        "id": "01t0N000009M6nDQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE S2125-8 deckt einen Leistungsbereich bis 5.3 kW ab (bei A-7/W55). Leiser Betrieb von 50 dB(A) Nacht-Schallleistungspegel und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nDas in der NIBE S2125 eingesetzte Kältemittel ist umweltfreundlich und nachhaltig. Bei reinem Verdichterbetrieb werden hohe Ladetemperaturen von bis zu 75 °C erreicht. Ebenso können bei Außentemperaturen von –25 °C noch Temperaturen von bis zu 65 °C erzeugt werden. Dies ermöglicht eine ganzjährig effiziente Betriebsweise mit hohem Brauchwasserkomfort und den Betrieb mit bestehenden Heizkörpern.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014miVLAAY",
                                "type": "pdf",
                                "size": 722379,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S2125 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:39:43.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDrAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-8",
                        "seoKeyword": "Nibe F2120-8",
                        "seoDescription": "Die NIBE F2120-8 deckt einen Leistungsbereich bis 5.5 kW ab (bei A-7/W55). Merkmale sind ihr leiser Betrieb & die geringen Betriebskosten. Hier mehr!",
                        "defaultImage": {
                            "id": "0695p000014yhDvAAI",
                            "name": "Hauptbild NIBE S2125 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yhD3AAI",
                                "name": "Offertenbild NIBE S2125-8 -12 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhDwAAI",
                                "name": "Bild 1 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhDxAAI",
                                "name": "Bild 2 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S2125-12",
                        "price": 11146,
                        "id": "01t0N000009M6nNQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE S2125-12 deckt einen Leistungsbereich bis 8.2 kW ab (bei A-7/W55). Leiser Betrieb von 50 dB(A) Nacht-Schallleistungspegel und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nDas in der NIBE S2125 eingesetzte Kältemittel ist umweltfreundlich und nachhaltig. Bei reinem Verdichterbetrieb werden hohe Ladetemperaturen von bis zu 75 °C erreicht. Ebenso können bei Außentemperaturen von –25 °C noch Temperaturen von bis zu 65 °C erzeugt werden. Dies ermöglicht eine ganzjährig effiziente Betriebsweise mit hohem Brauchwasserkomfort und den Betrieb mit bestehenden Heizkörpern.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014midSAAQ",
                                "type": "pdf",
                                "size": 722379,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S2125 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:42:06.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhGpAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-12",
                        "seoKeyword": "Nibe F2120-12",
                        "seoDescription": "Die Monoblock-Ausseneinheit NIBE F2120-12 deckt einen Leistungsbereich bis 7.6 kW ab. ✅ Leiser Betrieb ✅ minimale Betriebskosten. Hier mehr!",
                        "defaultImage": {
                            "id": "0695p000014yhDsAAI",
                            "name": "Hauptbild NIBE S2125 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yhGqAAI",
                                "name": "Offertenbild NIBE S2125-8 -12 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhGuAAI",
                                "name": "Bild 1 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhGvAAI",
                                "name": "Bild 2 NIBE S2125 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe F2120-16",
                        "price": 14280,
                        "id": "01t0N000009M6nXQAS",
                        "description": "Die Monoblock-Ausseneinheit NIBE F2120-16 deckt einen Leistungsbereich bis 10.9 kW ab (bei A-7/W55). Flüsterleiser Betrieb dank einem Schallleistungspegel von 55 dB(A) und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nMeilenstein in der Effizienz mit einem SCOP grösser als 5.0. Vorlauftemperatur bis 65°C ideal für die Sanierung. Keine Vignettenpflicht da weniger als 3 kg Kältemittel.",
                        "attributes": "<ul><li>Hocheffizienz-Wärmepumpe für den Heizungstausch.</li><li>Erreicht Effizienzwerte die bisher nur mit Erdwärme möglich waren.</li><li>Bisher unerreichter Betriebsbereich mit bis zu 65 °C Ladetemperatur und 63 °C bei –25 °C Aussenlufttemperatur</li><li>Minimaler Geräuschpegel, auch bei voller Leistung.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014miabAAA",
                                "type": "pdf",
                                "size": 433933,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE F2120 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:45:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDyAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-16",
                        "seoKeyword": "Nibe F2120-16",
                        "seoDescription": "Die Monoblock-Ausseneinheit NIBE F2120-16 deckt einen Leistungsbereich bis 10.9 kW ab. Leiser Betrieb dank einem Schallleistungspegel von 55 dB(A).",
                        "defaultImage": {
                            "id": "0690N00000I36q6QAB",
                            "name": "Hauptbild NIBE F2120 -8 -12 -16 -20",
                            "alt": "NIBE F2120-16 Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I36pdQAB",
                                "name": "Offertenbild NIBE F2120 -8 -12 -16 -20",
                                "alt": "NIBE F2120-16 Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0695p000014yhKhAAI",
                                "name": "Ansicht 1 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKiAAI",
                                "name": "Ansicht 2 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKjAAI",
                                "name": "Ansicht 3 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe F2120-20",
                        "price": 15926,
                        "id": "01t0N000009M6ncQAC",
                        "description": "Die Monoblock-Ausseneinheit NIBE F2120-20 deckt einen Leistungsbereich bis 14.1 kW ab (bei A-7/W55). Flüsterleiser Betrieb dank einem Schallleistungspegel von 55 dB(A) und minimale Betriebskosten durch Inverter-Technologie zum optimalen Leistungsbereich.\r\n\r\nMeilenstein in der Effizienz mit einem SCOP grösser als 5.0. Vorlauftemperatur bis 65°C ideal für die Sanierung. Keine Vignettenpflicht da weniger als 3 kg Kältemittel.",
                        "attributes": "<ul><li>Hocheffizienz-Wärmepumpe für den Heizungstausch.</li><li>Erreicht Effizienzwerte die bisher nur mit Erdwärme möglich waren.</li><li>Bisher unerreichter Betriebsbereich mit bis zu 65 °C Ladetemperatur und 63 °C bei –25 °C Aussenlufttemperatur</li><li>Minimaler Geräuschpegel, auch bei voller Leistung.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mihUAAQ",
                                "type": "pdf",
                                "size": 433933,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE F2120 - Heizungsmacher AG",
                                "modified": "2022-08-02T13:45:53.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000014yhDtAAI",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe F2120-20",
                        "seoKeyword": "Nibe F2120-20",
                        "seoDescription": "Die NIBE F2120-20 deckt einen Leistungsbereich bis 14.1 kW ab. Flüsterleiser Betrieb und minimale Betriebskosten durch Inverter-Technologie.",
                        "defaultImage": {
                            "id": "0690N00000I36pXQAR",
                            "name": "Hauptbild NIBE F2120 -8 -12 -16 -20",
                            "alt": "NIBE F2120-20 Wärmepumpe (aussen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I36pcQAB",
                                "name": "Offertenbild NIBE F2120 -8 -12 -16 -20",
                                "alt": "NIBE F2120-20 Wärmepumpe (aussen)"
                            },
                            {
                                "id": "0695p000014yhDuAAI",
                                "name": "Ansicht 2 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKkAAI",
                                "name": "Ansicht 1 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yhKlAAI",
                                "name": "Ansicht 3 NIBE F2120 -8 -12 -16 -20",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher VVM S320",
                        "price": 6822,
                        "id": "01t0N00000BBwk3QAD",
                        "description": "Kompakte Inneneinheit NIBE VVM S320 mit sehr hohem Vorfertigungsgrad für Standardanwendungen im Einfamilienhaus. Das intelligente, anwenderfreundliche Regelgerät der NIBE VVM S320 ermöglicht eine effiziente Wärme- und Brauchwasserversorgung.\r\nVVM S320 wird in Kombination mit einer Luft-/Wasser-Wärmepumpe NIBE F2120 als zentrales Bindeglied zwischen Wärmeaufnahme, -speicherung und Wärmeabgabe eingesetzt. \r\n\r\nDie kompakte Inneneinheit ist mit einem 180 Liter Brauchwasserspeicher, einem Pufferspeicher sowie mit einem Ausdehnungsgefäß ausgestattet. Zusätzlich sind die hocheffizienz Lade- und Entladepumpen sowie eine elektrische Notheizung bereits integriert. \r\n\r\nMit integriertem WLAN fügt sich die S-Serie wie selbstverständlich in Ihr vernetztes Zuhause ein. Die intelligente Technologie passt das Raumklima automatisch an, wobei Sie via Smartphone oder Tablet die volle Kontrolle behalten. Bei minimalem Energieverbrauch genießen Sie höchsten Wohnkomfort und schonen die Umwelt.",
                        "attributes": "<ul><li>In Kombination mit einer NIBE Luft-/Wasser-Wärmepumpe bildet die NIBE VVM S320 Inneneinheit ein integriertes System für das Einfamilienhaus.</li><li>Anwenderfreundliche Touchscreen-Bedienung, integrierte Drahtlosverbindung sowie die energiesparende Smart-Technologie sorgen für höchsten Komfort.</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000JA0v7QAD",
                                "type": "pdf",
                                "size": 1641419,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:16:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37aiQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000JA0vCQAT",
                                "type": "pdf",
                                "size": 514870,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:16:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37anQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000JA0vHQAT",
                                "type": "pdf",
                                "size": 4505784,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE VVM S320 - Heizungsmacher AG",
                                "modified": "2019-12-26T14:15:55.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000I37asQAB",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": "NIBE Kompaktspeicher VVM S320 (NEU)",
                        "seoKeyword": "VVM S320 Nibe",
                        "seoDescription": "Kompakte Inneneinheit NIBE VVM S320 mit sehr hohem Vorfertigungsgrad für Standardanwendungen im Einfamilienhaus. ➤ Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000I37aVQAR",
                            "name": "Hauptbild NIBE VVM S320 - Heizungsmacher AG",
                            "alt": "NIBE VVM S320 Kompaktspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000I37aTQAR",
                                "name": "Ansicht 1 NIBE VVM S320 - Heizungsmacher AG",
                                "alt": "NIBE VVM S320 Kompaktspeicher"
                            },
                            {
                                "id": "0690N00000I37aUQAR",
                                "name": "Ansicht 2 NIBE VVM S320 - Heizungsmacher AG",
                                "alt": "Moderne Küche mit Kompaktspeicher VVM S320 von NIBE"
                            },
                            {
                                "id": "0690N00000I37aYQAR",
                                "name": "Offertenbild NIBE VVM S320 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher WWS 405",
                        "price": 2765,
                        "id": "01t0N000009MBvoQAG",
                        "description": "Der Trinkwarmwasserspeicher WWS 405 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert durch hochwirksame Wärmedämmung die Wärmeverluste (Baugleich Alpha InnoTec). \r\n\r\nVolumen: 362 Liter (5.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 163 cm / Isolierung nicht abnehmbar\r\nKippmass: 172cm",
                        "attributes": "<ul><li>Durchmesser (mit Isolation, nicht demontierbar): 75cm</li><li>Höhe: 163cm</li><li>Kippmass: 172cm</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsTQAV",
                                "type": "pdf",
                                "size": 131119,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Trinkwarmwasserspeicher WWS 405-2 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:44:38.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXxQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLv0QAF",
                                "type": "pdf",
                                "size": 4424427,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Trinkwarmwasserspeicher WWS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:42:37.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYaGQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha innotec Trinkwarmwasserspeicher WWS 405.2",
                        "seoKeyword": "Alpha innotec WWS 405.2",
                        "seoDescription": "Der Trinkwarmwasserspeicher WWS 405 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert Wärmeverluste.",
                        "defaultImage": {
                            "id": "0690N00000Piwd5QAB",
                            "name": "Hauptbild NIBE alpha innotec WWS 405 - Heizungsmacher AG",
                            "alt": "NIBE WWS 405 Trinkwarmwasserspeicher (362 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000Piwd6QAB",
                                "name": "Offertbild NIBE alpha innotec WWS 405 - Heizungsmacher AG",
                                "alt": "NIBE WWS 405 Trinkwarmwasserspeicher (362 Liter)"
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WWS 507",
                        "price": 3522,
                        "id": "01t0N000009NrkwQAC",
                        "description": "Der Trinkwarmwasserspeicher WWS 507 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert durch hochwirksame Wärmedämmung die Wärmeverluste (Baugleich Alpha InnoTec).\r\n\r\nDurchmesser (mit Isolation, nicht demontierbar): 75cm\r\nKippmass: 203cm\r\nRegisterfläche: 7.0m2",
                        "attributes": "<ul><li>Durchmesser (mit Isolation, nicht demontierbar): 75cm</li><li>Höhe: 196cm</li><li>Kippmass: 203cm</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfLsUQAV",
                                "type": "pdf",
                                "size": 169890,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Massblatt NIBE alpha innotec Trinkwarmwasserspeicher WWS 507-2 - Heizungsmacher AG",
                                "modified": "2020-06-07T13:45:47.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYXyQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfLv0QAF",
                                "type": "pdf",
                                "size": 4424427,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bedienungsanleitung NIBE alpha innotec Trinkwarmwasserspeicher WWS - Heizungsmacher AG",
                                "modified": "2020-06-07T13:42:37.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYaGQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "alpha innotec",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": "Alpha Innotec Trinkwarmwasserspeicher WWS 507.2",
                        "seoKeyword": "Alpha Innotec wws 507.2",
                        "seoDescription": "Der Trinkwarmwasserspeicher WWS 507 ist nach DIN 4753 emailliert. Dies garantiert eine hygienische Warmwasseraufbereitung und minimiert Wärmeverluste.",
                        "defaultImage": {
                            "id": "0690N00000PiwkKQAR",
                            "name": "Hauptbild NIBE alpha innotec WWS 507 - Heizungsmacher AG",
                            "alt": "NIBE WWS 507 Trinkwarmwasserspeicher (432 Liter)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwkLQAR",
                                "name": "Offertbild NIBE alpha innotec WWS 507 - Heizungsmacher AG",
                                "alt": "NIBE WWS 507 Trinkwarmwasserspeicher (432 Liter)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe LICV 82R1/3",
                        "price": 13860,
                        "id": "01t0N000009MCeHQAW",
                        "description": "Mit der neuen LICV 8.2R1/3 (innen) von NIBE (baugleich Novelan und Alpha Innotec LWCV) entscheiden Sie sich für eine vielseitige Modullösung. Sehr leise 38 dB(A) Schallleistung im Nachtbetrieb. 6.4 kW Heizleistung bei einer Aussentemperatur von -7°C.\r\n\r\nDie Geräte sind werkseitig mit folgenden Komponenten ausgestattet:\r\n– Wärmepumpenregler Luxtronik 2.1\r\n– Energieeffizienz-Umwälzpumpe für Heizung\r\n– Umschaltventil Heizung/ Trinkwasser\r\n– Überströmventil für den Heizkreis\r\n– Pufferspeicher 82 Liter für Heizung\r\n– Elektroheizelement zur Unterstützung der Heizung und Brauchwarmwasserladung\r\n– Ausdehnungsgefäss (18l)\r\n– Wärmemengenerfassung\r\n– Schwingungsentkopplung für den Heizkreis",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vLylYAAS",
                                "type": "pdf",
                                "size": 599943,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE LICV - Heizungsmacher AG",
                                "modified": "2022-01-21T09:11:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000ukjnzAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe LICV 82R1/3",
                        "seoKeyword": "Nibe LICV",
                        "seoDescription": "Mit der neuen LICV-Serie von NIBE entscheiden Sie sich für eine vielseitige und sehr leise Modullösung. ➤ Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiwBUQAZ",
                            "name": "Offertbild LICV - Heizungsmacher AG",
                            "alt": "NIBE Wärmepumpe LICV 8.2R1/3 (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000PiwBPQAZ",
                                "name": "Bild 1 LICV - Heizungsmacher AG",
                                "alt": "Einzelteile der NIBE Wärmepumpe LICV 8.2R1/3 (innen)"
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe LICV 122R1/3",
                        "price": 15408,
                        "id": "01t0N000009NbvBQAS",
                        "description": "Mit der neuen LICV 12.2R1/3 (innen) von NIBE (baugleich Novelan und Alpha Innotec LWCV) entscheiden Sie sich für eine vielseitige Modullösung. Leise 43dB(A) Schallleistung im Nachtbetrieb. 8.5 kW Heizleistung bei einer Aussentemperatur von -7°C. Die Geräte sind werkseitig mit folgenden Komponenten ausgestattet:\r\n– Wärmepumpenregler Luxtronik 2.1\r\n– Energieeffizienz-Umwälzpumpe für Heizung\r\n– Umschaltventil Heizung/ Trinkwasser\r\n– Überströmventil für den Heizkreis\r\n– Pufferspeicher 82 Liter für Heizung\r\n– Elektroheizelement zur Unterstützung der Heizung und Brauchwarmwasserladung\r\n– Ausdehnungsgefäss (12l)\r\n– Wärmemengenerfassung\r\n– Schwingungsentkopplung für den Heizkreis",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000vLylYAAS",
                                "type": "pdf",
                                "size": 599943,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE LICV - Heizungsmacher AG",
                                "modified": "2022-01-21T09:11:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000ukjnzAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe LICV 122R1/3",
                        "seoKeyword": "Nibe LICV 122R1/3",
                        "seoDescription": "Mit der neuen LICV-Serie von NIBE entscheiden Sie sich für eine vielseitige Modullösung. Alle Vorteile erfahren Sie bei uns!",
                        "defaultImage": {
                            "id": "0690N00000PiwAqQAJ",
                            "name": "Offertbild LICV - Heizungsmacher AG",
                            "alt": "NIBE Wärmepumpe LICV 12.2R1/3 (innen)"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000Piw86QAB",
                                "name": "Bild 2 LICV Einzelteile - Heizungsmacher AG",
                                "alt": "Einzelteile der NIBE Wärmepumpe LICV 12.2R1/3 (innen)"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hydraulikmodul Vitocal 200-A",
                        "price": 2666,
                        "id": "01t0N000009OlebQAC",
                        "description": "Inneneinheit mit Wärmepumpenregelung Vitotronic 200, Hocheffizienz- Umwälzpumpe für den Sekundärkreis, eingebautem Heizwasser- Durchlauferhitzer (Notheizung), 3- Wege- Umschaltventil und Sicherheitsgruppe. Inklusive benötigter Temperatursensoren und Vitoconnect 100, Typ OPTO1.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MekCDQAZ",
                                "type": "pdf",
                                "size": 343061,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Viessmann Vitocal 200-A - Heizungsmacher AG",
                                "modified": "2020-04-22T12:33:44.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NVQsmQAH",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Hydraulikmodul Vitocal 200-A ➤ Inneneinheit mit Wärmepumpenregelung ✔️ Hocheffizienz- Umwälzpumpe ✔️ Temperatursensoren ✔️",
                        "defaultImage": {
                            "id": "0690N0000072DZrQAM",
                            "name": "Hauptbild Viessmann Hydraulikmodul Vitocal-200",
                            "alt": "Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072DZvQAM",
                                "name": "Schnitt Viessmann Hydraulikmodul Vitocal-200",
                                "alt": "Schnitt Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                            },
                            {
                                "id": "0690N00000NVQrPQAX",
                                "name": "Offertbild Viessmann Hydraulikeinheit Vitocal-200 - Heizungsmacher AG",
                                "alt": "Viessmann Hydraulikmodul Vitocal 200-A Inneneinheit"
                            },
                            {
                                "id": "0690N00000NV8bOQAT",
                                "name": "Vicare-App",
                                "alt": null
                            },
                            {
                                "id": "0690N00000NV8bTQAT",
                                "name": "Vitotronic-Regelung",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hochleistungs-Photovoltaikmodul MAXEON (430Wp)",
                        "price": 386,
                        "id": "01t5p00000CRBDmAAP",
                        "description": "Mit über 30-Jahre Produkterfahrung gehört SunPower zu den Pionierunternehmen der Solarbranche und konnte schon mit Projekten für die NASA oder Solar Impulse seine Qualität unter Beweis stellen. Solarexperten wissen, dass SunPower Module die widerstandsfähigsten auf dem Markt sind. Mit 25 Jahre Produkt-und Leistungs Garantie ist SunPower die Benchmark bei Garantieleistungen und dadurch die Referenz für Zuverlässigkeit und Langlebigkeit. Die Maxeon® Solarzellen sind grundlegend anders und besser. Durch die Kupferbasis bieten die Solarzellen eine beispiellose Zuverlässigkeit und ermöglichen die Entwicklung von Modulen mit einem der weltweit höchsten Wirkungsgrade im Markt.\r\n\r\nModulwirkungsgrad: 22.70 %\r\nLänge: 1812 mm\r\nBreite: 1046 mm",
                        "attributes": "<ul><li>Modulleistung<ul><li>430 Wp</li></ul></li><li>Typ<ul><li><span style=\"font-size: 13px;\">SunPower MAXEON 3</span></li></ul></li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001Apgs7AAB",
                                "type": "pdf",
                                "size": 734161,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Sunpower Maxeon 3 430 Wp - Heizungsmacher AG",
                                "modified": "2022-10-21T10:58:41.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001AxU7JAAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Sunpower",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Hochleistungs-Module"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Sunpower Hochleistungs-Photovoltaikmodul MAXEON (430Wp) ➤ Kupferbasis bietet eine beispiellose Zuverlässigkeit ✔️ SunPower MAXEON 3 ✔️",
                        "defaultImage": {
                            "id": "0695p00000wr3MOAAY",
                            "name": "Hauptbild Sunpower Maxeon 3 430 Wp - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Hochleistungs-Photovoltaikmodul MAXEON (420Wp Schwarz)",
                        "price": 386,
                        "id": "01t5p00000CRpq2AAD",
                        "description": "Mit über 30-Jahre Produkterfahrung gehört SunPower zu den Pionierunternehmen der Solarbranche und konnte schon mit Projekten für die NASA oder Solar Impulse seine Qualität unter Beweis stellen. Solarexperten wissen, dass SunPower Module die widerstandsfähigsten auf dem Markt sind. Mit 25 Jahre Produkt-und Leistungs Garantie ist SunPower die Benchmark bei Garantieleistungen und dadurch die Referenz für Zuverlässigkeit und Langlebigkeit. Die Maxeon® Solarzellen sind grundlegend anders und besser. Durch die Kupferbasis bieten die Solarzellen eine beispiellose Zuverlässigkeit und ermöglichen die Entwicklung von Modulen mit einem der weltweit höchsten Wirkungsgrade im Markt.\r\n\r\nModulwirkungsgrad: 22.20 %\r\nLänge: 1812 mm\r\nBreite: 1046 mm",
                        "attributes": "<ul><li>Modulleistung<ul><li>420 Wp</li></ul></li><li>Typ<ul><li><span style=\"font-size: 13px;\">SunPower MAXEON 3 BLACK</span></li></ul></li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001ApgwXAAR",
                                "type": "pdf",
                                "size": 693284,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Sunpower Maxeon 3 420 Wp black - Heizungsmacher AG",
                                "modified": "2022-10-21T10:58:02.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001AxUBeAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Sunpower",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Hochleistungs-Module"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Sunpower Hochleistungs-Photovoltaikmodul MAXEON (420Wp Schwarz) ➤ SunPower MAXEON 3 BLACK ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p00000zfEU6AAM",
                            "name": "Sunpower Maxeon 3 420 Wp Black - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpenregler SMO S40",
                        "price": 1425,
                        "id": "01t5p00000BozQFAAZ",
                        "description": "Der neue SMO S40 ist ein äusserst flexibel einsetzbares Regelgerät für NIBE Wärmepumpen, das sehr viele Anwendungsmöglichkeiten abdeckt. Umfangreiche, übersichtliche Einstellungen und die integrierte Internetverbindung via www.myuplink.com runden das Einsatzspektrum ab.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00000XXJ7KAAX",
                                "type": "pdf",
                                "size": 617382,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE SMO S40 - Heizungsmacher AG",
                                "modified": "2020-12-08T08:39:46.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jB4AAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p00000XXJ7LAAX",
                                "type": "pdf",
                                "size": 188054,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE MyUplink Internetverbindung - Heizungsmacher AG",
                                "modified": "2020-12-08T08:39:32.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jB5AAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p00000XXJ7UAAX",
                                "type": "pdf",
                                "size": 3917056,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE SMO S40 - Heizungsmacher AG",
                                "modified": "2020-12-08T08:37:44.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00000X9jBEAAZ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "regler"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "NIBE Wärmepumpenregler SMO S40 ➤ Flexibel einsetzbares Regelgerät ✔️ Viele Anwendungsmöglichkeiten ✔️ NIBE ✔️",
                        "defaultImage": {
                            "id": "0695p00000X9jBAAAZ",
                            "name": "Hauptbild NIBE SMO S40 - Heizungsmacher AG",
                            "alt": "NIBE SMO S40 Wärmepumpenregler"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000X9jB9AAJ",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": "Smartphone mit Applikation zum NIBE SMO S40 Wärmepumpenregler"
                            },
                            {
                                "id": "0695p00000X9jBJAAZ",
                                "name": "Offertenbild NIBE SMO S40 Regler - Heizungsmacher AG",
                                "alt": "NIBE SMO S40 Wärmepumpenregler"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S1155-6",
                        "price": 11310,
                        "id": "01t0N00000BCnC8QAL",
                        "description": "NIBE S1155-6 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 139</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-6",
                        "seoKeyword": "Nibe s1155-6",
                        "seoDescription": "Die NIBE S1155 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Mehr dazu hier!",
                        "defaultImage": {
                            "id": "0690N00000L5U9bQAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-6"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U9gQAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-6"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-6 PC",
                        "price": 13284,
                        "id": "01t0N00000BCnCFQA1",
                        "description": "NIBE S1155-6 PC ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung, Kühlung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 139</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "NIBE Wärmepumpe S1155-6 PC - Erfahren Sie bei Heizungsmacher alle Infos über dieses Produkt und fordern Sie sofort einen Expertenrat an!",
                        "defaultImage": {
                            "id": "0690N00000L5U9qQAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U9vQAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-12",
                        "price": 13675,
                        "id": "01t0N00000BCnByQAL",
                        "description": "NIBE S1155-12 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 3–12 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 167</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-12",
                        "seoKeyword": "Nibe S1155-12",
                        "seoDescription": "NIBE S1155 ist eine intelligente Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird nach Bedarf gewählt.",
                        "defaultImage": {
                            "id": "0690N00000L5U97QAF",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-12"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5U98QAF",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-12"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1155-16",
                        "price": 14149,
                        "id": "01t0N00000BCc7aQAD",
                        "description": "NIBE S1155-16 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Der separate Brauchwasserspeicher wird jeweils nach dem gewünschten Brauchwasserbedarf gewählt. Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nDie meisten benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nHeizleistung 4–16 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1500</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620 </li><li>Leergewicht (kg)172 </li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcnUQAR",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8YQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnVQAR",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:25:30.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8ZQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcnYQAR",
                                "type": "pdf",
                                "size": 9066477,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1155 - Heizungsmacher AG",
                                "modified": "2020-03-05T04:24:25.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8cQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000007L3DdIAK",
                                "type": "pdf",
                                "size": 501520,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1155 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2024-07-08T09:48:07.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5U8dQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1155-16",
                        "seoKeyword": "Nibe S1155-16",
                        "seoDescription": "NIBE S1155 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Brauchwasserbereitung. Alle Infos bei uns!",
                        "defaultImage": {
                            "id": "0690N00000L4RGIQA3",
                            "name": "Hauptbild NIBE S1155 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1155-16"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L4RInQAN",
                                "name": "Offertenbild NIBE S1155 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1155-16"
                            },
                            {
                                "id": "0690N00000L5U8aQAF",
                                "name": "Bild 2 Nibe S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5U8bQAF",
                                "name": "Bild 1 NIBE S1155 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UCBQA3",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe S1255-6",
                        "price": 14396,
                        "id": "01t0N00000BCnCkQAL",
                        "description": "NIBE S1255-6 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 220</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-6",
                        "seoKeyword": "Nibe S1255-6",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung. Hier alle Infos!",
                        "defaultImage": {
                            "id": "0690N00000L5UDjQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDkQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-12",
                        "price": 15716,
                        "id": "01t0N00000BCnChQAL",
                        "description": "NIBE S1255-12 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 3–12 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 250</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-12",
                        "seoKeyword": "Nibe s1255-12",
                        "seoDescription": "NIBE S1255 ist eine intelligente Erdwärmepumpe mit integrierter Brauchwasserbereitung. Die Wärmepumpe bietet höchstes Einsparpotenzial. ➤ Mehr hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UDnQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-12"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDsQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-12"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-6 PC",
                        "price": 16331,
                        "id": "01t0N00000BCnCrQAL",
                        "description": "NIBE S1255-6 PC ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung und Kühlung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 1.5-6 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 220</li><li>Mit integrierter Kühlung</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-6 PC",
                        "seoKeyword": "Nibe s1255 PC",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe mit integrierter Brauchwasserbereitung. ➤ Weitere Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UDdQAN",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6 PC"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UDiQAN",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-6 PC"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe S1255-16",
                        "price": 17109,
                        "id": "01t0N00000BCnCNQA1",
                        "description": "NIBE S1255-16 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung.  Diese Wärmepumpe bietet Ihnen höchstes Einsparpotenzial, da sie sich automatisch an den aktuellen Energiebedarf des Gebäudes anpasst.\r\n\r\nSämtliche benötigten Komponenten sind bereits eingebaut. Der hohe Vorfertigungsgrad ermöglicht eine kompakte Installation. Die integrierte Notheizung schaltet bei Bedarf zu, damit Sie stets warm haben.\r\n\r\nSchüttleistung Warmwasser bei 40°C und 10l/min: 240 Liter.\r\nHeizleistung 4–16 kW bei B0/W35°C.",
                        "attributes": "<ul><li>Höhe (mm) 1800</li><li>Breite (mm) 600</li><li>Tiefe (mm) 620</li><li>Leergewicht (kg) 255 </li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbcqxQAB",
                                "type": "pdf",
                                "size": 2167100,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Benutzerhandbuch NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:39.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBcQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqyQAB",
                                "type": "pdf",
                                "size": 9238701,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Elektroschema NIBE S1155 S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:40.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBdQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbcqzQAB",
                                "type": "pdf",
                                "size": 485521,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer NIBE S1255 Sole-Wasser Wärmepumpe - Heizungsmacher AG",
                                "modified": "2020-03-05T05:10:27.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBeQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000Mbcr0QAB",
                                "type": "pdf",
                                "size": 7195734,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installateurhandbuch NIBE S1255 - Heizungsmacher AG",
                                "modified": "2020-03-05T05:09:43.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5UBfQAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "NIBE",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": "NIBE Wärmepumpe S1255-16",
                        "seoKeyword": "Nibe S2155-16",
                        "seoDescription": "Die NIBE S1255 ist eine intelligente, vollkommen leistungsvariable Erdwärmepumpe zur Heizung mit integrierter Brauchwasserbereitung. Alle Infos hier!",
                        "defaultImage": {
                            "id": "0690N00000L5UCLQA3",
                            "name": "Hauptbild NIBE S1255 - Heizungsmacher AG",
                            "alt": "NIBE Sole/Wasser Wärmepumpe S1255-16"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5UCMQA3",
                                "name": "Offertenbild NIBE S1255 - Heizungsmacher AG",
                                "alt": "NIBE Sole/Wasser Wärmepumpe S1255-16"
                            },
                            {
                                "id": "0690N00000L5UBwQAN",
                                "name": "Bild 1 NIBE S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UBxQAN",
                                "name": "Bild 2 Nibe S1155 S1255 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0690N00000L5UMfQAN",
                                "name": "Bild 3 NIBE S-Serie - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher HSBC 300 L",
                        "price": 5655,
                        "id": "01t0N000009MR6PQAW",
                        "description": "Beim Integralspeicher HSBC 300 L cool stehen nicht zwei Einzelspeicher nebeneinander, sondern Trinkwarmwasser- und Pufferspeicher sind übereinander in einem Gerät angeordnet. Der Platzbedarf wird so quasi halbiert und eine Aufstellung auf engstem Raum wird möglich – für mehr Nutzfläche.\r\n\r\nPräzise abgestimmt auf die Nutzung mit einer Wärmepumpe deckt der HSBC L cool alles ab, was man in einem Einfamilienhaus in Bezug auf Wärme und Trinkwarmwasser benötigt.\r\n\r\nNenninhalt Trinkwarmwasser: 270 Liter\r\nNenninhalt Pufferspeicher: 100 Liter",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OdtNMQAZ",
                                "type": "pdf",
                                "size": 129463,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron HSBC 300 L Kompaktspeicher - Heizungsmacher AG",
                                "modified": "2022-05-17T09:31:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000NWsRSQA1",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kompaktspeicher HSBC 300 L cool",
                        "seoKeyword": "Stiebel Eltron HSBC 300 L cool",
                        "seoDescription": "Beim Integralspeicher HSBC 300 L cool sind Trinkwarmwasser- und Pufferspeicher platzsparend übereinander in einem Gerät angeordnet. Hier alle Infos!",
                        "defaultImage": {
                            "id": "0690N00000NWsTNQA1",
                            "name": "Offertenbild Stiebel Eltron HSBC 300 L - Heizungsmacher AG",
                            "alt": "Stiebel Eltron HSBC 300 L Kompaktspeicher"
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014zaqOAAQ",
                                "name": "Hauptbild Stiebel Eltron HSBC 300 L - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "WPL 09 ICS classic",
                        "price": 12579,
                        "id": "01t5p00000CQ9x7AAD",
                        "description": "Diese Luft-Wasser-Wärmepumpe ist für die kompakte Innenaufstellung konzipiert. Hohe Flexibilität bei Aufstellung und Installation Invertertechnologie für hohe Effizienz und niedrige Energiekosten. \r\n- Integrierter Wärmepumpenmanager\r\n- Vorlauftemperaturen von bis zu 60 °C\r\n- Max. Heizleistung bei A-7/W35: 4,23 kW\r\n- Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aJKOAA2",
                                "type": "pdf",
                                "size": 142945,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt WPL 09 ICS classic - Heizungsmacher AG",
                                "modified": "2022-06-07T06:06:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000013VmHHAA0",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron WPL 09 ICS classic ➤ Luft/Wasser-Wärmepumpe ✔️ Kompakte Innenaufstellung ✔️ Hohe Effizienz ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013VmEAAA0",
                            "name": "Hauptbild WPL 09 17 ICS classic - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000013VmHIAA0",
                                "name": "Offertenbild Stiebel Eltron WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000013VmJ8AAK",
                                "name": "Bild 2 WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WPL 17 ICS classic",
                        "price": 14718,
                        "id": "01t0N00000A3Cd0QAF",
                        "description": "Diese Luft-Wasser-Wärmepumpe ist für die kompakte Innenaufstellung konzipiert. Hohe Flexibilität bei Aufstellung und Installation Invertertechnologie für hohe Effizienz und niedrige Energiekosten.\r\n- Integrierter Wärmepumpenmanager\r\n- Vorlauftemperaturen von bis zu 60 °C\r\n- Max. Heizleistung bei A-7/W35: 8.02 kW\r\n- Inkl. Internet Service Gateway ISG Funktionen via \"Aktives Monitoring\".",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aJKjAAM",
                                "type": "pdf",
                                "size": 142325,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt WPL 17 ICS classic - Heizungsmacher AG",
                                "modified": "2022-06-07T06:08:40.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p000013VmHhAAK",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-innenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Stiebel Eltron WPL 17 ICS classic ➤ Luft/Wasser-Wärmepumpe ✔️ Hohe Flexibilität ✔️ Invertertechnologie für hohe Effizienz ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmHbAAK",
                            "name": "Hauptbild WPL 09 17 ICS classic - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000013VmHgAAK",
                                "name": "Offertenbild Stiebel Eltron WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000013VmJ9AAK",
                                "name": "Bild 2 WPL 09 17 ICS classic - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Batteriespeicher RESU10",
                        "price": 5320,
                        "id": "01t0N00000BCkSzQAL",
                        "description": "Die kompakte und leichtgewichtige Natur des RESU ist erstklassig. Das System ist besonders einfach zu installieren. Hochwertiger Niedervolt-Akku vom Markenhersteller LG. \r\n\r\nNutzbare Kapazität: 8.8 kWh bei 90% Entladetiefe.\r\nMax. DC-Ausgangsleistung: 5.0 kW",
                        "attributes": "<ul><li>Nutzbare Kapazität: 8.8 kWh bei 90% Entladetiefe.</li><li>DC-Ausgangsleistung: 5.0 kW</li><li>DC-Nennspannung: 51.80 V</li><li>Batterietechnologie: Lithium-Nickel-Mangan-Kobaltoxid</li><li>Länge: 452 mm</li><li>Breite: 227 mm</li><li>Höhe: 483 mm</li><li>Gewicht: 75 kg</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014kOzQAAU",
                                "type": "pdf",
                                "size": 281238,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt LG Chem RESU 65 10 12 - Heizungsmacher AG",
                                "modified": "2022-07-04T22:01:50.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wRYKAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "LG Chem",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "LG Chem Batteriespeicher RESU10 ➤ Kompakt und leichtgewichtig ✔️ Einfache Installation ✔️ Hochwertiger Niedervolt-Akku ✔️",
                        "defaultImage": {
                            "id": "0695p00000hP44YAAS",
                            "name": "RESU 10",
                            "alt": "LG Chem Batteriespeicher RESU10"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5ONEQA3",
                                "name": "Bild 1 LG Chem Akku RESU 6.5-10 - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Batteriespeicher RESU12",
                        "price": 6840,
                        "id": "01t5p00000CRBInAAP",
                        "description": "Die kompakte und leichtgewichtige Natur des RESU ist erstklassig. Das System ist besonders einfach zu installieren. Hochwertiger Niedervolt-Akku vom Markenhersteller LG. \r\n\r\nNutzbare Kapazität: 11.7 kWh bei 90% Entladetiefe.\r\nMax. DC-Ausgangsleistung: 5.0 kW",
                        "attributes": "<ul><li>Nutzbare Kapazität: 11.7 kWh bei 90% Entladetiefe.</li><li>DC-Ausgangsleistung: 5.0 kW</li><li>DC-Nennspannung: 51.80 V</li><li>Batterietechnologie: Lithium-Nickel-Mangan-Kobaltoxid</li><li>Länge: 452 mm</li><li>Breite: 227 mm</li><li>Höhe: 625 mm</li><li>Gewicht: 75 kg</li></ul>",
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014kOzQAAU",
                                "type": "pdf",
                                "size": 281238,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt LG Chem RESU 65 10 12 - Heizungsmacher AG",
                                "modified": "2022-07-04T22:01:50.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wRYKAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "LG Chem",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "LG Chem Batteriespeicher RESU12 ➤ Kompakt & leichtgewichtig ✔️ Einfache Installation ✔️ Hochwertiger Niedervolt-Akku ✔️",
                        "defaultImage": {
                            "id": "0695p00000wr8GfAAI",
                            "name": "LG RESU 12",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5ONEQA3",
                                "name": "Bild 1 LG Chem Akku RESU 6.5-10 - Solarmacher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE5K-RWS 48V",
                        "price": 2710.1,
                        "id": "01t0N00000BBqAFQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 5 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE5K-RWS 48V\r\n ➤ Einfache Installation ✔️ Dreiphasige Installationen mit Batteriespeicher 5 kVA ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LG4QAN",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LG9QAN",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE7K-RWS 48V",
                        "price": 2946.3,
                        "id": "01t0N00000BBqAKQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 7 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE7K-RWS 48V\r\n ➤ Ein einziger Wechselrichter ✔️ Dreiphasige Installationen ✔️",
                        "defaultImage": {
                            "id": "0695p00001Q1a7MAAR",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGYQA3",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE8K-RWS 48V",
                        "price": 3029.2,
                        "id": "01t0N00000BBqAUQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 8 kVA\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE8K-RWS 48V ➤ Dreiphasige Installationen mit Batteriespeicher 8 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LGZQA3",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGAQA3",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Hybrid-Wechselrichter StorEdge SE10K-RWS 48V",
                        "price": 3206.9,
                        "id": "01t0N00000BBqAZQA1",
                        "description": "Ideale Lösung für dreiphasige Installationen mit Batteriespeicher 10 kVA.\r\n\r\n- Einfache Installation mit einem einzigen Wechselrichter, der sowohl die Erzeugung von PV-Energie wie auch eines Batteriespeichers steuert\r\n- Mehr Energie durch DC-gekoppelte Lösungsarchitektur, die den PV-Strom direkt speichert, wobei keine Verluste durch AC-Umwandlung entstehen\r\n- Schnelle und einfache Inbetriebnahme des Wechselrichters direkt von einem Smartphone aus mit der SolarEdge SetApp\r\n- Bietet mehr Sicherheit durch die Vermeidung von Hochspannung bei Installation, Wartung oder Transport\r\n- Eingebautes Monitoring auf Modulebene und vollständige Übersicht über Batteriestatus, PV-Produktion und Eigenverbrauch\r\n- Anschluss von 48V-Niedervoltbatterien verschiedener Batteriehersteller für mehr Flexibilität möglich.\r\n\r\nHersteller: SolarEdge\r\nGarantie: 12 Jahre Herstellergarantie",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000MbTQHQA3",
                                "type": "pdf",
                                "size": 30748003,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:44:34.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5KzoQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000MbTT8QAN",
                                "type": "pdf",
                                "size": 252955,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "modified": "2020-03-03T11:46:22.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000L5L2CQAV",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Hybrid-Wechselrichter StorEdge SE10K-RWS 48V ➤ Dreiphasige Installationen mit Batteriespeicher 10 kVA ✔️",
                        "defaultImage": {
                            "id": "0690N00000L5LGnQAN",
                            "name": "Hauptbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0690N00000L5LGaQAN",
                                "name": "Offertenbild SolarEdge Batteriewechselrichter SE5-10K-RWS - Solarmacher",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Kombispeicher SBS 601 W",
                        "price": 3770,
                        "id": "01t0N0000098rATQAY",
                        "description": "SBS 601 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung. \r\n\r\nNenninhalt: 613 Liter\r\nDurchmesser ohne Isolation: 750mm\r\nDurchmesser mit Isolation: 970mm\r\nHöhe mit Isolation: 1775mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMKJQA3",
                                "type": "pdf",
                                "size": 299445,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 601 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:23:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYwvQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 601 W",
                        "seoKeyword": "Stiebel Eltron SBS 601 W",
                        "seoDescription": "SBS 601 Heizungs-Pufferspeicher ✅ integrierte Trinkwarmwasserbereitung ✅ hygienische Warmwasseraufbereitung. Hier mehr erfahren!",
                        "defaultImage": {
                            "id": "0690N00000PiZ2UQAV",
                            "name": "Hauptbild SBS 600 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 601 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072G5jQAE",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 601 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYufQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 601 W"
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher SBS 801 W",
                        "price": 3959,
                        "id": "01t0N0000098rAdQAI",
                        "description": "SBS 801 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung.\r\n\r\nNenninhalt: 759 Liter\r\nDurchmesser ohne Isolation: 790mm\r\nDurchmesser mit Isolation: 1010mm\r\nHöhe mit Isolation: 1940mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMHFQA3",
                                "type": "pdf",
                                "size": 299276,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 801 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:13:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYviQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 801 W",
                        "seoKeyword": "SBS 801 W",
                        "seoDescription": "Stiebel Eltron SBS 801 Heizungs-Pufferspeicher ✅ Trinkwarmwasserbereitung im Durchflussbetrieb ✅ hygienische Warmwasseraufbereitung. Mehr dazu hier!",
                        "defaultImage": {
                            "id": "0690N00000PiYvoQAF",
                            "name": "Hauptild SBS 800 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 801 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072GQIQA2",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 801 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYvnQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 801 W"
                            }
                        ]
                    },
                    {
                        "title": "Kombispeicher SBS 1001 W",
                        "price": 4676,
                        "id": "01t0N0000098rAnQAI",
                        "description": "SBS 1001 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Dies garantiert eine hygienische Warmwasseraufbereitung.\r\n\r\nNenninhalt: 941 Liter\r\nDurchmesser ohne Isolation: 790mm\r\nDurchmesser mit Isolation: 1010mm\r\nHöhe mit Isolation: 2350mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0680N00000OfMGhQAN",
                                "type": "pdf",
                                "size": 299337,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Stiebel Eltron Kombispeicher SBS 1001 W - Heizungsmacher AG",
                                "modified": "2020-06-08T05:15:54.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0690N00000PiYtjQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0680N00000OfMIoQAN",
                                "type": "pdf",
                                "size": 15591862,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Gebrauchsanleitung Stiebel Eltron Kombispeicher SBS - Heizungsmacher AG",
                                "modified": "2020-06-08T05:11:23.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0690N00000PiYvdQAF",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Stiebel Eltron",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kombi-speicher"
                            }
                        ],
                        "seoTitle": "Stiebel Eltron Kombispeicher SBS 1001 W",
                        "seoKeyword": "Kombispeicher SBS 1001 W",
                        "seoDescription": "SBS 1001 Heizungs-Pufferspeicher mit integrierter Trinkwarmwasserbereitung im Durchflussbetrieb. Garantierte hygienische Warmwasseraufbereitung.",
                        "defaultImage": {
                            "id": "0690N00000PiYugQAF",
                            "name": "Hauptbild SBS 1000 Kombispeicher Stiebel Eltron - Heizungsmacher AG",
                            "alt": "Stiebel Eltron Kombispeicher SBS 1001 W"
                        },
                        "additionalImages": [
                            {
                                "id": "0690N0000072H69QAE",
                                "name": "Bild 2 Kombispeicher Stiebel Eltron",
                                "alt": "Stiebel Eltron Kombispeicher SBS 1001 W in schwarz"
                            },
                            {
                                "id": "0690N00000PiYtiQAF",
                                "name": "Offertbild Stiebel Eltron SBS Kombispeicher - Heizungsmacher AG",
                                "alt": "Stiebel Eltron Kombispeicher SBS 1001 W"
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Meyer Burger Black 390 Wp",
                        "price": 252,
                        "id": "01t5p00000D3R3VAAV",
                        "description": "Das neue Meyer Burger Heterojunction Modul bietet bis zu 20 Prozent mehr Energieertrag. Produktion der Solarzellen und -module nach höchsten Standards ausschließlich in Deutschland mit Schweizer Technologie.\r\n\r\nDie Meyer Burger HJT-Zellen nehmen deutlich mehr Sonnenenergie als herkömmliche Zellen auf und der umgewandelte elektrische Strom fliesst mit weniger Widerstand durch das Modul. Auch bei schwachem Sonnenlicht, beispielsweise wenn es bewölkt ist.\r\n\r\nZusätzliche Effizienzgewinne bringt die Verbindung der Zellen durch unsere patentierte SmartWire-Technologie. Denn die hauchdünnen Verbindungsdrähte verringern die Abschattung auf den Solarzellen um bis zu 30 Prozent. Die Module nehmen so mehr Energie auf und produzieren länger Strom. Für spürbar mehr Leistung pro Fläche auf dem Dach. Und nicht nur das.\r\n\r\nSWCT verbessert auch die Zellstabilität und macht die Module weniger anfällig für Mikrorisse, einem der häufigsten Gründe für Energieverluste bei Solarmodulen.\r\n\r\nModulwirkungsgrad: 21.5 %\r\nLänge: 1767 mm\r\nBreite: 1041 mm\r\nHöhe: 35 mm\r\nGewicht: 19.7 kg",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0lJvAAJ",
                                "type": "pdf",
                                "size": 630080,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Meyer Burger Black - Heizungsmacher AG",
                                "modified": "2023-06-09T11:28:37.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "0695p00001I3hTOAAZ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Meyer Burger",
                        "inCategories": [
                            {
                                "value": "Photovoltaikmodul"
                            },
                            {
                                "value": "Made-in-Germany"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Meyer Burger Black 390 Wp Photovoltaikmodul - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001I3hTiAAJ",
                            "name": "Hauptbild Meyer Burger Photovoltaikmodul Black - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Pufferspeicher PU 200",
                        "price": 752,
                        "id": "01t5p00000CMHlMAAX",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 190 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 60 x 121 cm (43 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 200 - Alle Produktinformationen bei Heizungsmacher ansehen, Vergleiche ziehen und persönliche Beratung erhalten!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yxTaAAI",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 300",
                        "price": 912,
                        "id": "01t5p00000CLuqtAAD",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 282 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 65 x 147 cm (61 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 300 - Bei Heizungsmacher finden Sie nicht nur spezifische Produktdetails, sondern erhalten auch eine umfassende Beratung!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXRaAAM",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 400",
                        "price": 1195,
                        "id": "01t5p00000D1MfQAAV",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 377 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 140 cm (70 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 400 - Sichern Sie sich jetzt bei Heizungsmacher wertvolle Infos über die Produktdetails und fordern Sie eine Beratung an!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Fe5E1AAJ",
                                "name": "Offertbild hpa Pufferspeicher PU - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PU 500",
                        "price": 1224,
                        "id": "01t5p00000CwnWpAAJ",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, aussen 60 mm PUR-Hartschaum fix geschäumt. Skaimantel 5 mm mit Reissverschluss. Silber.\r\n\r\nVolumen: 479 Liter\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 174 cm (80 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 500 - Alle Produktdetails im detaillierten Überblick bei Heizungsmacher und direkte Expertenberatung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001AJL5cAAH",
                                "name": "hpa Puffer-neu",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Pufferspeicher PSF 1000",
                        "price": 2998,
                        "id": "01tVj000006Qp8IIAS",
                        "description": "Die Speicher werden aus hochwertigem Stahl nach EN 10025 gefertigt. Innen unbehandelt, Isolierung aus Hightech Faservlies. Skaimantel mit Reissverschluss, inklusive Rosetten und Abdeckhaube. Silber.\r\n\r\nVolumen: 887 Liter\r\nMasse ohne Isolierung: ⌀xH: 79 x 204 cm (111kg) (ohne Isolierung)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B2QcnIAF",
                                "type": "pdf",
                                "size": 339263,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PSF - Heizungsmacher AG",
                                "modified": "2024-10-26T13:01:01.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": false,
                                "documentId": "069Vj00000AsdD7IAJ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "068Vj000009GUctIAG",
                                "type": "pdf",
                                "size": 637289,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Pufferspeicher PU - Heizungsmacher AG",
                                "modified": "2024-09-03T06:36:55.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXRZAA2",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Pufferspeicher PU 500 - Alle Produktdetails im detaillierten Überblick bei Heizungsmacher und direkte Expertenberatung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXT1AAM",
                            "name": "Hauptbild hpa Pufferspeicher PU - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj00000AscowIAB",
                                "name": "PSF1000 Offertbild",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 300",
                        "price": 1955,
                        "id": "01t5p00000CMHmTAAX",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 304 Liter (3.2 m² Registeroberfläche)\r\nMasse mit/ohne Isolierung: ⌀xH: 65 x 157 cm (141 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 300 - Hier bei Heizungsmacher genau über das Produkt informieren und eine umfassende Beratung sichern!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yy3eAAA",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 400",
                        "price": 2318,
                        "id": "01t5p00000CLutmAAD",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 408 Liter (4.3 m² Registeroberfläche)\r\nMasse mit/ohne Isolierung: ⌀xH: 75 x 150 cm (179 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 400 - Bei Heizungsmacher alle Produktdetails erhalten und eine kompetente und persönliche Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXbyAAE",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 500",
                        "price": 2977,
                        "id": "01t5p00000D1R1IAAV",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 498 Liter (5.4 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 180 cm (217 kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p00001DLYPqAAP",
                            "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014wXZ9AAM",
                                "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 600",
                        "price": 3069,
                        "id": "01tVj000005aQ9BIAU",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. 60 mm PUR-Hartschaum fix geschäumt mit Skaimantel in Silber.\r\n\r\nVolumen: 559 Liter (5.4 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 75 x 200 cm (228kg) / Isolierung nicht abnehmbar",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000009AEWDIA4",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 800",
                        "price": 4898,
                        "id": "01tVj000003BxB8IAK",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. Mehrschicht-Isolierung aus 80 mm Hartschaum + 20 mm Faservlies. PS-Mantel und Hakenleiste, inklusive Rosetten und Abdeckhaube. Brandschutzklasse B2. Silber\r\n\r\nVolumen: 830 Liter (6.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 199 cm (291 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 500",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "069Vj000004q6LeIAI",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG (2)",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Trinkwarmwasserspeicher WP/E 1000",
                        "price": 5680,
                        "id": "01t5p00000CxBO2AAN",
                        "description": "Die Speicher werden aus hochwertigem Stahl EN 10025 gefertigt, der Wärmetauscher aus Stahlrohr. Korrosionsschutz innen, Zweischicht-\r\nEmaillierung nach DIN 4753. Mehrschicht-Isolierung aus 80 mm Hartschaum + 20 mm Faservlies. PS-Mantel und Hakenleiste, inklusive Rosetten und Abdeckhaube. Brandschutzklasse B2. Silber\r\n\r\nVolumen: 925 Liter (6.0 m² Registeroberfläche)\r\nMasse mit (ohne) Isolierung: ⌀xH: 99 (79) x 219 cm (308 kg)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj000009FVJIIA4",
                                "type": "pdf",
                                "size": 304721,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt hpa Trinkwasserspeicher WP_E - Heizungsmacher AG-3",
                                "modified": "2024-09-02T15:05:42.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GLgAAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "HPA",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "effizienz-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "HPA Trinkwarmwasserspeicher WP/E 1000 - Bei Heizungsmacher tiefgehende Produktdetails einsehen und sofort einen Expertenrat anfordern!",
                        "defaultImage": {
                            "id": "0695p000014wXZ9AAM",
                            "name": "Hauptbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001ALDEBAA5",
                                "name": "Offertbild hpa Trinkwarmwasserspeicher WP_E - Heizungsmacher AG (1)",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WSW196i.2-6",
                        "price": 11039,
                        "id": "01t5p00000CMH8DAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 2-5.85 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-6 - Hier bei Heizungsmacher detailierte Produktdetails finden und bei Bedarf eine gezielte Beratung erhalten!",
                        "defaultImage": {
                            "id": "0695p000014yujQAAQ",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-8",
                        "price": 11230,
                        "id": "01t5p00000CMH8yAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 2-7.61 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-8 - Bei Heizungsmacher finden Sie genaue Produktdetails und können sich ausführlich informieren und beraten lassen!",
                        "defaultImage": {
                            "id": "0695p000014yujRAAQ",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-12",
                        "price": 12467,
                        "id": "01t5p00000CMH9DAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 3-12 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-12 - Erfahren Sie hier bei Heizungsmacher mehr über das Produkt und erhalten Sie um eine professionelle Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yujzAAA",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-16",
                        "price": 12657,
                        "id": "01t5p00000CMH9VAAX",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x118cm\r\nHeizleistung: 4-15 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mw7sAAA",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:09.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuisAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw81AAA",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:00:45.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yuj1AAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mw8zAAA",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:10:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yujuAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-16 - Hier bei Heizungsmacher detaillierte Produktdetails ansehen und professionelle Beratung sichern!",
                        "defaultImage": {
                            "id": "0695p000014yulvAAA",
                            "name": "Offertenbild Buderus WSW - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yuirAAA",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe WSW196i.2-6 TP50",
                        "price": 12134,
                        "id": "01t5p00000CMH96AAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal. \r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 61x60x178cm\r\nHeizleistung: 2-5.85 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-6 TP50 - Bei Heizungsmacher mehr über die umfassenden Produktdetails erfahren und individuelle Beratung verlangen!",
                        "defaultImage": {
                            "id": "0695p000014yuitAAA",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yukOAAQ",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-8 TP50",
                        "price": 12322,
                        "id": "01t5p00000CMH9fAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 2-7.61 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-8 TP50 - Schauen Sie sich bei Heizungsmacher die Produktdetails an und erhalten Sie eine professionelle Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yul2AAA",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yul3AAA",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-12 TP50",
                        "price": 13451,
                        "id": "01t5p00000CMH9pAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 3-12 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-12 TP50 - Bei Heizungsmacher detaillierte Produktdetails einsehen und qualifizierte Beratung schnell erhalten!",
                        "defaultImage": {
                            "id": "0695p000014zvUsAAI",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe WSW196i.2-16 TP50",
                        "price": 13639,
                        "id": "01t5p00000CMH9vAAH",
                        "description": "Ihre Effizienz erreicht die Logatherm WSW196i durch den modulierenden Kompressor: Der Betrieb richtet sich nach dem tatsächlichen Wärmebedarf. Durch den integrierten Pufferspeicher bleibt der Platzbedarf im Keller minimal.\r\n\r\nDie Wärmepumpe ist serienmäßig mit der Systembedieneinheit Logamatic BC400 und einem Farbdisplay mit Touch-Screen ausgestattet und lässt sich dank integrierter Internet-Schnittstelle komfortabel per Internet und der App MyBuderus steuern.\r\n\r\nGrösse LxBxH: 60x60x178cm\r\nHeizleistung: 4-15 kW bei B0/W35°C.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000014mwAsAAI",
                                "type": "pdf",
                                "size": 2293250,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Bediensungsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:40:00.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulOAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwAtAAI",
                                "type": "pdf",
                                "size": 12940090,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Installationsanleitung Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:41.000Z",
                                "isHidden": true,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulPAAQ",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            },
                            {
                                "versionId": "0685p000014mwBFAAY",
                                "type": "pdf",
                                "size": 257898,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Flyer Buderus WSW - Heizungsmacher AG",
                                "modified": "2022-08-04T19:39:51.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014yulgAAA",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "sole-wasser-erdsonde"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Buderus Wärmepumpe WSW196i.2-16 TP50 - Entdecken Sie bei Heizungsmacher präzise Produktdetails und sichern Sie fundierte Beratung!",
                        "defaultImage": {
                            "id": "0695p000014yulHAAQ",
                            "name": "Offertenbild Buderus WSW Puffer - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014yulMAAQ",
                                "name": "Bild 1 Buderus WSW - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yulNAAQ",
                                "name": "Detail Regler",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Wärmepumpe Vitocal 250-A06",
                        "price": 9725,
                        "id": "01t5p00000DDEbZAAX",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 0.02 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C.\r\n\r\nTyp: \t250-A AWO-M-E-AC 251.A06\r\nLeistung bei A-7/W35: 5.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Wärmepumpe Vitocal 250-A8 - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001T830nAAB",
                            "name": "Hauptbild Viessmann Vitocal 250-A08 - Heizungsmacher AG (1)",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T820XAAR",
                                "name": "Offertenbild Viessmann 250-A klein - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A08",
                        "price": 10438,
                        "id": "01t5p00000Cy8UoAAJ",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 0.02 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C.\r\n\r\nTyp: \t250-A AWO-M-E-AC 251.A08\r\nLeistung bei A-7/W35: 6.5 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Wärmepumpe Vitocal 250-A8 - Bei Heizungsmacher detaillierte Produktdetails anschauen und umfassende, kompetente Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00001I3GGvAAN",
                            "name": "Hauptbild Viessmann Vitocal 250-A08 - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001MDACyAAP",
                                "name": "Offertenbild Viessmann 250-A klein - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A10",
                        "price": 12271,
                        "id": "01t5p00000CPr4LAAT",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 3 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C – und das bei einer Außentemperatur von bis zu –15 °C. \r\n\r\nTyp: AWO-E-AC-AF 251.A10\r\nLeistung bei A-7/W35: 9.7 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Viessmann Vitocal 250-A10 ➤ Viessmann Wärmepumpentechnik ✔️ Modernes Design ✔️ „Grünes“ Kältemittel R290 (Propan) ✔️",
                        "defaultImage": {
                            "id": "0695p00001I3G8XAAV",
                            "name": "Hauptbild Viessmann Vitocal 250-A - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001I3G8hAAF",
                                "name": "Offertbild Vitocal 250-A 2-fach",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Wärmepumpe Vitocal 250-A13",
                        "price": 12944,
                        "id": "01t5p00000CPr4IAAT",
                        "description": "Nicht nur technisch auf dem neuesten Stand, sondern auch mit modernem Design: Die Viessmann Wärmepumpentechnik fügt sich optisch in jede Umgebung ein.\r\n\r\nDas in der Vitocal 250-A verwendete „grüne“ Kältemittel R290 (Propan) gilt mit seinem besonders niedrigen GWP-Wert von 3 als umweltfreundlich.\r\n\r\nDie Luft/Wasser-Wärmepumpe in Monoblock-Bauweise erreicht hohe Vorlauftemperaturen von bis zu 70 °C – und das bei einer Außentemperatur von bis zu –15 °C. \r\n\r\nTyp: AWO-E-AC-AF 251.A13\r\nLeistung bei A-7/W35: 11.1 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Viessmann Vitocal 250-A13 ➤ Viessmann Wärmepumpentechnik ✔️ Modernes Design ✔️ „Grünes“ Kältemittel R290 (Propan ✔️",
                        "defaultImage": {
                            "id": "0695p00000zgeHKAAY",
                            "name": "Hauptbild Viessmann Vitocal 250-A - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000ppXzNAAU",
                                "name": "Offertbild Vitocal 250-A 2-fach",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF4AAI",
                                "name": "Viessmann Vitocal 250-A Bild 1 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeF9AAI",
                                "name": "Viessmann Vitocal 250-A Bild 2 - Heizungsmacher AG",
                                "alt": null
                            },
                            {
                                "id": "0695p00000zgeFAAAY",
                                "name": "Viessmann Vitocal 250-A Bild 3 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Viessmann Vitocal 250-A Hydraulikeinheit",
                        "price": 2909,
                        "id": "01t5p00000CPr4XAAT",
                        "description": "Mit neuer Hydro AutoControl Hydraulik zur Bereitstellung der Notwendigen\r\nAbtauenergie und Sicherstellung der erforderlichen Mindest-Umlaufmenge im Wärmepumpen Kreis\r\n- Mit eingebauter Hocheffizienz-Umwälzpumpe für den Heizkreis\r\n- Abtaupuffer 16 l\r\n- Bypassfunktion\r\n- Heizwasser Durchlauferhitzer\r\n- 4-3-Wegeventil\r\n- Volumenstromsensor\r\n\r\nBedienung über 7-Zoll-Farb-Touchdisplay mit Klartext- und Grafikanzeige,\r\nInbetriebnahme-Assistenten, Anzeigen für Energieverbräuche sowie\r\nalternativ Bedienung über mobiles Endgerät, Internetfähig durch integrierte\r\nWLAN-Schnittstelle für Bedienung und Service über Viessmann Apps.\r\n\r\nFür 1 Heizkreis.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0K2EAAV",
                                "type": "pdf",
                                "size": 233279,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Viessmann Vitocal 250A - Heizungsmacher AG",
                                "modified": "2023-06-06T05:52:03.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00001I3GN8AAN",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T822xAAB",
                            "name": "Hauptbild Viessmann Vitocal 250-A Hydraulikeinheit - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00000ppYNyAAM",
                                "name": "Offertbild Vitocal 250-A Hydrailikeinheit 1HK",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T820YAAR",
                                "name": "Hauptbild Viessmann Vitocal 250-A Inneneinheit - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Kompaktspeicher Vitocal 252-A",
                        "price": 4802,
                        "id": "01t5p00000CPr4iAAD",
                        "description": "Die bodenstehende Kompakt-Einheit ist die intelligente Kombination aus effizienter Wärmepumpentechnik und hohem Warmwasserkomfort – dank integriertem 190-Liter-Warmwasserspeicher in einem Gehäuse. \r\n\r\nMit neuer Hydro AutoControl Hydraulik zur Bereitstellung der Notwendigen\r\nAbtauenergie und Sicherstellung der erforderlichen Mindest-Umlaufmenge im Wärmepumpen Kreis\r\n- Mit eingebauter Hocheffizienz-Umwälzpumpe für den Heizkreis\r\n- Abtaupuffer 16 l\r\n- Bypassfunktion\r\n- Heizwasser Durchlauferhitzer\r\n- 4-3-Wegeventil\r\n- Volumenstromsensor\r\n\r\nBedienung über 7-Zoll-Farb-Touchdisplay mit Klartext- und Grafikanzeige,\r\nInbetriebnahme-Assistenten, Anzeigen für Energieverbräuche sowie\r\nalternativ Bedienung über mobiles Endgerät, Internetfähig durch integrierte\r\nWLAN-Schnittstelle für Bedienung und Service über Viessmann Apps.\r\n\r\nFür 1 Heizkreis.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001N0JwQAAV",
                                "type": "pdf",
                                "size": 260604,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Viessmann Vitocal 252A - Heizungsmacher AG",
                                "modified": "2023-06-06T05:41:15.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000014wXn1AAE",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Viessmann",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "Viessmann Kompaktspeicher Vitocal 252-A - Bei Heizungsmacher alle Produktdetails kennenlernen und zielgerichtete Beratung einholen!",
                        "defaultImage": {
                            "id": "0695p00000ppYXQAA2",
                            "name": "Offertbild Vitocal 250-A Kompakteinheit 1HK",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p000014ygjwAAA",
                                "name": "viessmann-waermepumpe-252A-2C_5",
                                "alt": null
                            },
                            {
                                "id": "0695p000014yh1zAAA",
                                "name": "Vitocal 252-A_Inneneinheit_Schnitt",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "SMA Hybrid Sunny Tripower 5.0 Smart Energy",
                        "price": 2212,
                        "id": "01t5p00000CRAkyAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 5.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 5 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8AAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 6.0 Smart Energy",
                        "price": 2548,
                        "id": "01t5p00000CRAkUAAX",
                        "description": "Der SMA Hybrid-Wechselrichter STP 6.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 6 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8FAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 8.0 Smart Energy",
                        "price": 2980,
                        "id": "01t5p00000CRAkjAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 8.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 8 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SMA Solar Technology AG",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8BAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "SMA Hybrid Sunny Tripower 10.0 Smart Energy",
                        "price": 3220,
                        "id": "01t5p00000CRAkkAAH",
                        "description": "Der SMA Hybrid-Wechselrichter STP 10.0 SE für HV-Batterien ist ein Hybrid-Wechselrichter, der sich intelligent mit dem SMA Energiemanagementsystem vernetzt. Durch das prognosebasierte Laden erhöhst du die Effizienz des Gesamtsystems und lädst die Batterie während der Mittagszeit.\r\n\r\n- Integrierte Umschalteinrichtung für Ersatzstrom\r\n- AC-Nennleistung: 10 kVA\r\n- MPP-Tracker: 2\r\n- Abmessungen: 598 x 500 x 173 mm (H x B x T)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000B7sRxIAJ",
                                "type": "pdf",
                                "size": 688551,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SMA Sunny Tripower Smart Energy 5-10",
                                "modified": "2024-10-29T15:40:26.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p00000wqtVVAAY",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001Q1a8PAAR",
                            "name": "Hauptbild SMA Sunny Tripower Smart Energy",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001Q1a7vAAB",
                                "name": "Bild 1 SMA Sunny Tripower Smart Energy",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "Home Hub Wechselrichter SE5K-RWB 48V",
                        "price": 2880,
                        "id": "01t5p00000CpanfAAB",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 5 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE5K-RWB 48V ➤ Kein MPP Tracker ✔️ Zwei Eingänge für SolarEdge Modul Optimierer ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmEDAA0",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Hub Wechselrichter SE8K-RWB 48V",
                        "price": 3192,
                        "id": "01t5p00000CpaoFAAR",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 8 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE8K-RWB 48V ➤ Dreiphasige Installationen mit Batteriespeicher 8 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmEIAA0",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Hub Wechselrichter SE10K-RWB 48V",
                        "price": 3360,
                        "id": "01t5p00000CpaoiAAB",
                        "description": "SolarEdge Wechselrichter haben keinen MPP Tracker sondern zwei Eingänge für die SolarEdge Modul Optimierer. Mit der Festspannungstechnologie arbeitet der SolarEdge Wechselrichter stets im optimalen Eingangsspannungsbereich, unabhängig von der Modul-und Stranganzahl. Über die Strangleitung werden die Leistungsdaten von jedem einzelnen PV-Moduloptimierer an den SolarEdge Wechselrichter übertragen und können über das Internet im SolarEdge Monitoring Portal visualisiert werden. \r\n\r\nIdeale Lösung für dreiphasige Installationen mit Batteriespeicher 10 kVA, Ersatzstromfähig mit Backup-Box.\r\n\r\nGarantie: 12 Jahre Herstellergarantie (auf den Wechselrichter, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000BKkIGIA1",
                                "type": "pdf",
                                "size": 792790,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Hub Wechselrichter SE5-10K RWB 48V - Heizungsmacher AG",
                                "modified": "2024-11-05T14:48:25.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013VjzpAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Batterieanschluss"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Hub Wechselrichter SE10K-RWB 48V ➤ Dreiphasige Installationen mit Batteriespeicher 10 kVA ✔️ Ersatzstromfähig mit Backup-Box ✔️",
                        "defaultImage": {
                            "id": "0695p000013VmE8AAK",
                            "name": "Hauptbild SolarEdge Home Hub Wechselrichter  - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Home Batterie 4.6 kWh",
                        "price": 3081,
                        "id": "01t5p00000CpapUAAR",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 4.6 kWh\r\nAnzahl Module: 1 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 4.6 kWh ➤ Optimierte Speicherlösung ✔️ dreiphasig mit optimalem Ersatzstrom✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013Vk5TAAS",
                            "name": "Hauptbild SolarEdge Home Battery 4-6kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 9.2 kWh",
                        "price": 5842,
                        "id": "01t5p00000CpapnAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 9.2 kWh\r\nAnzahl Module: 2 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 9.2 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit Ersatzstrom ✔️ Skalierbar von 4.6 – 23kWh ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk5EAAS",
                            "name": "Hauptbild SolarEdge Home Battery 9-2kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 13.8 kWh",
                        "price": 8603,
                        "id": "01t5p00000CpaplAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 13.8 kWh\r\nAnzahl Module: 3 Stk.\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 13.8 kWh ➤ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️ Hier entlang!",
                        "defaultImage": {
                            "id": "0695p000013Vk3tAAC",
                            "name": "Hauptbild SolarEdge Home Battery 13-8kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 18.4 kWh",
                        "price": 11535,
                        "id": "01t5p00000CpapWAAR",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 18.4 kWh\r\nAnzahl Module: 4 Stk. (2+2)\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 18.4 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk3sAAC",
                            "name": "Hauptbild SolarEdge Home Battery 18-4kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    },
                    {
                        "title": "Home Batterie 23.0 kWh",
                        "price": 14265,
                        "id": "01t5p00000CpaoaAAB",
                        "description": "Optimierte Speicherlösung für den SolarEdge Home Hub Wechselrichter - dreiphasig mit optinalem Ersatzstrom. Intelligentes Energiespeichersystem, skalierbar von 4.6 – 23kWh. Inkl. Bodenständer und Kabelsatz.\r\n\r\nKapazität: 23.0 kWh\r\nAnzahl Module: 5 Stk. (3+2)\r\nGewicht pro Batteriemodul 54,7 kg\r\nZellchemie: Lithium-Eisenphosphat\r\nDimensionen (B x H x T): 540 x 500 x 240 mm pro Batteriemodul\r\n\r\nGarantie: 10 Jahre Herstellergarantie (auf die Batterie, zzgl. Montage)",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p00001AsmeMAAR",
                                "type": "pdf",
                                "size": 142753,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Battery 48V - Heizungsmacher AG",
                                "modified": "2022-11-29T12:20:23.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk0xAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Batteriespeicher"
                            },
                            {
                                "value": "Niedervolt"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": "SolarEdge Home Batterie 23.0 kWh ➤ Optimierte Speicherlösung ✔️ Dreiphasig mit optimalem Ersatzstrom ✔️ Intelligentes Energiespeichersystem ✔️",
                        "defaultImage": {
                            "id": "0695p000013Vk3rAAC",
                            "name": "Hauptbild SolarEdge Home Battery 23kWh 48V - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Home Backup Interface (Ersatzstrom)",
                        "price": 1314,
                        "id": "01t5p00000CpaqUAAR",
                        "description": "Mit dem Home Backup-Interface bietet SolarEdge eine Ersatzstromlösung mit automatischer Umschaltung sollte das Stromnetz einmal ausfallen.\r\n- automatische Umschaltung < 10 Sekunden\r\n- Ersatzstrom für das ganze Haus (WP nicht empfohlen)\r\n- Schwarzstartfähig\r\n- Nur in Kombination mit Batterien möglich\r\nInkl. Verkabelung 5x6/10.0mm2 3LNPE bis 6m.",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "0685p000013aGtJAAU",
                                "type": "pdf",
                                "size": 216111,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt SolarEdge Home Backup-Interface - Heizungsmacher AG",
                                "modified": "2022-06-06T08:41:14.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "0695p000013Vk6vAAC",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "SolarEdge",
                        "inCategories": [
                            {
                                "value": "Wechselrichter"
                            },
                            {
                                "value": "Ersatzstrom"
                            }
                        ],
                        "seoTitle": "SolarEdge Home Backup Interface",
                        "seoKeyword": "SolarEdge Home Backup Interface",
                        "seoDescription": "SolarEdge Home Backup Interface ➤ Automatische Umschaltung ✔️ Schwarzstartfähig ✔️ Dreiphasige Ersatzstromlösung ✔️ Hier informieren!",
                        "defaultImage": {
                            "id": "0695p000013Vk7ZAAS",
                            "name": "Hauptbild SolarEdge Home Backup Interface - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": []
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": [
                    {
                        "title": "WLW186i-5 AR E",
                        "price": 8169,
                        "id": "01t5p00000D3tn2AAB",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 110 x 54 x 80 cm\r\nLeistung bei A-7/W35: 5.4 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBimAAAT",
                            "name": "Offertenbild Buderus WSW 186i AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-7 AR E",
                        "price": 8693,
                        "id": "01t5p00000D3tmiAAB",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 110 x 54 x 80 cm\r\nLeistung bei A-7/W35: 6.7 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBilvAAD",
                            "name": "Offertenbild Buderus WSW 186i AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-10 AR E",
                        "price": 10813,
                        "id": "01t5p00000DDEO5AAP",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 135 x 54 x 105 cm\r\nLeistung bei A-7/W35: 9.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T80VvAAJ",
                            "name": "Offertenbild Buderus WSW 186i 10-12 AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "WLW186i-12 AR E",
                        "price": 11523,
                        "id": "01t5p00000DDET2AAP",
                        "description": "Die Logatherm WLW186i AR im Titanium Design sorgt für umwelt- und ressourcenschonendes Heizen. Sie ist durch die hohe Vorlauftemperatur speziell für die Modernisierung von Einfamilienhäusern geeignet.\r\n- Zukunftsfähig durch neues und natürliches Kältemittel R290 (Propan)\r\n- Hoher Komfort durch Inverter\r\n- Modernes Regelungssystem mit MyBuderus App\r\n\r\nMasse B x T x H: 135 x 54 x 105 cm\r\nLeistung bei A-7/W35: 11.6 kW",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [
                            {
                                "versionId": "068Vj00000Ae6VaIAJ",
                                "type": "pdf",
                                "size": 1104682,
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Datenblatt Buderus WLW186i - Heizungsmacher AG",
                                "modified": "2024-10-14T14:18:19.000Z",
                                "isHidden": false,
                                "isAttachedToMetaproduct": true,
                                "documentId": "069Vj00000ATwu9IAD",
                                "descriptionFr": null,
                                "descriptionEn": null,
                                "descriptionDe": null,
                                "description": null
                            }
                        ],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "luft-wasser-aussenaufstellung"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001T80xwAAB",
                            "name": "Offertenbild Buderus WSW 186i 10-12 AR - Heizungsmacher AG",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001T7zw0AAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xyAAB",
                                "name": "Buderus WLW 186i AR Ansicht aussen 2",
                                "alt": null
                            },
                            {
                                "id": "0695p00001T80xzAAB",
                                "name": "Buderus WLW 186i AR Offen",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": [
                    {
                        "title": "Inneneinheit wandhängend",
                        "price": 2519,
                        "id": "01t5p00000D3uQXAAZ",
                        "description": "Bei einer Sanierung eignet sich eine wandhängende Inneneinheit. Denn sie kann mit einem nebenstehenden Warmwasser- und Pufferspeicher flexibel kombiniert werden.\r\n- Eingebaute Hocheffizienz-Umwälzpumpe\r\n- Notbetrieb Durchlauferhitzer\r\n- 3-Weg-Umschaltventil für Heizung und Brauchwarmwasser\r\n- Touch-Systembedieneinheit \r\nLogamatic BC400 mit Funkmodul MX300\r\n\r\nMasse (L x B x T): 720 x 400 x 297 mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBkrXAAT",
                            "name": "Buderus Inneneinheit Wand offen",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001OBks0AAD",
                                "name": "Offertenbild Buderus WSW 186i AR Inneneinheit Wand - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    },
                    {
                        "title": "Inneneinheit mit 70 L Puffer",
                        "price": 4037,
                        "id": "01t5p00000D3uQYAAZ",
                        "description": "Die kompakte Variante mit integriertem 70 Liter Pufferspeicher benötigt wenig Platz im Haus und eignet sich mit einem geeigneten Heizverteilsystem als Vollintegrierte Lösung. In Kombination mit einem externen Warmwasserspeicher bietet die Inneneinheit höchsten Warmwasserkomfort. \r\n\r\n- Eingebaute Hocheffizienz-Umwälzpumpe\r\n- Notbetrieb Durchlauferhitzer\r\n- Umschalt-Wegeventil für Heizung und Brauchwarmwasser\r\n- Touch-Systembedieneinheit \r\nLogamatic BC400 mit Funkmodul MX300\r\n\r\nMasse (L x B x T): 1275 x 600 x 600 mm",
                        "attributes": null,
                        "isAvailableOnline": true,
                        "files": [],
                        "manufacturer": "Buderus",
                        "inCategories": [
                            {
                                "value": "waermepumpen"
                            },
                            {
                                "value": "kompakt-speicher"
                            }
                        ],
                        "seoTitle": null,
                        "seoKeyword": null,
                        "seoDescription": null,
                        "defaultImage": {
                            "id": "0695p00001OBkrWAAT",
                            "name": "Buderus Inneneinheit TP70 offen",
                            "alt": null
                        },
                        "additionalImages": [
                            {
                                "id": "0695p00001OBkrbAAD",
                                "name": "Offertenbild Buderus WSW 186i AR Inneneinheit TP70 - Heizungsmacher AG",
                                "alt": null
                            }
                        ]
                    }
                ]
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            },
            {
                "productVariants": []
            }
        ]
    },
    "configuration": {
        "productCategories": [
            {
                "nameFr": "HeizungenFr",
                "nameEn": "HeizungenEn",
                "nameDe": "HeizungenDe",
                "name": "Heizungen",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "waermepumpen",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "luft-wasser-aussenaufstellung",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "sole-wasser-erdsonde",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "effizienz-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "kombi-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "kompakt-speicher",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "luft-wasser-innenaufstellung",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "regler",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Speicher",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Regler-ALT",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Pumpen und Heizkreisgruppen",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Sonstiges",
                        "level": 1,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Erdsondenbohrung",
                        "level": 1,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": "SolarFr",
                "nameEn": "SolarEn",
                "nameDe": "SolarDe",
                "name": "Solar",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Standard",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Batterieanschluss",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Integrierte-Batterie",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Optimizer",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Ersatzstrom",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Batteriespeicher",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Niedervolt",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Hochvolt",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Photovoltaikmodul",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Standard-Module",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Hochleistungs-Module",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Made-in-Germany",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Monitoring",
                        "level": 1,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "waermepumpen",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "luft-wasser-aussenaufstellung",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "sole-wasser-erdsonde",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "effizienz-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "kombi-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "kompakt-speicher",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "luft-wasser-innenaufstellung",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "regler",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Speicher",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Regler-ALT",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Pumpen und Heizkreisgruppen",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Sonstiges",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Standard",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Batterieanschluss",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Integrierte-Batterie",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Optimizer",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Ersatzstrom",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Batteriespeicher",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Niedervolt",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Hochvolt",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Photovoltaikmodul",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Standard-Module",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Hochleistungs-Module",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Made-in-Germany",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Erdsondenbohrung",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Monitoring",
                "level": 1,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "luft-wasser-aussenaufstellung",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "sole-wasser-erdsonde",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "effizienz-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "kombi-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "kompakt-speicher",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Standard",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Batterieanschluss",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Integrierte-Batterie",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Standard-Module",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Hochleistungs-Module",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Made-in-Germany",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Niedervolt",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "luft-wasser-innenaufstellung",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "regler",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Optimizer",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Ersatzstrom",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Hochvolt",
                "level": 2,
                "childCategories": []
            }
        ],
        "manufacturers": [
            {
                "name": "alpha innotec",
                "id": "0010N00004FH7LzQAL"
            },
            {
                "name": "Stiebel Eltron",
                "id": "0010N00004FKSvnQAH"
            },
            {
                "name": "Viessmann",
                "id": "0010N00004Pn2D9QAJ"
            },
            {
                "name": "NIBE",
                "id": "0010N00004WO11tQAD"
            },
            {
                "name": "Kostal Solar Electric",
                "id": "0010N00004Z2W1CQAV"
            },
            {
                "name": "BYD",
                "id": "0010N00004aPbKlQAK"
            },
            {
                "name": "HPA",
                "id": "0010N00004aPx4MQAS"
            },
            {
                "name": "BOSCH",
                "id": "0010N00004acd3HQAQ"
            },
            {
                "name": "Belimo",
                "id": "0010N00004acgb9QAA"
            },
            {
                "name": "Grundfos",
                "id": "0010N00004addypQAA"
            },
            {
                "name": "PAW",
                "id": "0010N00004ademzQAA"
            },
            {
                "name": "SolarEdge",
                "id": "0010N00004bkWC8QAM"
            },
            {
                "name": "Wikora",
                "id": "0010N00004bml3WQAQ"
            },
            {
                "name": "AEH Oekotherm AG",
                "id": "0010N00004cdDKUQA2"
            },
            {
                "name": "Griesser AG",
                "id": "0010N00004eKCDiQAO"
            },
            {
                "name": "HakaGerodur AG",
                "id": "0010N00004eKCPeQAO"
            },
            {
                "name": "Buderus",
                "id": "0010N00004gGBuWQAW"
            },
            {
                "name": "R. Nussbaum AG",
                "id": "0010N00004hcWc5QAE"
            },
            {
                "name": "Alera energies AG",
                "id": "0010N00004koyf8QAA"
            },
            {
                "name": "NIBE AB (Sweden - nicht verwenden)",
                "id": "0010N00004kp2DKQAY"
            },
            {
                "name": "Paul Bauder AG",
                "id": "0010N00004p3o7FQAQ"
            },
            {
                "name": "Jinko Solar",
                "id": "0010N00004uFHCkQAO"
            },
            {
                "name": "LG Electronics",
                "id": "0010N00004uFHTYQA4"
            },
            {
                "name": "Sunpower",
                "id": "0010N00004uFInjQAG"
            },
            {
                "name": "SMA Solar Technology AG",
                "id": "0010N00004uFJC6QAO"
            },
            {
                "name": "E3DC",
                "id": "0010N00004uFMPaQAO"
            },
            {
                "name": "Trina Solar",
                "id": "0010N00004uFMfnQAG"
            },
            {
                "name": "K2 Systems",
                "id": "0010N000050Hmj5QAC"
            },
            {
                "name": "Depping AG",
                "id": "0010N00005G7tHoQAJ"
            },
            {
                "name": "CTC",
                "id": "0010N00005IvNUBQA3"
            },
            {
                "name": "LG Chem",
                "id": "0010N00005MykGKQAZ"
            },
            {
                "name": "A. Tschümperlin AG",
                "id": "0010N00005MywvqQAB"
            },
            {
                "name": "Novelan",
                "id": "0010N00005Phfq3QAB"
            },
            {
                "name": "Bucher Blechtechnik AG",
                "id": "0015p00005TtVtkAAF"
            },
            {
                "name": "Dani's Metalldesign GmbH",
                "id": "0015p00005UTfQFAA1"
            },
            {
                "name": "Meyer Burger",
                "id": "0015p00005aeeprAAA"
            },
            {
                "name": "Fronius",
                "id": "0015p00005b5gMdAAI"
            },
            {
                "name": "LAMBDA Wärmepumpen GmbH",
                "id": "0015p00005fuigMAAQ"
            },
            {
                "name": "JA Solar",
                "id": "0015p00005kPHpmAAG"
            },
            {
                "name": "Regli Energy",
                "id": "0015p00005pRYZlAAO"
            },
            {
                "name": "Wärmeaustausch Technologien AG WT",
                "id": "0015p00005qczPHAAY"
            },
            {
                "name": "BRUGG Rohrsystem AG",
                "id": "0015p00005vBc3qAAC"
            },
            {
                "name": "WS Weinmann & Schanz GmbH",
                "id": "0015p000061WrYWAA0"
            },
            {
                "name": "Oekoswiss Supply AG",
                "id": "0015p000066srNiAAI"
            },
            {
                "name": "Dimplex",
                "id": "0015p000068z1lqAAA"
            },
            {
                "name": "Sun2World Sustainable Energy Projects GmbH",
                "id": "0015p000068zR7fAAE"
            },
            {
                "name": "Debrunner Acifer AG",
                "id": "0015p000068zU7wAAE"
            },
            {
                "name": "Glaromat AG",
                "id": "0015p000068zfv8AAA"
            },
            {
                "name": "Forstner Speichertechnik GmbH",
                "id": "001Vj000004LDEdIAO"
            },
            {
                "name": "LONGi",
                "id": "001Vj000004U3J8IAK"
            }
        ],
        "bundleCategories": [
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Heizungen",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": "Wärmepumpen Fr",
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wärmepumpen",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Luft/Wasser",
                                "level": 2,
                                "childCategories": [
                                    {
                                        "nameFr": null,
                                        "nameEn": null,
                                        "nameDe": null,
                                        "name": "Innenaufgestellt",
                                        "level": 3,
                                        "childCategories": []
                                    },
                                    {
                                        "nameFr": null,
                                        "nameEn": null,
                                        "nameDe": null,
                                        "name": "Aussenaufgestellt",
                                        "level": 3,
                                        "childCategories": []
                                    }
                                ]
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Sole/Wasser (Erdsonde)",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Solar",
                "level": 0,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Photovoltaik",
                        "level": 1,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter mit Batterieanschluss (extern)",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter",
                                "level": 2,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Wechselrichter mit integrierter Batterie",
                                "level": 2,
                                "childCategories": []
                            }
                        ]
                    }
                ]
            },
            {
                "nameFr": "Wärmepumpen Fr",
                "nameEn": null,
                "nameDe": null,
                "name": "Wärmepumpen",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Luft/Wasser",
                        "level": 2,
                        "childCategories": [
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Innenaufgestellt",
                                "level": 3,
                                "childCategories": []
                            },
                            {
                                "nameFr": null,
                                "nameEn": null,
                                "nameDe": null,
                                "name": "Aussenaufgestellt",
                                "level": 3,
                                "childCategories": []
                            }
                        ]
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Sole/Wasser (Erdsonde)",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Photovoltaik",
                "level": 1,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter mit Batterieanschluss (extern)",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter",
                        "level": 2,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Wechselrichter mit integrierter Batterie",
                        "level": 2,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Luft/Wasser",
                "level": 2,
                "childCategories": [
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Innenaufgestellt",
                        "level": 3,
                        "childCategories": []
                    },
                    {
                        "nameFr": null,
                        "nameEn": null,
                        "nameDe": null,
                        "name": "Aussenaufgestellt",
                        "level": 3,
                        "childCategories": []
                    }
                ]
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Sole/Wasser (Erdsonde)",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter mit Batterieanschluss (extern)",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Wechselrichter mit integrierter Batterie",
                "level": 2,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Innenaufgestellt",
                "level": 3,
                "childCategories": []
            },
            {
                "nameFr": null,
                "nameEn": null,
                "nameDe": null,
                "name": "Aussenaufgestellt",
                "level": 3,
                "childCategories": []
            }
        ]
    }
}} location={props.location}
          />
<div
          className='bg__flex--grey'
          key='7tVlCKOU1i7oBX3S6D4iTK-4DT7nVgnk80H8EO2XXSSTr'
          id='auch-in-ihrer-region-unterwegs'
        >
          <Locations
            {...{
    "type": "locations",
    "id": "4DT7nVgnk80H8EO2XXSSTr",
    "updatedAt": "2024-02-20T15:44:28.230Z",
    "title": "Auch in Ihrer Region",
    "map": {
        "description": "Schweizerkarte mit eingezeichneten Solarmacherstandorten in Aarau, Basel, Bern, Schlieren, Solothurn, Sursee, Winterthur und Zürich",
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/3l41fQAuVA5kNolGtlTe8L/c00bd49ed665edb3a9fe4ba145d5ad4d/Standort-Karte_Solarmacher.svg"
    },
    "locations": [
        {
            "type": "location",
            "id": "3KWGFqrTw4cgOkS2uiCsgm",
            "updatedAt": "2019-02-25T13:13:26.295Z",
            "street": "Laurenzenvorstadt 25",
            "zipCode": 5000,
            "locality": "Aarau",
            "phoneNumber": "062 822 32 00"
        },
        {
            "type": "location",
            "id": "653NOXsIp2g2Ke0O0ogmUq",
            "updatedAt": "2024-03-15T09:23:10.398Z",
            "street": "Aeschenplatz 6",
            "zipCode": 4052,
            "locality": "Basel",
            "phoneNumber": "061 322 32 00"
        },
        {
            "type": "location",
            "id": "7Jo5SKBkZIGb1VKYH9sPDy",
            "updatedAt": "2019-02-28T12:33:16.833Z",
            "street": "Spitalgasse 28",
            "zipCode": 3011,
            "locality": "Bern",
            "phoneNumber": "031 305 32 00"
        },
        {
            "type": "location",
            "id": "1THwBEJT6tCR6os3RLVrmE",
            "updatedAt": "2023-07-04T13:54:31.100Z",
            "street": "Zürcherstrasse 39",
            "zipCode": 8952,
            "locality": "Schlieren",
            "phoneNumber": "044 312 32 00"
        },
        {
            "type": "location",
            "id": "5o0IW9QCE7S0VbeF7NemAt",
            "updatedAt": "2024-09-10T15:15:52.585Z",
            "street": "Grabackerstrasse 6",
            "zipCode": 4500,
            "locality": "Solothurn",
            "phoneNumber": "032 623 32 00"
        },
        {
            "type": "location",
            "id": "40zK6hpg1yiS4iSS48UyEA",
            "updatedAt": "2019-02-19T12:42:09.096Z",
            "street": "Wassergrabe 14",
            "zipCode": 6210,
            "locality": "Sursee",
            "phoneNumber": "041 360 32 00"
        },
        {
            "type": "location",
            "id": "13uDvMB8Huo81Wg1eD3VPg",
            "updatedAt": "2024-09-10T15:15:24.954Z",
            "street": "Technoparkstrasse 2",
            "zipCode": 8406,
            "locality": "Winterthur",
            "phoneNumber": "052 243 32 00"
        }
    ],
    "anchorName": "auch-in-ihrer-region-unterwegs",
    "text": ""
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='7tVlCKOU1i7oBX3S6D4iTK-6mO9AaUpchBQcL9yf9a9RT'
          id='wuenschen-sie-eine-persoenliche-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "6mO9AaUpchBQcL9yf9a9RT",
    "updatedAt": "2022-03-11T09:36:23.982Z",
    "title": "Wünschen Sie eine persönliche Beratung?",
    "anchorName": "wuenschen-sie-eine-persoenliche-beratung",
    "text": "<p>Sie interessieren sich für eine <strong>Wärmepumpe in Kombination mit einer Photovoltaikanlage</strong> und sind noch nicht sicher, ob dies die richtige Wahl ist? Lassen Sie sich heute noch von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\">Heiz-Experten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7uEHZy5iE9oCmB81zLnVLQ",
        "updatedAt": "2020-09-01T20:05:21.110Z",
        "label": "Beratung anfordern",
        "url": "https://www.heizungsmacher.ch/kontakt"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
